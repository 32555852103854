import React, { useEffect, useState, useReducer } from "react";
import { Segment, Header } from "semantic-ui-react";

import { Popup, ToolbarItem } from "devextreme-react/popup";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import "devextreme-react/text-area";
import { Item } from "devextreme-react/form";

import CountryForm from "../ecoforms/CountryForm";
import CountryPopup from "./CountryPopup";

//import { baseAPI } from "../../../api/APIEndpoints";

import DataGrid, {
  FilterPanel,
  FilterRow,
  SearchPanel,
  Button,
  Paging,
  Lookup,
  // Popup,
  Position,
  Editing,
  Export,
  Pager,
  Grouping,
  GroupPanel,
  RowDragging,
  Column,
  RequiredRule,
  // PatternRule,
  // EmailRule,
  // AsyncRule,
} from "devextreme-react/data-grid";
// import { useSelector } from "react-redux";
import CustomStore from "devextreme/data/custom_store";
import "whatwg-fetch";
import { store, useStore } from "../../../app/stores/store";

export const Country = () => {
  const [dataSource, setDataSource] = React.useState({});
  const [countryOID, setCountryOID] = React.useState("GRID");
  const [rowData, setRowData]: any = React.useState({});
  const { countryStore } = useStore();
  const { loading, setLoadingInitial, loadingInitial } = countryStore;
  const [mode, setMode] = React.useState<"GRID" | "FORM-EDIT">("GRID");
  const [onVisible, setOnVisible] = React.useState(false);

  const baseAPI = process.env.REACT_APP_API_URL; // "http://localhost:5000/api";

  const jwt = store.commonStore.token;
  function handleErrors(response: any) {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  }

  const customDataSource: any = new CustomStore({
    // key: "countryOID",
    load: () => {
      return fetch(`${baseAPI}/EcoCountry`, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      })
        .then(handleErrors)
        .then((response) => response.json())
        .then((response) => {
          return {
            data: response.result.data,
            totalCount: response.count,
            summary: response.summary,
            groupCount: response.count,
          };
        });
    },
    insert: (values: any) => {
      return fetch(`${baseAPI}/EcoCountry`, {
        method: "POST",
        body: JSON.stringify(values),
        headers: {
          Authorization: `Bearer ${jwt}`,
          "Content-Type": "application/json",
        },
      }).then(handleErrors);
    },
    remove: (key) => {
      return fetch(`${baseAPI}/EcoCountry`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${jwt}`,
          "Content-Type": "application/json",
        },
      }).then(handleErrors);
    },
    update: (values) => {
      return values;
    },
  });

  const handleEdit = (e: any) => {
    // const data = e.row.data;
    setRowData((data: any) => {
      return { ...e.row.result.data };
    });
    setMode("FORM-EDIT");
    // console.log(rowData);
  };

  const handleAddNew = () => {
    setOnVisible(true);
  };

  const handleView = (e: any) => {
    // const data = e.row.data;
    setCountryOID(e.row.data.countryOID);
    setLoadingInitial(true);
    // console.log(e.row.data);
    setMode("FORM-EDIT");
    // console.log(rowData);
  };

  function onToolbarPreparing(e: any) {
    let toolbarItems = e.toolbarOptions.items;

    // customize addRow toolbar button
    for (let i = 0; i < toolbarItems.length; i++) {
      let item = toolbarItems[i];
      if (item.name === "addRowButton") {
        item.options.onClick = handleAddNew;
        break;
      }
    }
  }

  return mode === "GRID" ? (
    <Segment>
      <h1>Country</h1>
      <div className="ui warning message">
        <div className="header">Manage Countries.</div>
        <p>Create and manage Countries.</p>
      </div>
      <div className="ui divider" title="List of Countries"></div>
      <div>
        <DataGrid
          id="grdResources"
          dataSource={customDataSource}
          hoverStateEnabled={true}
          columnAutoWidth={true}
          activeStateEnabled={true}
          columnResizingMode="widget"
          allowColumnResizing={true}
          onToolbarPreparing={onToolbarPreparing}
          columnHidingEnabled={true}>
          <RowDragging allowReordering={true} />
          <FilterPanel visible={true} />
          <SearchPanel visible={true} />
          <FilterRow visible={true} />
          <Paging defaultPageSize={10} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={[10, 20, 50, 100]}
            showInfo={true}
          />
          <Grouping contextMenuEnabled={true} />
          <GroupPanel visible={true} />
          <Editing
            mode="popup"
            useIcons={true}
            allowUpdating={true}
            allowDeleting={true}
            allowAdding={true}>
            {" "}
          </Editing>

          {/* <Popup
            title="Resources"
            showTitle={true}
            width={40}
            height={30}></Popup> */}

          <Export
            enabled={true}
            fileName="Country"
            allowExportSelectedData={true}
          />

          <Column type="buttons">
            <Button text="View" onClick={handleView} cssClass={"edit-btn"} />
          </Column>
          {/* <Column type="buttons" width={110}>
            <Button name="edit" />
            <Button name="delete" />
          </Column> */}
          <Column
            dataField="countryCode"
            // allowEditing={false}
            visible={false}></Column>
          <Column dataField="country">
            <RequiredRule />
          </Column>
          <Column dataField="dialCode">
            <RequiredRule />
          </Column>
        </DataGrid>
        {/* <Popup
          // title="Create"
          // showTitle={true}
          visible={onVisible}
          showCloseButton={false}
          width={700}
          minHeight={"auto"}
          height={600}>
          <CountryPopup
            setOnVisible={() => setOnVisible(false)}
            countryOID={0}
          />
        </Popup> */}
      </div>
    </Segment>
  ) : (
    <CountryForm setMode={() => setMode("GRID")} countryOID={countryOID} />
  );
};

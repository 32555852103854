import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Segment, Divider } from "semantic-ui-react";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import "devextreme-react/text-area";
import { Item } from "devextreme-react/form";
//import { baseAPI } from "../../../api/APIEndpoints";
import DataGrid, {
  FilterPanel,
  FilterRow,
  SearchPanel,
  Button,
  Paging,
  Lookup,
  Popup,
  Position,
  Editing,
  Export,
  Pager,
  Grouping,
  GroupPanel,
  RowDragging,
  Column,
  RequiredRule,
  PatternRule,
  EmailRule,
  AsyncRule,
} from "devextreme-react/data-grid";
// import { useSelector } from "react-redux";
import CustomStore from "devextreme/data/custom_store";
import "whatwg-fetch";
import { store, useStore } from "../../../app/stores/store";
// import AssignRoleForm from "../ecoforms/AssignRoleForm";
export const AssignRoleToUser = () => {
  const [onVisible, setOnVisible] = React.useState(false);
  const baseAPI = process.env.REACT_APP_API_URL; // "http://localhost:5000/api";

  const jwt = store.commonStore.token;
  function handleErrors(response: any) {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  }

  const customDataSource: any = new CustomStore({
    key: "roleOID",
    load: () => {
      return fetch(`${baseAPI}/EcoUserRoleTask`, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      })
        .then(handleErrors)
        .then((response) => response.json())
        .then((response) => {
          return {
            data: response.result,
            totalCount: response.count,
            summary: response.summary,
            groupCount: response.count,
          };
        });
    },
    insert: (values: any) => {
      return fetch(`${baseAPI}/EcoUserRoleTask`, {
        method: "POST",
        body: JSON.stringify(values),
        headers: {
          Authorization: `Bearer ${jwt}`,
          "Content-Type": "application/json",
        },
      }).then(handleErrors);
    },
    remove: (key: any) => {
      return fetch(`${baseAPI}/EcoUserRoleTask/${key}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }).then(handleErrors);
    },
    update: (values: any) => {
      return values;
    },
  });
  const onRowUpdating = (e: any) => {
    e.newData = Object.assign(e.oldData, e.newData);

    fetch(`${baseAPI}/EcoUserRoleTask`, {
      method: "PUT",
      body: JSON.stringify(e.newData),
      headers: {
        Authorization: `Bearer ${jwt}`,
        "Content-Type": "application/json",
      },
    }).then(customDataSource.load());
  };
  return (
    <Segment>
      <h1>Assign Role To User</h1>
      <div className="ui warning message">
        <div className="header">Manage Assign Role To User.</div>
        <p>Create and manage Assign Role To User.</p>
      </div>
      <div className="ui divider" title="List of Assign Role To User"></div>
      <div>
        {/* <AssignRoleForm setOnVisible={() => setOnVisible(false)} taskOID={0} /> */}
      </div>
    </Segment>
  );
};

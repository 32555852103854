import React, { useEffect, useState } from "react";
import { Segment, Form as FormBox } from "semantic-ui-react";
import axios from "axios";
import notify from "devextreme/ui/notify";
import "devextreme-react/autocomplete";
import Form, {
  ButtonItem,
  GroupItem,
  SimpleItem,
  Label,
  EmptyItem,
  EmailRule,
  PatternRule,
  RequiredRule,
} from "devextreme-react/form";
import { SelectBox } from "devextreme-react/select-box";
import { store, useStore } from "../../../app/stores/store";
import { LoadPanel } from "devextreme-react/load-panel";
import CustomToolbar from "./CustomToolbar";
import "./style.css";

const dateBoxOptions = {
  invalidDateMessage: "The date must have the following format: MM/dd/yyyy",
};
const data = [
  {
    ID: 1,
    Name: "YES",
  },
  {
    ID: 2,
    Name: "NO",
  },
];
const buttonOptions = {
  text: "Save",
  type: "success",
  useSubmitBehavior: true,
};
const colCountByScreen = {
  sm: 1,
  md: 2,
  lg: 3,
};
export const CompanyForm = ({ formMode, closePopup, fetchedData }: any) => {
  const formRef: any = React.useRef();
  const hiddenBtnRef: any = React.useRef();
  const [dataFetched, setDataFetched]: any = React.useState(false);
  const [states, setStates]: any = React.useState();
  const [country, setCountry]: any = React.useState(null);
  const [businessSector, setBusinessSector]: any = React.useState(null);
  // const [disable, setDisable] = React.useState(true);
  const [formValues, setFormValues]: any = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [, setDisabledForm] = React.useState(false);
  const [mode, setMode] = React.useState(false);
  const [shouldEdit, setShouldEdit] = React.useState(false);
  const baseAPI = process.env.REACT_APP_API_URL; // "http://localhost:5000/api";

  const jwt = store.commonStore.token;
  const url = `${baseAPI}/EcoCompany`;
  const privilege = {
    canEdit: 1,
    canCreate: 0,
    canDelete: 0,
    canPost: 0,
    canExport: 0,
  };
  const onFieldDataChanged = (e: any) => {
    console.log(e.component.option("formData"));
    // setCompanyInfoData(e.component.option("formData"));
  };

  const onLoad = () => {
    if (loading === true) {
    }
  };

  useEffect(() => {
    if (!fetchedData || !fetchedData.result) {
      setLoading(true);
      axios
        .get(`${url}`, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        })
        .then((res) => {
          const data = res.data;
          setCountry(data.referenceObjects.countries.selectData);
          setStates(data.referenceObjects.states.selectData);
          setBusinessSector(data.referenceObjects.businessSectors.selectData);
          setFormValues(data.result[0]);

          setLoading(false);
          setDataFetched(true);
        })
        .catch((error) => {
          notify(error.message, "error", 3000);
          console.log(error);

          setLoading(false);
        });
    } else {
      setFormValues(fetchedData.result[0]);
      setCountry(fetchedData.referenceObjects.countries.selectData);
      setStates(fetchedData.referenceObjects.states.selectData);
      setBusinessSector(
        fetchedData.referenceObjects.businessSectors.selectData
      );
      // console.log(res.data);
      setLoading(false);
      setDataFetched(true);
    }
  }, [jwt, url, fetchedData]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    console.log(e, formValues);

    const validationSummary =
      formRef.current.instance._$element[0].children[1].children;
    console.log(formRef.current.instance._$element[0].children[1].children);

    if (validationSummary.length > 0) {
      console.log(validationSummary[0]);
      return false;
    }

    setLoading(true);
    axios
      .put(`${url}`, formValues, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      })
      .then((res) => {
        setLoading(false);

        const companyName = res.data.result
          ? res.data.result[0].companyName
          : formValues.companyName;

        notify(
          {
            message: "Company Information updated successfully",
            position: {
              my: "center top",
              at: "center top",
            },
          },
          "success",
          3000
        );

        if (formMode === "POPUP") return closePopup(companyName);
      })
      .catch((error) => {
        setLoading(false);
        notify(
          {
            message: "An error occurred while updating company record",
            position: {
              my: "center top",
              at: "center top",
            },
          },
          "danger",
          3000
        );
      });
  };

  function screenByWidth(width: any) {
    if (formMode !== "POPUP") {
      return width > 1140 ? "lg" : width > 720 ? "md" : "sm";
    } else {
      return "sm";
    }
  }
  return (
    <Segment>
      <div>
        <LoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          position={{ of: "#user" }}
          // onHiding={hideLoadPanel}
          visible={loading}
          showIndicator={true}
          shading={true}
          showPane={true}
          // closeOnOutsideClick={closeOnOutsideClick}
        />
        {formMode !== "POPUP" && (
          <CustomToolbar
            formRef={formRef}
            btnRef={hiddenBtnRef}
            hideBackButton
            setMode={setMode}
            isEditMode={mode}
            setShouldEdit={setShouldEdit}
            setFormData={setFormValues}
            formData={dataFetched && formValues}
            formKey={true}
            setLoadPanelVisible={setLoading}
            url={`${url}`}
            // getOneUrl={"/EcoCompany"}
            // getAllUrl={"/EcoCompany"}
            deleteUrl={"/EcoCompany"}
            putUrl={"EcoCompany"}
            postUrl={"/EcoCompany"}
            privilege={privilege}
            setEngineDetailsUIOID={null}

            // uiType={null}
          />
        )}
        <FormBox onSubmit={handleSubmit}>
          <Form
            ref={formRef}
            formData={formValues}
            disabled={!shouldEdit}
            // disabled={formMode !== "POPUP" ? !shouldEdit : false}
            showColonAfterLabel={true}
            // onFieldDataChanged={onFieldDataChanged}
            showValidationSummary={true}
            validationGroup="companyData"
            colCountByScreen={colCountByScreen}
            screenByWidth={screenByWidth}
            labelLocation="top"
            minColWidth={233}
            colCount="auto">
            <GroupItem
              caption=" General Info"
              colSpan={3}
              cssClass={"group-caption"}>
              {" "}
            </GroupItem>
          </Form>
          <Form colCount={3} labelLocation="top">
            <SimpleItem
              dataField="companyOID"
              editorOptions={{ disabled: true }}>
              <RequiredRule message="Company OID is required" />
              <PatternRule message="" pattern={/[0-9]+$/} />
            </SimpleItem>
            <SimpleItem
              dataField="companyName"
              editorType="dxTextBox"
              editorOptions={{
                disabled: !shouldEdit,
              }}
              isRequired={true}>
              <RequiredRule message="Company Name is required" />
              {/* <PatternRule message="" pattern={/w+$/} /> */}
            </SimpleItem>
            <SimpleItem
              dataField="businessSector"
              editorType="dxSelectBox"
              editorOptions={{
                dataSource: businessSector && businessSector,
                searchEnabled: true,
                valueExpr: "value",
                displayExpr: "title",
                disabled: !shouldEdit,
                // defaultValue: "businessSector",
                // onValueChange: (e: any) => {
                //   console.log(e);
                // },
              }}></SimpleItem>
            <SimpleItem
              dataField="email"
              editorType="dxTextBox"
              isRequired={true}
              editorOptions={{
                disabled: !shouldEdit,
              }}>
              <RequiredRule message="Email is required" />
              <EmailRule message="Email is invalid" />
            </SimpleItem>
            <SimpleItem
              dataField="ownerName"
              editorType="dxTextBox"
              isRequired={true}
              editorOptions={{
                disabled: !shouldEdit,
              }}>
              <RequiredRule message="Owner name is required" />
            </SimpleItem>
            <SimpleItem
              dataField="message"
              editorOptions={{
                disabled: !shouldEdit,
              }}
              editorType="dxTextBox"
              isRequired={true}>
              {/* <RequiredRule message="Business City is required" /> */}
            </SimpleItem>

            <GroupItem caption="Address" colSpan={3} cssClass={"group-caption"}>
              {" "}
            </GroupItem>
            <SimpleItem
              dataField="address1"
              editorType="dxTextBox"
              isRequired={true}
              editorOptions={{
                disabled: !shouldEdit,
              }}>
              <RequiredRule message="Company address is required" />
            </SimpleItem>

            <SimpleItem
              dataField="address2"
              editorType="dxTextBox"
              isRequired={true}
              editorOptions={{
                disabled: !shouldEdit,
              }}></SimpleItem>

            <SimpleItem
              dataField="country"
              editorType="dxSelectBox"
              editorOptions={{
                dataSource: country && country,
                searchEnabled: true,
                valueExpr: "value",
                displayExpr: "title",
                disabled: !shouldEdit,
                // onValueChange: (e: any) => {
                //   console.log(e);
                // },
              }}>
              <RequiredRule message="Country is required" />
            </SimpleItem>

            <SimpleItem
              dataField="state"
              editorType="dxSelectBox"
              editorOptions={{
                defaultValue: states && states,
                dataSource: states && states,
                searchEnabled: true,
                valueExpr: "value",
                displayExpr: "title",
                disabled: !shouldEdit,
              }}>
              <RequiredRule message="Business State is required" />
            </SimpleItem>

            <SimpleItem
              dataField="city"
              editorType="dxTextBox"
              isRequired={true}
              editorOptions={{
                disabled: !shouldEdit,
              }}>
              <RequiredRule message="Business City is required" />
            </SimpleItem>

            <SimpleItem
              dataField="zip"
              editorOptions={{
                disabled: !shouldEdit,
              }}></SimpleItem>

            <SimpleItem
              dataField="website"
              editorOptions={{
                disabled: !shouldEdit,
              }}></SimpleItem>
            <EmptyItem colSpan={2} />
            {/* <EmptyItem /> */}
            <GroupItem
              caption="Transaction Info"
              colSpan={3}
              cssClass={"group-caption"}>
              {" "}
            </GroupItem>
            <SimpleItem
              dataField="acceptPOS"
              isRequired={true}
              editorOptions={{
                disabled: !shouldEdit,
              }}>
              <SelectBox
                dataSource={data}
                valueExpr="ID"
                defaultValue={
                  formValues ? (formValues.acceptPOS === "YES" ? 1 : 0) : ""
                }
                displayExpr="Name"
                disabled={!shouldEdit}
              />
              <RequiredRule message="acceptPOS is required" />
            </SimpleItem>

            <SimpleItem
              dataField="fedralTaxNumber"
              editorOptions={{
                disabled: !shouldEdit,
              }}>
              <RequiredRule message="federal Tax Number is required" />
            </SimpleItem>
            {/* <SimpleItem dataField="fedralTaxNumber">
            <RequiredRule message="Business City is required" />
          </SimpleItem> */}
            <SimpleItem
              dataField="fiscalPeriodStartDate"
              editorType="dxDateBox"
              // editorOptions={dateBoxOptions}
              isRequired={true}
              editorOptions={{
                disabled: !shouldEdit,
              }}>
              <Label text="fiscal Period Start Date" />
              <RequiredRule message="fiscal Period Start Date is required" />
            </SimpleItem>
            {formMode === "POPUP" && (
              <ButtonItem
                cssClass={"tf-form-btn"}
                ref={hiddenBtnRef}
                horizontalAlignment="left"
                buttonOptions={buttonOptions}
              />
            )}
            <ButtonItem
              cssClass={"hidden-btn tf-form-btn"}
              ref={hiddenBtnRef}
              horizontalAlignment="left"
              buttonOptions={buttonOptions}
            />
          </Form>
        </FormBox>
      </div>
    </Segment>
  );
};

export interface BusinessSectorModel {
  businessSectorOID: string;
  businessSectorCode: string;
  status: string;
  businessSectorID: string;
  description: string;
  dateUploaded: Date | null;

  // date: Date | null;
  // description: string;
  // category: string;
  // city: string;
  // venue: string;
  // hostUsername: string;
  // isCancelled: boolean;
  // isGoing: boolean;
  // isHost: boolean;
  // host?: Profile;
  // attendees: Profile[]
}

export class BusinessSectorModel implements BusinessSectorModel {
  constructor(init?: BusinessSectorFormValues) {
    Object.assign(this, init);
  }
}

export class BusinessSectorFormValues {
  businessSectorOID?: string = undefined;
  businessSectorCode: string = "";
  status: string = "";
  businessSectorID: string = "";
  description: string = "";

  // dateUploaded: Date  = Date.now();

  constructor(bussinessSector?: BusinessSectorFormValues) {
    if (bussinessSector) {
      this.businessSectorOID = bussinessSector.businessSectorOID;
      this.businessSectorCode = bussinessSector.businessSectorCode;
      this.status = bussinessSector.status;
      this.description = bussinessSector.description;
      this.businessSectorID = bussinessSector.businessSectorID;
    }
  }
}

import React from "react";
import { useField } from "formik";
import { Form, Label } from "semantic-ui-react";
import { DateBox } from "devextreme-react";

type Props = {
  name?: string;
  label?: string;
  displayFormat?: string;
  placeholder?: string;
};

export default function DXDateBox(props: Partial<Props>) {
  const [field, meta, helpers] = useField(props.name!);
  return (
    <Form.Field error={meta.touched && !!meta.error}>
      <label>{props.label}</label>

      <DateBox
        type="date"
        // displayFormat="mmmm-dd-yyyy"
        {...field}
        {...props}
        onChange={(e: any) =>
          helpers.setValue(new Date(e.event?.currentTarget?.value))
        }
        onValueChanged={(e) => helpers.setValue(e.value)}
        valueChangeEvent="keyup"
        // dateOutOfRangeMessage="Date is out of range"
        useMaskBehavior={true}
      />

      {meta.touched && meta.error ? (
        <Label basic color="red">
          {meta.error}
        </Label>
      ) : null}
    </Form.Field>
  );
}

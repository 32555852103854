import React, { useEffect } from "react";
import { Segment, Container, Divider } from "semantic-ui-react";

import Axios from "axios";

import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import "devextreme-react/text-area";
import { Item } from "devextreme-react/form";
//import { baseAPI } from "../../../api/APIEndpoints";
import DataGrid, {
  FilterPanel,
  FilterRow,
  SearchPanel,
  Button,
  Paging,
  Lookup,
  Popup,
  Position,
  Editing,
  Export,
  Pager,
  Grouping,
  GroupPanel,
  RowDragging,
  Column,
  RequiredRule,
  PatternRule,
  EmailRule,
  AsyncRule,
} from "devextreme-react/data-grid";
// import { useSelector } from "react-redux";
import CustomStore from "devextreme/data/custom_store";
import "whatwg-fetch";
import { store, useStore } from "../../../app/stores/store";

export const Task = () => {
  const DataSource = [
    {
      TaskOID: 2,
      ApplicationCode: 0,
      ModulerOrder: 3,
      ModuleDescription: "Ecosystem",
      ApplicationDescription: "",
      ApplicationOID: 8,
      TaskCode: "ApplicationTask",
      TaskDescription: "Task Defintion",

      UiDescription: "Task Definition",
    },
    {
      TaskOID: 3,
      userRoleTaskOID: 0,
      ApplicationDescription: "",
      ApplicationOID: 8,
      TaskCode: "Applications",
      TaskDescription: "Applications",
      TaskOrder: 22,
      UiDescription: "",
      ModuleDescription: "Ecosystem",
    },
    {
      TaskOID: 4,
      userRoleTaskOID: 0,
      ApplicationDescription: "",
      ApplicationOID: 8,
      TaskCode: "CompanyInfo",
      TaskDescription: "CompanyInfomation",
      TaskOrder: 1,
      UiDescription: "CompanyInfomation",
      ModuleDescription: "Ecosystem",
    },
    {
      TaskOID: 5,
      userRoleTaskOID: 0,
      ApplicationDescription: "",
      ApplicationOID: 8,
      TaskCode: "Role Definition",
      TaskDescription: "Role Definition",
      TaskOrder: 1,
      UiDescription: "Role Definition",
      ModuleDescription: "Ecosystem",
    },
  ];
  const [dataSource, setDataSource] = React.useState({});
  const [taskOID, setTaskOID] = React.useState("GRID");
  const [rowData, setRowData]: any = React.useState({});
  const { taskStore } = useStore();
  const { loading, setLoadingInitial, loadingInitial } = taskStore;
  const [mode, setMode] = React.useState("GRID");
  const [onVisible, setOnVisible] = React.useState(false);

  const baseAPI = process.env.REACT_APP_API_URL; // "http://localhost:5000/api";

  const jwt = store.commonStore.token;

  function handleErrors(response: any) {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  }

  const customDataSource: any = new CustomStore({
    key: "taskOID",
    load: () => {
      return fetch(`${baseAPI}/EcoTask`, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      })
        .then(handleErrors)
        .then((response) => response.json())
        .then((response) => {
          return {
            data: response.result,
            totalCount: response.count,
            summary: response.summary,
            groupCount: response.count,
          };
        });
    },
    insert: (values: any) => {
      return fetch(`${baseAPI}/EcoTask`, {
        method: "POST",
        body: JSON.stringify(values),
        headers: {
          Authorization: `Bearer ${jwt}`,
          "Content-Type": "application/json",
        },
      }).then(handleErrors);
    },
    remove: (key: any) => {
      return fetch(`${baseAPI}/EcoTask/${key}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }).then(handleErrors);
    },
    update: (values: any) => {
      return values;
    },
  });
  const onRowUpdating = (e: any) => {
    e.newData = Object.assign(e.oldData, e.newData);

    fetch(`${baseAPI}/EcoTask`, {
      method: "PUT",
      body: JSON.stringify(e.newData),
      headers: {
        Authorization: `Bearer ${jwt}`,
        "Content-Type": "application/json",
      },
    }).then(customDataSource.load());
  };
  const handleView = (e: any) => {
    // const data = e.row.data;
    setTaskOID(e.row.data.countryOID);
    setLoadingInitial(true);
    // console.log(e.row.data);
    setMode("FORM-EDIT");
    // console.log(rowData);
  };

  return (
    <Segment>
      <h1>Task</h1>
      <div className="ui warning message">
        <div className="header">Manage Tasks.</div>
        <p>Create and manage Tasks.</p>
      </div>
      <div className="ui divider" title="List of Tasks"></div>
      <div>
        <DataGrid
          id="[$GridID]"
          // dataSource={customDataSource}
          dataSource={DataSource}
          className="dx-datagrid dx-row-alt"
          hoverStateEnabled={true}
          columnAutoWidth={true}
          activeStateEnabled={true}
          columnResizingMode="widget"
          allowColumnResizing={true}
          showBorders={true}
          onRowUpdating={onRowUpdating}
          columnHidingEnabled={true}>
          <RowDragging allowReordering={true} />
          <FilterPanel visible={true} />
          <SearchPanel visible={true} />
          <FilterRow visible={true} />
          <Paging defaultPageSize={20} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={[2, 10, 20, 50, 100]}
            showInfo={true}
          />
          <Grouping contextMenuEnabled={true} />
          <GroupPanel visible={true} />
          <Editing
            mode="popup"
            useIcons={true}
            allowUpdating={true}
            allowDeleting={true}
            allowAdding={true}
          />
          {/* <Scrolling mode="standard" /> */}

          <Export
            enabled={true}
            fileName="Email List Messages"
            allowExportSelectedData={true}
          />
          <Column type="buttons">
            <Button text="View" onClick={handleView} cssClass={"edit-btn"} />
          </Column>

          <Column dataField=" TaskOID" allowEditing={false}></Column>

          <Column dataField="ApplicationCode">
            <RequiredRule />
          </Column>
          <Column dataField="ModulerOrder" dataType="number"></Column>
          <Column dataField="ModuleDescription">
            <RequiredRule />
          </Column>
          <Column dataField="TaskCode" dataType="number"></Column>
          <Column dataField="TaskDescription">
            <RequiredRule />
          </Column>
          <Column dataField="Taskorder" dataType="number">
            <RequiredRule message="Status is required and it must be a number" />
          </Column>
          <Column dataField="UIDescription" dataType="number"></Column>
          <Column dataField="ModulerDescription" dataType="number"></Column>
        </DataGrid>
      </div>
    </Segment>
  );
};

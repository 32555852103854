import React from "react";
import { Route, Routes } from "react-router-dom";
import { Application } from "../../features/ecosystem/administration/Application";
import { AssignRoleToUser } from "../../features/ecosystem/administration/AssignRoleToUser";
import { AssignTaskToRoles } from "../../features/ecosystem/administration/AssignTaskToRoles";
import { CompanyInformation } from "../../features/ecosystem/administration/CompanyInformation";
import { RoleDefinition } from "../../features/ecosystem/administration/RoleDefinition";
import { Subscription } from "../../features/ecosystem/administration/Subscription";
import { SystemCode } from "../../features/ecosystem/administration/SystemCode";
import { SystemSetting } from "../../features/ecosystem/administration/SystemSetting";
import { Task } from "../../features/ecosystem/administration/Task";
import { UserDefinition } from "../../features/ecosystem/administration/UserDefinition";
import { Bank } from "../../features/ecosystem/masterdata/Bank";
import { BusinessArea } from "../../features/ecosystem/masterdata/BusinessArea";
import { BusinessSector } from "../../features/ecosystem/masterdata/BusinessSector";
import { Country } from "../../features/ecosystem/masterdata/Country";
import { Feedback } from "../../features/ecosystem/masterdata/Feedback";
import { LGA } from "../../features/ecosystem/masterdata/LGA";
import { State } from "../../features/ecosystem/masterdata/State";
import { Messaging } from "../../features/ecosystem/Messaging";

export default function EcosystemRoutes() {
  return (
    <Routes>
      <Route path={`masterdata-bank`} element={<Bank />} />
      <Route path={`masterdata-businessarea`} element={<BusinessArea />} />
      <Route path={`masterdata-businesssector`} element={<BusinessSector />} />
      <Route path={`masterdata-country`} 
      element={<Country />}
       />
      <Route path={`masterdata-feedback`} element={<Feedback />} />
      <Route path={`masterdata-lga`} element={<LGA />} />
      <Route path={`masterdata-state`} element={<State />} />
      <Route path={`messaging`} element={<Messaging />} />
      <Route
        path={`administration-companyinformation`}
        element={<CompanyInformation />}
      />
      <Route
        path={`administration-systemsetting`}
        element={<SystemSetting />}
      />
      <Route
        path={`administration-roledefinition`}
        element={<RoleDefinition />}
      />
      <Route path={`administration-task`} element={<Task />} />
      <Route
        path={`administration-userdefinition`}
        element={<UserDefinition />}
      />
      <Route path={`administration-systemcode`} element={<SystemCode />} />
      <Route path={`application`} element={<Application />} />
      <Route path={`administration-subscription`} element={<Subscription />} />
      <Route
        path={`administration-assignroletouser`}
        element={<AssignRoleToUser />}
      />
      <Route
        path={`administration-assigntasktorole`}
        element={<AssignTaskToRoles />}
      />
    </Routes>
  );
}

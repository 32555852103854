import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Form, Header, Segment } from "semantic-ui-react";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { useStore } from "../../../app/stores/store";
import { v4 as uuid } from "uuid";
// import { Formik, Form } from 'formik';
import { Form as FForm, Formik } from "formik";
import * as Yup from "yup";
// import MyTextInput from "../../../app/common/form/MyTextInput";
// import MyTextArea from "../../../app/common/form/MyTextArea";
// import MySelectInput from "../../../app/common/form/MySelectInput";
// import { categoryOptions } from "../../../app/common/options/categoryOptions";
// import MyDateInput from "../../../app/common/form/MyDateInput";
import { ActivityFormValues } from "../../../app/models/activity";
import { optionsActivityType } from "../../../app/common/staticData";
import CADTextInput from "../../../app/common/form/CADTextInput";
import CADTextArea from "../../../app/common/form/CADTextArea";
import CADDateInput from "../../../app/common/form/CADDateInput";
import CADSelectInput from "../../../app/common/form/CADSelectInput";

export default observer(function ActivityForm() {
  const navigate = useNavigate();
  const { activityStore } = useStore();
  const { createActivity, updateActivity, loadActivity, loadingInitial } =
    activityStore;
  const { id } = useParams<{ id: string }>();

  const [activity, setActivity] = useState<ActivityFormValues>(
    new ActivityFormValues()
  );

  const validationSchema = Yup.object({
    title: Yup.string().required("The activity title is required"),
    description: Yup.string().required("The activity description is required"),
    // category: Yup.string().required(),
    date: Yup.string().required("Date is required").nullable(),
    venue: Yup.string().required(),
    city: Yup.string().required(),
    activityType: Yup.string().required(),
  });

  useEffect(() => {
    if (id)
      loadActivity(id).then((activity) =>
        setActivity(new ActivityFormValues(activity))
      );
  }, [id, loadActivity]);

  function handleFormSubmit(activity: ActivityFormValues) {
    if (!activity.id) {
      let newActivity = {
        ...activity,
        id: uuid(),
        category: activity.activityType,
      };
      createActivity(newActivity).then(() =>
        navigate(`/activities/${newActivity.id}`)
      );
    } else {
      let updatedActivity = {
        ...activity,
        id: uuid(),
        category: activity.activityType,
      };
      updateActivity(updatedActivity).then(() =>
        navigate(`/activities/${activity.id}`)
      );
    }
  }
  // function handleFormSubmit(activity: ActivityFormValues) {
  //     if (!activity.id) {
  //         let newActivity = {
  //             ...activity,
  //             id: uuid()
  //         };
  //         createActivity(newActivity).then(() => history.push(`/activities/${newActivity.id}`))
  //     } else {
  //         updateActivity(activity).then(() => history.push(`/activities/${activity.id}`))
  //     }
  // }

  if (loadingInitial) return <LoadingComponent content='Loading activity...' />;

  return (
    <Segment clearing>
      {/* <Header content="Activity Details" sub color="brown" /> */}
      <Header as='h3' dividing color='brown'>
        ACTIVITY DETAILS
      </Header>
      <Formik
        validationSchema={validationSchema}
        enableReinitialize
        initialValues={activity}
        onSubmit={(values) => handleFormSubmit(values)}>
        {({ handleSubmit, isValid, isSubmitting, dirty }) => (
          <FForm className='ui form' onSubmit={handleSubmit} autoComplete='off'>
            <Form.Group widths='equal'>
              <CADTextInput
                name='title'
                placeholder='Enter the title for the event.'
                label='Event Title'
              />
            </Form.Group>
            <Form.Group widths='equal'>
              {" "}
              <CADTextArea
                rows={3}
                label='Activity Description'
                placeholder='Describe the event.'
                name='description'
              />
            </Form.Group>
            <Form.Group widths='equal'>
              {/* <CADSelectInput
                  // options={categoryOptions}
                  options={optionsActivityType}
                  placeholder="Select the type of activity."
                  name="category"
                  label="Activity Type"
                /> */}
              <CADSelectInput
                options={optionsActivityType}
                label='Select the type of Activity.'
                placeholder='Activity Type'
                name='activityType'
              />
              <CADDateInput
                placeholderText='Date of the Event'
                name='date'
                showTimeSelect
                timeCaption='time'
                dateFormat='MMMM d, yyyy h:mm aa'
              />
            </Form.Group>
            <Header as='h3' dividing color='brown'>
              LOCATION
            </Header>

            <Form.Group widths='equal'>
              {" "}
              <CADTextInput placeholder='City' name='city' />
              <CADTextInput placeholder='Venue' name='venue' />
            </Form.Group>

            <Button
              disabled={isSubmitting || !dirty || !isValid}
              loading={isSubmitting}
              floated='right'
              positive
              type='submit'
              content='Submit'
            />
            <Button
              as={Link}
              to='/activities'
              floated='right'
              type='button'
              content='Cancel'
            />
          </FForm>
        )}
      </Formik>
    </Segment>
  );
});

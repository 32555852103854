// export type GetCommodityByID = {
//   count: number;
//   result: [CosterCommodity];
// };

export interface CosterCommodity {
  offtakerPriceOID?: number;
  commodityDescription: string;
  unitPrice: number;
  unitOfMeasure: string;
}

export class CosterCommodity implements CosterCommodity {
  constructor(init?: CosterCommoditysFormValues) {
    Object.assign(this, init);
  }
}

export class CosterCommoditysFormValues {
  offtakerPriceOID?: number = 0;
  commodityDescription = "";
  unitPrice = 0;
  unitOfMeasure = "";

  constructor(values?: CosterCommoditysFormValues) {
    if (values) {
      this.offtakerPriceOID = values.offtakerPriceOID;
      this.commodityDescription = values.commodityDescription;
      this.unitPrice = values.unitPrice;
      this.unitOfMeasure = values.unitOfMeasure;
    }
  }
}

import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../api/agent";
import {
  CosterExpense,
  CosterExpenseFormValues,
} from "../models/CosterExpense";
// import { format } from "date-fns";
// import { store } from "./store";
// import { Profile } from "../models/profile";
import { Pagination, PagingParams } from "../models/pagination";
// import agent from "../api/agent"

export default class ToolBLinkCosterExpenseStore {
  costerExpenseData: CosterExpense[] = [];
  costerExpenseRegistry = new Map<number, CosterExpense>();
  selectedCosterExpense: CosterExpense | undefined = undefined;
  editMode = false;
  loading = false;
  loadingInitial = false;
  pagination: Pagination | null = null;
  pagingParams = new PagingParams();
  predicate = new Map().set("all", true);

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.predicate.keys(),
      () => {
        this.pagingParams = new PagingParams();
        this.costerExpenseRegistry.clear();
        this.loadCosterExpenses();
      }
    );
  }

  setPagingParams = (pagingParams: PagingParams) => {
    this.pagingParams = pagingParams;
  };

  // setPredicate = (predicate: string, value: string | Date) => {
  //   const resetPredicate = () => {
  //     this.predicate.forEach((value, key) => {
  //       if (key !== "startDate") this.predicate.delete(key);
  //     });
  //   };
  //   switch (predicate) {
  //     case "all":
  //       resetPredicate();
  //       this.predicate.set("all", true);
  //       break;
  //     case "isGoing":
  //       resetPredicate();
  //       this.predicate.set("isGoing", true);
  //       break;
  //     case "isHost":
  //       resetPredicate();
  //       this.predicate.set("isHost", true);
  //       break;
  //     case "startDate":
  //       this.predicate.delete("startDate");
  //       this.predicate.set("startDate", value);
  //   }
  // };

  get axiosParams() {
    const params = new URLSearchParams();
    params.append("pageNumber", this.pagingParams.pageNumber.toString());
    params.append("pageSize", this.pagingParams.pageSize.toString());
    this.predicate.forEach((value, key) => {
      if (key === "startDate") {
        params.append(key, (value as Date).toISOString());
      } else {
        params.append(key, value);
      }
    });
    return params;
  }

  // get ByDate() {
  //   return Array.from(this.costerExpenseRegistry.values()).sort(
  //     (a, b) => a.date!.getTime() - b.date!.getTime()
  //   );
  // }

  // get groupedToolBLinkCosterExpenses() {
  //   return Object.entries(
  //     this.ByDate.reduce((, toolblinkcosterexpense) => {
  //       const date = format(toolblinkcosterexpense.date!, "dd MMM yyyy");
  //       [date] = [date]
  //         ? [...[date], toolblinkcosterexpense]
  //         : [toolblinkcosterexpense];
  //       return ;
  //     }, {} as { [key: string]: ToolBLinkCosterExpense[] })
  //   );
  // }

  loadCosterExpenses = async () => {
    this.loadingInitial = true;
    try {
      const result = await agent.ToolBLinkCosterExpenses.list(this.axiosParams);
      // console.log(result.data);
      result.data.forEach((costerExpense) => {
        this.setCosterExpense(costerExpense);
      });
      this.setPagination(result.pagination);
      this.setLoadingInitial(false);
    } catch (error) {
      // console.log(error);
      this.setLoadingInitial(false);
    }
  };

  setPagination = (pagination: Pagination) => {
    this.pagination = pagination;
  };

  loadCosterExpense = async (id: number) => {
    let value = this.getToolBLinkCosterExpense(id);
    if (value) {
      this.selectedCosterExpense = value;
      return value;
    } else {
      // this.loadingInitial = true;
      this.setLoadingInitial(true);
      try {
        value = await agent.ToolBLinkCosterExpenses.details(id);
        // this.setCosterExpense(toolblinkcosterexpense);
        runInAction(() => {
          this.selectedCosterExpense = value;
        });
        return value;
      } catch (error) {
        console.log(error);
        this.setLoadingInitial(false);
      } finally {
        this.setLoadingInitial(false);
      }
    }
  };

  private setToolBLinkCosterExpenseData = (
    toolblinkcosterexpense: CosterExpense
  ) => {
    this.costerExpenseData.push(toolblinkcosterexpense);
  };

  private setCosterExpense = (toolblinkcosterexpense: CosterExpense) => {
    this.costerExpenseRegistry.set(
      toolblinkcosterexpense.expenseOID,
      toolblinkcosterexpense
    );
  };

  private getToolBLinkCosterExpense = (id: number) => {
    return this.costerExpenseRegistry.get(id);
  };

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  createCosterExpense = async (
    toolblinkcosterexpense: CosterExpenseFormValues
  ) => {
    try {
      await agent.ToolBLinkCosterExpenses.create(toolblinkcosterexpense);
      const newTOOLBLinkCosterExpense = new CosterExpense(
        toolblinkcosterexpense
      );
      this.setCosterExpense(newTOOLBLinkCosterExpense);
      runInAction(() => {
        this.selectedCosterExpense = newTOOLBLinkCosterExpense;
      });
    } catch (error) {
      console.log(error);
    }
  };

  updateCosterExpense = async (
    toolblinkcosterexpense: CosterExpenseFormValues
  ) => {
    try {
      await agent.ToolBLinkCosterExpenses.update(toolblinkcosterexpense);
      runInAction(() => {
        if (toolblinkcosterexpense.expenseOID) {
          let updatedTOOLBLinkCosterExpense = {
            ...this.getToolBLinkCosterExpense(
              toolblinkcosterexpense.expenseOID
            ),
            ...toolblinkcosterexpense,
          };
          this.costerExpenseRegistry.set(
            toolblinkcosterexpense.expenseOID,
            updatedTOOLBLinkCosterExpense as CosterExpense
          );
          this.selectedCosterExpense =
            updatedTOOLBLinkCosterExpense as CosterExpense;
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  deleteCosterExpense = async (id: number) => {
    this.loading = true;
    try {
      await agent.ToolBLinkCosterExpenses.delete(id);
      runInAction(() => {
        this.costerExpenseRegistry.delete(id);
        this.loading = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  clearSelectedTOOLBLinkCosterExpense = () => {
    this.selectedCosterExpense = undefined;
  };
}

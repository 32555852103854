import { Verify } from "crypto";
import { Route, Routes } from "react-router-dom";
import ConfirmEmail from "../../features/users/ConfirmEmail";
import LoginForm from "../../features/users/LoginForm/LoginForm";
import RegisterForm from "../../features/users/RegisterForm/RegisterForm";
import RegisterForm1 from "../../features/users/RegisterForm/RegisterForm1";
import RegisterSuccess from "../../features/users/RegisterSuccess";

export default function AuthRoutes() {
  return (
    <Routes>
      <Route path={`login`} element={<LoginForm />} />
      <Route path={`register`} element={<RegisterForm />} />
      <Route path={`registerPage`} element={<RegisterForm1 />} />
      <Route path={`registerSuccess`} element={<RegisterSuccess />} />
      <Route path={`verifyEmail`} element={<ConfirmEmail />} />
    </Routes>
  );
}

import React from "react";
import { Segment, Button, Confirm, Header } from "semantic-ui-react";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { useStore } from "../../../app/stores/store";
import { v4 as uuid } from "uuid";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import MyTextInput from "../../../app/common/form/MyTextInput";
import MyTextArea from "../../../app/common/form/MyTextArea";
import MySelectInput from "../../../app/common/form/MySelectInput";
import { resourceOptions } from "../../../app/common/options/categoryOptions";
import MyDateInput from "../../../app/common/form/MyDateInput";
import {
  CompanyInfo,
  CompanyInfoFormValues,
} from "../../../app/models/companyInfo";
import { CompanyForm } from "../ecoforms/CompanyForm";

export const CompanyInformation = () => {
  return (
    <Segment>
      <Header>
        Company Information
        <Button
          negative
          type="button"
          floated="right"
          content="Delete"
          size="tiny"
          // onClick={() => show()}
        />
        <Confirm
          // open={open}
          content="Are you sure you wish to delete this entity?"
          cancelButton="No"
          confirmButton="Yes"
          // onConfirm={() => handleDelete(countryOID)}
          // onCancel={handleCancel}
          // size="tiny"
        />
        <Button
          // disabled={isSubmitting || !dirty || !isValid}
          // loading={isSubmitting}
          floated="right"
          positive
          type="submit"
          content="Submit"
          size="tiny"
        />
        <Button
          // disabled={isSubmitting || !dirty || !isValid}
          // loading={isSubmitting}
          floated="right"
          color="teal"
          type="submit"
          content="Edit"
          size="tiny"
          // onClick={editButtonOptions}
        />
      </Header>

      <div className="ui warning message">
        <div className="header">Manage Company Information.</div>
        <p>Manage Company Information.</p>
      </div>

      <div className="ui divider" title="List of Resources"></div>

      <CompanyForm />
    </Segment>
  );
};

import { observer } from "mobx-react-lite";
import React, { Fragment, useEffect, useState } from "react";
// import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Header, Segment } from "semantic-ui-react";
import { Table } from "semantic-ui-react";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { useStore } from "../../../app/stores/store";
//import { ActivityFormValues } from "../../../app/models/activity";
import { Resource, ResourceFormValues } from "../../../app/models/resource";

export default function ResourceList() {
  // const history = useHistory();
  const { resourceStore } = useStore();
  const { resourceData, loadResources, loadingInitial } = resourceStore;
  // const { resourceGUID } = useParams<{ resourceGUID: string }>();

  useEffect(() => {
    if (resourceData.length === 0) loadResources();
  }, [resourceData.length, loadResources]);

  // console.log("4. resourceData after useEffect");
  // console.log(resourceData);
  // console.log("5. resourceData after useEffect");
  // console.log(resourceData.length);

  return (
    // <ol>
    //   {/* {console.log(" 7 .resourceData", (resourceData as any).data)} */}

    //   {resourceData && resourceData.map((r) => {
    //         return <li key={r.resourceGUID}>{r.code}</li>;
    //       })}
    // </ol>
    <Fragment>
      <Table celled selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Sno.</Table.HeaderCell>
            <Table.HeaderCell>Resource GUID</Table.HeaderCell>
            <Table.HeaderCell>Code</Table.HeaderCell>
            <Table.HeaderCell>Category</Table.HeaderCell>
            <Table.HeaderCell>Title</Table.HeaderCell>
            <Table.HeaderCell>Description</Table.HeaderCell>
            <Table.HeaderCell>Size</Table.HeaderCell>
            <Table.HeaderCell>Source</Table.HeaderCell>
            <Table.HeaderCell>Downloads</Table.HeaderCell>
            <Table.HeaderCell>DateUp loaded</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {resourceData &&
            resourceData.map((resource, i) => (
              <Table.Row key={resource.resourceGUID}>
                <Table.Cell>{i + 1}</Table.Cell>
                <Table.Cell>{resource.resourceGUID}</Table.Cell>
                <Table.Cell>{resource.code}</Table.Cell>
                <Table.Cell>{resource.category}</Table.Cell>
                <Table.Cell>{resource.title}</Table.Cell>
                <Table.Cell>{resource.description}</Table.Cell>
                <Table.Cell>{resource.size}</Table.Cell>
                <Table.Cell>{resource.source}</Table.Cell>
                <Table.Cell>{resource.downloads}</Table.Cell>
                <Table.Cell>{resource.dateUploaded}</Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
    </Fragment>
  );
}

import React from "react";
import { Container, Segment, Divider, Grid, Header } from "semantic-ui-react";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import "devextreme-react/text-area";
import DataGrid, {
  FilterPanel,
  FilterRow,
  SearchPanel,
  Button,
  Paging,
  Lookup,
  Popup,
  Position,
  Editing,
  Export,
  Pager,
  Grouping,
  GroupPanel,
  RowDragging,
  Column,
  RequiredRule,
  PatternRule,
  EmailRule,
  AsyncRule,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import "whatwg-fetch";
import { store, useStore } from "../../../app/stores/store";

const SetupTypeGrid = ({ setupType }: any) => {
  const baseAPI = process.env.REACT_APP_API_URL; // "http://localhost:5000/api";
  const jwt = store.commonStore.token;
  function handleErrors(response: any) {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  }

  const setupTypeDataSource: any = new CustomStore({
    key: "oid",
    load: () => {
      return fetch(
        `${baseAPI}/EcoSystemCodes/GetEcoSystemCodeBySetupType/${setupType}`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      )
        .then(handleErrors)
        .then((response) => response.json())
        .then((response) => {
          // console.log(response.result.data)
          return {
            data: response.result.data,
            totalCount: response.count,
            summary: response.summary,
            groupCount: response.count,
          };
        });
    },
    update: (values) => {
      return values;
    },
  });
  const onRowUpdating = (e: any) => {
    e.newData = Object.assign(e.oldData, e.newData);
    // console.log(e.newData)
    fetch(`${baseAPI}/EcoSystemCodes`, {
      method: "PUT",
      body: JSON.stringify(e.newData),
      headers: {
        Authorization: `Bearer ${jwt}`,
        "Content-Type": "application/json",
      },
    }).then(setupTypeDataSource.load());
  };

  return (
    <DataGrid
      id="[$GridID]"
      dataSource={setupTypeDataSource}
      // dataSource={data}
      className="dx-datagrid dx-row-alt"
      hoverStateEnabled={true}
      columnAutoWidth={true}
      showBorders={true}
      selection={{ mode: "single" }}
      onRowUpdating={onRowUpdating}
      activeStateEnabled={true}
      // keyExpr="oid"
      //  onSelectionChanged={onSelectionChanged}
      columnResizingMode="widget"
      allowColumnResizing={true}
      columnHidingEnabled={true}>
      {/* <RowDragging allowReordering={true} /> */}
      <FilterPanel visible={true} />
      <SearchPanel visible={true} />
      <FilterRow visible={true} />
      <Paging defaultPageSize={10} />
      <Pager
        showPageSizeSelector={true}
        allowedPageSizes={[2, 10, 20, 50, 100]}
        showInfo={true}
      />
      <Grouping contextMenuEnabled={true} />
      <GroupPanel visible={true} />
      <Editing
        mode="popup"
        useIcons={true}
        allowUpdating={true}
        allowAdding={true}
      />
      <Export
        enabled={true}
        fileName="SystemCodes"
        allowExportSelectedData={true}
      />
      <Column type="buttons" />{" "}
      <Column dataField="oid" allowEditing={false}></Column>
      <Column dataField="setupType" allowEditing={false}>
        <RequiredRule />
      </Column>
      <Column dataField="code">
        <RequiredRule />
      </Column>
      <Column dataField="description">
        <RequiredRule />
      </Column>
      <Column dataField="codeOrderApplication" allowEditing={true}>
        <RequiredRule message="Code Order Application is required and is a number" />
      </Column>
    </DataGrid>
  );
};
export default SetupTypeGrid;

import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../api/agent";
import {
  MSMEBlinkWeeklyUpdate,
  MSMEBlinkWeeklyUpdateFormValues,
} from "../models/MSMEBlinkWeeklyUpdate";
// import { format } from "date-fns";
import { Pagination, PagingParams } from "../models/pagination";

export default class MSMEBlinkWeeklyUpdateStore {
  MSMEBlinkWeeklyUpdateData: MSMEBlinkWeeklyUpdate[] = []; //This is all  data for get all
  msmeBlinkWeeklyUpdateRegistry = new Map<number, MSMEBlinkWeeklyUpdate>();
  selectedMSMEBlinkWeeklyUpdate: MSMEBlinkWeeklyUpdate | undefined = undefined;
  editMode: boolean = false;
  loading: boolean = false;
  loadingInitial: boolean = false;
  pagination: Pagination | null = null;
  pagingParams = new PagingParams();
  predicate = new Map().set("all", true);
  baseAPI = process.env.REACT_APP_API_URL;

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.predicate.keys(),
      () => {
        this.pagingParams = new PagingParams();
        this.msmeBlinkWeeklyUpdateRegistry.clear();
      }
    );
  }

  get axiosParams() {
    const params = new URLSearchParams();
    params.append("pageNumber", this.pagingParams.pageNumber.toString());
    params.append("pageSize", this.pagingParams.pageNumber.toString());
    this.predicate.forEach((value, key) => {
      params.append(key, value);
    });
    return params;
  }

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  private getMSMEBlinkWeeklyUpdate = (id: number) => {
    return this.msmeBlinkWeeklyUpdateRegistry.get(id);
  };

  private setMSMEBlinkWeeklyUpdateData = (values: MSMEBlinkWeeklyUpdate) => {
    this.MSMEBlinkWeeklyUpdateData.push(values);
  };

  loadMSMEBlinkWeeklyUpdate = async (id: number) => {
    let values = this.getMSMEBlinkWeeklyUpdate(id);

    if (values) {
      this.selectedMSMEBlinkWeeklyUpdate = values;
      return values;
    } else {
      this.setLoadingInitial(true);
      try {
        values = await agent.MSMEBlinkWeeklyUpdates.details(id);
        this.setMSMEBlinkWeeklyUpdateData(values);
        runInAction(() => {
          this.selectedMSMEBlinkWeeklyUpdate = values;
        });
        return values;
      } finally {
        this.setLoadingInitial(false);
      }
    }
  };

  createMSMEBlinkWeeklyUpdate = async (
    values: MSMEBlinkWeeklyUpdateFormValues
  ) => {
    await agent.MSMEBlinkWeeklyUpdates.create(values);
    const data = new MSMEBlinkWeeklyUpdate(values);

    runInAction(() => {
      this.selectedMSMEBlinkWeeklyUpdate = data;
    });
  };

  updateMSMEBlinkWeeklyUpdate = async (
    values: MSMEBlinkWeeklyUpdateFormValues
  ) => {
    await agent.MSMEBlinkWeeklyUpdates.update(values);
  };

  deleteMSMEBlinkWeeklyUpdate = async (id: number) => {
    this.loading = true;
    try {
      await agent.MSMEBlinkWeeklyUpdates.delete(id);
      runInAction(() => {
        this.msmeBlinkWeeklyUpdateRegistry.delete(id);
        this.loading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  clearMSMEBlinkWeeklyUpdate = () => {
    this.selectedMSMEBlinkWeeklyUpdate = undefined;
  };
}

import React from "react";
import { Segment } from "semantic-ui-react";

export const Articles = () => {
  return (
    <Segment>
      <h1>Articles Dashboard</h1>
      <div className="ui warning message">
        <div className="header">Dashboards for accessing Articles.</div>
        <p>
          This dashboard provides a centralized access to Articles.
        </p>
      </div>
      <div className="ui divider" title="List of Resources"></div>
    </Segment>
  );
};

import { Route, Routes } from "react-router-dom";
import HomePage from "../../features/home/HomePage";
import AuthRoutes from "./authRoutes";
import { WrapperContainer } from "./MainLayout";

export default function Router() {
  return (
    <>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='auth/*' element={<AuthRoutes />} />
        <Route path='app/*' element={<WrapperContainer />} />
      </Routes>
    </>
  );
}

//import { Profile } from "./profile";

export interface HelpersOnline {
  id: string;
  email: string;
  bio: string;
  displayName: string;
  followers: number;
  followings: number;
  phoneNumber: string;
  userName: string;

  // date: Date | null;
  // description: string;
  // category: string;
  // city: string;
  // venue: string;
  // hostUsername: string;
  // isCancelled: boolean;
  // isGoing: boolean;
  // isHost: boolean;
  // host?: Profile;
  // attendees: Profile[]
}

export class HelpersOnline implements HelpersOnline {
  constructor(init?: HelpersOnlineFormValues) {
    Object.assign(this, init);
  }
}

export class HelpersOnlineFormValues {
  id?: string = undefined;
  email: string = "";
  bio: string = "";
  displayName: string = "";
  followers: number = 0;
  followings: number = 0;
  phoneNumber: string = "";
  userName: string = "";

  // dateUploaded: Date  = Date.now();

  constructor(helpersOnline?: HelpersOnlineFormValues) {
    if (helpersOnline) {
      this.id = helpersOnline.id;
      this.email = helpersOnline.email;
      this.bio = helpersOnline.bio;
      this.displayName = helpersOnline.displayName;
      this.followers = helpersOnline.followers;
      this.followings = helpersOnline.followings;
      this.phoneNumber = helpersOnline.phoneNumber;
      this.userName = helpersOnline.userName;
    }
  }
}

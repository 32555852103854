import React, {useState} from 'react';
import {useStore} from "../../app/stores/store";
import {Button, Grid, Header, Segment, Tab} from "semantic-ui-react";
import ProfileEditForm from "./ProfileEditForm";
import { observer } from 'mobx-react-lite';

export default observer(function ProfileQualification() {
  const { profileStore } = useStore();
  const { isCurrentUser, profile } = profileStore;
  const [editMode, setEditMode] = useState(false);

  return (
    <Tab.Pane>
      <Grid>
        <Grid.Column width="16">
          <Segment>
            <h1>Professional Qualification</h1>

            <div className="ui warning message">
              {isCurrentUser && (
                <Button
                  floated="right"
                  basic
                  content={editMode ? "Cancel" : "Edit Data"}
                  onClick={() => setEditMode(!editMode)}
                />
              )}
              <div className="header">Professional Qualification Update.</div>
              <p>
                Provide correct detail information about your professional
                qualification. Please note that your data is protected and can
                only be viewed by the users you want to view it.
              </p>
            </div>
            <div className="ui divider" title="List of Resources"></div>
          </Segment>
        </Grid.Column>
        <Grid.Column width="16">
          {editMode ? (
            <ProfileEditForm setEditMode={setEditMode} />
          ) : (
            <span style={{ whiteSpace: "pre-wrap" }}>{profile?.bio}</span>
          )}
        </Grid.Column>
      </Grid>
    </Tab.Pane>
  );
});

import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../api/agent";
import {
  CosterCommodity,
  CosterCommoditysFormValues,
} from "../models/costerCommoditys";
// import { format } from "date-fns";
import { Pagination, PagingParams } from "../models/pagination";

export default class CosterCommoditysStore {
  commodityData: CosterCommodity[] = []; //This is all the commodity data for get all
  commodityRegistry = new Map<number, CosterCommodity>(); //Not sure what this is used for yet
  selectedCommodity: CosterCommodity | undefined = undefined;
  editMode: boolean = false;
  loading: boolean = false;
  loadingInitial: boolean = false;
  pagination: Pagination | null = null;
  pagingParams = new PagingParams();
  predicate = new Map().set("all", true);
  baseAPI = process.env.REACT_APP_API_URL;

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.predicate.keys(),
      () => {
        this.pagingParams = new PagingParams();
        this.commodityRegistry.clear();
        // this.loadCommodity();
      }
    );
  }

  get axiosParams() {
    const params = new URLSearchParams();
    params.append("pageNumber", this.pagingParams.pageNumber.toString());
    params.append("pageSize", this.pagingParams.pageNumber.toString());
    this.predicate.forEach((value, key) => {
      params.append(key, value);
    });
    return params;
  }

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  private getCommodity = (id: number) => {
    return this.commodityRegistry.get(id);
  };

  private setCommodityData = (values: CosterCommodity) => {
    this.commodityData.push(values);
  };

  loadCommodity = async (id: number) => {
    let commodity = this.getCommodity(id);

    // let commodity :any
    if (commodity) {
      // if (id) {
      this.selectedCommodity = commodity;
      return commodity;
    } else {
      this.setLoadingInitial(true);
      try {
        commodity = await agent.CosterCommodities.details(id);
        this.setCommodityData(commodity);
        runInAction(() => {
          this.selectedCommodity = commodity;
        });
        // this.setLoadingInitial(false);
        return commodity;
      } finally {
        this.setLoadingInitial(false);
      }
      //   catch (error) {
      //     console.log(error);
      //   }
    }
  };

  createCommodity = async (values: CosterCommoditysFormValues) => {
    // try {
    await agent.CosterCommodities.create(values);
    const newCommodity = new CosterCommodity(values);
    //   this
    runInAction(() => {
      this.selectedCommodity = newCommodity;
    });
    // }
    // catch (error) {
    //   console.log(error);
    // }
  };

  updateCommodity = async (values: CosterCommoditysFormValues) => {
    // try {
    await agent.CosterCommodities.update(values);
    // //////Commented this out suspecting the update Error to be the cause of this
    // runInAction(() => {
    //   if (values.offtakerPriceOID) {
    //     let updateCommodity = {
    //       ...this.getCommodity(values.offtakerPriceOID),
    //       ...values,
    //     };

    //     this.commodityRegistry.set(
    //       values.offtakerPriceOID,
    //       updateCommodity as CosterCommodity
    //     );

    //     this.selectedCommodity = updateCommodity as CosterCommodity;
    //   }
    // });
    // } catch (error) {
    //   console.log(error);
    // }
  };

  deleteCommodity = async (id: number) => {
    this.loading = true;
    try {
      await agent.CosterCommodities.delete(id);
      runInAction(() => {
        this.commodityRegistry.delete(id);
        this.loading = false;
      });
    } catch (error) {
      // console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  clearSelectedCommodity = () => {
    this.selectedCommodity = undefined;
  };
}

//*************************************************************
// PROJECT NAME : Blinh Hub Network
// CLASS        : MSMEBlinkActivitiesChallenges
// DATE CREATED : 14 February 2022
// TIME CREATED : 04:53:57
// DEVELOPER    : Ibinaiye Wale Kayode
//*************************************************************
export interface ActivityChallenge {
  challengeOID: number;
  blinkActivityOID: number;
  logType: string;
  challenge: string;
  lessonsLerned: string;
}

export class ActivityChallenge implements ActivityChallenge {
  constructor(init?: ActivityChallengeFormValues) {
    Object.assign(this, init);
  }
}

export class ActivityChallengeFormValues {
  challengeOID: number = 0;
  blinkActivityOID: number = 0;
  logType: string = "";
  challenge: string = "";
  lessonsLerned: string = "";

  constructor(values?: ActivityChallengeFormValues) {
    if (values) {
      this.challengeOID = values.challengeOID;
      this.blinkActivityOID = values.blinkActivityOID;
      this.logType = values.logType;
      this.challenge = values.challenge;
      this.lessonsLerned = values.lessonsLerned;
    }
  }
}

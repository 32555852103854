import axios, { AxiosError, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { history } from "../..";
import { BlinkPlanSSOFormValues } from "../models/blinkPlanSSO";
import { HelpersOnline } from "../models/helpersOnline";
import { PersonalData, PersonalDataFormValues } from "../models/personalData";
// import { HelpersOnline } from "../models/helpersOnline";
// import { Activity, ActivityFormValues } from "../models/activity";
import { Resource, ResourceFormValues } from "../models/resource";
import { CountryCode, CountryFormValues } from "../models/countryModel";
// import { PaginatedResult } from "../models/pagination";
// import { Photo, Profile, UserActivity } from "../models/profile";
// import { User, UserFormValues } from "../models/user";
import { store } from "../stores/store";
import { ApplicationModel, ApplicationFormValues } from "../models/application";
import {
  RoleDefinitionModel,
  RoleDefinitionFormValues,
} from "../models/roleDefinition";
import { LgaModel, LgaFormValues } from "../models/lga";
import { AssignTask, AssignTaskFormValues } from "../models/assignTaskToRole";
import { StateModel, StateFormValues } from "../models/state";
import {
  BusinessSectorModel,
  BusinessSectorFormValues,
} from "../models/businessSector";
import {
  BusinessAreaModel,
  BusinessAreaFormValues,
} from "../models/businessArea";
import { Subscriber, SubscriberFormValues } from "../models/subscribers";
import { AssignRole, AssignRoleFormValues } from "../models/assignRoleToUser";
import { CompanyInfo, CompanyInfoFormValues } from "../models/companyInfo";
import { SystemCodeModel, SystemCodeFormValues } from "../models/systemCode";
import { SystemSet, SystemSetFormValues } from "../models/systemSetting";
import { TaskModel, TaskFormValues } from "../models/task";
import { Console } from "console";
// import { PageSize } from "devextreme-react/diagram";
import { FeedbackModel, FeedbackFormValues } from "../models/feedaback";
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use((config) => {
  const token = store.commonStore.token;
  if (token) config.headers.Authorization = `Bearer ${token}`;
  return config;
});

axios.interceptors.response.use(
  async (response) => {
    // if (process.env.NODE_ENV === "development");
    // const pagination = response.headers["pagination"];
    // if (pagination) {
    //   response.data = new PaginatedResult(
    //     response.data,
    //     JSON.parse(pagination)
    //   );
    //   return response as AxiosResponse<PaginatedResult<any>>;
    // }
    return response;
  },

  (error: AxiosError) => {
    console.log(error);
    const { data, status, config, headers } = error.response!;

    switch (status) {
      case 400:
        if (config.method === "get" && data.errors.hasOwnProperty("id")) {
          history.push("/not-found");
        }
        if (data.errors) {
          const modalStateErrors = [];

          for (const key in data.errors) {
            if (data.errors[key]) {
              modalStateErrors.push(data.errors[key]);
            }
          }
          throw modalStateErrors.flat();
        } else {
          toast.error(data);
        }
        break;
      case 401:
        if (
          status === 401 &&
          headers["www-authenticate"]?.startsWith(
            'Bearer error="invalid_token"'
          )
        ) {
          store.userStore.logout();
          toast.error("Session expired - please login again");
        }
        break;
      case 404:
        history.push("/not-found");
        break;
      case 500:
        store.commonStore.setServerError(data);
        history.push("/server-error");
        break;
    }
    return Promise.reject(error);
  }
);

const responseBody = <T>(response: AxiosResponse<T>) => {
  return response.data;
};

const requests = {
  get: <T>(url: string) => axios.get<T>(url).then(responseBody),
  post: <T>(url: string, body: {}) =>
    axios.post<T>(url, body).then(responseBody),
  put: <T>(url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
  del: <T>(url: string) => axios.delete<T>(url).then(responseBody),
};

const Resources = {
  list: () => axios.get<any>("/resourcev2s").then(responseBody),
  details: (id: string) => requests.get<Resource>(`/resourcev2s/${id}`),
  create: (resource: ResourceFormValues) =>
    requests.post<void>("/resourcev2s", resource),
  update: (resource: ResourceFormValues) =>
    requests.put<void>(`/resourcev2s/${resource.resourceGUID}`, resource),
  delete: (id: string) => requests.del<void>(`/resourcev2s/${id}`),
  listResourceDashboard: () => requests.get<Resource[]>("/resourcev2s"),
};

const Countries = {
  list: () => axios.get<any>("/ecocountry").then(responseBody),
  details: (id: string) => requests.get<CountryCode | any>(`/EcoCountry/${id}`),
  create: (country: CountryFormValues) =>
    requests.post<void>("/Ecocountry", country),
  update: (country: CountryFormValues) =>
    requests.put<void>(`/Ecocountry/${country.countryOID}`, country),
  delete: (id: string) => requests.del<void>(`/Ecocountry/${id}`),
  listCountryCodeDashboard: () => requests.get<CountryCode[]>("/Ecocountry"),
};

const Applications = {
  list: () => axios.get<any>("/EcoApplications").then(responseBody),
  details: (id: string) =>
    requests.get<ApplicationModel | any>(`/EcoApplications/${id}`),
  create: (application: ApplicationFormValues) =>
    requests.post<void>("/EcoApplications", application),
  update: (application: ApplicationFormValues) =>
    requests.put<void>(
      `/EcoApplications/${application.applicationOID}`,
      application
    ),
  delete: (id: string) => requests.del<void>(`/EcoApplications/${id}`),
  listApplicationModelDashboard: () =>
    requests.get<ApplicationModel[]>("/EcoApplications"),
};
const RoleDefinitions = {
  list: () => axios.get<any>("/applicationrole").then(responseBody),
  details: (id: string) =>
    requests.get<RoleDefinitionModel>(`/applicationrole/${id}`),
  create: (roledefinition: RoleDefinitionFormValues) =>
    requests.post<void>("/applicationrole", roledefinition),
  update: (roledefinition: RoleDefinitionFormValues) =>
    requests.put<void>(
      `/applicationrole/${roledefinition.roleOID}`,
      roledefinition
    ),
  delete: (id: string) => requests.del<void>(`/applicationrole/${id}`),
  listRoleDefinitionDashboard: () =>
    requests.get<RoleDefinitionModel[]>("/applicationrole"),
};

const EcoLga = {
  list: () => axios.get<any>("/ecolga").then(responseBody),
  details: (id: string) => requests.get<LgaModel>(`/ecolga/${id}`),
  create: (lga: LgaFormValues) => requests.post<void>("/ecolga", lga),
  update: (lga: LgaFormValues) =>
    requests.put<void>(`/ecolga/${lga.lgaOID}`, lga),
  delete: (id: string) => requests.del<void>(`/ecolga/${id}`),
  listLgaDashboard: () => requests.get<LgaModel[]>("/ecolga"),
};

const AssignTasks = {
  list: () => axios.get<any>("/ecotask").then(responseBody),
  details: (id: string) => requests.get<AssignTask>(`/ecotask/${id}`),
  create: (resource: AssignTaskFormValues) =>
    requests.post<void>("/ecotask", resource),
  update: (assigntask: AssignTaskFormValues) =>
    requests.put<void>(`/ecotask/${assigntask.taskOID}`, assigntask),
  delete: (id: string) => requests.del<void>(`/ecotask/${id}`),
  listResourceDashboard: () => requests.get<AssignTask[]>("/ecotask"),
};

const EcoState = {
  list: () => axios.get<any>("/ecostate").then(responseBody),
  details: (id: string) => requests.get<StateModel>(`/ecostate/${id}`),
  create: (state: StateFormValues) => requests.post<void>("/ecostate", state),
  update: (state: StateFormValues) =>
    requests.put<void>(`/ecostate/${state.stateOID}`, state),
  delete: (id: string) => requests.del<void>(`/ecostate/${id}`),
  listStateDashboard: () => requests.get<StateModel[]>("/ecostate"),
};

const EcoBusinessSector = {
  list: () => axios.get<any>("/ecobusinesssector").then(responseBody),
  details: (id: string) =>
    requests.get<BusinessSectorModel>(`/ecobusinesssector/${id}`),
  create: (businessSector: BusinessSectorFormValues) =>
    requests.post<void>("/ecobusinesssector", businessSector),
  update: (businessSector: BusinessSectorFormValues) =>
    requests.put<void>(
      `/ecobusinesssector/${businessSector.businessSectorOID}`,
      businessSector
    ),
  delete: (id: string) => requests.del<void>(`/ecobusinesssector/${id}`),
  listBusinessSectorDashboard: () =>
    requests.get<BusinessSectorModel[]>("/ecobusinesssector"),
};

const EcoBusinessArea = {
  list: () => axios.get<any>("/ecobusinessarea").then(responseBody),
  details: (id: string) =>
    requests.get<BusinessAreaModel | any>(`/ecobusinessarea/${id}`),
  create: (businessArea: BusinessAreaFormValues) =>
    requests.post<void>("/ecobusinessarea", businessArea),
  update: (businessArea: BusinessAreaFormValues) =>
    requests.put<void>(
      `/ecobusinessarea/${businessArea.businessAreaOID}`,
      businessArea
    ),
  delete: (id: string) => requests.del<void>(`/ecobusinessarea/${id}`),
  listBusinessAreaDashboard: () =>
    requests.get<BusinessAreaModel[]>("/ecobusinessarea"),
};

const Subscribers = {
  list: () => axios.get<any>("/ecosubscriber").then(responseBody),
  details: (id: string) => requests.get<Subscriber>(`/ecosubscriber/${id}`),
  create: (subscriber: SubscriberFormValues) =>
    requests.post<void>("/ecosubscriber", subscriber),
  update: (subscriber: SubscriberFormValues) =>
    requests.put<void>(
      `/ecosubscriber/${subscriber.subscriberOID}`,
      subscriber
    ),
  delete: (id: string) => requests.del<void>(`/ecosubscriber/${id}`),
  listSubscriberDashboard: () => requests.get<Subscriber[]>("/ecosubscriber"),
};
const AssignRoles = {
  list: () => axios.get<any>("/resourcev2s").then(responseBody),
  details: (id: string) => requests.get<AssignRole>(`/ecouserroletask/${id}`),
  create: (assigntask: AssignRoleFormValues) =>
    requests.post<void>("/ecouserroletask", assigntask),
  update: (assigntask: AssignRoleFormValues) =>
    requests.put<void>(`/ecouserroletask/${assigntask.taskOID}`, assigntask),
  delete: (id: string) => requests.del<void>(`/ecouserroletask/${id}`),
  listAssignRoleDashboard: () => requests.get<AssignRole[]>("/ecouserroletask"),
};

const CompanyInfos = {
  list: () => axios.get<any>("/EcoCompany").then(responseBody),
  details: (id: string) => requests.get<CompanyInfo | any>(`/EcoCompany/${id}`),
  create: (companyinfo: CompanyInfoFormValues) =>
    requests.post<void>("/EcoCompany", companyinfo),
  update: (companyinfo: CompanyInfoFormValues) =>
    requests.put<void>(`/EcoCompany/${companyinfo.companyOID}`, companyinfo),
  delete: (id: string) => requests.del<void>(`/EcoCompany/${id}`),
  listCompanyInfoDashboard: () => requests.get<CompanyInfo[]>("/EcoCompany"),
};
const SystemCodes = {
  list: () => axios.get<any>("/ecouserroletask").then(responseBody),
  details: (id: string) =>
    requests.get<SystemCodeModel>(`/ecouserroletask/${id}`),
  create: (systemcode: SystemCodeFormValues) =>
    requests.post<void>("/ecouserroletask", systemcode),
  update: (systemcode: SystemCodeFormValues) =>
    requests.put<void>(`/ecouserroletask/${systemcode.oid}`, systemcode),
  delete: (id: string) => requests.del<void>(`/ecouserroletask/${id}`),
  listSystemCodeModelDashboard: () =>
    requests.get<SystemCodeModel[]>("/ecouserroletask"),
};

const SystemSettings = {
  list: () => axios.get<any>("/ecouserroletask").then(responseBody),
  details: (id: string) => requests.get<SystemSet>(`/ecouserroletask/${id}`),
  create: (systemset: SystemSetFormValues) =>
    requests.post<void>("/ecosystemsettings", systemset),
  update: (systemset: SystemSetFormValues) =>
    requests.put<void>(`/ecosystemsettings/${systemset.OID}`, systemset),
  delete: (id: string) => requests.del<void>(`/ecosystemsettings/${id}`),
  listSystemSetDashboard: () => requests.get<SystemSet[]>("/ecosystemsettings"),
};

const Tasks = {
  list: () => axios.get<any>("/ecotask").then(responseBody),
  details: (id: string) => requests.get<TaskModel>(`/ecouserroletask/${id}`),
  create: (task: TaskFormValues) => requests.post<void>("/ecotask", task),
  update: (task: TaskFormValues) =>
    requests.put<void>(`/ecotask/${task.taskOID}`, task),
  delete: (id: string) => requests.del<void>(`/ecotask/${id}`),
  listTaskModelDashboard: () => requests.get<TaskModel[]>("/ecotask"),
};

const Feedbacks = {
  list: () => axios.get<any>("/feedback").then(responseBody),
  details: (id: string) => requests.get<FeedbackModel>(`/feedback/${id}`),
  create: (feedback: FeedbackFormValues) =>
    requests.post<void>("/feedback", feedback),
  update: (feedback: FeedbackFormValues) =>
    requests.put<void>(`/feedback/${feedback.feedbackOID}`, feedback),
  delete: (id: string) => requests.del<void>(`/feedback/${id}`),
  listFeedbackModelDashboard: () => requests.get<FeedbackModel[]>("/ecotask"),
};
// const agent = {

const HelpersOnlines = {
  list: () => axios.get<any>("/account/getallusersonline").then(responseBody),
  details: (id: string) =>
    requests.get<HelpersOnline>(`/account/getallusersonlinedetails/${id}`),
  // create: (resource: ResourceFormValues) =>
  //   requests.post<void>("/resourcev2s", resource),
  // update: (resource: ResourceFormValues) =>
  //   requests.put<void>(`/resourcev2s/${resource.resourceGUID}`, resource),
  // delete: (id: string) => requests.del<void>(`/resourcev2s/${id}`),
  //listResourceDashboard: () => requests.get<Resource[]>("/resourcev2s"),
};

const BlinkPlanSSO = {
  //list: () =>
  //  axios.get<any>("/blinkplansso/logintoblinkplannmfb").then(responseBody),
  //details: (id: string) =>
  //  requests.get<HelpersOnline>(`/account/getallusersonlinedetails/${id}`),
  // create: (resource: ResourceFormValues) =>
  //   requests.post<void>("/resourcev2s", resource),
  autoRegister: (blinkplansso: BlinkPlanSSOFormValues) =>
    requests.post<any>(`/blinkplansso/logintoblinkplannmfb`, blinkplansso),
  // delete: (id: string) => requests.del<void>(`/resourcev2s/${id}`),
  //listResourceDashboard: () => requests.get<Resource[]>("/resourcev2s"),
};

const agent = {
  Resources,
  HelpersOnlines,

  Countries,
  Applications,
  RoleDefinitions,
  EcoLga,
  AssignTasks,
  EcoState,
  EcoBusinessSector,
  EcoBusinessArea,
  Subscribers,
  AssignRoles,
  CompanyInfos,
  SystemCodes,
  SystemSettings,
  Tasks,
  Feedbacks,
  BlinkPlanSSO,
};

export default agent;

//*************************************************************
// PROJECT NAME : Blinh Hub Network
// CLASS        : MSMEBlinkWeeklyUpdate
// DATE CREATED : 11 February 2022
// TIME CREATED : 08:09:30
// DEVELOPER    : Ibinaiye Wale Kayode
//*************************************************************
export interface MSMEBlinkWeeklyUpdate {
  weeklyUpdateOID: number;
  weekCode: string;
  startDate: Date;
  description: string;
  performedBy: string;
  msmeBlinkWeeklyUpdateDetailVirtual: any[];
}

export class MSMEBlinkWeeklyUpdate implements MSMEBlinkWeeklyUpdate {
  constructor(init?: MSMEBlinkWeeklyUpdateFormValues) {
    Object.assign(this, init);
  }
}

export class MSMEBlinkWeeklyUpdateFormValues {
  weeklyUpdateOID = 0;
  weekCode: string = "";
  startDate = new Date();
  description: string = "";
  performedBy: string = "";
  msmeBlinkWeeklyUpdateDetailVirtual = [];

  constructor(values?: MSMEBlinkWeeklyUpdateFormValues) {
    if (values) {
      this.weeklyUpdateOID = values.weeklyUpdateOID;
      this.weekCode = values.weekCode;
      this.startDate = values.startDate;
      this.description = values.description;
      this.performedBy = values.performedBy;
      this.msmeBlinkWeeklyUpdateDetailVirtual =
        values.msmeBlinkWeeklyUpdateDetailVirtual;
    }
  }
}

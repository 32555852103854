import { observer } from "mobx-react-lite";
import React from "react";
// import { Link } from "react-router-dom";
// import {
//   Container,
//   Header,
//   Segment,
//   Image,
//   Button,
//   Divider,
// } from "semantic-ui-react";
// import { useStore } from "../../app/stores/store";
// import LoginForm from "../users/LoginForm/LoginForm";
// import RegisterForm from "../users/RegisterForm";
import LandingPage from "./LandingPage";
export default observer(function HomePage() {
  //   const { userStore, modalStore } = useStore();
  return (
    <>
      <div>
        <LandingPage />
      </div>
      {/* <Segment inverted textAlign='center' vertical className='masthead'>
            <Container text>
                <Header as='h1' inverted>
                    <Image width='200px' height='400px' src='/assets/Blinkhub_Logo.png' alt='logo' style={{ marginBottom: 12 }} />
                    
                </Header > 
                {userStore.isLoggedIn ? (
                    <>
                        <Header as='h2' inverted content='Welcome to Blinkhub' />
                        <Button as={Link} to='/activities' size='huge' inverted>
                            Go to Activities!
                        </Button>
                    </>

                ) : (
                        <>
                            <Button onClick={() => modalStore.openModal(<LoginForm />)} size='huge' inverted>
                                Login!
                            </Button>
                            <Button onClick={() => modalStore.openModal(<RegisterForm />)} size='huge' inverted>
                                Register!
                            </Button>
                            <Divider horizontal inverted>Or</Divider>
                            <Button 
                                loading={userStore.fbLoading}
                                size='huge'
                                inverted
                                color='facebook'
                                content='Login with Facebook'
                                onClick={userStore.facebookLogin}
                            />
                        </>

                    )}
            </Container>
            </Segment> */}
    </>
  );
});

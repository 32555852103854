import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../api/agent";
import agentExpress from "../api/agentExpress";
//import { Activity, ActivityFormValues } from "../models/activity";
import { Resource, ResourceFormValues } from "../models/resource";
import { LgaModel, LgaFormValues } from "../models/lga";
import { format } from "date-fns";
import { store } from "./store";
import { Profile } from "../models/profile";
import { Pagination, PagingParams } from "../models/pagination";
import axios from "axios";

export default class LgaStore {
  lgaData: LgaModel[] = [];
  // resourceV2Data: Resource[] = [];
  //comments: ChatComment[] = [];
  lgaRegistry = new Map<string, LgaModel>();
  selectedlgaModel: LgaModel | undefined = undefined;
  editMode = false;
  loading = false;
  loadingInitial = false;
  pagination: Pagination | null = null;
  pagingParams = new PagingParams();
  predicate = new Map().set("all", true);
  baseAPI = process.env.REACT_APP_API_URL;

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.predicate.keys(),
      () => {
        this.pagingParams = new PagingParams();
        this.lgaRegistry.clear();
        this.loadEcoLga();
      }
    );
  }

  setPagingParams = (pagingParams: PagingParams) => {
    pagingParams.pageNumber = 1;
    pagingParams.pageSize = 500;
    this.pagingParams = pagingParams;
  };

  setPredicate = (predicate: string, value: string | Date) => {
    const resetPredicate = () => {
      this.predicate.forEach((value, key) => {
        if (key !== "dateUploaded") this.predicate.delete(key);
      });
    };
    switch (predicate) {
      case "all":
        resetPredicate();
        this.predicate.set("all", true);
        break;
      case "isGoing":
        resetPredicate();
        this.predicate.set("isGoing", true);
        break;
      case "isHost":
        resetPredicate();
        this.predicate.set("isHost", true);
        break;
      case "dateUploaded":
        this.predicate.delete("dateUploaded");
        this.predicate.set("dateUploaded", value);
    }
  };

  get axiosParams() {
    const params = new URLSearchParams();
    // params.append("pageNumber", this.pagingParams.pageNumber.toString());
    // params.append("pageSize", this.pagingParams.pageSize.toString());
    params.append("pageNumber", "1");
    params.append("pageSize", "500");
    this.predicate.forEach((value, key) => {
      if (key === "dateUploaded") {
        params.append(key, (value as Date).toISOString());
      } else {
        params.append(key, value);
      }
    });
    return params;
  }

  get lgaByDate() {
    return Array.from(this.lgaRegistry.values()).sort(
      (a, b) => a.dateUploaded!.getTime() - b.dateUploaded!.getTime()
    );
  }

  // get groupedEcoLga() {
  //   return Object.entries(
  //     this.lgaByDate.reduce((lga, resource) => {
  //       const date = format(resource.dateUploaded!, "dd MMM yyyy");
  //       LgaModel[date] = lga[date] ? [...lga[date], lga] : [resource];
  //       return lga;
  //     }, {} as { [key: string]: LgaModel[] })
  //   );
  // }

  // loadActivities = async () => {
  //   this.loadingInitial = true;
  //   try {
  //     const result = await agent.Activities.list(this.axiosParams);
  //     result.data.forEach((activity) => {
  //       this.setActivity(activity);
  //     });
  //     this.setPagination(result.pagination);
  //     this.setLoadingInitial(false);
  //   } catch (error) {
  //     console.log(error);
  //     this.setLoadingInitial(false);
  //   }
  // };

  loadEcoLga = async () => {
    this.loadingInitial = true;
    try {
      console.log("1. LOG = " + this.axiosParams);
      const result = await agent.EcoLga.list(this.axiosParams);
      //this.resourceData.push(result);
      //console.log("1. result from loadResources()", result);
      // result.map(
      //   (resource) =>
      //     //this.resourceData.push(resource)
      //     this.setResourceData(resource)
      // );
      // console.log("2. result from loadResources()");
      this.lgaData = result.data;
      // console.log(this.resourceData);
      // result.data.forEach((resource) => {
      //   this.setResourceData(resource);
      // });
      //this.setPagination(result.pagination);
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
    }
  };

  loadEcoLgaDashboard = async () => {
    this.loadingInitial = true;
    try {
      const result = await agentExpress.Resources.list();
      this.lgaData = result;
      console.log("1. In loadLgaDashboard: result", result);
      console.log("2. In loadLgaDashboard: this.resourceData", this.lgaData);
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
    }
  };

  setPagination = (pagination: Pagination) => {
    this.pagination = pagination;
  };

  loadLgaModel = async (id: string) => {
    let lga = this.getLgaModel(id);
    if (lga) {
      this.selectedlgaModel = lga;
      return lga;
    } else {
      this.loadingInitial = true;
      try {
        lga = await agent.EcoLga.details(id);
        this.setLgamodelData(lga);
        runInAction(() => {
          this.selectedlgaModel = lga;
        });
        this.setLoadingInitial(false);
        return lga;
      } catch (error) {
        console.log(error);
        this.setLoadingInitial(false);
      }
    }
  };
  private setLgamodelData = (lga: LgaModel) => {
    //resource.dateUploaded = new Date(resource.dateUploaded!);
    this.lgaData.push(lga);
  };

  private setLgaModel = (lga: LgaModel) => {
    lga.dateUploaded = new Date(lga.dateUploaded!);
    this.lgaRegistry.set(lga.lgaOID, lga);
  };

  private getLgaModel = (id: string) => {
    return this.lgaRegistry.get(id);
  };

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  createLgaModel = async (lga: LgaFormValues) => {
    try {
      await agent.EcoLga.create(lga);
      const newLgaModel = new LgaModel(lga);
      //   newResource.hostUsername = user!.username;
      //   newResource.attendees = [attendee];
      this.setLgaModel(newLgaModel);
      runInAction(() => {
        this.selectedlgaModel = newLgaModel;
      });
    } catch (error) {
      console.log(error);
    }
  };

  updateLgaModel = async (lga: LgaFormValues) => {
    try {
      await agent.EcoLga.update(lga);
      runInAction(() => {
        if (lga.lgaOID) {
          let updateLgaModel = {
            ...this.getLgaModel(lga.lgaOID),
            ...lga,
          };
          this.lgaRegistry.set(lga.lgaOID, updateLgaModel as LgaModel);
          this.selectedlgaModel = updateLgaModel as LgaModel;
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  deleteLgaModel = async (id: string) => {
    this.loading = true;
    try {
      await agent.EcoLga.delete(id);
      runInAction(() => {
        this.lgaRegistry.delete(id);
        this.loading = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  //   updateAttendance = async () => {
  //     const user = store.userStore.user;
  //     this.loading = true;
  //     try {
  //       await agent.Activities.attend(this.selectedActivity!.id);
  //       runInAction(() => {
  //         if (this.selectedActivity?.isGoing) {
  //           this.selectedActivity.attendees =
  //             this.selectedActivity.attendees?.filter(
  //               (a) => a.username !== user?.username
  //             );
  //           this.selectedActivity.isGoing = false;
  //         } else {
  //           const attendee = new Profile(user!);
  //           this.selectedActivity?.attendees?.push(attendee);
  //           this.selectedActivity!.isGoing = true;
  //         }
  //         this.activityRegistry.set(
  //           this.selectedActivity!.id,
  //           this.selectedActivity!
  //         );
  //       });
  //     } catch (error) {
  //       console.log(error);
  //     } finally {
  //       runInAction(() => (this.loading = false));
  //     }
  //   };

  cancelLgaModelToggle = async () => {
    this.loading = true;
    try {
      //await agent.Resources.attend(this.selectedResource!.resourceGUID);
      runInAction(() => {
        // this.selectedResource!.isCancelled =
        //   !this.selectedResource?.isCancelled;
        this.lgaRegistry.set(
          this.selectedlgaModel!.lgaOID,
          this.selectedlgaModel!
        );
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

  //   updateAttendeeFollowing = (username: string) => {
  //     this.activityRegistry.forEach((activity) => {
  //       activity.attendees.forEach((attendee) => {
  //         if (attendee.username === username) {
  //           attendee.following
  //             ? attendee.followersCount--
  //             : attendee.followersCount++;
  //           attendee.following = !attendee.following;
  //         }
  //       });
  //     });
  //   };

  clearSelectedlgaModel = () => {
    this.selectedlgaModel = undefined;
  };
}

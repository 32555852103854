import { useField } from "formik";
import React from "react";
import { Header, Label } from "semantic-ui-react";

interface Props {
  // placeholder?: string;
  name?: string;
  // type?: string;
  label: string;
  value: string;
}

export default function CADLabel(props: Props) {
  // const [field, meta] = useField(props.name);
  return (
    <Header as="h2" style={{ marginTop: "5px", marginBottom: "5px" }}>
      <div>
        <Header.Content>{props.label}</Header.Content>
        <Header.Subheader>{props.value}</Header.Subheader>
        {/* <label>{props.label}</label> */}
        {/* <input {...field} {...props} />
      {meta.touched && meta.error ? (
        <Label basic color="red">
          {meta.error}
        </Label>
      ) : null} */}
      </div>
    </Header>
  );
}

//import { Profile } from "./profile";

export interface CountryCode {
  countryOID: string;
  countryCode: string;
  country: string;
  dialCode: string;
  dateUploaded: Date | null;

  // date: Date | null;
  // description: string;
  // category: string;
  // city: string;
  // venue: string;
  // hostUsername: string;
  // isCancelled: boolean;
  // isGoing: boolean;
  // isHost: boolean;
  // host?: Profile;
  // attendees: Profile[]
}

export class CountryCode implements CountryCode {
  constructor(init?: CountryFormValues) {
    Object.assign(this, init);
  }
}

export class CountryFormValues {
  countryOID?: string = undefined;
  countryCode: string = "";
  country: string = "";
  dialCode: string = "";

  constructor(country?: CountryFormValues) {
    if (country) {
      this.countryOID = country.countryOID;
      this.countryCode = country.countryCode;
      this.country = country.country;
      this.dialCode = country.dialCode;
    }
  }
}

//*************************************************************
// PROJECT NAME : Blinh Hub Network
// CLASS        : MSMEBlinkActivitiesActors
// DATE CREATED : 11 February 2022
// TIME CREATED : 08:09:27
// DEVELOPER    : Ibinaiye Wale Kayode
//*************************************************************

export interface MSMEBlinkActivitiesActor {
  blinkActorOID: number;
  quarter: string;
  sNo: number;
  supportActorName: string;
  setupType: string;
  classification: string;
  contactDetails: string;
  telephoneNumber: string;
  supportFromPIND: string;
  engagement: string;
  engagementDate: Date;
  typeOfActivitiy: string;
  activityDate: Date;
  typeOfServiceRecipient: string;
  numberOfServiceRecipient: number;
  stateOfService: string;
  lGAOfService: string;
  communityOfService: string;
  longitude: string;
  latitude: string;
}

export class MSMEBlinkActivitiesActor implements MSMEBlinkActivitiesActor {
  constructor(init?: MSMEBlinkActivitiesActorFormValues) {
    Object.assign(this, init);
  }
}

export class MSMEBlinkActivitiesActorFormValues {
  blinkActorOID = 0;
  sNo = 0;
  quarter = "";
  supportActorName = "";
  setupType = "";
  classification = "";
  contactDetails = "";
  telephoneNumber = "";
  supportFromPIND = "";
  engagement = "";
  typeOfActivitiy = "";
  typeOfServiceRecipient = "";
  stateOfService = "";
  lGAOfService = "";
  communityOfService = "";
  longitude = "";
  latitude = "";
  engagementDate = new Date();
  activityDate = new Date();
  numberOfServiceRecipient = 0;

  constructor(values?: MSMEBlinkActivitiesActorFormValues) {
    if (values) {
      this.blinkActorOID = values.blinkActorOID;
      this.quarter = values.quarter;
      this.sNo = values.sNo;
      this.supportActorName = values.supportActorName;
      this.setupType = values.setupType;
      this.classification = values.classification;
      this.contactDetails = values.contactDetails;
      this.telephoneNumber = values.telephoneNumber;
      this.supportFromPIND = values.supportFromPIND;
      this.engagement = values.engagement;
      this.engagementDate = values.engagementDate;
      this.typeOfActivitiy = values.typeOfActivitiy;
      this.activityDate = values.activityDate;
      this.typeOfServiceRecipient = values.typeOfServiceRecipient;
      this.numberOfServiceRecipient = values.numberOfServiceRecipient;
      this.stateOfService = values.stateOfService;
      this.lGAOfService = values.lGAOfService;
      this.communityOfService = values.communityOfService;
      this.longitude = values.longitude;
      this.latitude = values.latitude;
    }
  }
}

import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../api/agent";
import agentExpress from "../api/agentExpress";
//import { Activity, ActivityFormValues } from "../models/activity";
import { Resource, ResourceFormValues } from "../models/resource";
import { AssignRole, AssignRoleFormValues } from "../models/assignRoleToUser";
import { format } from "date-fns";
import { store } from "./store";
import { Profile } from "../models/profile";
import { Pagination, PagingParams } from "../models/pagination";
import axios from "axios";

export default class AssignRoleStore {
  assignroleData: AssignRole[] = [];
  // resourceV2Data: Resource[] = [];
  //comments: ChatComment[] = [];
  assignroleRegistry = new Map<string, AssignRole>();
  selectedAssignRole: AssignRole | undefined = undefined;
  editMode = false;
  loading = false;
  loadingInitial = false;
  pagination: Pagination | null = null;
  pagingParams = new PagingParams();
  predicate = new Map().set("all", true);
  baseAPI = process.env.REACT_APP_API_URL;

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.predicate.keys(),
      () => {
        this.pagingParams = new PagingParams();
        this.assignroleRegistry.clear();
        this.loadAssignRoles();
      }
    );
  }

  setPagingParams = (pagingParams: PagingParams) => {
    pagingParams.pageNumber = 1;
    pagingParams.pageSize = 500;
    this.pagingParams = pagingParams;
  };

  setPredicate = (predicate: string, value: string | Date) => {
    const resetPredicate = () => {
      this.predicate.forEach((value, key) => {
        if (key !== "dateUploaded") this.predicate.delete(key);
      });
    };
    switch (predicate) {
      case "all":
        resetPredicate();
        this.predicate.set("all", true);
        break;
      case "isGoing":
        resetPredicate();
        this.predicate.set("isGoing", true);
        break;
      case "isHost":
        resetPredicate();
        this.predicate.set("isHost", true);
        break;
      case "dateUploaded":
        this.predicate.delete("dateUploaded");
        this.predicate.set("dateUploaded", value);
    }
  };

  get axiosParams() {
    const params = new URLSearchParams();
    // params.append("pageNumber", this.pagingParams.pageNumber.toString());
    // params.append("pageSize", this.pagingParams.pageSize.toString());
    params.append("pageNumber", "1");
    params.append("pageSize", "500");
    this.predicate.forEach((value, key) => {
      if (key === "dateUploaded") {
        params.append(key, (value as Date).toISOString());
      } else {
        params.append(key, value);
      }
    });
    return params;
  }

  get assignrolesByDate() {
    return Array.from(this.assignroleRegistry.values()).sort(
      (a, b) => a.dateUploaded!.getTime() - b.dateUploaded!.getTime()
    );
  }

  get groupedAssignRoles() {
    return Object.entries(
      this.assignrolesByDate.reduce((assignroles, assignrole) => {
        const date = format(assignrole.dateUploaded!, "dd MMM yyyy");
        assignroles[date] = assignroles[date]
          ? [...assignroles[date], assignrole]
          : [assignrole];
        return assignroles;
      }, {} as { [key: string]: AssignRole[] })
    );
  }

  // loadActivities = async () => {
  //   this.loadingInitial = true;
  //   try {
  //     const result = await agent.Activities.list(this.axiosParams);
  //     result.data.forEach((activity) => {
  //       this.setActivity(activity);
  //     });
  //     this.setPagination(result.pagination);
  //     this.setLoadingInitial(false);
  //   } catch (error) {
  //     console.log(error);
  //     this.setLoadingInitial(false);
  //   }
  // };

  loadAssignRoles = async () => {
    this.loadingInitial = true;
    try {
      console.log("1. LOG = " + this.axiosParams);
      const result = await agent.AssignRoles.list(this.axiosParams);
      //this.resourceData.push(result);
      //console.log("1. result from loadResources()", result);
      // result.map(
      //   (resource) =>
      //     //this.resourceData.push(resource)
      //     this.setResourceData(resource)
      // );
      // console.log("2. result from loadResources()");
      this.assignroleData = result.data;
      // console.log(this.resourceData);
      // result.data.forEach((resource) => {
      //   this.setResourceData(resource);
      // });
      //this.setPagination(result.pagination);
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
    }
  };

  loadAssignRolesDashboard = async () => {
    this.loadingInitial = true;
    try {
      const result = await agentExpress.AssignRoles.list();
      this.assignroleData = result;
      console.log("1. In loadAssignRolesDashboard: result", result);
      console.log(
        "2. In loadAssignRolesDashboard: this.resourceData",
        this.assignroleData
      );
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
    }
  };

  setPagination = (pagination: Pagination) => {
    this.pagination = pagination;
  };

  loadAssignRole = async (id: string) => {
    let assignrole = this.getAssignRole(id);
    if (assignrole) {
      this.selectedAssignRole = assignrole;
      return assignrole;
    } else {
      this.loadingInitial = true;
      try {
        assignrole = await agent.AssignRoles.details(id);
        this.setAssignRoleData(assignrole);
        runInAction(() => {
          this.selectedAssignRole = assignrole;
        });
        this.setLoadingInitial(false);
        return assignrole;
      } catch (error) {
        console.log(error);
        this.setLoadingInitial(false);
      }
    }
  };
  private setAssignRoleData = (assignrole: AssignRole) => {
    //resource.dateUploaded = new Date(resource.dateUploaded!);
    this.assignroleData.push(assignrole);
  };

  private setAssignRole = (assignrole: AssignRole) => {
    assignrole.dateUploaded = new Date(assignrole.dateUploaded!);
    this.assignroleRegistry.set(assignrole.taskOID, assignrole);
  };

  private getAssignRole = (id: string) => {
    return this.assignroleRegistry.get(id);
  };

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  createAssignRole = async (assignrole: AssignRoleFormValues) => {
    try {
      await agent.AssignRoles.create(assignrole);
      const newAssignRole = new AssignRole(assignrole);
      //   newResource.hostUsername = user!.username;
      //   newResource.attendees = [attendee];
      this.setAssignRole(newAssignRole);
      runInAction(() => {
        this.selectedAssignRole = newAssignRole;
      });
    } catch (error) {
      console.log(error);
    }
  };

  updateAssignRole = async (assignrole: AssignRoleFormValues) => {
    try {
      await agent.AssignRoles.update(assignrole);
      runInAction(() => {
        if (assignrole.taskOID) {
          let updatedAssignRole = {
            ...this.getAssignRole(assignrole.taskOID),
            ...assignrole,
          };
          this.assignroleRegistry.set(
            assignrole.taskOID,
            updatedAssignRole as AssignRole
          );
          this.selectedAssignRole = updatedAssignRole as AssignRole;
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  deleteAssignRole = async (id: string) => {
    this.loading = true;
    try {
      await agent.AssignRoles.delete(id);
      runInAction(() => {
        this.assignroleRegistry.delete(id);
        this.loading = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  //   updateAttendance = async () => {
  //     const user = store.userStore.user;
  //     this.loading = true;
  //     try {
  //       await agent.Activities.attend(this.selectedActivity!.id);
  //       runInAction(() => {
  //         if (this.selectedActivity?.isGoing) {
  //           this.selectedActivity.attendees =
  //             this.selectedActivity.attendees?.filter(
  //               (a) => a.username !== user?.username
  //             );
  //           this.selectedActivity.isGoing = false;
  //         } else {
  //           const attendee = new Profile(user!);
  //           this.selectedActivity?.attendees?.push(attendee);
  //           this.selectedActivity!.isGoing = true;
  //         }
  //         this.activityRegistry.set(
  //           this.selectedActivity!.id,
  //           this.selectedActivity!
  //         );
  //       });
  //     } catch (error) {
  //       console.log(error);
  //     } finally {
  //       runInAction(() => (this.loading = false));
  //     }
  //   };

  cancelResourceToggle = async () => {
    this.loading = true;
    try {
      //await agent.Resources.attend(this.selectedResource!.resourceGUID);
      runInAction(() => {
        // this.selectedResource!.isCancelled =
        //   !this.selectedResource?.isCancelled;
        this.assignroleRegistry.set(
          this.selectedAssignRole!.taskOID,
          this.selectedAssignRole!
        );
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

  //   updateAttendeeFollowing = (username: string) => {
  //     this.activityRegistry.forEach((activity) => {
  //       activity.attendees.forEach((attendee) => {
  //         if (attendee.username === username) {
  //           attendee.following
  //             ? attendee.followersCount--
  //             : attendee.followersCount++;
  //           attendee.following = !attendee.following;
  //         }
  //       });
  //     });
  //   };

  clearSelectedAssignRole = () => {
    this.selectedAssignRole = undefined;
  };
}

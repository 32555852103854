import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Menu,
  Image,
  // Dropdown,
  Icon,
  // Input,
  Button,
  Label,
  Card,
  // Sidebar,
} from "semantic-ui-react";
import { useStore } from "../stores/store";
import UsersOnline from "../uicontrols/UsersOnline";
import { createMedia } from "@artsy/fresnel";

const { MediaContextProvider, Media } = createMedia({
  // breakpoints values can be either strings or integers
  breakpoints: {
    sm: 0,
    md: 768,
    lg: 1024,
    xl: 1569,
  },
});

// const options = [
//   { key: "SupportTicket", text: "Support Ticket", value: "Support Ticket" },
//   { key: "Events", text: "Events", value: "Events" },
//   {
//     key: "OpenConversation",
//     text: "Open Conversation",
//     value: "Open Conversation",
//   },
//   {
//     key: "BusinessOpportunity",
//     text: "Business Opportunity",
//     value: "Business Opportunity",
//   },
//   { key: "Advertisement", text: "Advertisement", value: "Advertisement" },
//   { key: "PublicNotice", text: "Public Notice", value: "Public Notice" },
//   { key: "Poll", text: "Poll", value: "Poll" },
// ];

interface FuncProps {
  handleClick(): void;
  showMainMenu: boolean;
}

const MainMenu: React.FC<FuncProps> = ({ handleClick, showMainMenu }) => {
  const {
    userStore: { isLoggedIn },
  } = useStore();
  // const { activeItem, setActiveItem } = useState("");

  // const handleItemClick = (e,{ taskDescription }) => {

  //   alert(taskDescription);
  //   //setActiveItem({ activeItem: taskDescription });
  // }

  return (
    <>
      <MediaContextProvider>
        <Media lessThan='xl'>
          <div>
            {showMainMenu && (
              <>
                <Menu fixed='left' style={{ marginTop: "50px" }}>
                  {isLoggedIn && (
                    <>
                      <Menu pointing vertical>
                        <Card>
                          <Image
                            src='/assets/Blinkhub_Logo.png'
                            verticalAlign='middle'
                            wrapped
                            ui={false}
                            size='small'
                            alt='logo'
                            style={{ marginRight: "30px", marginLeft: "30px" }}
                            as='a'
                            href='/'
                          />
                        </Card>

                        <Menu.Item>
                          <UsersOnline />
                        </Menu.Item>

                        {
                          <>
                            <Menu.Item name='businessclinic'>
                              <Button as='div' labelPosition='right'>
                                <Button
                                  basic
                                  color='red'
                                  as={Link}
                                  to='view/businessclinic'>
                                  <h5>Business Clinic</h5>
                                </Button>
                                <Label as='div' color='red' pointing='left'>
                                  <Icon name='building outline' />
                                </Label>
                              </Button>
                            </Menu.Item>
                            <Menu.Item name='linkagemap'>
                              <Button as='div' labelPosition='right'>
                                <Button
                                  basic
                                  color='green'
                                  as={Link}
                                  to='view/linkagemap'>
                                  <h5>Business Linkage Map</h5>
                                </Button>
                                <Label as='div' color='green' pointing='left'>
                                  <Icon name='building outline' />
                                </Label>
                              </Button>
                            </Menu.Item>

                            <Menu.Item name='SupportTicket'>
                              <Button as='div' labelPosition='right'>
                                <Button
                                  basic
                                  color='brown'
                                  as={Link}
                                  to='create/supportticket'>
                                  <h5>Support Tickets</h5>
                                </Button>
                                <Label as='div' color='brown' pointing='left'>
                                  <Icon name='building outline' />
                                </Label>
                              </Button>
                            </Menu.Item>
                            <Menu.Item name='Events'>
                              <Button as='div' labelPosition='right'>
                                <Button
                                  basic
                                  color='brown'
                                  as={Link}
                                  to='view/activities'>
                                  <h5>Events and Activities</h5>
                                </Button>
                                <Label as='div' color='brown' pointing='left'>
                                  <Icon name='tasks' />
                                </Label>
                              </Button>
                            </Menu.Item>
                            <Menu.Item name='OpenConversations'>
                              <Button as='div' labelPosition='right'>
                                <Button
                                  basic
                                  color='brown'
                                  as={Link}
                                  to='create/openconversation'>
                                  <h5>Open Conversations</h5>
                                </Button>
                                <Label as='div' color='brown' pointing='left'>
                                  <Icon name='conversation' />
                                </Label>
                              </Button>
                            </Menu.Item>
                            <Menu.Item name='BusinessOpportunities'>
                              <Button as='div' labelPosition='right'>
                                <Button
                                  basic
                                  color='brown'
                                  as={Link}
                                  to='create/businessopportunity'>
                                  <h5>Business Opportunities</h5>
                                </Button>
                                <Label as='div' color='brown' pointing='left'>
                                  <Icon name='building' />
                                </Label>
                              </Button>
                            </Menu.Item>
                            <Menu.Item name='Advertisement'>
                              <Button as='div' labelPosition='right'>
                                <Button
                                  basic
                                  color='brown'
                                  as={Link}
                                  to='create/advertisement'>
                                  <h5>Product Adverts</h5>
                                </Button>
                                <Label as='div' color='brown' pointing='left'>
                                  <Icon name='adversal' />
                                </Label>
                              </Button>
                            </Menu.Item>
                            <Menu.Item name='PublicNotice'>
                              <Button as='div' labelPosition='right'>
                                <Button
                                  basic
                                  color='brown'
                                  as={Link}
                                  to='create/publicnotice'>
                                  <h5>Public Notifications</h5>
                                </Button>
                                <Label as='div' color='brown' pointing='left'>
                                  <Icon name='info circle' />
                                </Label>
                              </Button>
                            </Menu.Item>
                            <Menu.Item name='Poll'>
                              <Button as='div' labelPosition='right'>
                                <Button
                                  basic
                                  color='brown'
                                  as={Link}
                                  to='create/poll'>
                                  <h5>Polls and Surveys</h5>
                                </Button>
                                <Label as='div' color='brown' pointing='left'>
                                  <Icon name='question circle' />
                                </Label>
                              </Button>
                            </Menu.Item>
                          </>
                        }
                      </Menu>
                    </>
                  )}
                </Menu>
              </>
            )}
          </div>
        </Media>
        <Media greaterThanOrEqual='xl'>
          <Container>
            <DesktopMainMenu isLoggedIn={isLoggedIn} />
          </Container>
        </Media>
      </MediaContextProvider>
    </>
  );
};

interface Props {
  isLoggedIn?: boolean;
}

const DesktopMainMenu: React.FC<Props> = ({ isLoggedIn }) => {
  return (
    <>
      <Menu fixed='left' style={{ marginTop: "50px" }}>
        {/* <Menu.Item as={NavLink} exact to="/" header>
          <img
            src="/assets/Blinkhub_Logo.png"
            alt="logo"
            style={{ marginRight: "10px" }}
          />
          Blinkhub
        </Menu.Item> */}
        {isLoggedIn && (
          <>
            <Menu pointing vertical>
              {/* <Menu.Item>
                <Input placeholder="Search..." />
              </Menu.Item> */}

              <Card>
                <Image
                  src='/assets/Blinkhub_Logo.png'
                  verticalAlign='middle'
                  wrapped
                  ui={false}
                  size='small'
                  alt='logo'
                  style={{ marginRight: "30px", marginLeft: "30px" }}
                  as='a'
                  href='/'
                />
                {/* <Card.Content>
                  <Card.Header>Matthew</Card.Header>
                  <Card.Meta>
                    <span className="date">Joined in 2015</span>
                  </Card.Meta>
                  <Card.Description>
                    Matthew is a musician living in Nashville.
                  </Card.Description>
                </Card.Content> */}
                {/* <Card.Content extra>
                  <a>
                    <Icon name="user" />
                    22 Friends
                  </a>
                </Card.Content> */}
              </Card>

              {/* <Menu.Header>
                <Image
                  src="/assets/Blinkhub_Logo.png"
                  alt="logo"
                  style={{ marginRight: "10px" }}
                  as="a"
                  size="small"
                  href="http://google.com"
                  target="_blank"
                  verticalAlign="middle"
                />
              </Menu.Header> */}
              <Menu.Item>
                <UsersOnline />
              </Menu.Item>
              {/* <div>
                <Dropdown
                  placeholder="Filter by Post Type"
                  fluid
                  multiple
                  selection
                  options={options}
                />
              </div> */}

              {/* {user?.userTasks &&
                user?.userTasks.find((f) => f.taskCode === "Activities") && (
                  <Menu.Item name="Activities" active>
                    <Icon name="tasks" />
                    Activity
                  </Menu.Item>
                )} */}

              {
                <>
                  <Menu.Item name='businessclinic'>
                    <Button as='div' labelPosition='right'>
                      <Button
                        basic
                        color='red'
                        as={Link}
                        to='view/businessclinic'>
                        <h5>Business Clinic</h5>
                      </Button>
                      <Label as='div' color='red' pointing='left'>
                        <Icon name='building outline' />
                      </Label>
                    </Button>
                  </Menu.Item>
                  <Menu.Item name='linkagemap'>
                    <Button as='div' labelPosition='right'>
                      <Button
                        basic
                        color='green'
                        as={Link}
                        to='view/linkagemap'>
                        <h5>Business Linkage Map</h5>
                      </Button>
                      <Label as='div' color='green' pointing='left'>
                        <Icon name='building outline' />
                      </Label>
                    </Button>
                  </Menu.Item>

                  <Menu.Item name='SupportTicket'>
                    <Button as='div' labelPosition='right'>
                      <Button
                        basic
                        color='brown'
                        as={Link}
                        to='create/supportticket'>
                        <h5>Support Tickets</h5>
                      </Button>
                      <Label as='div' color='brown' pointing='left'>
                        <Icon name='building outline' />
                      </Label>
                    </Button>
                  </Menu.Item>
                  <Menu.Item name='Events'>
                    <Button as='div' labelPosition='right'>
                      <Button
                        basic
                        color='brown'
                        as={Link}
                        to='view/activities'>
                        <h5>Events and Activities</h5>
                      </Button>
                      <Label as='div' color='brown' pointing='left'>
                        <Icon name='tasks' />
                      </Label>
                    </Button>
                  </Menu.Item>
                  <Menu.Item name='OpenConversations'>
                    <Button as='div' labelPosition='right'>
                      <Button
                        basic
                        color='brown'
                        as={Link}
                        to='create/openconversation'>
                        <h5>Open Conversations</h5>
                      </Button>
                      <Label as='div' color='brown' pointing='left'>
                        <Icon name='conversation' />
                      </Label>
                    </Button>
                  </Menu.Item>
                  <Menu.Item name='BusinessOpportunities'>
                    <Button as='div' labelPosition='right'>
                      <Button
                        basic
                        color='brown'
                        as={Link}
                        to='create/businessopportunity'>
                        <h5>Business Opportunities</h5>
                      </Button>
                      <Label as='div' color='brown' pointing='left'>
                        <Icon name='building' />
                      </Label>
                    </Button>
                  </Menu.Item>
                  <Menu.Item name='Advertisement'>
                    <Button as='div' labelPosition='right'>
                      <Button
                        basic
                        color='brown'
                        as={Link}
                        to='create/advertisement'>
                        <h5>Product Adverts</h5>
                      </Button>
                      <Label as='div' color='brown' pointing='left'>
                        <Icon name='adversal' />
                      </Label>
                    </Button>
                  </Menu.Item>
                  <Menu.Item name='PublicNotice'>
                    <Button as='div' labelPosition='right'>
                      <Button
                        basic
                        color='brown'
                        as={Link}
                        to='create/publicnotice'>
                        <h5>Public Notifications</h5>
                      </Button>
                      <Label as='div' color='brown' pointing='left'>
                        <Icon name='info circle' />
                      </Label>
                    </Button>
                  </Menu.Item>
                  <Menu.Item name='Poll'>
                    <Button as='div' labelPosition='right'>
                      <Button
                        basic
                        color='brown'
                        as={Link}
                        to='create/poll'>
                        <h5>Polls and Surveys</h5>
                      </Button>
                      <Label as='div' color='brown' pointing='left'>
                        <Icon name='question circle' />
                      </Label>
                    </Button>
                  </Menu.Item>
                </>
              }

              {/* {
                user?.userTasks &&
                  user?.userTasks.map(
                    (task) =>
                      task.moduleDescription === "DashBoard" && (
                        <Menu.Item
                        // onClick={handleItemClick(task.taskDescription)}
                        // active={activeItem === task.taskDescription}
                        >
                          <Icon name="grid layout" />
                          {task.moduleDescription === "DashBoard" &&
                            task.taskDescription}
                        </Menu.Item>
                      )
                  )
                //console.log("1. User", user.userTasks)
              } */}
              {/* <Menu.Header>POSTS</Menu.Header> */}
              {/* {
                user?.userTasks &&
                  user?.userTasks.map(
                    (task) =>
                      task.moduleDescription === "Posts" && (
                        <Menu.Item>
                          <Icon name="grid layout" />
                          {task.moduleDescription === "Posts" &&
                            task.taskDescription}
                        </Menu.Item>
                      )
                  ) 
              } */}

              {/* <Menu.Item>
                Home
                <Menu.Menu>
                  <Menu.Item
                    name="search"
                    // active={activeItem === "search"}
                    // onClick={this.handleItemClick}
                  >
                    Search
                  </Menu.Item>
                  <Menu.Item
                    name="add"
                    // active={activeItem === "add"}
                    // onClick={this.handleItemClick}
                  >
                    Add
                  </Menu.Item>
                  <Menu.Item
                    name="about"
                    // active={activeItem === "about"}
                    // onClick={this.handleItemClick}
                  >
                    Remove
                  </Menu.Item>
                </Menu.Menu>
              </Menu.Item>

              <Menu.Item
                name="browse"
                // active={activeItem === "browse"}
                // onClick={this.handleItemClick}
              >
                <Icon name="grid layout" />
                Browse
              </Menu.Item>
              <Menu.Item
                name="messages"
                // active={activeItem === "messages"}
                // onClick={this.handleItemClick}
              >
                Messages
              </Menu.Item>

              <Dropdown item text="More">
                <Dropdown.Menu>
                  <Dropdown.Item icon="edit" text="Edit Profile" />
                  <Dropdown.Item icon="globe" text="Choose Language" />
                  <Dropdown.Item icon="settings" text="Account Settings" />
                </Dropdown.Menu>
              </Dropdown> */}
            </Menu>
          </>
        )}
      </Menu>
    </>
  );
};

export default observer(MainMenu);

//*************************************************************
// PROJECT NAME : Blinh Hub Network
// CLASS        : MSMEBlinkActivities
// DATE CREATED : 11 February 2022
// TIME CREATED : 08:09:12
// DEVELOPER    : Ibinaiye Wale Kayode
//*************************************************************
import React, { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Formik } from "formik";
import * as Yup from "yup";
import TabPanel, { Item } from "devextreme-react/tab-panel";
import { MSMEBlinkActivityFormValues } from "../../../app/models/MSMEBlinkActivities";
import { useStore } from "../../../app/stores/store";
import MyTextInput from "../../../app/common/form/MyTextInput";
import { Button, Form, Grid, Header, Icon, Message } from "semantic-ui-react";
import DXLoadingComponent from "../../../app/layout/DXLoadingComponent";
import { IMasterDetail } from "../../../app/common/staticProps";
import { AxiosError } from "axios";
import notify from "devextreme/ui/notify";
import DXDateBox from "../../../app/common/form/DXDateBox";
import DxSelectBox from "../../../app/common/form/DxSelectBox";
import {
  optionsActivityType,
  optionsState,
} from "../../../app/common/staticData";
import MSMEBlinkActivityFeedbackGrid from "../details/ActivityFeedbackList";
import ActivitiesActorsGrid from "../details/ActivitiesActorsList";
import MSMEBlinkActivitiesChallengesForm from "./ActivitiesChallengesForm";

type Props = {
  rowID: number;
  setMode: React.Dispatch<React.SetStateAction<IMasterDetail>>;
};

const tabNames = ["Attendees", "Feedback", "Challenges", "Actors"];

const MSMEBlinkActivitiesForm: React.FC<Props> = ({ rowID, setMode }) => {
  const [initialValues, setInitialValues] =
    useState<MSMEBlinkActivityFormValues>(new MSMEBlinkActivityFormValues());
  const { MSMEBlinkActivitiesStore } = useStore();
  const {
    createMSMEBlinkActivities,
    updateMSMEBlinkActivities,
    loadMSMEBlinkActivities,
    loadingInitial,
    setLoadingInitial,
  } = MSMEBlinkActivitiesStore;

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const validationSchema = Yup.object({
    blinkActivityOID: Yup.string().required(
      "The  BlinkActivityOID Is required"
    ),
    activityType: Yup.string().required("The ActivityType Is required"),
    activityTitle: Yup.string().required("The ActivityTitle Is required"),
    detailDescription: Yup.string().required(
      "The DetailDescription Is required"
    ),
    activityDate: Yup.string().required("The ActivityDate Is required"),
    keyActor: Yup.string().required("The KeyActor Is required"),
    activityState: Yup.string().required("The ActivityState Is required"),
    activityLGA: Yup.string().required("The ActivityLGA Is required"),
    activityCommunity: Yup.string().required(
      "The ActivityCommunity Is required"
    ),
    activityAddress: Yup.string().required("The ActivityAddress Is required"),
    outcomeType: Yup.string().required("The OutcomeType Is required"),
    outcomeValue: Yup.string().required("The OutcomeValue Is required"),
    typeOfSupport: Yup.string().required("The TypeOfSupport Is required"),
  });

  const handleBack = useCallback(() => {
    setMode("GRID");
  }, [setMode]);

  useEffect(() => {
    if (!rowID) return setLoadingInitial(false);
    loadMSMEBlinkActivities(rowID)
      .then((res: any) => {
        const response = res.result[0];
        setInitialValues(new MSMEBlinkActivityFormValues(response));
      })
      .catch((err: AxiosError) => {
        notify("Bad Request", "error", 3000);
        handleBack();
      });
  }, [rowID, loadMSMEBlinkActivities, setLoadingInitial, handleBack]);

  const handleSubmit = (values: MSMEBlinkActivityFormValues) => {
    try {
      if (rowID) {
        const newMSMEBlinkActivities = {
          ...values,
        };
        updateMSMEBlinkActivities(newMSMEBlinkActivities).then(() => {
          handleBack();
        });
      } else {
        createMSMEBlinkActivities(values).then(() => {
          handleBack();
        });
      }
    } catch (error) {
      notify("Server Error", "error", 3000);
    }
  };

  const onTabSelectionChanged = useCallback((args) => {
    let name = args.addedItems[0].title;
    let index = tabNames.indexOf(name);
    setSelectedTabIndex(index);
  }, []);

  if (loadingInitial) return <DXLoadingComponent />;

  return (
    <div>
      <Header as='h2'>
        <Icon
          onClick={handleBack}
          size='big'
          name='arrow circle left'
          color='blue'
          style={{ cursor: "pointer" }}
        />
        Activities Details
      </Header>
      <Message color='brown' size='mini'>
        <Message.Header>Manage Activities</Message.Header>
        <p> You can create and manage a Activity. </p>
      </Message>
      <Formik
        validationSchema={validationSchema}
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values) => handleSubmit(values)}>
        {({ handleSubmit, isValid, isSubmitting, dirty }) => (
          <Form className='ui form' autoComplete='off' onSubmit={handleSubmit}>
            <Grid>
              <Grid.Row columns={3}>
                <Grid.Column>
                  <MyTextInput
                    placeholder='BlinkActivityOID'
                    name='blinkActivityOID'
                    label='blinkActivityOID'
                    disabled
                  />
                </Grid.Column>

                <Grid.Column>
                  <DxSelectBox
                    label='Activity Type'
                    name='activityType'
                    dataSource={optionsActivityType}
                    valueExpr={"value"}
                    displayExpr={"value"}
                  />
                </Grid.Column>

                <Grid.Column>
                  <MyTextInput
                    placeholder='ActivityTitle'
                    name='activityTitle'
                    label='Activity Title'
                  />
                </Grid.Column>
                <Grid.Column>
                  <MyTextInput
                    placeholder='DetailDescription'
                    name='detailDescription'
                    label='Detail Description'
                  />
                </Grid.Column>

                <Grid.Column>
                  <DXDateBox
                    placeholder='activityDate'
                    name='activityDate'
                    label='Activity Date'
                  />
                </Grid.Column>
                <Grid.Column>
                  <MyTextInput
                    placeholder='KeyActor'
                    name='keyActor'
                    label='Key Actor'
                  />
                </Grid.Column>
                <Grid.Column>
                  <MyTextInput
                    placeholder='CollaboratingPartners'
                    name='collaboratingPartners'
                    label='Collaborating Partners'
                  />
                </Grid.Column>
                <Grid.Column>
                  <MyTextInput
                    placeholder='PartneringInstitutions'
                    name='partneringInstitutions'
                    label='Partnering Institutions'
                  />
                </Grid.Column>
                <Grid.Column>
                  <DxSelectBox
                    label='Activity State'
                    name='activityState'
                    dataSource={optionsState}
                    valueExpr={"value"}
                    displayExpr={"value"}
                  />
                </Grid.Column>
                <Grid.Column>
                  <MyTextInput
                    placeholder='ActivityLGA'
                    name='activityLGA'
                    label='Activity LGA'
                  />
                </Grid.Column>
                <Grid.Column>
                  <MyTextInput
                    placeholder='ActivityCommunity'
                    name='activityCommunity'
                    label='Activity Community'
                  />
                </Grid.Column>
                <Grid.Column>
                  <MyTextInput
                    placeholder='ActivityAddress'
                    name='activityAddress'
                    label='Activity Address'
                  />
                </Grid.Column>

                <Grid.Column>
                  <MyTextInput
                    placeholder='Longitude'
                    name='longitude'
                    label='Longitude'
                  />
                </Grid.Column>
                <Grid.Column>
                  <MyTextInput
                    placeholder='Latitude'
                    name='latitude'
                    label='Latitude'
                  />
                </Grid.Column>
                <Grid.Column>
                  <MyTextInput
                    placeholder='OutcomeType'
                    name='outcomeType'
                    label='Outcome Type'
                  />
                </Grid.Column>
                <Grid.Column>
                  <MyTextInput
                    placeholder='OutcomeValue'
                    name='outcomeValue'
                    type='number'
                    label='Outcome Value'
                  />
                </Grid.Column>
                <Grid.Column>
                  <MyTextInput
                    placeholder='TypeOfSupport'
                    name='typeOfSupport'
                    label='Type Of Support'
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "1em",
              }}>
              <Button
                disabled={isSubmitting || !dirty || !isValid}
                loading={isSubmitting}
                floated='right'
                positive
                type='submit'
                content='Submit'
              />
              <Button
                onClick={handleBack}
                floated='right'
                type='button'
                content='Cancel'
              />
            </div>
          </Form>
        )}
      </Formik>
      <TabPanel
        loop={true}
        animationEnabled={true}
        swipeEnabled={true}
        selectedIndex={selectedTabIndex}
        onSelectionChanged={onTabSelectionChanged}>
        <Item title='Attendees'>
          {/* <TabItemSMS setIsVisible={setIsVisible} /> */}
        </Item>
        <Item title='Feedback'>
          {/* <TabItemEmail setIsVisible={setIsVisible} /> */}
          <MSMEBlinkActivityFeedbackGrid />
        </Item>
        <Item title='Challenges'>
          <MSMEBlinkActivitiesChallengesForm />
          {/* <TabItemWhatsApp setIsVisible={setIsVisible} /> */}
        </Item>
        <Item title='Actors'>
          <ActivitiesActorsGrid />
          {/* <TabItemTelegram setIsVisible={setIsVisible} /> */}
        </Item>
      </TabPanel>
    </div>
  );
};

export default observer(MSMEBlinkActivitiesForm);

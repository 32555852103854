export interface BusinessAreaModel {
  businessAreaOID: string;
  description: string;
  status: string;
  businessAreaID: string;
  businessSectorID: string;
  dateUploaded: Date | null;

  // date: Date | null;
  // description: string;
  // category: string;
  // city: string;
  // venue: string;
  // hostUsername: string;
  // isCancelled: boolean;
  // isGoing: boolean;
  // isHost: boolean;
  // host?: Profile;
  // attendees: Profile[]
}

export class BusinessAreaModel implements BusinessAreaModel {
  constructor(init?: BusinessAreaFormValues) {
    Object.assign(this, init);
  }
}

export class BusinessAreaFormValues {
  businessAreaOID?: string = undefined;
  description: string = "";
  status: string = "";
  businessAreaID: string = "";
  businessSectorID: string = "";

  // dateUploaded: Date  = Date.now();

  constructor(businessArea?: BusinessAreaFormValues) {
    if (businessArea) {
      this.businessAreaOID = businessArea.businessAreaOID;
      this.description = businessArea.description;
      this.status = businessArea.status;
      this.businessAreaID = businessArea.businessAreaID;
      this.businessSectorID = businessArea.businessSectorID;
    }
  }
}

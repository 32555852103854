import React from "react";
import { Segment } from "semantic-ui-react";

export const GamePlan = () => {
  return (
    <Segment>
      <h1>GamePlan Dashboard</h1>
      <div className="ui warning message">
        <div className="header">Dashboards for accessing GamePlan.</div>
        <p>This dashboard provides a centralized access to GamePlan Business Simulation Applications.</p>
      </div>
      <div className="ui divider" title="List of Resources"></div>
    </Segment>
  );
};

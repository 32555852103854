import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../api/agent";
import {
  MSMEBlinkActivitiesActor,
  MSMEBlinkActivitiesActorFormValues,
} from "../models/MSMEBlinkActivitiesActors";
import { Pagination, PagingParams } from "../models/pagination";

export default class ActivitiesActorsStore {
  MSMEBlinkActivitiesActorsData: MSMEBlinkActivitiesActor[] = []; //This is all  data for get all
  activitiesActorsRegistry = new Map<number, MSMEBlinkActivitiesActor>();
  selectedMSMEBlinkActivitiesActors: MSMEBlinkActivitiesActor | undefined =
    undefined;
  editMode: boolean = false;
  loading: boolean = false;
  loadingInitial: boolean = false;
  pagination: Pagination | null = null;
  pagingParams = new PagingParams();
  predicate = new Map().set("all", true);
  baseAPI = process.env.REACT_APP_API_URL;

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.predicate.keys(),
      () => {
        this.pagingParams = new PagingParams();
        this.activitiesActorsRegistry.clear();
      }
    );
  }

  get axiosParams() {
    const params = new URLSearchParams();
    params.append("pageNumber", this.pagingParams.pageNumber.toString());
    params.append("pageSize", this.pagingParams.pageNumber.toString());
    this.predicate.forEach((value, key) => {
      params.append(key, value);
    });
    return params;
  }

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  private getActivitiesActors = (id: number) => {
    return this.activitiesActorsRegistry.get(id);
  };

  private setActivitiesActorsData = (values: MSMEBlinkActivitiesActor) => {
    this.MSMEBlinkActivitiesActorsData.push(values);
  };

  loadActivitiesActors = async (id: number) => {
    let values = this.getActivitiesActors(id);

    if (values) {
      this.selectedMSMEBlinkActivitiesActors = values;
      return values;
    } else {
      this.setLoadingInitial(true);
      try {
        values = await agent.MSMEBlinkActivitiesActors.details(id);
        this.setActivitiesActorsData(values);
        runInAction(() => {
          this.selectedMSMEBlinkActivitiesActors = values;
        });
        return values;
      } finally {
        this.setLoadingInitial(false);
      }
    }
  };

  createActivitiesActors = async (
    values: MSMEBlinkActivitiesActorFormValues
  ) => {
    await agent.MSMEBlinkActivitiesActors.create(values);
    const data = new MSMEBlinkActivitiesActor(values);

    runInAction(() => {
      this.selectedMSMEBlinkActivitiesActors = data;
    });
  };

  updateActivitiesActors = async (
    values: MSMEBlinkActivitiesActorFormValues
  ) => {
    await agent.MSMEBlinkActivitiesActors.update(values);
  };

  deleteActivitiesActors = async (id: number) => {
    this.loading = true;
    try {
      await agent.MSMEBlinkActivitiesActors.delete(id);
      runInAction(() => {
        this.activitiesActorsRegistry.delete(id);
        this.loading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  clearMSMEBlinkActivitiesActors = () => {
    this.selectedMSMEBlinkActivitiesActors = undefined;
  };
}

import React, { useState } from "react";
import { Formik, Form } from "formik";
import MyTextInput from "../../../app/common/form/MyTextInput";

import MyDisabledInput from "../../../app/common/form/MyDisbabledInput";

import { Button, Icon } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { store, useStore } from "../../../app/stores/store";

import {
  AssignTask,
  AssignTaskFormValues,
} from "../../../app/models/assignTaskToRole";
import MySelectInput from "../../../app/common/form/MySelectInput";
import SelectAssign from "../../../app/common/form/SelectAssign";

export default observer(function AssignTaskForm({
  setOnVisible,
  taskOID,
}: {
  taskOID: number;
  setOnVisible: Function;
}) {
  const navigate = useNavigate();
  const { assignTaskStore } = useStore();
  const {
    createAssignTask,
    updateAssignTask,
    loadAssignTask,
    deleteAssignTask,
    loadingInitial,
  } = assignTaskStore;
  const baseAPI = process.env.REACT_APP_API_URL; // "http://localhost:5000/api";
  const jwt = store.commonStore.token;
  const [assignTaskToRole, setAssignTaskToRole] = useState<AssignTask | any>(
    []
  );
  const [assignTask, setAssignTask] = useState<AssignTaskFormValues>(
    new AssignTaskFormValues()
  );
  const validationSchema = Yup.object({
    taskOID: Yup.string(),
    userRoleTaskOID: Yup.string().required("The userRoleTaskOID is required"),
    ApplicationDescription: Yup.string().required(
      "The ApplicationDescription is required"
    ),
    //date: Yup.string().required("Date is required").nullable(),
    ApplicationOID: Yup.string().required("The  ApplicationOID is required"),
    TaskCode: Yup.string().required("The  TaskCode is required"),
    TaskDescription: Yup.string().required("The  TaskDescription is required"),
    TaskOrder: Yup.string().required("The  TaskOrder is required"),
    UiDescription: Yup.string().required("The  UiDescription is required"),
    ModuleDescription: Yup.string().required(
      "The  ModuleDescription is required"
    ),
  });

  function handleErrors(response: any) {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  }

  const handleFormSubmit = (values: any) => {
    // console.log(values);
    fetch(`${baseAPI}/EcoCountry/`, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        Authorization: `Bearer ${jwt}`,
        "Content-Type": "application/json",
      },
    }).then((response) => {
      response.json().then((response) => {
        // console.log(response);
        setOnVisible();

        return {
          data: response.result.data,
        };
      });
    });
  };
  const dataOptions = [
    { text: "BSP", value: "BSP" },
    { text: "MSME", value: "MSME" },
  ];
  return (
    <div>
      <Formik
        validationSchema={validationSchema}
        enableReinitialize
        initialValues={assignTaskToRole}
        onSubmit={(values) => {
          handleFormSubmit(values);
          // setOnVisible();
        }}>
        {({ handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit} className="ui form" autoComplete="off">
            <SelectAssign
              options={dataOptions}
              placeholder="Select Category"
              name="assigntasktorole"
            />
          </Form>
        )}
      </Formik>
    </div>

    // </Segment>
  );
});

import { observer } from "mobx-react-lite";
import React from "react";
import { Tab } from "semantic-ui-react";
import { Profile } from "../../app/models/profile";
import { useStore } from "../../app/stores/store";
import { LinkageMap } from "../views/LinkageMap";
import ProfileAbout from "./ProfileAbout";
import ProfileActivities from "./ProfileActivities";
import ProfileBlinkhubStatus from "./ProfileBlinkhubStatus";
import ProfileBusinessInformation from "./ProfileBusinessInformation";
import ProfileFollowers from "./ProfileFollowers";
import ProfileFollowings from "./ProfileFollowings";
import ProfileManagementInfo from "./ProfileManagementInfo";
import ProfileMyPreferences from "./ProfileMyPreferences";
import ProfilePersonalData from "./ProfilePersonalData";
import ProfilePhotos from "./ProfilePhotos";
import ProfileProductServiceConsumed from "./ProfileProductServiceConsumed";
import ProfileProductServiceOffered from "./ProfileProductServiceOffered";
import ProfileQualification from "./ProfileQualification";

interface Props {
  profile: Profile;
}

export default observer(function ProfileContent({ profile }: Props) {
  const { profileStore, userStore } = useStore();
  const { user } = userStore;

  // const panes = [
  //     {menuItem: 'About', render: () => <ProfileAbout />},
  //     {menuItem: 'Photos', render: () => <ProfilePhotos profile={profile} />},
  //     {menuItem: 'Events', render: () => <ProfileActivities />},
  //     {menuItem: 'Followers', render: () => <ProfileFollowings />},
  //     {menuItem: 'Following', render: () => <ProfileFollowings />},
  // ];

  const panes = [
    { menuItem: "Default Information", render: () => <ProfileAbout /> },
    { menuItem: "Personal Details", render: () => <ProfilePersonalData /> },
    {
      menuItem: "Business Information",
      render: () => <ProfileBusinessInformation userGUID={user?.userGUID} />,
    },
    {
      menuItem: "Professional Qualification",
      render: () => <ProfileQualification />,
    },
    {
      menuItem: "Management Information",
      render: () => <ProfileManagementInfo />,
    },
    {
      menuItem: "Products/Services I Offer",
      render: () => <ProfileProductServiceOffered />,
    },
    {
      menuItem: "Product/Service Consumed",
      render: () => <ProfileProductServiceConsumed />,
    },
    { menuItem: "My Linkage Map", render: () => <LinkageMap /> },
    {
      menuItem: "My Photo Gallery",
      render: () => <ProfilePhotos profile={profile} />,
    },
    { menuItem: "Events", render: () => <ProfileActivities /> },
    { menuItem: "Followers", render: () => <ProfileFollowers /> },
    { menuItem: "Following", render: () => <ProfileFollowings /> },
    { menuItem: "My Preferences", render: () => <ProfileMyPreferences /> },
    { menuItem: "Blinkhub Status", render: () => <ProfileBlinkhubStatus /> },
  ];

  return (
    <Tab
      menu={{
        color: "brown",
        fluid: true,
        vertical: true,
        inverted: true,
        attached: false,
        tabular: true,
      }}
      menuPosition="left"
      panes={panes}
      onTabChange={(e, data) => profileStore.setActiveTab(data.activeIndex)}
    />
  );
});

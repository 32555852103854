import React from "react";
import { Grid, Table } from "semantic-ui-react";
import MyTextInput from "../../app/common/form/MyTextInput";
import classes from "./coster.module.css";

type Props = {
  values:any
};

const ContractInformation = (props: Props) => {
  // const values = props.values
  return (
    <section>
      <div  className="">
        <Grid className={classes.border}>
          <Grid.Row columns={1} className={classes.row}>
            <Grid.Column className={classes.column}>
              <header className={classes.header}>Contract Information</header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={2} className={classes.row}>
            <Grid.Column className={classes.column}>
              <Table celled basic="very">
                <Table.Body>
                  <TableRow
                    label={" LPO Number"}
                    placeholder={"12"}
                    name={"lopNumber"}
                  />

                  <TableRow
                    label={" LPO Date"}
                    placeholder={"12"}
                    name={"lopDate"}
                  />
                  <TableRow
                    label={"  Expected Delivery Date"}
                    name={"expectedDeliveryDate"}
                    placeholder={"12-Dec-2021"}
                  />
                  <TableRow
                    label={"  Actual Delivery Date"}
                    name={"actualDeliveryDate"}
                    placeholder={"12-Dec-2021"}
                  />
                  <TableRow
                    label={"LPO Value"}
                    name={"lpoValue"}
                    placeholder={"12"}
                  />
                  {/* <TableRow label={""} name={"a"} placeholder={""} /> */}
                  <TableRow
                    label={"Payment Terms"}
                    name={"paymentTerms"}
                    placeholder={"14 Days"}
                  />
                  <TableRow
                    label={"Expected Payment Date"}
                    name={"expectedPaymentDate"}
                    placeholder={""}
                  />
                  <TableRow
                    label={"Funding Source"}
                    name={"fundingSource"}
                    placeholder={"CAD Intermediate funds"}
                  />
                  <TableRow
                    label={"Intermediate Fund (N)"}
                    name={"intermediateFunds"}
                    placeholder={"N 565,000.00"}
                  />
                  <TableRow
                    label={"Stakeholders"}
                    name={"stakeholders"}
                    placeholder={""}
                  />
                  <TableRow
                    label={"Stakeholders Sharing Ratio-CAD"}
                    name={"sharingRatioA"}
                    placeholder={""}
                  />
                  <TableRow
                    label={"Stakeholders Sharing Ratio-CDF"}
                    name={"sharingRatioB"}
                    placeholder={"10"}
                  />
                </Table.Body>
              </Table>
            </Grid.Column>
            <Grid.Column className={classes.column}>
              {/* First Table on the right side  */}
              <Table celled structured className={classes.table_1}>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell
                      textAlign="center"
                      colSpan={2}
                      className={classes.table_header_1}
                    >
                      Corporate Buyer Details
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <TableRow2
                    label={"Company Name"}
                    name={"buyerCompanyName"}
                    placeholder={"Spar Port Harcourt Mall"}
                  />
                  <TableRow2
                    label={"Contact Person"}
                    name={"buyerContactPerson"}
                    placeholder={""}
                  />
                  <TableRow2
                    label={"Contact Phone"}
                    name={"buyerPhone"}
                    placeholder={""}
                  />
                  <TableRow2
                    label={"Contact Email"}
                    name={"buyerEmail"}
                    placeholder={""}
                  />
                </Table.Body>
              </Table>
              {/* Second Table on the right side  */}
              <Table celled structured className={classes.table_1}>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell
                      textAlign="center"
                      colSpan={2}
                      className={classes.table_header_2}
                    >
                      Procedure Information
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <TableRow2
                    label={"Company Name"}
                    name={"procurerCompanyName"}
                    placeholder={"Spar Port Harcourt Mall"}
                  />
                  <TableRow2
                    label={"Contact Person"}
                    name={"procurerContactPerson"}
                    placeholder={""}
                  />
                  <TableRow2
                    label={"Contact Phone"}
                    name={"procurerPhoneNumber"}
                    placeholder={""}
                  />
                  <TableRow2
                    label={"Contact Email"}
                    name={"procurerEmail"}
                    placeholder={""}
                  />
                  <TableRow2
                    label={"Commodity Source"}
                    name={"commoditySource"}
                    placeholder={"slaughter"}
                  />
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </section>
  );
};

interface ITableRow {
  label: string;
  name: string;
  placeholder: string;
}

export const TableRow2: React.FC<ITableRow> = ({
  label,
  name,
  placeholder,
}) => {
  return (
    <Table.Row className={`${classes.row_border} ${classes.row_border_3}`}>
      <Table.Cell textAlign="right" width={6}>
        {label}
      </Table.Cell>
      <Table.Cell textAlign="left" width={10}>
        <MyTextInput placeholder={placeholder} name={name} />
      </Table.Cell>
    </Table.Row>
  );
};

export const TableRow: React.FC<ITableRow> = ({ label, name, placeholder }) => {
  return (
    <Table.Row className={`${classes.row_border} ${classes.row_border_2}`}>
      <Table.Cell textAlign="right" width={7}>
        {label}
      </Table.Cell>
      <Table.Cell textAlign="left" width={9}>
        <MyTextInput placeholder={placeholder} name={name} />
      </Table.Cell>
    </Table.Row>
  );
};

export default ContractInformation;

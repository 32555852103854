import React from "react";
import DxHtmlEditor from "../../../../app/common/form/DXHtmlEditor";
import classes from "./ticket.module.css";

type Props = {};

const RecommendedSolution: React.FC<Props> = () => {
  return (
    <>
      <header className={classes.header_3}>Recommended Solution </header>

      <div className=''>
        <DxHtmlEditor name={"recommendedSolution"} height='725px' />
      </div>
    </>
  );
};

export default RecommendedSolution;

export interface AssignTask {
  taskOID: string;
  userRoleTaskOID: string;
  ApplicationDescription: string;
  ApplicationOID: string;
  TaskCode: string;
  TaskDescription: string;
  TaskOrder: string;
  UiDescription: number;
  ModuleDescription: number;
  dateUploaded: Date | null;

  // date: Date | null;
  // description: string;
  // category: string;
  // city: string;
  // venue: string;
  // hostUsername: string;
  // isCancelled: boolean;
  // isGoing: boolean;
  // isHost: boolean;
  // host?: Profile;
  // attendees: Profile[]
}

export class AssignTask implements AssignTask {
  constructor(init?: AssignTaskFormValues) {
    Object.assign(this, init);
  }
}

export class AssignTaskFormValues {
  taskOID?: string = undefined;
  userRoleTaskOID: string = "";
  ApplicationDescription: string = "";
  ApplicationOID: string = "";
  TaskCode: string = "";
  TaskDescription: string = "";
  TaskOrder: string = "";
  UiDescription: number = 0;
  ModuleDescription: number = 0;

  // dateUploaded: Date  = Date.now();

  constructor(assigntask?: AssignTaskFormValues) {
    if (assigntask) {
      this.taskOID = assigntask.taskOID;
      this.userRoleTaskOID = assigntask.userRoleTaskOID;
      this.ApplicationDescription = assigntask.ApplicationDescription;
      this.ApplicationOID = assigntask.ApplicationOID;
      this.TaskCode = assigntask.TaskCode;
      this.TaskDescription = assigntask.TaskDescription;
      this.TaskOrder = assigntask.TaskOrder;
      this.UiDescription = assigntask.UiDescription;
      this.ModuleDescription = assigntask.ModuleDescription;
    }
  }
}

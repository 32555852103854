export interface TaskModel {
  taskOID: string;
  applicationCode: string;
  moduleDescription: string;
  taskDescription: string;
  userRoleTaskOID: string;
  canEdit: string;

  dateUploaded: Date | null;

  // date: Date | null;
  // description: string;
  // category: string;
  // city: string;
  // venue: string;
  // hostUsername: string;
  // isCancelled: boolean;
  // isGoing: boolean;
  // isHost: boolean;
  // host?: Profile;
  // attendees: Profile[]
}

export class TaskModel implements TaskModel {
  constructor(init?: TaskFormValues) {
    Object.assign(this, init);
  }
}

export class TaskFormValues {
  taskOID?: string = undefined;
  applicationCode: string = "";
  moduleDescription: string = "";
  taskDescription: string = "";
  userRoleTaskOID: string = "";
  canEdit: string = "";

  // dateUploaded: Date  = Date.now();

  constructor(task?: TaskFormValues) {
    if (task) {
      this.taskOID = task.taskOID;
      this.applicationCode = task.applicationCode;
      this.moduleDescription = task.moduleDescription;
      this.taskDescription = task.taskDescription;
      this.userRoleTaskOID = task.userRoleTaskOID;
      this.canEdit = task.canEdit;
    }
  }
}

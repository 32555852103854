import { observer } from "mobx-react-lite";
import React, { Fragment } from "react";
import { Header } from "semantic-ui-react";
import { useStore } from "../../../app/stores/store";
import { BusinessPlan } from "../../create/posts/BusinessPlan";
import ActivityListItem from "./ActivityListItem";
import AdvertisementListItem from "./AdvertisementListItem";
import BusinessOpportunityListItem from "./BusinessOpportunityListItem";
import BusinessPlanListItem from "./BusinessPlanListItem";
import OpenConversationListItem from "./OpenConversationListItem";
import PollListItem from "./PollListItem";
import PublicNoticeListItem from "./PublicNoticeListItem";
import SupportTicketListItem from "./SupportTicketListItem";

export default observer(function ActivityList() {
  const { activityStore } = useStore();
  const { groupedActivities } = activityStore;

  return (
    <>
      {groupedActivities.map(([group, activities]) => (
        <Fragment key={group}>
          <Header sub color="teal">
            {group}
          </Header>
          {activities.map((activity) => (
            <>
              {console.log(activity.activityType)}
              {/* <ActivityListItem key={activity.id} activity={activity} /> */}
              {/* {activity.activityType === "Event" ? ( */}
              {console.log("Activity Type Log",activity.activityType)}
              <ActivityListItem key={activity.id} activity={activity} />
              {/* ) : null} */}
              <BusinessPlanListItem key={activity.id} activity={activity} />
              {/* <PublicNoticeListItem key={activity.id} activity={activity} />
              <SupportTicketListItem key={activity.id} activity={activity} />
              <AdvertisementListItem key={activity.id} activity={activity} />
              <PollListItem key={activity.id} activity={activity} />
              <OpenConversationListItem key={activity.id} activity={activity} />
              <BusinessOpportunityListItem
                key={activity.id}
                activity={activity}
              /> */}
              {/* if (activity.actiityType==="Quiz")
              {
                <BusinessOpportunityListItem
                  key={activity.id}
                  activity={activity}
                />
              } */}
              {/*  */}
              {/* <PublicNoticeListItem key={activity.id} activity={activity} />
                Event
                PubNote
                Support
                Advert
                Poll
                OpenConv
                BizOpp
                Quiz

                <SupportTicketListItem key={activity.id} activity={activity} />
                <AdvertisementListItem key={activity.id} activity={activity} />
                <PollListItem key={activity.id} activity={activity} />
                <OpenConversationListItem
                  key={activity.id}
                  activity={activity}
                />
                <BusinessOpportunityListItem
                  key={activity.id}
                  activity={activity}
                /> */}
            </>
          ))}
        </Fragment>
      ))}
    </>
  );
});

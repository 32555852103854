import React, {  useState } from "react";
import { Segment } from "semantic-ui-react";

import { Popup } from "devextreme-react/popup";
// import "devextreme/dist/css/dx.common.css";
// import "devextreme/dist/css/dx.light.css";
import "devextreme-react/text-area";
// import { Item } from "devextreme-react/form";
// import ResourceForm from "../../resources/form/ResourceForm";

import ApplicationForm from "../ecoforms/ApplicationForm";
// import CountryPopup from "./CountryPopup";

//import { baseAPI } from "../../../api/APIEndpoints";

import DataGrid, {
  FilterPanel,
  FilterRow,
  SearchPanel,
  Button,
  Paging,
  // Lookup,
  // Popup,
  // Position,
  Editing,
  Export,
  Pager,
  Grouping,
  GroupPanel,
  RowDragging,
  Column,
  RequiredRule,
  // PatternRule,
  // EmailRule,
  // AsyncRule,
} from "devextreme-react/data-grid";
// import { useSelector } from "react-redux";
import CustomStore from "devextreme/data/custom_store";
import "whatwg-fetch";
import { store, useStore } from "../../../app/stores/store";
import ApplicationPopup from "../ecoforms/ApplicationPopup";


export const Application = () => {
  const [dataSource, setDataSource] = useState({});
  const [applicationOID, setApplicationOID] = useState("GRID");
  const [rowData, setRowData]: any = useState({});
  const { applicationStore } = useStore();
  const { loading, setLoadingInitial, loadingInitial } = applicationStore;
  const [mode, setMode] = useState("GRID");
  const [onVisible, setOnVisible] = useState(false);

  const baseAPI = process.env.REACT_APP_API_URL; // "http://localhost:5000/api";

  const jwt = store.commonStore.token;
  function handleErrors(response: any) {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  }

  const handleFormSubmit: any = new CustomStore({
    // key: "countryOID",
    load: () => {
      return fetch(`${baseAPI}/EcoApplications`, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      })
        .then(handleErrors)
        .then((response) => response.json())
        .then((response) => {
          return {
            data: response.result,
            totalCount: response.count,
            summary: response.summary,
            groupCount: response.count,
          };
        });
    },
    insert: (values: any) => {
      return fetch(`${baseAPI}/EcoApplications`, {
        method: "POST",
        body: JSON.stringify(values),
        headers: {
          Authorization: `Bearer ${jwt}`,
          "Content-Type": "application/json",
        },
      }).then(handleErrors);
    },
    remove: (key) => {
      return fetch(`${baseAPI}/EcoApplications`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${jwt}`,
          "Content-Type": "application/json",
        },
      }).then(handleErrors);
    },
    update: (values) => {
      return values;
    },
  });

  const handleEdit = (e: any) => {
    // const data = e.row.data;
    setRowData((data: any) => {
      return { ...e.row.result.data };
    });
    setMode("FORM-EDIT");
    // console.log(rowData);
  };

  const handleAddNew = () => {
    setOnVisible(true);
  };

  const handleView = (e: any) => {
    // const data = e.row.data;
    setApplicationOID(e.row.data.applicationOID);
    setLoadingInitial(true);
    // console.log(e.row.data);
    setMode("FORM-EDIT");
    // console.log(rowData);
  };

  function onToolbarPreparing(e: any) {
    let toolbarItems = e.toolbarOptions.items;

    // customize addRow toolbar button
    for (let i = 0; i < toolbarItems.length; i++) {
      let item = toolbarItems[i];
      if (item.name === "addRowButton") {
        item.options.onClick = handleAddNew;
        break;
      }
    }
  }

  return mode === "GRID" ? (
    <Segment>
      <h1>Application</h1>
      <div className="ui warning message">
        <div className="header">Manage Applications.</div>
        <p>Create and manage Applications.</p>
      </div>
      <div className="ui divider" title="List of Countries"></div>
      <div>
        <DataGrid
          id="grdResources"
          dataSource={handleFormSubmit}
          // className="dx-datagrid dx-row-alt"
          hoverStateEnabled={true}
          columnAutoWidth={true}
          activeStateEnabled={true}
          columnResizingMode="widget"
          allowColumnResizing={true}
          onToolbarPreparing={onToolbarPreparing}
          // onRowUpdating={onRowUpdating}
          columnHidingEnabled={true}>
          <RowDragging allowReordering={true} />
          <FilterPanel visible={true} />
          <SearchPanel visible={true} />
          <FilterRow visible={true} />
          <Paging defaultPageSize={10} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={[10, 20, 50, 100]}
            showInfo={true}
          />
          <Grouping contextMenuEnabled={true} />
          <GroupPanel visible={true} />
          <Editing
            mode="popup"
            useIcons={true}
            allowUpdating={true}
            allowDeleting={true}
            allowAdding={true}>
            {" "}
          </Editing>

          {/* <Popup
            title="Resources"
            showTitle={true}
            width={40}
            height={30}></Popup> */}

          <Export
            enabled={true}
            fileName="Country"
            allowExportSelectedData={true}
          />

          <Column type="buttons">
            <Button text="View" onClick={handleView} cssClass={"edit-btn"} />
          </Column>
          {/* <Column type="buttons" width={110}>
            <Button name="edit" />
            <Button name="delete" />
          </Column> */}
          <Column dataField="applicationOID" allowEditing={false}></Column>
          <Column dataField="applicationName">
            <RequiredRule />
          </Column>
          <Column dataField="applicationDescription">
            <RequiredRule />
          </Column>
          <Column dataField="appPrefix">
            <RequiredRule />
          </Column>
          <Column dataField="status" dataType="number">
            <RequiredRule message="Status is required and it must be a number" />
          </Column>

          <Column dataField="applicationOrder" dataType="number">
            <RequiredRule message="Application Order is required and must be a number" />{" "}
          </Column>
          <Column dataField="icon"> </Column>
        </DataGrid>
        {/* <Popup
          // title="Create"
          // showTitle={true}
          visible={onVisible}
          showCloseButton={false}
          width={800}
          minHeight={"auto"}
          height={1000}>
          <ApplicationPopup
            setOnVisible={() => setOnVisible(false)}
            applicationOID={0}
          />
        </Popup> */}
      </div>
      Application
    </Segment>
  ) : (
    <ApplicationForm
      setMode={() => setMode("GRID")}
      applicationOID={applicationOID}
    />
  );
};

import React from "react";
import { Segment } from "semantic-ui-react";

export const Survey = () => {
  return (
    <Segment>
      <h1>Survey</h1>
      <div className="ui warning message">
        <div className="header">Manage Survey.</div>
        <p>Create and manage Survey.</p>
      </div>
      <div className="ui divider" title="List of Resources"></div>
    </Segment>
  );
};

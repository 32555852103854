import React, {useState} from 'react';
import {useStore} from "../../app/stores/store";
import {Button, Grid, Header, Segment, Tab} from "semantic-ui-react";
import ProfileEditForm from "./ProfileEditForm";
import { observer } from 'mobx-react-lite';

export default observer(function ProfileAbout() {
    const {profileStore} = useStore();
    const {isCurrentUser, profile} = profileStore;
    const [editMode, setEditMode] = useState(false);

    return (
      <Tab.Pane>
        <Segment>
          <h1>Default Information</h1>

          <div className="ui warning message">
            {/* {isCurrentUser && (
              <Button
                floated="right"
                basic
                content={editMode ? "Cancel" : "Edit Data"}
                onClick={() => setEditMode(!editMode)}
              />
            )} */}
            <div className="header">Default Information Update.</div>
            <p>
              This is your default profile as soon as you signup with Blinkhub. You are required to update you full profile for us to "Blink" your business for you.
            </p>
          </div>
          <div className="ui divider" title="List of Resources"></div>
        </Segment>
        <Grid>
          <Grid.Column width="16">
            {/* <Header
              floated="left"
              icon="user"
              content={`About ${profile?.displayName}`}
            />
            {isCurrentUser && (
              <Button
                floated="right"
                basic
                content={editMode ? "Cancel" : "Edit Profile"}
                onClick={() => setEditMode(!editMode)}
              />
            )} */}
          </Grid.Column>
          <Grid.Column width="16">
            {/* {editMode ? ( */}
              <ProfileEditForm setEditMode={setEditMode} />
            {/* ) : (
              <span style={{ whiteSpace: "pre-wrap" }}>{profile?.bio}</span>
            )} */}
          </Grid.Column>
        </Grid>
      </Tab.Pane>
    );
})

import React, { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Formik } from "formik";
import * as Yup from "yup";
import { CosterCommoditysFormValues } from "../../../app/models/costerCommoditys";
import { useStore } from "../../../app/stores/store";
import MyTextInput from "../../../app/common/form/MyTextInput";
import { Button, Form, Header, Icon, Message } from "semantic-ui-react";
import DXLoadingComponent from "../../../app/layout/DXLoadingComponent";
import { IMasterDetail } from "../../../app/common/staticProps";
import { AxiosError } from "axios";
import notify from "devextreme/ui/notify";

type Props = {
  rowID: number;
  setMode: React.Dispatch<React.SetStateAction<IMasterDetail>>;
};

const CosterCommoditysForm: React.FC<Props> = ({ rowID, setMode }) => {
  const [initialValues, setInitialValues] =
    useState<CosterCommoditysFormValues>(new CosterCommoditysFormValues());
  const { costerCommoditysStore } = useStore();
  const {
    createCommodity,
    updateCommodity,
    loadCommodity,
    loadingInitial,
    setLoadingInitial,
  } = costerCommoditysStore;

  const validationSchema = Yup.object({
    commodityDescription: Yup.string().required("Description is required"),
    unitPrice: Yup.number().required("unit price is required"),
    unitOfMeasure: Yup.string().required("Unit of measure is required"),
  });

  const handleBack = useCallback(() => {
    setMode("GRID");
  }, [setMode]);

  useEffect(() => {
    if (!rowID) return setLoadingInitial(false);
    loadCommodity(rowID)
      .then((res: any) => {
        // loadCommodity(0).then((res: any) => {
        const response = res.result[0];
        setInitialValues(new CosterCommoditysFormValues(response));
      })
      .catch((err: AxiosError) => {
        // console.log("Big Error", err);
        notify("Bad Request", "error", 3000);
        handleBack();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowID]);

  const handleSubmit = (values: CosterCommoditysFormValues) => {
    try {
      if (rowID) {
        const newResources = {
          ...values,
        };
        updateCommodity(newResources).then(() => {
          handleBack();
        });
      } else {
        createCommodity(values).then(() => {
          handleBack();
        });
      }
    } catch (error) {
      notify("Server Error", "error", 3000);
    }
  };

  if (loadingInitial) return <DXLoadingComponent />;

  //   debugger;
  //   console.log("commodityRegistry", commodityRegistry.values());

  return (
    <div>
      <Header as='h2'>
        <Icon
          onClick={handleBack}
          size='big'
          name='arrow circle left'
          color='blue'
          style={{ cursor: "pointer" }}
        />
        Commodity
      </Header>
      <Message color='brown' size='mini'>
        <Message.Header>Manage Commodity</Message.Header>
        <p> You can create and manage a Commodity. </p>
      </Message>
      <Formik
        validationSchema={validationSchema}
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values) => handleSubmit(values)}>
        {({ handleSubmit, isValid, isSubmitting, dirty }) => (
          <Form className='ui form' autoComplete='off' onSubmit={handleSubmit}>
            <Form.Group widths={2}>
              <MyTextInput
                placeholder='offtakerPriceOID'
                name='offtakerPriceOID'
                label='ID'
                disabled
              />
              <MyTextInput
                placeholder='unitPrice'
                name='unitPrice'
                label='unit price'
              />
            </Form.Group>
            <Form.Group widths={2}>
              <MyTextInput
                placeholder='commodityDescription'
                name='commodityDescription'
                label='commodity description'
              />
              <MyTextInput
                placeholder='unitOfMeasure'
                name='unitOfMeasure'
                label='unit of measure'
              />
            </Form.Group>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                disabled={isSubmitting || !dirty || !isValid}
                loading={isSubmitting}
                floated='right'
                positive
                type='submit'
                content='Submit'
              />
              <Button
                onClick={handleBack}
                to='/resources'
                floated='right'
                type='button'
                content='Cancel'
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default observer(CosterCommoditysForm);

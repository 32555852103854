import React from 'react';
import {Tab, Grid, Header, Card, Segment} from "semantic-ui-react";
import ProfileCard from "./ProfileCard";
import {useStore} from "../../app/stores/store";
import { observer } from 'mobx-react-lite';

export default observer(function ProfileFollowings() {
    const {profileStore} = useStore();
    const {profile, followings, loadingFollowings, activeTab} = profileStore;

    return (
      <Tab.Pane loading={loadingFollowings}>
        <Segment>
          <h1>View Followings</h1> 
          <div className="ui warning message">
            <div className="header">View Businesses you are Following.</div>
            <p>
                        You can view all the businesses you are following from here. These are businesses you are getting business support from or business providing services to your business. They are your Business Support Providers (BSP).
                    </p>
          </div>
          <div className="ui divider" title=""></div>
        </Segment>
        <Grid>
          <Grid.Column width="16">
            <Header
              floated="left"
              icon="user"
              content={
                activeTab === 3
                  ? `People following ${profile!.displayName}`
                  : `People ${profile?.displayName} is following`
              }
            />
          </Grid.Column>
          <Grid.Column width="16">
            <Card.Group itemsPerRow={4}>
              {followings.map((profile) => (
                <ProfileCard key={profile.username} profile={profile} />
              ))}
            </Card.Group>
          </Grid.Column>
        </Grid>
      </Tab.Pane>
    );
})

import { observer } from "mobx-react-lite";
import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Image,
  Container,
  Message,
  Divider,
  Segment,
  Header,
  Grid,
  Card,
} from "semantic-ui-react";
// import { Activity } from '../../../app/models/activity';
// import {format} from 'date-fns';
// import ActivityListItemAttendee from './ActivityListItemAttendee';

// interface Props {
//     activity: Activity
// }

const dashboardItems = [
  {
    id: "1",
    title: "Create and Manage Business Plan",
    description: "Create a professional business plan in minutes.  ",
    image: "/assets/BH-business-plan.jpg",
    link: "/businessplan-workspace",
    tab: true,
  },
  {
    id: "2",
    title: "Get Business support from experts",
    description:
      "Interact with Business Support Providers to help you out with business related issues.",
    image: "/assets/BH-business-support-services.jpg",
    link: "/view-businessclinic",
    tab: false,
  },
  {
    id: "3",
    title: "I need Business Linkage",
    description:
      "Get linked to various  stakeholders an business oppotunities. ",
    image: "/assets/BH-business-linkages.png",
    link: "/view-linkagemap",
    tab: false,
  },
  {
    id: "4",
    title: "Visit Business Clinic",
    description: "Report issues and allow business 'doctors' to fix it. ",
    image: "/assets/BH-BUSINESS-CLINIC.jpg",
    link: "/view-businessclinic",
    tab: false,
  },
  {
    id: "5",
    title: "Look for Upcoming events",
    description: "Search for an upcomming event that is beneficial to attend. ",
    image: "/assets/BH-upcomming-events.jpg",
    link: "/activities",
    tab: false,
  },
  {
    id: "6",
    title: "Helpers online",
    description:
      "Access business support providers currently online to help you .",
    image: "/assets/BH-helpers-online.png",
    link: "/view-helpersonline",
    tab: false,
  },
];

const HelpersOnlineList = () => {
  return (
    <Container>
      <Segment.Group style={{ marginTop: "50px" }}>
        <Segment color="brown">
          <Header size="medium">Helpers Online </Header>
          <Message
            icon="info circle"
            color="brown"
            content="Blinkhub provides you with a lot of business support services. Tou can select one from the list provided below"
          />
          <Header dividing as="h3">
            Here is a list of BSP's online
          </Header>

          <Grid columns="3" stackable stretched>
            {dashboardItems.map((item) => (
              <>
                <Grid.Column key={item.id} stretched name="dashboard">
                  <Card
                    style={{
                      height: "100%",
                      boxShadow: "0 5px 15px rgba(0, 0, 0, 0.2)",
                    }}
                    as={Link}
                    target={item.tab && "_blank"}
                    to={`${item.link}`}
                    labelPosition="right">
                    {/* <Label style={{textAlign:'center'}} as="div" color="brown" > */}
                    {/* <Icon fitted size='massive' name="building outline" /> */}
                    <Image
                      style={{ height: "150px" }}
                      src={item.image}
                      fluid
                      verticalAlign="middle"
                    />
                    {/* </Label> */}
                    <Card.Content>
                      <Card.Description
                        basic
                        // color='brown'
                        // as={Link}
                        // to={`${item.link}`}
                      >
                        <h5>{item.title}</h5>
                      </Card.Description>
                      <Divider />
                      <Card.Meta> {item.description} </Card.Meta>
                    </Card.Content>
                    <Card.Content>
                      <Button fluid content="Continue" color="brown" />
                    </Card.Content>
                  </Card>
                </Grid.Column>
              </>
            ))}
          </Grid>
        </Segment>
      </Segment.Group>
    </Container>
  );
};
export default observer(HelpersOnlineList);

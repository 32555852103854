import React from "react";
import { ErrorMessage, Form, Formik } from "formik";
// import { useHistory } from "react-router";
import { observer } from "mobx-react-lite";
import { createMedia } from "@artsy/fresnel";
import {
  Button,
  Header,
  Label,
  // Segment,
  // Grid,
  // Divider,
  // Container,
  Image,
} from "semantic-ui-react";
import MyTextInput from "../../../app/common/form/MyTextInput";
import { useStore } from "../../../app/stores/store";
import "./LoginForm.css";
import { Link } from "react-router-dom";

const { MediaContextProvider, Media } = createMedia({
  // breakpoints values can be either strings or integers
  breakpoints: {
    sm: 0,
    md: 768,
    lg: 1024,
    xl: 1569,
  },
});

export default observer(function LoginForm() {
  const { userStore } = useStore();
  // const history = useHistory();
  return (
    <MediaContextProvider>
      <div className="login-screen">
        <div className="">
          <div className="login-container">
            <div className="curve-circle" />
            <section className="login-left">
              <div className="img-continer">
                <Media greaterThanOrEqual="md">
                  <Image
                    centered
                    size="small"
                    src="/assets/Blinkhub_Logo.png"
                  />
                </Media>
              </div>
              <Header className="header" textAlign="center" as="h2">
                Login to BlinkHub
              </Header>
              <Formik
                initialValues={{ email: "", password: "", error: null }}
                onSubmit={(values, { setErrors }) =>
                  userStore
                    .login(values)
                    .catch((error) => setErrors({ error: error.response.data }))
                }>
                {({ handleSubmit, isSubmitting, errors }) => (
                  <Form
                    className="ui form"
                    onSubmit={handleSubmit}
                    autoComplete="off">
                    <div className="form-control">
                      <label htmlFor="user-name">User Name</label>
                      <MyTextInput
                        id="user-name"
                        name="email"
                        placeholder="Email"
                      />
                    </div>
                    <div className="form-control">
                      <label htmlFor="pasword">Password</label>
                      <MyTextInput
                        id="password"
                        name="password"
                        placeholder="Password"
                        type="password"
                      />
                    </div>
                    <ErrorMessage
                      name="error"
                      render={() => (
                        <Label
                          style={{ marginBottom: 10 }}
                          basic
                          color="red"
                          content={errors.error}
                        />
                      )}
                    />
                    <div className="button-position">
                      <Button
                        loading={isSubmitting}
                        inverted
                        color="brown"
                        content="Login"
                        type="submit"
                        fluid
                      />
                      <Button
                        color="brown"
                        inverted
                        content="Cancel"
                        as={Link}
                        to="/"
                        fluid
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </section>
            <section className="login-right">
              <h2> Welcome, </h2>
              <p>To the world of business support services </p>
              <h3> Business Linkage Network </h3>
            </section>
            <Media lessThan="md">
              <Image centered size="small" src="/assets/Blinkhub_Logo.png" />
            </Media>
            {/* </div> */}
          </div>
        </div>
      </div>
    </MediaContextProvider>
  );
});

// {
//   /* <Segment placeholder>
//       <Formik
//         initialValues={{ email: "", password: "", error: null }}
//         onSubmit={(values, { setErrors }) =>
//           userStore
//             .login(values)
//             .catch((error) => setErrors({ error: error.response.data }))
//         }>
//         {({ handleSubmit, isSubmitting, errors }) => (
//           <Form
//             // className="ui form"
//             onSubmit={handleSubmit}
//             autoComplete="off">
//             <Header
//               as="h2"
//               content="Login to Blinkhub"
//               color="teal"
//               textAlign="center"
//             />
//             <MyTextInput name="email" placeholder="Email" />
//             <MyTextInput
//               name="password"
//               placeholder="Password"
//               type="password"
//             />
//             <ErrorMessage
//               name="error"
//               render={() => (
//                 <Label
//                   style={{ marginBottom: 10 }}
//                   basic
//                   color="red"
//                   content={errors.error}
//                 />
//               )}
//             />
//             <Button
//               loading={isSubmitting}
//               positive
//               content="Login"
//               type="submit"
//               fluid
//             />
//           </Form>
//         )}
//       </Formik>
//     </Segment> */
// }

// original comments
// {
//   /* <Grid columns={2} relaxed="very" stackable>
//         <Grid.Column width={10}>
//           <Formik
//             initialValues={{ email: "", password: "", error: null }}
//             onSubmit={(values, { setErrors }) =>
//               userStore
//                 .login(values)
//                 .catch((error) => setErrors({ error: error.response.data }))
//             }
//           >
//             {({ handleSubmit, isSubmitting, errors }) => (
//               <Form
//                 className="ui form"
//                 onSubmit={handleSubmit}
//                 autoComplete="off"
//               >
//                 <Header
//                   as="h2"
//                   content="Login to Blinkhub"
//                   color="teal"
//                   textAlign="center"
//                 />
//                 <MyTextInput name="email" placeholder="Email" />
//                 <MyTextInput
//                   name="password"
//                   placeholder="Password"
//                   type="password"
//                 />
//                 <ErrorMessage
//                   name="error"
//                   render={() => (
//                     <Label
//                       style={{ marginBottom: 10 }}
//                       basic
//                       color="red"
//                       content={errors.error}
//                     />
//                   )}
//                 />
//                 <Button
//                   loading={isSubmitting}
//                   positive
//                   content="Login"
//                   type="submit"
//                   fluid
//                 />
//               </Form>
//             )}
//           </Formik>
//         </Grid.Column>

//         <Grid.Column width={4}  verticalAlign="middle">
//           <Button content="Sign up" icon="signup" size="big" />
//         </Grid.Column>
//       </Grid>

//       <Divider vertical>Or</Divider>
//        */
// }

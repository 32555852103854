//*************************************************************
// PROJECT NAME : Blinh Hub Network
// CLASS        : MSMEBlinkActivityFeedback
// DATE CREATED : 14 February 2022
// TIME CREATED : 04:53:53
// DEVELOPER    : Ibinaiye Wale Kayode
//*************************************************************

import React, { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Formik } from "formik";
import * as Yup from "yup";
import { MSMEBlinkActivityFeedbackFormValues } from "../../../app/models/MSMEBlinkActivityFeedback";
import { useStore } from "../../../app/stores/store";
import MyTextInput from "../../../app/common/form/MyTextInput";
import { Button, Form, Grid, Message, Modal } from "semantic-ui-react";
import DXLoadingComponent from "../../../app/layout/DXLoadingComponent";
import { AxiosError } from "axios";
import notify from "devextreme/ui/notify";
import MyTextArea from "../../../app/common/form/MyTextArea";

type Props = {
  rowID: number;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
};

const MSMEBlinkActivityFeedbackForm: React.FC<Props> = ({
  rowID,
  setOpen,
  open,
}) => {
  const [initialValues, setInitialValues] =
    useState<MSMEBlinkActivityFeedbackFormValues>(
      new MSMEBlinkActivityFeedbackFormValues()
    );
  const { MSMEBlinkActivityFeedbackStore } = useStore();
  const {
    createMSMEBlinkActivityFeedback,
    updateMSMEBlinkActivityFeedback,
    loadMSMEBlinkActivityFeedback,
    loadingInitial,
    setLoadingInitial,
  } = MSMEBlinkActivityFeedbackStore;

  const validationSchema = Yup.object({
    // feedbackOID: Yup.string().required(
    //   "The FeedbackOID Is required"
    // ),
    blinkActivityOID: Yup.string().required("The BlinkActivityOID Is required"),
    transformationTitle: Yup.string().required(
      "The TransformationTitle Is required"
    ),
    caseDescription: Yup.string().required("The CaseDescription Is required"),
    beforeBSPSupport: Yup.string().required("The BeforeBSPSupport Is required"),
    bspSupportProvided: Yup.string().required(
      "The BSPSupportProvided Is required"
    ),
    afterBSPSupport: Yup.string().required("The AfterBSPSupport Is required"),
    quote: Yup.string().required("The Quote Is required"),
  });

  const handleBack = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  useEffect(() => {
    if (!rowID) return setLoadingInitial(false);
    loadMSMEBlinkActivityFeedback(rowID)
      .then((res: any) => {
        const response = res.result[0];
        setInitialValues(new MSMEBlinkActivityFeedbackFormValues(response));
      })
      .catch((err: AxiosError) => {
        notify("Bad Request", "error", 3000);
        handleBack();
      });
  }, [
    rowID,
    loadMSMEBlinkActivityFeedback,
    setLoadingInitial,
    handleBack,
    open,
  ]);

  const handleSubmit = (values: MSMEBlinkActivityFeedbackFormValues) => {
    try {
      if (rowID) {
        const newMSMEBlinkActivityFeedback = {
          ...values,
        };
        updateMSMEBlinkActivityFeedback(newMSMEBlinkActivityFeedback).then(
          () => {
            handleBack();
          }
        );
      } else {
        createMSMEBlinkActivityFeedback(values).then(() => {
          handleBack();
        });
      }
    } catch (error) {
      notify("Server Error", "error", 3000);
    }
  };

  return (
    <div>
      <Modal open={open}>
        <Modal.Header>Activity Feedback </Modal.Header>
        <Message color='brown' size='mini'>
          <Message.Header>Manage Activity Feedback</Message.Header>
          <p> You can create and manage a activity feedback. </p>
        </Message>
        <Modal.Content>
          {loadingInitial ? (
            <DXLoadingComponent />
          ) : (
            <Formik
              validationSchema={validationSchema}
              enableReinitialize
              initialValues={initialValues}
              onSubmit={(values) => handleSubmit(values)}>
              {({ handleSubmit, isValid, isSubmitting, dirty }) => (
                <Form
                  className='ui form'
                  autoComplete='off'
                  onSubmit={handleSubmit}>
                  <Grid>
                    <Grid.Row columns={3}>
                      <Grid.Column>
                        <MyTextInput
                          placeholder='FeedbackOID'
                          label='Feedback OID'
                          name='feedbackOID'
                          disabled
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <MyTextInput
                          placeholder='BlinkActivity OID'
                          label='BlinkActivity OID'
                          name='blinkActivityOID'
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <MyTextInput
                          placeholder='Transformation Title'
                          label='Transformation Title'
                          name='transformationTitle'
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <MyTextArea
                          rows={3}
                          placeholder='Case Description'
                          label='Case Description'
                          name='caseDescription'
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <MyTextArea
                          rows={3}
                          placeholder='Before BSP Support'
                          label='Before BSP Support'
                          name='beforeBSPSupport'
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <MyTextArea
                          rows={3}
                          placeholder='BSP Support Provided'
                          label='Support Provided'
                          name='bspSupportProvided'
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <MyTextArea
                          rows={3}
                          placeholder='After BSP Support'
                          label='After BSP Support'
                          name='afterBSPSupport'
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <MyTextArea
                          rows={3}
                          placeholder='Quote'
                          label='Quote'
                          name='quote'
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <MyTextArea
                          rows={3}
                          placeholder='Activity Outcome'
                          label='Activity Outcome'
                          name='activityOutcome'
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>

                  <Modal.Actions
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      padding: ".5em",
                    }}>
                    <Button
                      type='button'
                      color='black'
                      onClick={() => setOpen(false)}>
                      Cancel
                    </Button>
                    <Button
                      disabled={isSubmitting || !dirty || !isValid}
                      content='Submit'
                      loading={isSubmitting}
                      type='submit'
                      labelPosition='right'
                      icon='checkmark'
                      positive
                    />
                  </Modal.Actions>
                </Form>
              )}
            </Formik>
          )}
        </Modal.Content>
      </Modal>
    </div>
  );
};

export default observer(MSMEBlinkActivityFeedbackForm);

import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Header, Segment } from "semantic-ui-react";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { useStore } from "../../../app/stores/store";
import { v4 as uuid } from "uuid";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import MyTextInput from "../../../app/common/form/MyTextInput";
import MyTextArea from "../../../app/common/form/MyTextArea";
import MySelectInput from "../../../app/common/form/MySelectInput";
import { resourceOptions } from "../../../app/common/options/categoryOptions";
import MyDateInput from "../../../app/common/form/MyDateInput";
//import { ActivityFormValues } from "../../../app/models/activity";
// import { ResourceFormValues } from "../../../app/models/resource";
import { LgaModel, LgaFormValues } from "../../../app/models/lga";

export default observer(function LgaForm({
  lgaOID,
  setMode,
}: {
  lgaOID: string;
  setMode: Function;
}) {
  const navigate = useNavigate();
  const { lgaStore } = useStore();
  const { createLgaModel, updateLgaModel, loadLgaModel, loadingInitial } =
    lgaStore;
  // const { resourceGUID } = useParams<{ resourceGUID: string }>();

  const [lga, setLga] = useState<LgaFormValues>(new LgaFormValues());

  const validationSchema = Yup.object({
    title: Yup.string().required("The resource title is required"),
    description: Yup.string().required("The resource description is required"),
    category: Yup.string().required("The resource category is required"),
    //date: Yup.string().required("Date is required").nullable(),
    source: Yup.string().required("The resource source is required"),
    code: Yup.string().required("The resource code is required"),
  });

  useEffect(() => {
    if (lgaOID)
      loadLgaModel(lgaOID).then((lga) => setLga(new LgaFormValues(lga)));
  }, [lgaOID, loadLgaModel]);

  function handleFormSubmit(lga: LgaFormValues) {
    if (!lga.lgaOID) {
      let newLgaModel = {
        ...lga,
        lgaOID: uuid(),
      };
      createLgaModel(newLgaModel).then(() =>
        fetch(`/ecolga/${newLgaModel.lgaOID}`)
      );
    } else {
      updateLgaModel(lga).then(() => navigate(`/ecolga/${lga.lgaOID}`));
    }
  }

  if (loadingInitial) return <LoadingComponent content="Loading lga..." />;

  return (
    <Segment clearing>
      <Button
        // disabled={isSubmitting || !dirty || !isValid}
        // loading={isSubmitting}
        floated="left"
        positive
        type="button"
        content="Back"
        onClick={() => setMode()}
        icon="back-arrow"
      />
      <h1>Resource Details</h1>
      <div className="ui warning message">
        <div className="header">Create, edit and delete LGA.</div>
        <p>
          You can manage resources from this page. You can create, edit, delete
          a LGA.
        </p>
      </div>
      {/* <Header content="Resource Details" sub color="teal" /> */}
      <Formik
        validationSchema={validationSchema}
        enableReinitialize
        initialValues={lga}
        onSubmit={(values) => handleFormSubmit(values)}>
        {({ handleSubmit, isValid, isSubmitting, dirty }) => (
          <Form className="ui form" onSubmit={handleSubmit} autoComplete="off">
            <MyTextInput placeholder="lgaCode" name="lgacode" />
            <MyTextInput name="LGA" placeholder="lga" />
            <MyTextArea rows={3} placeholder="stateOID" name="stateOID" />

            <Button
              disabled={isSubmitting || !dirty || !isValid}
              loading={isSubmitting}
              floated="right"
              positive
              type="submit"
              content="Submit"
            />
            <Button
              as={Link}
              to="/resources"
              floated="right"
              type="button"
              content="Cancel"
            />
          </Form>
        )}
      </Formik>
    </Segment>
  );
});

import React from "react";
import { Segment } from "semantic-ui-react";

export const JobLinkage = () => {
  return (
    <Segment>
      <h1>Job Linkages</h1>
      <div className="ui warning message">
        <div className="header">Manage Job Linkages.</div>
        <p>Create and manage job linkages.</p>
      </div>
      <div className="ui divider" title="List of Resources"></div>
    </Segment>
  );
};

import React from "react";
import DxHtmlEditor from "../../../../app/common/form/DXHtmlEditor";
import { useStore } from "../../../../app/stores/store";

import classes from "./ticket.module.css";

type Props = {};

const DescriptionOfIssues: React.FC<Props> = () => {
  const {
    userStore: { user },
  } = useStore();

  const date = new Date();
  const [month, day, year] = [
    date.getMonth(),
    date.getDate(),
    date.getFullYear(),
  ];

  const todayDate = `${day.toString().padStart(2, "0")}-${(month + 1)
    .toString()
    .padStart(2, "0")}-${year}`;
  return (
    <div>
      <header className={classes.header_3}>Description Of Issues</header>

      <div className=''>
        <DxHtmlEditor name={"descriptionOfIssues"} height='725px' />
      </div>

      <div className={classes.ticket_container}>
        <p className={classes.ticket_p}> Date Posted : {todayDate} </p>
        <p className={classes.ticket_p}> Posted By : {user?.displayName} </p>
      </div>
    </div>
  );
};

export default DescriptionOfIssues;

import { FieldArray } from "formik";
import React from "react";
import { Grid, Label, Table } from "semantic-ui-react";
import MyTextInput from "../../app/common/form/MyTextInput";
import classes from "./coster.module.css";

type Props = {
  values: any;
};

const ExpenseDetails = (props: Props) => {
  const values = props.values;
  return (
    <section>
      <FieldArray name='toolbLinkCosterExpenseDetailVirtual'>
        {({ insert, remove, push }) => (
          <Grid className={classes.border}>
            <Grid.Row columns={1} className={classes.row}>
              <Grid.Column className={classes.column}>
                <header className={classes.header}>
                  Expense Details
                  <Label
                    as={"a"}
                    size='large'
                    onClick={() =>
                      push({
                        quantity: 0,
                        unitOfMeasure: "",
                        unitPrice: 0,
                        comment: "",
                        commodityType: "",
                      })
                    }>
                    +
                  </Label>
                </header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1} className={classes.row}>
              <Grid.Column className={classes.column}>
                <Table celled>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell className={classes.table_header_3}>
                        Description
                      </Table.HeaderCell>
                      <Table.HeaderCell className={classes.table_header_3}>
                        Quantity
                      </Table.HeaderCell>
                      <Table.HeaderCell className={classes.table_header_3}>
                        UOM
                      </Table.HeaderCell>
                      <Table.HeaderCell className={classes.table_header_3}>
                        Unit Price
                      </Table.HeaderCell>
                      <Table.HeaderCell className={classes.table_header_3}>
                        Total Amount
                      </Table.HeaderCell>
                      <Table.HeaderCell className={classes.table_header_3}>
                        Comment
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {values.toolbLinkCosterExpenseDetailVirtual.length > 0 &&
                      values.toolbLinkCosterExpenseDetailVirtual.map(
                        (item: any, index: number) => (
                          <TableRow
                            description={`toolbLinkCosterExpenseDetailVirtual${index}.commodityType`}
                            quantity={`toolbLinkCosterExpenseDetailVirtual${index}.quantity`}
                            uom={`toolbLinkCosterExpenseDetailVirtual${index}.unitOfMeasure`}
                            unitPrice={`toolbLinkCosterExpenseDetailVirtual${index}.unitPrice`}
                            totalAmount={`toolbLinkCosterExpenseDetailVirtual${index}.unitPrice`}
                            comment={`toolbLinkCosterExpenseDetailVirtual${index}.comment`}
                            values
                          />
                        )
                      )}
                    {/* <TableRow
                      description={"Long Distant Transport  - Human"}
                      quantity={"1.00"}
                      uom={"Pcs"}
                      unitPrice={"-"}
                      totalAmount={"-"}
                      comment={""}
                    /> */}
                    {/* <TableRow
                      description={"Accommodation - Human"}
                      quantity={"1.00"}
                      uom={"Pcs"}
                      unitPrice={"-"}
                      totalAmount={"-"}
                      comment={""}
                    /> */}
                    {/* <TableRow
                      description={"Feeding - Human"}
                      quantity={"1.00"}
                      uom={"Pcs"}
                      unitPrice={""}
                      totalAmount={"-"}
                      comment={""}
                    /> */}
                    {/* <TableRow
                      description={"Local Transport - Goods"}
                      quantity={"1.00"}
                      uom={"Pcs"}
                      unitPrice={"2,000.00"}
                      totalAmount={"2,000.00"}
                      comment={""}
                    /> */}
                    {/* <TableRow
                      description={"Long Distant Transport - Goods"}
                      quantity={"1.00"}
                      uom={"Pcs"}
                      unitPrice={"10,000.00"}
                      totalAmount={"10,000.00"}
                      comment={""}
                    /> */}
                    {/* <TableRow
                      description={"Accommodation - Goods"}
                      quantity={"1.00"}
                      uom={"Pcs"}
                      unitPrice={"-"}
                      totalAmount={"-"}
                      comment={""}
                    /> */}
                    {/* <TableRow
                      description={"Package Tray for 30"}
                      quantity={"1.00"}
                      uom={"Pcs"}
                      unitPrice={"200.00"}
                      totalAmount={"140.00"}
                      comment={""}
                    /> */}
                    {/* <TableRow
                      description={"Sticker "}
                      quantity={"200.00"}
                      uom={"Pcs"}
                      unitPrice={"30.00"}
                      totalAmount={"6,000.00"}
                      comment={""}
                    /> */}
                    {/* <TableRow
                      description={" "}
                      quantity={""}
                      uom={"Pcs"}
                      unitPrice={""}
                      totalAmount={"-"}
                      comment={""}
                    /> */}
                  </Table.Body>
                  <Table.Footer className={classes.table_footer_1}>
                    <Table.Row>
                      <Table.HeaderCell colSpan={2} textAlign='right'>
                        -
                      </Table.HeaderCell>
                      <Table.HeaderCell colSpan={4}>
                        Total Amount
                        <span className=''>380,000.00</span>
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Footer>
                </Table>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
      </FieldArray>
    </section>
  );
};

interface ITableRow {
  description: string;
  quantity: string;
  uom: string;
  unitPrice: string;
  totalAmount: string;
  comment: string;
  values: any;
}

export const TableRow: React.FC<ITableRow> = ({
  description,
  quantity,
  uom,
  unitPrice,
  totalAmount,
  comment,
  values,
}) => {
  return (
    <Table.Row className={`${classes.row_border_4}`}>
      <Table.Cell textAlign='left'>
        {/* {description} */}
        <MyTextInput name={description} placeholder='' />
      </Table.Cell>
      <Table.Cell textAlign='left'>
        {/* {quantity} */}
        <MyTextInput name={quantity} placeholder='' />
      </Table.Cell>
      <Table.Cell textAlign='left'>
        {/* {uom} */}
        <MyTextInput name={uom} placeholder='' />
      </Table.Cell>
      <Table.Cell textAlign='left'>
        {/* {unitPrice} */}
        <MyTextInput name={unitPrice} placeholder='' />
      </Table.Cell>
      <Table.Cell textAlign='left' className={classes.readonly_cell_1}>
        {values.unitPrice * values.quantity}
      </Table.Cell>
      <Table.Cell textAlign='left'>
        {/* {comment} */}
        <MyTextInput name={comment} placeholder='' />
        {/* unitPrice */}
      </Table.Cell>
    </Table.Row>
  );
};

export default ExpenseDetails;

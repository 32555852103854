import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
// import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Header, Form, Segment } from "semantic-ui-react";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { useStore } from "../../app/stores/store";
import { v4 as uuid } from "uuid";
import { Form as FForm, Formik } from "formik";
import * as Yup from "yup";
import MyTextInput from "../../app/common/form/MyTextInput";
import MyTextArea from "../../app/common/form/MyTextArea";
import MySelectInput from "../../app/common/form/MySelectInput";
import { resourceOptions } from "../../app/common/options/categoryOptions";
import MyDateInput from "../../app/common/form/MyDateInput";
//import { ActivityFormValues } from "../../../app/models/activity";
import { ResourceFormValues } from "../../app/models/resource";
import {
  BusinessData,
  PersonalDataFormValues,
} from "../../app/models/personalData";
import {
  optionsAgeRange,
  optionsGender,
  optionsMaritalStatus,
  optionsState,
} from "../../app/common/staticData";
import CADTextInput from "../../app/common/form/CADTextInput";
import CADSelectInput from "../../app/common/form/CADSelectInput";
import UserStore from "../../app/stores/userStore";

export default observer(function ProfilePersonalDataEditForm({
  userGUID,
  setMode,
}: {
  userGUID: string | undefined;
  setMode: Function;
}) {
  // const history = useNavigate();
  const { personalDataStore, userStore } = useStore();
  const {
    createPersonalData,
    updatePersonalData,
    loadPersonalData,
    loadingInitial,
  } = personalDataStore;
  const { user } = userStore;
  const [loading, setLoading] = useState(false);
  // const {
  //   profileStore: { profile,  },
  // } = useStore();

  // const { userGUID } = useParams<{ userGUID: string }>();

  const [personalData, setPersonalData] = useState<
    PersonalDataFormValues | BusinessData
  >(new PersonalDataFormValues());
  const {
    profileStore: { profile },
  } = useStore();

  const validationSchema = Yup.object({
    surname: Yup.string().required("Surname is required."),
    otherNames: Yup.string().required("Other names is required."),
    gender: Yup.string().required("Gender is required."),
    //date: Yup.string().required("Date is required").nullable(),
    ageRange: Yup.string().required("Age Range is required"),
    stateOfOrigin: Yup.string().required("State of origin is required"),
    maritalStatus: Yup.string().required("Marital Status is required"),
    mobilePhone: Yup.string().required("Mobile Phone is required"),
    businessEmail: Yup.string().required("Business Email is required"),
  });

  useEffect(() => {
    if (userGUID) {
      console.log("Calling endpoint", userGUID);
      loadPersonalData(userGUID).then(async (personalData) => {
        console.log(personalData?.value, "last here DATA HERE");
        const newObj = {
          ...personalData?.value,
          displayName: profile?.displayName,
          msmeCode:
            personalData?.value?.msmeCode === ""
              ? "NONE"
              : personalData?.value?.msmeCode,
        };

        //      const pagination = response.headers["pagination"];
        // if (pagination) {
        //   response.data = new PaginatedResult(
        //     response.data,
        //     JSON.parse(pagination)
        //   );

        // console.log("2. loadPersonalData", { ...personalData?.value });
        setPersonalData(new PersonalDataFormValues(newObj as BusinessData));
        // return setPersonalData(personalData?.value as BusinessData);
      });
    }
  }, [userGUID, loadPersonalData, profile?.displayName]);

  function handleFormSubmit(
    personalData: PersonalDataFormValues | BusinessData
  ) {
    console.log("Form Values", personalData);
    setLoading(true);
    if (!personalData.userGUID) {
      let newPersonalData = {
        ...personalData,
        userGUID: user?.userGUID,
        dispayName: user?.displayName,
      };
      createPersonalData(newPersonalData).then(
        () => setLoading(false)
        // history.push(`/personaldata/${newPersonalData.msmeoid}`)
      );
    } else {
      updatePersonalData(personalData).then(
        () => setLoading(false)
        // history.push(`/personalData/${personalData.msmeoid}`)
      );
    }
  }

  // console.log("Loading Status", loading);

  // console.log(
  //   personalData.userGUID,
  //   !personalData.userGUID,
  //   loadingInitial,
  //   personalData
  // );

  // console.log("personalData", personalData);

  // if (loadingInitial && !personalData.userGUID)
  //   return <LoadingComponent content="Loading resource..." />;

  return personalData?.userGUID ? (
    <Segment clearing>
      {/* <Button
        // disabled={isSubmitting || !dirty || !isValid}
        // loading={isSubmitting}
        floated="left"
        positive
        type="button"
        content="Back"
        // onClick={() => setMode()}
        icon="back-arrow"
      /> */}
      <h1>Personal Data Details</h1>
      <div className='ui warning message'>
        <div className='header'>Personal Data Update.</div>
        <p>
          Provide correct detail information about yourself. Please note that
          your data is protected and can only be viewed by the users you want to
          view it.
        </p>
      </div>
      {/* <Header content="Resource Details" sub color="teal" /> */}
      <Formik
        // initialValues={{
        //   mSMEOID: 0,
        //   displayName: personalData.displayName,
        //   surname: personalData.surname,
        //   otherNames: personalData.otherNames,
        //   userGUID: personalData.userGUID,
        //   mSMECode: personalData.mSMECode,
        //   gender: personalData.gender,
        //   ageRange: personalData.ageRange,
        //   maritalStatus: personalData.maritalStatus,
        //   mobilePhone: personalData.mobilePhone,
        //   stateOfOrigin: personalData.stateOfOrigin,
        //   personalBankName: personalData.personalBankName,
        //   personalBankAccount: personalData.personalBankAccount,
        //   personalAccountNumber: personalData.personalAccountNumber,
        // }}
        validationSchema={validationSchema}
        //enableReinitialize
        initialValues={personalData}
        // initialValues={BusinessData}
        //onSubmit={(values) => handleFormSubmit(values)}
        onSubmit={(values) => {
          handleFormSubmit(values);
        }}
        // validationSchema={Yup.object({
        //   displayName: Yup.string().required(),
        // })}
      >
        {({ handleSubmit, isValid, isSubmitting, dirty }) => (
          <FForm className='ui form' onSubmit={handleSubmit} autoComplete='off'>
            <Form.Group widths='equal'>
              {/* {console.log("initialValues", personalData)} */}
              <Button
                // disabled={isSubmitting || !dirty || !isValid}
                //disabled={isSubmitting || !true || !isValid}
                // disabled={false || !true || !true}
                //disabled={!(false || false || true)}
                // disabled={isSubmitting || !dirty || !isValid}
                disabled={loading || !dirty || !isValid}
                // loading={isSubmitting || loading}
                loading={loading}
                floated='right'
                positive
                type='submit'
                content='Update Personal Data'
              />
            </Form.Group>

            <Header as='h3' dividing color={"brown"}>
              GENERAL INFORMATION
            </Header>
            <Form.Group widths='equal'>
              <CADTextInput
                label='Display Name'
                // placeholder="Display Name"
                name='displayName'
                disabled
              />
              <CADTextInput
                label='MSME OID'
                // placeholder="MSME OID"
                name='msmeoid'
                // name="mSMEOID"
                disabled
              />
            </Form.Group>
            <Form.Group widths='equal'>
              <CADTextInput
                label='User GUID'
                //placeholder="User GUID"
                name='userGUID'
                disabled
              />
              <CADTextInput
                label='MSME Code'
                //placeholder="MSME Code"
                name='msmeCode'
                disabled
              />
            </Form.Group>
            <Form.Group widths='equal'>
              <CADTextInput
                name='surname'
                //placeholder="Surname"
                label='Surname'
              />
              <CADTextInput
                name='otherNames'
                //placeholder="Other Names"
                label='Other Names'
              />
            </Form.Group>
            <Form.Group widths='equal'>
              <CADTextInput
                name='mobilePhone'
                //placeholder="Mobile Phone"
                label='Mobile Phone'
              />
              <CADTextInput
                name='businessEmail'
                //placeholder="Business Email"
                label='Business Email'
              />
            </Form.Group>
            <Form.Group widths='equal'>
              <CADSelectInput
                options={optionsState}
                //placeholder="State of Origin"
                label='State of Origin'
                name='stateOfOrigin'
              />
              <CADSelectInput
                options={optionsGender}
                //placeholder="Gender"
                label='Gender'
                name='gender'
              />
            </Form.Group>

            <Form.Group widths='equal'>
              <CADSelectInput
                options={optionsAgeRange}
                //placeholder="Age Range"
                label='Age Range'
                name='ageRange'
              />
              <CADSelectInput
                options={optionsMaritalStatus}
                //placeholder="Marital Status"
                label='Marital Status'
                name='maritalStatus'
              />
            </Form.Group>

            <Header as='h3' dividing color={"brown"}>
              BANK DETAILS
            </Header>
            <Form.Group widths='equal'>
              <CADTextInput
                label='Personal Bank Name'
                //placeholder="Personal Bank Name"
                name='personalBankName'
              />
              <CADTextInput
                label='Personal Bank Account Name'
                //placeholder="Personal Bank Account Name"
                name='personalBankAccount'
              />
              <CADTextInput
                label='Personal Account Number'
                //placeholder="Personal Account Number"
                name='personalAccountNumber'
              />
            </Form.Group>
          </FForm>
        )}
      </Formik>
    </Segment>
  ) : (
    <LoadingComponent content='Loading resource...' />
  );
});

import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import agent from "../../app/api/agentExpress";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { AutoRegisterReq } from "../../app/models/user";
import { useStore } from "../../app/stores/store";

export function useURLQuery() {
  return new URLSearchParams(useLocation().search);
}

export default observer(function RedirectPage() {
  const { userStore } = useStore();
  const {
    userStore: { user },
  } = useStore();
  const navigate = useNavigate();

  //   const urlQueries = useURLQuery();
  //   const token = urlQueries.get("token");
  //   const email = urlQueries.get("email");
  // console.log(user?.email)

  const fullName = user?.username;
  const [firstName, otherNames] = fullName?.split(" ") || [];

  useEffect(() => {
    const req = {
      email: user?.email || "testNIB@gmail.com",
      //   bvn: "22211122211",
      //   centerName: "CAD",
      //   dateOfBirth: new Date().toJSON(),
      firstName: firstName,
      otherNames: otherNames ? otherNames : firstName,
      //   password: "NIBapp@123",
      //   phoneNumber: "080",
      //   role: "APPLICANT",
      userGUID: user?.userGUID || "",
      //   userSource: "NIB",
    };

    agent.BlinkPlanSSO.autoRegister(req).then((res) => {
      const data = res?.serializedBody;
      //   console.log(res?.serializedBody);
      const url = data?.redirectToBlinkPlanDashboard;
      // redirect to blinkplan
      if (url) {
        window.open(url, "_blank");
        navigate(-1);
      }
    });
  }, [user?.userGUID, user?.username, userStore, user?.email, firstName, otherNames, navigate]);
  //  userStore
  //    .autoRegister(req)
  //    .then((res) => {
  //      const data = res?.data?.data?.[0];
  //      console.log(data);
  //      const url = data?.redirectToBlinkPlanDashboard;
  //      // redirect to blinkplan
  //      window.open(url, "_blank");
  //    })
  //    .catch((error) => {
  //      // global alert here
  //    });

  return (
    <div
      style={{
        width: "100%",
        // height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}>
      <LoadingComponent />
    </div>
  );
});

import React, { Dispatch, SetStateAction } from "react";
import { observer } from "mobx-react-lite";
import {
  Header,
  Divider,
  //   Segment,
  Button,
  //   Modal,
  //   Image,
} from "semantic-ui-react";
import { useStore } from "../../../app/stores/store";

// import

interface Props {
  open?: boolean;
  setOpen?: Dispatch<SetStateAction<boolean>>;
}

const BPTemplatesViewItem: React.FC<Props> = () => {
  //   const [open, setOpen] = React.useState(false);
  const { modalStore } = useStore();
  return (
    <div>
      <Header as="h2">Fish Farming Template</Header>
      <Divider />
      <Divider />
      <Button color="brown" inverted content="Use" />
      <Button
        color="brown"
        onClick={() => modalStore.closeModal()}
        inverted
        content="Cancel"
      />
    </div>
  );
};

export default observer(BPTemplatesViewItem);

import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../api/agent";
//import agentExpress from "../api/agentExpress";
//import { Activity, ActivityFormValues } from "../models/activity";
import { PersonalData, PersonalDataFormValues } from "../models/personalData";
import { format } from "date-fns";
//import { store } from "./store";
import { Profile } from "../models/profile";
import { Pagination, PagingParams } from "../models/pagination";
//import axios from "axios";

export default class PersonalDataStore {
  personalDataData: PersonalData[] = [];
  //personalDataV2Data: PersonalData[] = [];
  //comments: ChatComment[] = [];
  personalDataRegistry = new Map<string, PersonalData>();
  selectedPersonalData: PersonalData | undefined = undefined;
  editMode = false;
  loading = false;
  loadingInitial = false;
  pagination: Pagination | null = null;
  pagingParams = new PagingParams();
  predicate = new Map().set("all", true);
  baseAPI = process.env.REACT_APP_API_URL;

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.predicate.keys(),
      () => {
        this.pagingParams = new PagingParams();
        this.personalDataRegistry.clear();
        this.loadPersonalDatas();
      }
    );
  }

  setPagingParams = (pagingParams: PagingParams) => {
    pagingParams.pageNumber = 1;
    pagingParams.pageSize = 500;
    this.pagingParams = pagingParams;
  };

  setPredicate = (predicate: string, value: string | Date) => {
    const resetPredicate = () => {
      this.predicate.forEach((value, key) => {
        if (key !== "dateUploaded") this.predicate.delete(key);
      });
    };
    switch (predicate) {
      case "all":
        resetPredicate();
        this.predicate.set("all", true);
        break;
      case "isGoing":
        resetPredicate();
        this.predicate.set("isGoing", true);
        break;
      case "isHost":
        resetPredicate();
        this.predicate.set("isHost", true);
        break;
      case "dateUploaded":
        this.predicate.delete("dateUploaded");
        this.predicate.set("dateUploaded", value);
    }
  };

  get axiosParams() {
    const params = new URLSearchParams();
    // params.append("pageNumber", this.pagingParams.pageNumber.toString());
    // params.append("pageSize", this.pagingParams.pageSize.toString());
    params.append("pageNumber", "1");
    params.append("pageSize", "500");
    this.predicate.forEach((value, key) => {
      if (key === "dateUploaded") {
        params.append(key, (value as Date).toISOString());
      } else {
        params.append(key, value);
      }
    });
    return params;
  }

  // get personalDatasByDate() {
  //   return Array.from(this.personalDataRegistry.values()).sort(
  //     (a, b) => a.dateUploaded!.getTime() - b.dateUploaded!.getTime()
  //   );
  // }

  // get groupedPersonalDatas() {
  //   return Object.entries(
  //     this.personalDatasByDate.reduce((personalDatas, personalData) => {
  //       const date = format(personalData.dateUploaded!, "dd MMM yyyy");
  //       personalDatas[date] = personalDatas[date]
  //         ? [...personalDatas[date], personalData]
  //         : [personalData];
  //       return personalDatas;
  //     }, {} as { [key: string]: PersonalData[] })
  //   );
  // }

  // loadActivities = async () => {
  //   this.loadingInitial = true;
  //   try {
  //     const result = await agent.Activities.list(this.axiosParams);
  //     result.data.forEach((activity) => {
  //       this.setActivity(activity);
  //     });
  //     this.setPagination(result.pagination);
  //     this.setLoadingInitial(false);
  //   } catch (error) {
  //     console.log(error);
  //     this.setLoadingInitial(false);
  //   }
  // };

  loadPersonalDatas = async () => {
    this.loadingInitial = true;
    try {
      console.log("1. LOG = " + this.axiosParams);
      const result = await agent.PersonalDatas.list(this.axiosParams);
      //this.personalDataData.push(result);
      //console.log("1. result from loadPersonalDatas()", result);
      // result.map(
      //   (personalData) =>
      //     //this.personalDataData.push(personalData)
      //     this.setPersonalDataData(personalData)
      // );
      // console.log("2. result from loadPersonalDatas()");
      this.personalDataData = result.data;
      // console.log(this.personalDataData);
      // result.data.forEach((personalData) => {
      //   this.setPersonalDataData(personalData);
      // });
      //this.setPagination(result.pagination);
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
    }
  };

  // loadPersonalDatasDashboard = async () => {
  //   this.loadingInitial = true;
  //   try {
  //     const result = await agentExpress.PersonalDatas.list();
  //     this.personalDataV2Data = result;
  //     console.log("1. In loadPersonalDatasDashboard: result", result);
  //     console.log(
  //       "2. In loadPersonalDatasDashboard: this.personalDataData",
  //       this.personalDataV2Data
  //     );
  //     this.setLoadingInitial(false);
  //   } catch (error) {
  //     console.log(error);
  //     this.setLoadingInitial(false);
  //   }
  // };

  setPagination = (pagination: Pagination) => {
    this.pagination = pagination;
  };

  loadPersonalData = async (vUserGUID: string) => {
    let personalData = this.getPersonalData(vUserGUID);
    // if (personalData) {
    //   this.selectedPersonalData = personalData;
    //   console.log("5. Gt Here", vUserGUID);
    //   console.log("6. personalDatastore", personalData);
    //   return personalData;
    // } else {
      this.loadingInitial = true;
      try {
        personalData = await agent.PersonalDatas.detailsByUserGUID(vUserGUID);
        console.log("4. agent.PersonalDatas.detailsByUserGUID", personalData);
        this.setPersonalDataData(personalData);
        runInAction(() => {
          this.selectedPersonalData = personalData;
        });
        this.setLoadingInitial(false);
        return personalData;
      } catch (error) {
        console.log(error);
        this.setLoadingInitial(false);
      }
    // }
  };
  private setPersonalDataData = (personalData: PersonalData) => {
    //personalData.dateUploaded = new Date(personalData.dateUploaded!);
    this.personalDataData.push(personalData);
  };

  private setPersonalData = (personalData: PersonalData) => {
    // personalData.dateUploaded = new Date(personalData.dateUploaded!);
    this.personalDataRegistry.set(personalData.userGUID, personalData);
  };

  private getPersonalData = (id: string) => {
    return this.personalDataRegistry.get(id);
  };

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  createPersonalData = async (personalData: PersonalDataFormValues) => {
    try {
      console.log("2. createPersonalData", personalData);
      await agent.PersonalDatas.create(personalData);
      const newPersonalData = new PersonalData(personalData);
      //   newPersonalData.hostUsername = user!.username;
      //   newPersonalData.attendees = [attendee];
      this.setPersonalData(newPersonalData);
      runInAction(() => {
        this.selectedPersonalData = newPersonalData;
      });
    } catch (error) {
      console.log(error);
    }
  };

  updatePersonalData = async (personalData: PersonalDataFormValues) => {
    try {
      await agent.PersonalDatas.update(personalData);
      runInAction(() => {
        if (personalData.userGUID) {
          let updatedPersonalData = {
            ...this.getPersonalData(personalData.userGUID),
            ...personalData,
          };
          this.personalDataRegistry.set(
            personalData.userGUID,
            updatedPersonalData as PersonalData
          );
          this.selectedPersonalData = updatedPersonalData as PersonalData;
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  deletePersonalData = async (id: string) => {
    this.loading = true;
    try {
      await agent.PersonalDatas.delete(id);
      runInAction(() => {
        this.personalDataRegistry.delete(id);
        this.loading = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  //   updateAttendance = async () => {
  //     const user = store.userStore.user;
  //     this.loading = true;
  //     try {
  //       await agent.Activities.attend(this.selectedActivity!.id);
  //       runInAction(() => {
  //         if (this.selectedActivity?.isGoing) {
  //           this.selectedActivity.attendees =
  //             this.selectedActivity.attendees?.filter(
  //               (a) => a.username !== user?.username
  //             );
  //           this.selectedActivity.isGoing = false;
  //         } else {
  //           const attendee = new Profile(user!);
  //           this.selectedActivity?.attendees?.push(attendee);
  //           this.selectedActivity!.isGoing = true;
  //         }
  //         this.activityRegistry.set(
  //           this.selectedActivity!.id,
  //           this.selectedActivity!
  //         );
  //       });
  //     } catch (error) {
  //       console.log(error);
  //     } finally {
  //       runInAction(() => (this.loading = false));
  //     }
  //   };

  cancelPersonalDataToggle = async () => {
    this.loading = true;
    try {
      //await agent.PersonalDatas.attend(this.selectedPersonalData!.personalDataGUID);
      runInAction(() => {
        // this.selectedPersonalData!.isCancelled =
        //   !this.selectedPersonalData?.isCancelled;
        this.personalDataRegistry.set(
          this.selectedPersonalData!.userGUID,
          this.selectedPersonalData!
        );
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

  //   updateAttendeeFollowing = (username: string) => {
  //     this.activityRegistry.forEach((activity) => {
  //       activity.attendees.forEach((attendee) => {
  //         if (attendee.username === username) {
  //           attendee.following
  //             ? attendee.followersCount--
  //             : attendee.followersCount++;
  //           attendee.following = !attendee.following;
  //         }
  //       });
  //     });
  //   };

  clearSelectedPersonalData = () => {
    this.selectedPersonalData = undefined;
  };
}

import { ErrorMessage, Form, useFormik } from "formik";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
// import { ErrorMessage, Form, Formik } from "formik";
// import { observer } from "mobx-react-lite";
import { Button, Header, Input } from "semantic-ui-react";
// import MyTextInput from "../../../app/common/form/MyTextInput";
// import { useStore } from "../../../app/stores/store";
// import ValidationErrors from "../../errors/ValidationErrors";
import { createMedia } from "@artsy/fresnel";
import { Image } from "semantic-ui-react";
import "./RegisterForm.css";

// import { AccountContext } from "./context";
import * as yup from "yup";
import axios from "axios";

const PASSWORD_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;

const url = 'http://localhost:5000/api/Account/register'

const { MediaContextProvider, Media } = createMedia({
  // breakpoints values can be either strings or integers
  breakpoints: {
    sm: 0,
    md: 768,
    lg: 1024,
    xl: 1569,
  },
});
const validationSchema = yup.object({
  displayname: yup
    .string()
    .min(3, "Please enter a min of 3 or above")
    .required("Display name is required!"),
  userName: yup
    .string()
    .min(3, "Please enter a min of 3 or above")
    .required("Username is required!"),
  email: yup.string().email("Please enter a valid email address").required(),
  password: yup
    .string()
    .matches(PASSWORD_REGEX, "Password must contain a lowercase, uppercase , number and symbol ")
    .required(),
    })
    type ISignupProps={
      displayname:string;
      userName:string;
      email:string;
      password:string;
    }
export default function RegisterForm1() {
  // const { switchToSignin } = useContext(AccountContext);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);

  const signupUser = async (values: ISignupProps) => {
    // const { password, ...data } = values;
    
    console.log(values)
    
    await axios.post(url, values).then((res) => {
        console.log(res.data)
        // push("/auth-login");
        // push(`/account/registerSuccess?email=${res.email}`);
        setError(null);
        setSuccess(res.data.message);
      })
      .catch((err) => {
        console.log(err.response)
        if (err && err.response) setError(err.response.data.message);
        setSuccess(null);
      });
  };
  const formik = useFormik({
    initialValues: {
      displayname: "",
      userName: "",
      email: "",
      password: "",
    }, validateOnBlur: true,
    onSubmit: async (values: ISignupProps) => {
      signupUser(values);
    },
    validationSchema: validationSchema,
  });

  const {
    errors,
    handleSubmit,
    handleChange,
    // setFieldValue,
    isSubmitting,
    isValid,
    dirty,
    values,
  } = formik

  // console.log("Error", error);
  
  return (
    <MediaContextProvider>
      <div className="register-screen">
        <div className="">
          <div className="register-container">
            <div className="curve-circle" />
            <section className="register-left">
              <div className="img-continer">
                <Media greaterThanOrEqual="md">
                  <Image
                    centered
                    size="small"
                    src="/assets/Blinkhub_Logo.png"
                  />
                </Media>
              </div>
              <Header className="header" textAlign="center" as="h2">
                Sign up to BlinkHub
              </Header>
              <div>

              {!error && <p>{success ? success : ""}</p>}
              {!success && <p>{error ? error : ""}</p>}

              <form  
                className="ui form error" 
                onSubmit={handleSubmit}>

                {/* <div className="form-control">
                  <label>Display Name</label>
                  <input type="text" 
                  id="txtName" 
                  value={values.displayname} 
                  onChange={handleChange}/><br></br>
                  <p className="errMsg">{errors.displayname}</p>
                </div> */}
                <div className="form-control">
                  <label>Display Name</label>
                  <input type="text"
                  id="displayname"
                  placeholder="Display Name"
                  value={values.displayname}
                  onChange={handleChange}/><br></br>
                  
                  <p className="errMsg">{errors.displayname}</p>
                </div>
                
                <div className="form-control">
                  <label>UserName</label>
                  <input type="text" 
                  id="txtPhoneNo" 
                  name='userName' 
                  value={values.userName} 
                  onChange={handleChange}/><br></br> 
                  <p className="errMsg">{errors.userName}</p>
                </div>

                <div className="form-control">
                  <label>Email</label>
                  <input type="text" 
                  id="txtAddress" 
                  name='email' 
                  value={values.email} 
                  onChange={handleChange}/><br></br> 
                  <p className="errMsg">{errors.email}</p>
                </div>

                <div className="form-control">
                  <label>Password</label>                 
                  <input type="text" 
                  id="txtAddress" 
                  name='password' 
                  value={values.password} 
                  onChange={handleChange}/><br></br> 
                  <p className="errMsg">{errors.password}</p>
                </div>

                <Button 
                  disabled={!isValid || !dirty || isSubmitting}
                  loading={isSubmitting}
                  type="submit"
                  color="brown"
                  inverted
                  >
                   Register
                </Button>

                <Button 
                  type="submit"
                  color="brown"
                  inverted
                  as={Link}
                  to="/"
                  >cancel
                </Button>

              </form>
            </div>
            </section>
            <section className="register-right">
              <h2> Welcome, </h2>
              <p>To the world of business support services </p>
              <h3> Business Linkage Network </h3>
            </section>
            <Media lessThan="md">
              <Image centered size="small" src="/assets/Blinkhub_Logo.png" />
            </Media>
          </div>
        </div>
      </div>
    </MediaContextProvider>
  );
  }


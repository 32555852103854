// import {
//   Redirect,
//   Route,
//   RouteComponentProps,
//   RouteProps,
// } from "react-router-dom";
import { Navigate } from "react-router-dom";

import { useStore } from "../stores/store";

// interface Props extends RouteProps {
//   component:
//     | React.ComponentType<RouteComponentProps<any>>
//     | React.ComponentType<any>;
//   children?:
//     | React.ComponentType<RouteComponentProps<any>>
//     | React.ComponentType<any>;
// }

// export default function PrivateRoute({
//   component: Component,
//   children,
//   ...rest
// }: Props) {
//   const {
//     userStore: { isLoggedIn },
//   } = useStore();
//   return (
//     <Route
//       {...rest}
//       render={(props) =>
//         isLoggedIn ? <Component {...props} /> : <Redirect to="/" />
//       }>
//       {children}
//     </Route>
//   );
// }

export default function PrivateRoute({ children }: { children: JSX.Element }) {
  const {
    userStore: { isLoggedIn },
  } = useStore();

  if (!isLoggedIn) {
    return <Navigate to="/auth/login" />;
  }

  return children;
}

// export const RedirectRoute = ({ children }: { children: JSX.Element }) => {
//   const userData = useSelector((state: IStateProps) => state?.user);
//   const token = userData ? userData?.token : null;

//   return token ? <Navigate to={"/app"} /> : children;
// };

// export const AuthRoutes = ({
//   children,
//   redirectTo = "/login",
// }: {
//   children: JSX.Element;
//   redirectTo?: string;
// }) => {
//   const userData = useSelector((state: IStateProps) => state?.user);
//   const token = userData ? userData?.token : null;

//   return !token ? <Navigate to={redirectTo} /> : children;
// };

import React from "react";
import { Segment } from "semantic-ui-react";

export const Quiz = () => {
  return (
    <Segment>
      <h1>Quiz</h1>
      <div className="ui warning message">
        <div className="header">Manage Quiz.</div>
        <p>Create and manage Quiz.</p>
      </div>
      <div className="ui divider" title="List of Resources"></div>
    </Segment>
  );
};

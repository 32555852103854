import React from "react";
import { Segment } from "semantic-ui-react";

export const UserDefinition = () => {
  return (
    <Segment>
      <h1>User Definition</h1>
      <div className="ui warning message">
        <div className="header">Manage Users.</div>
        <p>Create and manage User.</p>
      </div>
      <div className="ui divider" title="List of User."></div>
    </Segment>
  );
};

import { FieldArray } from "formik";
// import { values } from "mobx";
import React from "react";
import { Grid, Label, Table } from "semantic-ui-react";
import MyTextInput from "../../app/common/form/MyTextInput";
import classes from "./coster.module.css";

type Props = {
  values: any;
};

const CommodityInformation = (props: Props) => {
  const values = props.values;
  return (
    <section>
      <FieldArray name='toolbLinkCosterOfftakePricelVirtual'>
        {({ insert, remove, push }) => (
          <Grid className={classes.border}>
            <Grid.Row columns={1} className={classes.row}>
              <Grid.Column className={classes.column}>
                <header className={classes.header}>
                  Commodity and Offtaker Price Information
                  <Label
                    as={"a"}
                    size='large'
                    onClick={() =>
                      push({
                        quantity: 0,
                        unitOfMeasure: "",
                        unitPrice: 0,
                        comment: "",
                        commodityType: "",
                      })
                    }>
                    +
                  </Label>
                </header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1} className={classes.row}>
              <Grid.Column className={classes.column}>
                <Table celled>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell className={classes.table_header_3}>
                        Item
                      </Table.HeaderCell>
                      <Table.HeaderCell className={classes.table_header_3}>
                        Quantity
                      </Table.HeaderCell>
                      <Table.HeaderCell className={classes.table_header_3}>
                        UOM
                      </Table.HeaderCell>
                      <Table.HeaderCell className={classes.table_header_3}>
                        Unit Price
                      </Table.HeaderCell>
                      <Table.HeaderCell className={classes.table_header_3}>
                        Total Amount
                      </Table.HeaderCell>
                      <Table.HeaderCell className={classes.table_header_3}>
                        Comment
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {values.toolbLinkCosterOfftakePricelVirtual.length > 0 &&
                      values.toolbLinkCosterOfftakePricelVirtual.map(
                        (item: any, index: number) => (
                          <TableRow
                            description={`toolbLinkCosterOfftakePricelVirtual.${index}.commodityType`}
                            quantity={`toolbLinkCosterOfftakePricelVirtual.${index}.quantity`}
                            uom={`toolbLinkCosterOfftakePricelVirtual.${index}.unitOfMeasure`}
                            unitPrice={`toolbLinkCosterOfftakePricelVirtual.${index}.unitPrice`}
                            totalAmount={`toolbLinkCosterOfftakePricelVirtual.${index}.commodityType`}
                            comment={`toolbLinkCosterOfftakePricelVirtual.${index}.comment`}
                            unitPrice2={item.unitPrice}
                            quantity2={item.quantity}
                          />
                        )
                      )}
                  </Table.Body>
                  <Table.Footer className={classes.table_footer_1}>
                    <Table.Row>
                      <Table.HeaderCell colSpan={2} textAlign='right'>
                        200.00
                      </Table.HeaderCell>
                      <Table.HeaderCell colSpan={4}>
                        Total Amount
                        <span className=''>380,000.00</span>
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Footer>
                </Table>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
      </FieldArray>
    </section>
  );
};

interface ITableRow {
  description: string;
  quantity: string;
  uom: string;
  unitPrice: string;
  totalAmount: string;
  comment: string;
  unitPrice2: number;
  quantity2: number;
}

export const TableRow: React.FC<ITableRow> = ({
  description,
  quantity,
  uom,
  unitPrice,
  totalAmount,
  comment,
  unitPrice2,
  quantity2,
}) => {
  return (
    <Table.Row className={`${classes.row_border_4}`}>
      <Table.Cell textAlign='left'>
        <MyTextInput name={description} placeholder='' />
      </Table.Cell>
      <Table.Cell textAlign='left'>
        <MyTextInput name={quantity} type='number' placeholder='' />
      </Table.Cell>
      <Table.Cell textAlign='left'>
        <MyTextInput name={uom} placeholder='' />
      </Table.Cell>
      <Table.Cell textAlign='left'>
        <MyTextInput name={unitPrice} type='number' placeholder='' />
      </Table.Cell>
      <Table.Cell textAlign='left' className={classes.readonly_cell_1}>
        {/* <MyTextInput name={totalAmount} placeholder="" /> */}
        {unitPrice2 * quantity2}
      </Table.Cell>
      <Table.Cell textAlign='left'>
        <MyTextInput name={comment} placeholder='' />
      </Table.Cell>
    </Table.Row>
  );
};

export default CommodityInformation;

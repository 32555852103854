import { observer } from "mobx-react-lite";
import React from "react";
import { Link, NavLink } from "react-router-dom";
import { createMedia } from "@artsy/fresnel";
import {
  // Button,
  Container,
  Menu,
  Image,
  Dropdown,
  Icon,
  // Button,
  // Label,
} from "semantic-ui-react";
import { useStore } from "../stores/store";
// import UsersOnline from "../uicontrols/UsersOnline";
// import { NavBar } from "devextreme-react";

const { MediaContextProvider, Media } = createMedia({
  // breakpoints values can be either strings or integers
  breakpoints: {
    sm: 0,
    md: 768,
    lg: 1024,
    xl: 1569,
  },
});

const view = `view`;
const create = `create`;
const ecosystem = `ecosystem`;

interface FuncProps {
  handleClick(): void;
}

const NavBar: React.FC<FuncProps> = ({ handleClick }) => {
  const {
    userStore: { user, logout, isLoggedIn },
  } = useStore();
  return (
    <MediaContextProvider>
      <Menu inverted fixed='top'>
        <Container>
          {isLoggedIn && (
            <>
              <Media lessThan='xl'>
                <Menu.Item onClick={handleClick}>
                  <Icon name='sidebar' /> Menu
                </Menu.Item>
              </Media>
              {/* <Media greaterThanOrEqual="xl"> */}
              <DesktopNav />
              {/* </Media> */}
              {/* <Button.Group>
              <Button
                icon="building"
                color="red"
                as={Link}
                to="/view-businessclinic"
                type="button"
              >
                <h3>Business Clinic</h3>
              </Button>

              <Button
                icon="building"
                color="green"
                as={Link}
                to="/view-linkagemap"
                type="button"
              >
                <h3>Linkage Map</h3>
              </Button>
            </Button.Group> */}

              <Menu.Item position='right'>
                <Image
                  src={user?.image || "/assets/user.png"}
                  avatar
                  spaced='right'
                />
                <Dropdown pointing='top left' text={user?.displayName}>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      as={Link}
                      to={`${view}/profiles/${user?.username}`}
                      text='My Profile'
                      icon='user'
                    />{" "}
                    {/* <Dropdown.Item
                    as={Link}
                    to={`/loadResourcesDx`}
                    text="Manage Resources"
                    icon="item"
                  /> */}
                    {/* <Dropdown.Item
                    as={Link}
                    to={`/loadResources`}
                    text="Load Resources"
                    icon="item"
                  /> */}
                    {/* <Dropdown.Item
                    as={Link}
                    to={`/createResource`}
                    text="Resources"
                    icon="item"
                  /> */}
                    <Dropdown.Item
                      onClick={logout}
                      text='Logout'
                      icon='power'
                    />
                  </Dropdown.Menu>
                </Dropdown>
              </Menu.Item>
              {/* <UsersOnline/> */}
            </>
          )}
        </Container>
      </Menu>
    </MediaContextProvider>
  );
};

const DesktopNav = () => {
  // const handleOpenURL = () => {
  //   const host = window.location.host;
  //   const url = host + "/connect-blinkplan";

  //   window.open(url, "_blank");
  // };
  return (
    <>
      <div style={{ display: "flex" }}>
        <Menu.Item as={NavLink} to='/' header>
          <img
            src='/assets/Blinkhub_Logo.png'
            alt='logo'
            style={{ marginRight: "10px" }}
          />
          Blinkhub
        </Menu.Item>
        <Menu.Item as={NavLink} to='/' name='Home' />
        <Dropdown text='View' pointing className='link item'>
          <Dropdown.Menu>
            <Dropdown.Header>Dashboards</Dropdown.Header>
            <Dropdown.Item
              as={Link}
              to={`${view}/activities`}
              text='Activities'
              icon='tasks'></Dropdown.Item>
            <Dropdown.Item
              as={Link}
              to={`${view}/listResourceDashboard`}
              text='Resources'
              icon='resolving'></Dropdown.Item>
            <Dropdown.Item
              as={Link}
              to={`${view}/businessclinic`}
              text='Business Clinic'
              icon='resolving'></Dropdown.Item>

            <Dropdown.Header>Resources</Dropdown.Header>
            <Dropdown.Item
              as={Link}
              to={`${view}/tools`}
              text='Tools'
              icon='cut'></Dropdown.Item>
            <Dropdown.Item
              as={Link}
              to={`${view}/marketplace`}
              text='Marketplace'
              icon='briefcase'></Dropdown.Item>
            <Dropdown.Item
              as={Link}
              // onClick={handleOpenURL}
              to={`${view}/connect-blinkplan`}
              text='BlinkPlan'
              icon='map'></Dropdown.Item>
            <Dropdown.Item
              as={Link}
              to={`view/linkagemap`}
              text='Linkage Map'
              icon='sitemap'></Dropdown.Item>
            <Dropdown.Item
              as={Link}
              to={`${view}/gameplan`}
              text='Game Plan'
              icon='gamepad'></Dropdown.Item>
            <Dropdown.Item
              as={Link}
              to={`${view}/articles`}
              text='Articles'
              icon='sticky note'></Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown text='Create' pointing className='link item'>
          <Dropdown.Menu>
            <Dropdown.Header>Activities</Dropdown.Header>
            <Dropdown.Item>
              <Dropdown text='Posts'>
                <Dropdown.Menu>
                  <Dropdown.Header>Posts</Dropdown.Header>
                  <Dropdown.Item
                    as={NavLink}
                    to={`${create}/supportticket`}
                    text='Support Ticket'
                    icon='building outline'></Dropdown.Item>
                  <Dropdown.Item
                    as={NavLink}
                    to={`${view}/createActivity`}
                    text='Activities/Events'
                    icon='tasks'></Dropdown.Item>
                  <Dropdown.Item
                    as={NavLink}
                    to={`${create}/openconversation`}
                    text='Open Conversation'
                    icon='conversation'></Dropdown.Item>
                  <Dropdown.Item
                    as={NavLink}
                    to={`${create}/businessopportunity`}
                    text='Business Opportunity'
                    icon='building'></Dropdown.Item>

                  <Dropdown.Item
                    as={NavLink}
                    to={`${create}/advertisement`}
                    text='Advertisement'
                    icon='adversal'></Dropdown.Item>
                  <Dropdown.Item
                    as={NavLink}
                    to={`${create}/publicnotice`}
                    text='Public Notice'
                    icon='info circle'></Dropdown.Item>
                  <Dropdown.Item
                    as={NavLink}
                    to={`${create}/quiz`}
                    text='Quiz'
                    icon='question circle outline'></Dropdown.Item>
                  <Dropdown.Item
                    as={NavLink}
                    to={`${create}/poll`}
                    text='Poll'
                    icon='question circle'></Dropdown.Item>
                  <Dropdown.Item
                    as={NavLink}
                    to={`${create}/survey`}
                    text='Survey'
                    icon='grid layout'></Dropdown.Item>
                  <Dropdown.Item
                    as={NavLink}
                    to={`${create}/markets`}
                    text='Markets'
                    icon='briefcase'></Dropdown.Item>
                  <Dropdown.Item
                    as={NavLink}
                    to={`${create}/paymentterms`}
                    text='Payment terms'
                    icon='briefcase'></Dropdown.Item>
                  <Dropdown.Item
                    as={NavLink}
                    to={`${create}/productservices`}
                    text='Product and services'
                    icon='food'></Dropdown.Item>
                  <Dropdown.Item
                    as={NavLink}
                    to={`${create}/group`}
                    text='Create Groups'
                    icon='group'></Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Header>Others</Dropdown.Header>
            <Dropdown.Item
              as={NavLink}
              to={`${create}/joblinkage`}
              text='Job Linkages'
              icon='linkify'></Dropdown.Item>
            <Dropdown.Item
              as={NavLink}
              to={`${create}/group`}
              text='Group'
              icon='group'></Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        {/* <Menu.Menu position="right">
              <Dropdown item text="Resources">
                <Dropdown.Menu>
                  <Dropdown.Item
                    as={Link}
                    to={`/loadResources`}
                    text="Tools"
                    icon="item"
                  ></Dropdown.Item>
                  <Dropdown.Item
                    as={Link}
                    to={`/listResourceDashboard`}
                    text="Resources Dashboard"
                    icon="item"
                  />

                  <Dropdown.Item
                    as={Link}
                    to={`/loadResourcesDx`}
                    text="Marketplace"
                    icon="item"
                  ></Dropdown.Item>
                  <Dropdown.Item
                    as={Link}
                    to={`/loadResourcesDx`}
                    text="Linkage Map"
                    icon="audio description"
                  ></Dropdown.Item>
                  <Dropdown.Item
                    as={Link}
                    to={`/loadResourcesDx`}
                    text="Gameplan"
                    icon="item"
                  ></Dropdown.Item>
                  <Dropdown.Item
                    as={Link}
                    to={`/loadResourcesDx`}
                    text="Articles"
                    icon="item"
                  ></Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Menu> */}
        <Dropdown text='Ecosystem' pointing className='link item'>
          <Dropdown.Menu>
            <Dropdown.Header>Ecosystem</Dropdown.Header>
            <Dropdown.Item>
              <Dropdown text='Administration'>
                <Dropdown.Menu>
                  <Dropdown.Header>Setup</Dropdown.Header>
                  <Dropdown.Item
                    as={Link}
                    to={`${ecosystem}/administration-companyinformation`}
                    text='Company Information'
                    icon='building outline'></Dropdown.Item>
                  <Dropdown.Item
                    as={Link}
                    to={`${ecosystem}/administration-systemsetting`}
                    text='System Settings'
                    icon='settings'></Dropdown.Item>
                  <Dropdown.Item
                    as={Link}
                    to={`${ecosystem}/administration-roledefinition`}
                    text='Role Definition'
                    icon='user md'></Dropdown.Item>
                  <Dropdown.Item
                    as={Link}
                    to={`${ecosystem}/administration-task`}
                    text='Tasks'
                    icon='tasks'></Dropdown.Item>

                  <Dropdown.Item
                    as={Link}
                    to={`${ecosystem}/administration-userdefinition`}
                    text='User Definition'
                    icon='user'></Dropdown.Item>
                  <Dropdown.Item
                    as={Link}
                    to={`${ecosystem}/administration-systemcode`}
                    text='System Codes'
                    icon='systems'></Dropdown.Item>
                  <Dropdown.Item
                    as={Link}
                    to={`${ecosystem}/application`}
                    text='Applications'
                    icon='app store'></Dropdown.Item>
                  <Dropdown.Item
                    as={Link}
                    to={`${ecosystem}/administration-subscription`}
                    text='Subscribers'
                    icon='angellist'></Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Header>Configuration</Dropdown.Header>
                  <Dropdown.Item
                    as={Link}
                    to={`${ecosystem}/administration-assignroletouser`}
                    text='Assign Role to User'
                    icon='assistive listening devices'></Dropdown.Item>
                  <Dropdown.Item
                    as={Link}
                    to={`${ecosystem}/administration-assigntasktorole`}
                    text='Assign Task to Role'
                    icon='asl interpreting'></Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Dropdown.Item>

            <Dropdown.Item
              as={Link}
              to={`${ecosystem}/messaging`}
              text='Messaging'
              icon='conversation'></Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Header>Other Setup</Dropdown.Header>
            <Dropdown.Item>
              <Dropdown text='Master Data'>
                <Dropdown.Menu>
                  <Dropdown.Header>Setup</Dropdown.Header>
                  <Dropdown.Item
                    as={Link}
                    to={`${ecosystem}/masterdata-businessarea`}
                    text='Business Area'
                    icon='building'></Dropdown.Item>
                  <Dropdown.Item
                    as={Link}
                    to={`${ecosystem}/masterdata-businesssector`}
                    text='Business Sector'
                    icon='certificate'></Dropdown.Item>
                  <Dropdown.Item
                    as={Link}
                    // to={`${ecosystem}/masterdata-country`}
                    to={`${ecosystem}/masterdata-country`}
                    text='Country'
                    icon='docker'></Dropdown.Item>
                  <Dropdown.Item
                    as={Link}
                    to={`${ecosystem}/masterdata-state`}
                    text='State'
                    icon='500px'></Dropdown.Item>

                  <Dropdown.Item
                    as={Link}
                    to={`${ecosystem}/masterdata-lga`}
                    text='LGA'
                    icon='accusoft'></Dropdown.Item>
                  <Dropdown.Item
                    as={Link}
                    to={`${ecosystem}/masterdata-bank`}
                    text='Banks'
                    icon='warehouse'></Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Header>Others</Dropdown.Header>
                  <Dropdown.Item
                    as={Link}
                    to={`${view}/loadResourcesDx`}
                    text='Manage Resources'
                    icon='superpowers'></Dropdown.Item>
                  <Dropdown.Item
                    as={Link}
                    to={`${ecosystem}/masterdata-feedback`}
                    text='Manage Feedback'
                    icon='feed'></Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </>
  );
};

export default observer(NavBar);

import React, { useEffect } from "react";
import { Segment, Container, Divider } from "semantic-ui-react";

import Axios from "axios";

import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import "devextreme-react/text-area";
import { Item } from "devextreme-react/form";
//import { baseAPI } from "../../../api/APIEndpoints";
import DataGrid, {
  FilterPanel,
  FilterRow,
  SearchPanel,
  Button,
  Paging,
  Lookup,
  Popup,
  Position,
  Editing,
  Export,
  Pager,
  Grouping,
  GroupPanel,
  RowDragging,
  Column,
  RequiredRule,
  PatternRule,
  EmailRule,
  AsyncRule,
} from "devextreme-react/data-grid";
// import { useSelector } from "react-redux";
import CustomStore from "devextreme/data/custom_store";
import "whatwg-fetch";
import { store, useStore } from "../../../app/stores/store";
import StateForm from "../ecoforms/StateForm";

export const State = () => {
  const [dataSource, setDataSource] = React.useState({});
  const [stateOID, setStateOID] = React.useState("GRID");
  const [rowData, setRowData]: any = React.useState({});
  const { stateStore } = useStore();
  const { loading, setLoadingInitial, loadingInitial } = stateStore;
  const [mode, setMode] = React.useState("GRID");
  const baseAPI = process.env.REACT_APP_API_URL; // "http://localhost:5000/api";

  const jwt = store.commonStore.token;
  function handleErrors(response: any) {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  }

  const customDataSource: any = new CustomStore({
    // key: "countryOID",
    load: () => {
      return fetch(`${baseAPI}/EcoState`, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      })
        .then(handleErrors)
        .then((response) => response.json())
        .then((response) => {
          return {
            data: response.result.data,
            totalCount: response.count,
            summary: response.summary,
            groupCount: response.count,
          };
        });
    },
    insert: (values: any) => {
      return fetch(`${baseAPI}/EcoState`, {
        method: "POST",
        body: JSON.stringify(values),
        headers: {
          Authorization: `Bearer ${jwt}`,
          "Content-Type": "application/json",
        },
      }).then(handleErrors);
    },
    remove: (key) => {
      return fetch(`${baseAPI}/EcoState`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${jwt}`,
          "Content-Type": "application/json",
        },
      }).then(handleErrors);
    },
    update: (values) => {
      return values;
    },
  });

  const handleEdit = (e: any) => {
    // const data = e.row.data;
    setRowData((data: any) => {
      return { ...e.row.data };
    });
    setMode("FORM-EDIT");
    // console.log(rowData);
  };
  // const onRowUpdating = (e: any) => {
  //   e.newData = Object.assign(e.oldData, e.newData);

  //   fetch(`${baseAPI}/EcoCountry`, {
  //     method: "PUT",
  //     body: JSON.stringify(e.newData),
  //     headers: {
  //       Authorization: `Bearer ${jwt}`,
  //       "Content-Type": "application/json",
  //     },
  //   }).then(customDataSource.load());
  // };
  const handleView = (e: any) => {
    // const data = e.row.data;
    setStateOID(e.row.data.stateOID);
    setLoadingInitial(true);
    // console.log(e.row.data);
    setMode("FORM-EDIT");
    // console.log(rowData);
  };

  return mode === "GRID" ? (
    <Segment>
      <h1>State</h1>
      <div className="ui warning message">
        <div className="header">Manage States.</div>
        <p>Create and manage States.</p>
      </div>
      <div className="ui divider" title="List of States"></div>
      <div>
        <DataGrid
          id="grdLGA"
          dataSource={customDataSource}
          className="dx-datagrid dx-row-alt"
          hoverStateEnabled={true}
          columnAutoWidth={true}
          showBorders={true}
          activeStateEnabled={true}
          columnResizingMode="widget"
          allowColumnResizing={true}
          // onRowUpdating={onRowUpdating}
          columnHidingEnabled={true}>
          <RowDragging allowReordering={true} />
          <FilterPanel visible={true} />
          <SearchPanel visible={true} />
          <FilterRow visible={true} />
          <Paging defaultPageSize={10} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={[2, 10, 20, 50, 100]}
            showInfo={true}
          />
          <Grouping contextMenuEnabled={true} />
          <GroupPanel visible={true} />
          <Editing
            mode="row"
            useIcons={true}
            allowUpdating={true}
            allowDeleting={true}
            allowAdding={true}
          />
          {/* <Popup
          title="Local Government Area"
          showTitle={true}
          width={40}
          height={30}
        ></Popup> */}
          <Export
            enabled={true}
            fileName="EcoLGAs"
            allowExportSelectedData={true}
          />
          <Column type="buttons" />
          <Column dataField="stateOID" allowEditing={false}></Column>
          <Column dataField="stateCode" allowEditing={false}></Column>
          <Column dataField="state">
            <RequiredRule />
          </Column>
          <Column dataField="countryOID">
            <RequiredRule />
          </Column>
          <Column dataField="ecoCountry" caption="State" width={125}>
            {/* <Lookup dataSource={states} displayExpr="title" valueExpr="value" /> */}
            <RequiredRule />
          </Column>
          <Column dataField="geoPoliticalZone" caption="State" width={125}>
            {/* <Lookup dataSource={states} displayExpr="title" valueExpr="value" /> */}
            <RequiredRule />
          </Column>
          <Column dataField="ecoLGAs" caption="State" width={125}>
            {/* <Lookup dataSource={states} displayExpr="title" valueExpr="value" /> */}
            <RequiredRule />
          </Column>
        </DataGrid>
      </div>
    </Segment>
  ) : (
    <StateForm setMode={() => setMode("GRID")} stateOID={stateOID} />
  );
};

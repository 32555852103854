import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../api/agent";
import { MSMEBlinkActivity, MSMEBlinkActivityFormValues } from "../models/MSMEBlinkActivities";
// import { format } from "date-fns";
import { Pagination, PagingParams } from "../models/pagination";

export default class MSMEBlinkActivitiesStore {
  MSMEBlinkActivitiesData: MSMEBlinkActivity[] = []; //This is all  data for get all
  msmeblinkactivitiesRegistry = new Map<number, MSMEBlinkActivity>(); 
  selectedMSMEBlinkActivities: MSMEBlinkActivity | undefined = undefined;
  editMode: boolean = false;
  loading: boolean = false;
  loadingInitial: boolean = false;
  pagination: Pagination | null = null;
  pagingParams = new PagingParams();
  predicate = new Map().set("all", true);
  baseAPI = process.env.REACT_APP_API_URL;

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.predicate.keys(),
      () => {
        this.pagingParams = new PagingParams();
        this.msmeblinkactivitiesRegistry.clear();
      }
    );
  }

  get axiosParams() {
    const params = new URLSearchParams();
    params.append("pageNumber", this.pagingParams.pageNumber.toString());
    params.append("pageSize", this.pagingParams.pageNumber.toString());
    this.predicate.forEach((value, key) => {
      params.append(key, value);
    });
    return params;
  }

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  private getMSMEBlinkActivities = (id: number) => {
    return this.msmeblinkactivitiesRegistry.get(id);
  };

  private setMSMEBlinkActivitiesData = (values: MSMEBlinkActivity) => {
    this.MSMEBlinkActivitiesData.push(values);
  };

  loadMSMEBlinkActivities = async (id: number) => {
    let values = this.getMSMEBlinkActivities(id);

    if (values) {
      this.selectedMSMEBlinkActivities = values;
      return values;
    } else {
      this.setLoadingInitial(true);
      try {
        values = await agent.MSMEBlinkActivities.details(id);
        this.setMSMEBlinkActivitiesData(values);
        runInAction(() => {
          this.selectedMSMEBlinkActivities = values;
        });
        return values;
      } finally {
        this.setLoadingInitial(false);
      }
    }
  };

  createMSMEBlinkActivities = async (values: MSMEBlinkActivityFormValues) => {
        await agent.MSMEBlinkActivities.create(values);
    const data = new MSMEBlinkActivity(values);
    
    runInAction(() => {
      this.selectedMSMEBlinkActivities = data;
    });
  };

  updateMSMEBlinkActivities = async (values: MSMEBlinkActivityFormValues) => {
    await agent.MSMEBlinkActivities.update(values);

  };

  deleteMSMEBlinkActivities = async (id: number) => {
    this.loading = true;
    try {
      await agent.MSMEBlinkActivities.delete(id);
      runInAction(() => {
        this.msmeblinkactivitiesRegistry.delete(id);
        this.loading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  clearMSMEBlinkActivities = () => {
    this.selectedMSMEBlinkActivities = undefined;
  };
}

import React from "react";
import { Segment, Grid, Header, Image, List, Card } from "semantic-ui-react";
import { advertismentData } from "../../../app/common/TemStaticData";

export const Advertisement = () => {
  return (
    <Segment>
      <h1>Advertisement</h1>
      <div className="ui warning message">
        <div className="header">Manage Advertisement.</div>
        <p>Create and manage Advertisement.</p>
      </div>
      <div className="ui divider" title="List of Resources"></div>
      <Card fluid>
        <Card.Content>
          {/* <Grid.Column width="16"> */}
          <Header style={{ margin: 0 }} content="ADVERTISMENT" />
          {/* </Grid.Column> */}
        </Card.Content>
        <Card.Content>
          <Grid divided>
            <Grid.Column width="10">
              <Header style={{ margin: 0 }} size="medium">
                {advertismentData[0].productName}
              </Header>
              <div>Description</div>
              <List bulleted>
                {advertismentData[0].productDescription.map((item) => (
                  <List.Item>{item}</List.Item>
                ))}
              </List>
            </Grid.Column>
            <Grid.Column width="6">
              <Image size="large" src="/assets/placeholder.png" />
            </Grid.Column>
          </Grid>
        </Card.Content>
        <Card.Content>
          <Card.Meta style={{ fontWeight: 700 }}> Contact </Card.Meta>
          <Grid columns="2">
            <Grid.Column style={{ paddingBottom: 0 }}>
              <div style={{ fontWeight: 600 }}>
                Contact Name: {advertismentData[0].contactName}
              </div>
            </Grid.Column>
            <Grid.Column style={{ paddingBottom: 0 }}>
              <div style={{ fontWeight: 600 }}>
                Website: {advertismentData[0].website}
              </div>
            </Grid.Column>
            <Grid.Column style={{ paddingBottom: 0 }}>
              <div style={{ fontWeight: 600 }}>
                Phone Number: {advertismentData[0].phoneNumber}
              </div>
            </Grid.Column>
            <Grid.Column style={{ paddingBottom: 0 }}>
              <div style={{ fontWeight: 600 }}>
                Facebook : {advertismentData[0].facebook}
              </div>
            </Grid.Column>
            <Grid.Column style={{ paddingBottom: 0 }}>
              <div style={{ fontWeight: 600 }}>
                Email : {advertismentData[0].email}
              </div>
            </Grid.Column>
            <Grid.Column style={{ paddingBottom: 0 }}>
              <div style={{ fontWeight: 600 }}>
                Instagram : {advertismentData[0].instagram}
              </div>
            </Grid.Column>
            <Grid.Column style={{ paddingBottom: 0 }}>
              <div style={{ fontWeight: 600 }}>
                Posted By : {advertismentData[0].postedBy}
              </div>
            </Grid.Column>
            <Grid.Column style={{ paddingBottom: 0 }}>
              <div style={{ fontWeight: 600 }}>
                Twitter : {advertismentData[0].twitter}
              </div>
            </Grid.Column>
          </Grid>
        </Card.Content>
      </Card>
    </Segment>
  );
};

/**
 * this is the ideal props I think every dev-express grid should have
 */
export const dxGridProps: any = {
  wordWrapEnabled: true,
  hoverStateEnabled: true,
  columnAutoWidth: true,
  activeStateEnabled: true,
  columnResizingMode: "widget",
  allowColumnResizing: true,
  showBorders: true,
  columnHidingEnabled: true,
  rowAlternationEnabled: true,
};

export type IMasterDetail = "GRID" | "FORM-EDIT" | "ADD-NEW";

import React, { useState } from "react";
import { NavHashLink as HashLink } from "react-router-hash-link";
import { Images } from "./Assets/Assets";
import { Link } from "react-router-dom";
import {
  onboarding,
  blinkhubBenefits,
  topBenfits,
  networkPartners,
  about,
  navItems,
} from "./data";
import {
  Segment,
  Container,
  Grid,
  Image,
  Header,
  Divider,
  Icon,
  Menu,
  List,
  Button,
} from "semantic-ui-react";
import "./LandingPage.css";
import { useStore } from "../../app/stores/store";
// import LoginForm from "../users/LoginForm";
// import RegisterForm from "../users/RegisterForm/RegisterForm";
// import ModalStore from "../../app/stores/modalStore";
import UserStore from "../../app/stores/userStore";

const LandingPage = () => {
  const [index, setIndex] = useState(0);
  const { title, text, images } = blinkhubBenefits[index];
  const { userStore } = useStore();

  const checkIndex = (number: number) => {
    if (number > blinkhubBenefits.length - 1) {
      return 0;
    }
    return number;
  };

  const next = () => {
    setIndex((index) => {
      let newIndex = index + 1;
      return checkIndex(newIndex);
    });
  };

  return (
    <>
      {/* this is the navigation("navbar") component */}
      <Navigation />
      {/* i think this is going to be the semantic-ui banner comonent  */}
      <WelcomeBanner userStore={userStore} />
      {/* this is the semantic ui dashboard */}
      <Dashboard
        title={title}
        text={text}
        images={images}
        index={index}
        next={next}
      />
      {/* this is the semantic about section */}
      <About />

      <Footer />
    </>
  );
};

interface DashboardProps {
  title?: string;
  text?: string;
  images?: string;
  index: number;
  next?: () => void;
}

interface BannerProps {
  // modalStore: ModalStore;
  userStore: UserStore;
}

const Dashboard: React.FC<DashboardProps> = ({
  title,
  text,
  images,
  index,
  next,
}) => {
  return (
    <>
      <div className='dashboard'>
        <div className='content'>
          {/* <StyledDashboarditems> */}
          <div className='item  left-one' id='subscription'>
            <div className='inner'>
              <div className='circle w-2/5 text-white'>
                <h3 className='text-2xl  font-bold mb-3'>Onboarding</h3>
                <p className=' text-lg w-11/12 font-semibold mb-3'>
                  Complete These Steps to start working with blinkhub
                </p>
                <p className='text-lg font-normal'>2 Completed / 3</p>
              </div>
              <div className='steps-list w-3/5  flex items-center  pl-5 '>
                <ul className='flex gap-y-3 flex-col '>
                  {onboarding.map((item, index) => (
                    <li
                      key={index + item.text}
                      className='flex gap-x-3  items-start '>
                      <Icon name='check circle' color='yellow' />
                      <span className='text-lg'>{item.text}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          {/* </StyledDashboarditems> */}

          {/* <StyledDashboarditems> */}
          <div className='item right-one '>
            <header>
              Business Clinic
              <Icon name='info circle' color='blue' />
            </header>
            <p>
              Our business advice and support will save you time and money,
              allowing you to concentrate on running your business.
            </p>
          </div>
          {/* </StyledDashboarditems> */}

          {/* <StyledDashboarditems> */}
          <div className='item left-two' id='benefits'>
            <div className='benefits'>
              <div className='card'>
                <div className='circle '>
                  <h3>Top {blinkhubBenefits.length} Benefits of BlinkHub</h3>
                  <p>{title}</p>
                  <div className='slide-index '>
                    {blinkhubBenefits.map((element, i) => (
                      <>
                        <div
                          key={i}
                          className={`position  ${
                            i <= index ? "bg-yellow-500" : "bg-red-900"
                          }`}></div>
                      </>
                    ))}
                  </div>
                </div>
                <div className='text'>
                  <img src={images} alt={title} className='icon' />
                  <span>{text}</span>
                </div>
                <div onClick={next} className='next'>
                  <Icon name='chevron right' size='large' />
                </div>
              </div>
            </div>
          </div>
          {/* </StyledDashboarditems> */}

          {/* <StyledDashboarditems> */}
          <div className='item right-two '>
            <header>Top {topBenfits.length} Benefits</header>
            <section>
              {topBenfits.map((item, i) => (
                <article key={i + item.title}>
                  <h4>{item.title}</h4>
                  <p>{item.text}</p>
                </article>
              ))}
            </section>
          </div>
          {/* </StyledDashboarditems> */}

          {/* <StyledDashboarditems> */}
          <div className='item bottom ' id='partners'>
            <header>Network of Partners</header>
            <section>
              {networkPartners.map((item, i) => (
                <div key={i + item.title} className='network'>
                  <h4>{item.title}</h4>
                  <p>{item.text}</p>
                </div>
              ))}
            </section>
          </div>
          {/* </StyledDashboarditems> */}
        </div>
      </div>
    </>
  );
};

const WelcomeBanner: React.FC<BannerProps> = ({ userStore }) => {
  return (
    <div className='welcome-banner'>
      <div className='content'>
        <div className='left'>
          <div>
            <Image src={Images.BlinkHubLogo} alt='BlinkHubLogo' />
          </div>
          <div>
            <h3>Welcome</h3>
            <span>To the world of business support</span>
            <div style={{ marginTop: "10px" }}>
              <h3 style={{ fontSize: "27px" }}>
                Plan, Fund and grow your bussiness
              </h3>
              <span>
                Easily write a business plan, secure funding and get support
                from a Business Support Provider(BSP) to help you reach your
                goals{" "}
              </span>
            </div>
          </div>
        </div>
        <div className='right'>
          {userStore.isLoggedIn ? (
            <>
              <Header as='h2' inverted content='Welcome to Blinkhub' />
              <Button as={Link} to='/app/gateway' size='huge' inverted>
                Go to Activities!
              </Button>
            </>
          ) : (
            <>
              {/* <Button
                onClick={() => modalStore.openModal(<LoginForm />)}
                size="huge"
                inverted>
                Login!
              </Button> */}
              <Button as={Link} to='/auth/login' size='huge' inverted>
                Login!
              </Button>
              <Button
                // onClick={() => modalStore.openModal(<RegisterForm />)}
                as={Link}
                to='/auth/registerPage'
                size='huge'
                inverted>
                Register!
              </Button>
              {/* <Divider horizontal inverted className="divider-brown">
                Or
              </Divider>
              <Button
                loading={userStore.fbLoading}
                size="huge"
                inverted
                color="facebook"
                content="Login with Facebook"
                onClick={userStore.facebookLogin}
              /> */}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const About = () => {
  return (
    <div>
      <div className='box-size' id='about'>
        <div className='content'>
          <Header color='blue' size='huge'>
            Who we are and what we do
          </Header>
          <Divider hidden />
          {about.map((item, index) => (
            <Header key={index}>
              <div
                className='about'
                dangerouslySetInnerHTML={{ __html: item.caption }}
              />
            </Header>
          ))}
        </div>
        <Divider section hidden />
      </div>
    </div>
  );
};

const Navigation = () => {
  // const [activeItem, setActiveItem] = useState({});

  // const handleItemClick = ({e , name }) => {
  //   setActiveItem({ activeItem: name });
  // };

  return (
    <Segment inverted vertical>
      <Container>
        <Menu inverted stackable secondary>
          <Menu.Menu>
            {navItems.map((items) => (
              <Menu.Item
                key={items.id}
                name={items.text}
                // active={activeItem === items.text}
                // onClick={handleItemClick}
              >
                <HashLink smooth to={`${items.link}`}>
                  {items.text}
                </HashLink>
              </Menu.Item>
            ))}
          </Menu.Menu>
          {/* <Menu.Menu position="right">
            <Menu.Item
              name="sign-up"
              active={activeItem === "sign-up"}
              onClick={handleItemClick}
            >
              Sign Up
            </Menu.Item>
            <Menu.Item
              name="log-in"
              active={activeItem === "log-in"}
              onClick={handleItemClick}
            >
              Login
            </Menu.Item>
          </Menu.Menu> */}
        </Menu>
      </Container>
    </Segment>
  );
};

const Footer = () => {
  return (
    <>
      <Segment className='footer' vertical>
        <Container>
          <Grid columns='equal'>
            <Grid.Column className='logo img-container'>
              <Image src={Images.BlinkHubLogo} alt='BlinkHub Logo' />
            </Grid.Column>
            <Grid.Column>
              <Header size='medium' inverted>
                Business Ecosystem Linkages
              </Header>
              <List>
                <List.Item className='font-white'>
                  #30 trans woji road 2nd floor
                </List.Item>
                <List.Item className='font-white'>Port Harcourt</List.Item>
                <List.Item className='font-white'>Rivers State</List.Item>
                <List.Item className='font-white'>
                  <a href=' Tel:+23408033131904'>Tel: +234 08033131904</a>
                </List.Item>
              </List>
            </Grid.Column>

            <Grid.Column>
              <Header size='medium' inverted>
                Legal
              </Header>

              <List>
                <List.Item>
                  <Link to='/terms-of-use' className='font-white'>
                    Terms and Conditions
                  </Link>
                </List.Item>
                <List.Item className='font-white'>Cookie Policy</List.Item>
              </List>
            </Grid.Column>
          </Grid>
          <Divider hidden />
          <Menu className='footer-social'>
            <Menu.Menu position='right'>
              <Icon className='font-white' size='big' name='twitter' />
              <Icon className='font-white' size='big' name='facebook' />
              <Icon className='font-white' size='big' name='linkedin' />
            </Menu.Menu>
          </Menu>
        </Container>
      </Segment>
    </>
  );
};

export default LandingPage;

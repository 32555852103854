import React from "react";
import { Segment } from "semantic-ui-react";

export const OpenConversation = () => {
  return (
    <Segment>
      <h1>Open Conversation</h1>
      <div className="ui warning message">
        <div className="header">DasManage Open Conversation</div>
        <p>Create and manage Open Conversations.</p>
      </div>
      <div className="ui divider" title="List of Resources"></div>
    </Segment>
  );
};

import React, { useState } from "react";
import { useStore } from "../../app/stores/store";
import { Button, Grid, Header, Segment, Tab } from "semantic-ui-react";
import ProfileEditForm from "./ProfileEditForm";
import { observer } from "mobx-react-lite";
import UserStore from "../../app/stores/userStore";
import ProfileBlinkhubStatusEditForm from "./ProfileBlinkhubStatusEditForm";

export default observer(function ProfileBlinkhubStatus() {
  const { profileStore, userStore } = useStore();
  const { isCurrentUser, profile } = profileStore;
  const [editMode, setEditMode] = useState(false); 
  const { user } = userStore;

  return (
    <Tab.Pane>
      <Grid>
        {/* <Grid.Column width="16">
          <Segment>
            <h1>My Blinkhub Status</h1>

            <div className="ui warning message">
              {isCurrentUser && (
                <Button
                  floated="right"
                  basic
                  content={editMode ? "Cancel" : "Edit Data"}
                  onClick={() => setEditMode(!editMode)}
                />
              )}
              <div className="header">Blinkhub Status View.</div>
              <p>
                Your Blinkhub status can be viewed here. Some special Admin
                notification will be posted here for you to take action.
              </p>
            </div>
            <div className="ui divider" title="List of Resources"></div>
          </Segment>
        </Grid.Column> */}
        <Grid.Column width="16">
          {/* {editMode ? ( */}
            <ProfileBlinkhubStatusEditForm
              setMode={() => setEditMode(true)}
              userGUID={user?.userGUID}
            />
          {/* ) : (
            <span style={{ whiteSpace: "pre-wrap" }}>{profile?.bio}</span>
          )} */}
        </Grid.Column>
      </Grid>
    </Tab.Pane>
  );
});

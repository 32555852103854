//*************************************************************
// PROJECT NAME : Blink Hub Network
// CLASS        : TOOLBLinkCosterExpense
// DATE CREATED : 10 February 2022
// TIME CREATED : 01:20:53
// DEVELOPER    : Ibinaiye Wale Kayode
//*************************************************************

export interface CosterExpense {
  expenseOID: number;
  expenseDescription: string;
  unitPrice: number;
  unitOfMeasure: string;
}

export class CosterExpense implements CosterExpense {
  constructor(init?: CosterExpenseFormValues) {
    Object.assign(this, init);
  }
}

export class CosterExpenseFormValues {
  expenseOID = 0;
  expenseDescription: string = "";
  unitOfMeasure: string = "";
  unitPrice = 0;

  constructor(toolBLinkCosterExpense?: CosterExpenseFormValues) {
    if (toolBLinkCosterExpense) {
      this.expenseOID = toolBLinkCosterExpense.expenseOID;
      this.expenseDescription = toolBLinkCosterExpense.expenseDescription;
      this.unitPrice = toolBLinkCosterExpense.unitPrice;
      this.unitOfMeasure = toolBLinkCosterExpense.unitOfMeasure;
    }
  }
}

export interface FeedbackModel {
  feedbackOID: string;
  feedbackCategory: string;
  describeFeedback: string;
  dateUploaded: Date | null;
}

export class FeedbackModel implements FeedbackModel {
  constructor(init?: FeedbackFormValues) {
    Object.assign(this, init);
  }
}

export class FeedbackFormValues {
  feedbackOID?: string = undefined;
  feedbackCategory: string = "";
  describeFeedback: string = "";

  constructor(feedback?: FeedbackFormValues) {
    if (feedback) {
      this.feedbackOID = feedback.feedbackOID;
      this.feedbackCategory = feedback.feedbackCategory;
      this.describeFeedback = feedback.describeFeedback;
    }
  }
}

export interface CompanyInfo {
  companyOID: string;
  companyName: string;
  street: string;
  city: string;
  state: string;

  dateUploaded: Date | null;

  // date: Date | null;
  // description: string;
  // category: string;
  // city: string;
  // venue: string;
  // hostUsername: string;
  // isCancelled: boolean;
  // isGoing: boolean;
  // isHost: boolean;
  // host?: Profile;
  // attendees: Profile[]
}

export class CompanyInfo implements CompanyInfo {
  constructor(init?: CompanyInfoFormValues) {
    Object.assign(this, init);
  }
}

export class CompanyInfoFormValues {
  companyOID?: string = undefined;
  companyName: string = "";
  street: string = "";
  city: string = "";
  state: string = "";

  // dateUploaded: Date  = Date.now();

  constructor(companyinfo?: CompanyInfoFormValues) {
    if (companyinfo) {
      this.companyOID = companyinfo.companyOID;
      this.companyName = companyinfo.companyName;
      this.street = companyinfo.street;
      this.city = companyinfo.city;
      this.state = companyinfo.state;
    }
  }
}

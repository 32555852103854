// import React from 'react'
import React, { useEffect } from "react";
// import Axios from "axios";
//Insert any custom CSS here;
import "./ManageBPList.css";
// import Box from "../../../components/Box/Box";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import "devextreme-react/text-area";
// import { Item } from "devextreme-react/form";
//import { baseAPI } from "../../../api/APIEndpoints";
import DataGrid, {
  FilterPanel,
  FilterRow,
  SearchPanel,
  Button,
  Paging,
  //   Lookup,
  Popup,
  //   Position,
  Editing,
  Export,
  Pager,
  Grouping,
  GroupPanel,
  RowDragging,
  Column,
  RequiredRule,
  //   PatternRule,
  //   EmailRule,
  //   AsyncRule,
} from "devextreme-react/data-grid";
// import { useSelector } from "react-redux";
import CustomStore from "devextreme/data/custom_store";
import "whatwg-fetch";
import { store, useStore } from "../../../app/stores/store";
import {
  //   Container,
  Segment,
  Message,
  Grid,
  Button as SMButton,
} from "semantic-ui-react";
// import ResourceStore from "../../../app/stores/resourceStore";
// import { Divider } from "semantic-ui-react";
import ResourceForm from "../../resources/form/ResourceForm";
import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";

//********************FUNCTIONS******************************* */
function handleErrors(response: any) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}
//********************FUNCTIONS******************************* */

const ManageBPList = () => {
  const [dataSource, setDataSource] = React.useState({});
  const [rowData, setRowData]: any = React.useState({});
  const { resourceStore } = useStore();
  const { loading, setLoadingInitial, loadingInitial } = resourceStore;
  const [mode, setMode] = React.useState("GRID");
  const [resourceGUID, setResourceGUID] = React.useState("GRID");
  const jwt = store.commonStore.token;
  const baseAPI = process.env.REACT_APP_API_URL; // "https://localhost:44322/api";
  React.useEffect(() => {
    const customDataSource: any = new CustomStore({
      key: "resourceGUID",
      load: () => {
        return fetch(`${baseAPI}/ResourceV2s`, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        })
          .then(handleErrors)
          .then((response) => response.json())
          .then((response) => {
            return {
              data: response.result.data,
              totalCount: response.count,
              summary: response.summary,
              groupCount: response.count,
            };
          });
      },
      insert: (values) => {
        console.log("Value =" + JSON.stringify(values));
        return fetch(`${baseAPI}/ResourceV2s`, {
          method: "POST",
          body: JSON.stringify(values),
          headers: {
            Authorization: `Bearer ${jwt}`,
            "Content-Type": "application/json",
          },
        }).then(handleErrors);
      },
      remove: (key) => {
        return fetch(`${baseAPI}/ResourceV2s`, {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }).then(handleErrors);
      },
      update: (values) => {
        return values;
      },
    });

    //Save to store
    setDataSource(customDataSource);
  }, [baseAPI, jwt]);

  const handleEdit = (e: any) => {
    // const data = e.row.data;
    setRowData((data: any) => {
      return { ...e.row.data };
    });
    setMode("FORM-EDIT");
    // console.log(rowData);
  };
  const handleView = (e: any) => {
    // const data = e.row.data;
    setResourceGUID(e.row.data.resourceGUID);
    setLoadingInitial(true);
    // console.log(e.row.data);
    setMode("FORM-EDIT");
    // console.log(rowData);
  };

  //   const handleView = e: any) => {
  //   //  <Button
  //   //           as={Link}
  //   //           to={`/activities/${activity.id}`}
  //   //           color="teal"
  //   //           floated="right"
  //   //           content="View"
  //   //         />
  // };

  // const onRowUpdating = (e: any) => {
  //   e.newData = Object.assign(e.oldData, e.newData);
  //   updateResource(e.newData).then(dataSource.load());
  // };

  return mode === "GRID" ? (
    <Segment>
      <h1>Create/Manage Business Plan</h1>
      <Message color="brown">
        {/* <div className="header">List of Resources.</div>
        <p>The table below provides the list of all available resources.</p> */}
        <Grid>
          <Grid.Column width="8">
            <Message.Header>
              You can create a new business plan using an existing template or
              you can select from the list of existing business plan to edit
            </Message.Header>
          </Grid.Column>
          <Grid.Column width="8">
            <div
              style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
              <SMButton
                as={Link}
                to="/businessplan-workspace"
                color="brown"
                content="Create Blank Business Plan"
              />
              <SMButton
                as={Link}
                to="/bp-templates"
                color="brown"
                content="Create from Template"
              />
            </div>
          </Grid.Column>
        </Grid>
      </Message>
      <div className="ui divider" title="List of Resources"></div>
      <div>
        <DataGrid
          id="grdResources"
          dataSource={dataSource && dataSource}
          className="dx-datagrid dx-row-alt"
          hoverStateEnabled={true}
          columnAutoWidth={true}
          activeStateEnabled={true}
          columnResizingMode="widget"
          allowColumnResizing={true}
          // onRowUpdating={onRowUpdating}
          columnHidingEnabled={true}
          // onToolbarPreparing={handleToolPreparing}
        >
          <RowDragging allowReordering={true} />
          <FilterPanel visible={true} />
          <SearchPanel visible={true} />
          <FilterRow visible={true} />
          <Paging defaultPageSize={10} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={[10, 20, 50, 100]}
            showInfo={true}
          />
          <Grouping contextMenuEnabled={true} />
          <GroupPanel visible={true} />
          <Editing
            mode="popup"
            useIcons={true}
            allowUpdating={true}
            allowDeleting={true}
            allowAdding={true}
          />
          <Popup
            title="Resources"
            showTitle={true}
            width={40}
            height={30}></Popup>
          <Export
            enabled={true}
            fileName="Resources"
            allowExportSelectedData={true}
          />
          {/* <Segment clearing>
          <span>{activity.description}</span>
          <Button
            as={Link}
            to={`/activities/${activity.id}`}
            color="teal"
            floated="right"
            content="View"
          />
        </Segment> */}

          <Column type="buttons">
            <Button text="Edit" onClick={handleView} cssClass={"edit-btn"} />
            <Button text="View" onClick={handleView} cssClass={"edit-btn"} />
          </Column>
          <Column
            dataField="resourceGUID"
            allowEditing={false}
            visible={false}></Column>
          <Column dataField="category">
            <RequiredRule />
          </Column>
          <Column dataField="description">
            <RequiredRule />
          </Column>
          <Column dataField="code">
            <RequiredRule />
          </Column>

          <Column dataField="title">
            <RequiredRule />
          </Column>

          <Column dataField="size">
            <RequiredRule />
          </Column>
          <Column dataField="source">
            <RequiredRule />
          </Column>
          <Column dataField="downloads"></Column>
          <Column dataField="sortOrder">
            <RequiredRule />
          </Column>
          <Column dataField="dateUploaded"></Column>
        </DataGrid>
      </div>
    </Segment>
  ) : (
    <ResourceForm setMode={() => setMode("GRID")} resourceGUID={resourceGUID} />
  );
};

export default observer(ManageBPList);

import React from "react";
import { Segment, Card, Button } from "semantic-ui-react";
import { ErrorMessage, Form, Formik } from "formik";
import MyTextInput from "../../../app/common/form/MyTextInput";
import * as Yup from "yup";
import ValidationErrors from "../../errors/ValidationErrors";
import MyTextArea from "../../../app/common/form/MyTextArea";

export const PublicNotice = () => {
  return (
    <Segment>
      <h1>Public Notice</h1>
      <div className="ui warning message">
        <div className="header">Manage Public Notice.</div>
        <p>Create and Public Notice.</p>
      </div>
      <div className="ui divider" title="List of Resources"></div>
      <div>
        <div>
          <Formik
            initialValues={{ title: "", message: "" }}
            onSubmit={() => console.log("hi")}
            validationSchema={Yup.object({
              title: Yup.string().required(),
              message: Yup.string().required(),
            })}>
            {({ handleSubmit, isSubmitting, errors, isValid, dirty }) => (
              <Form className="ui form error">
                <MyTextInput
                  placeholder="Enter a title"
                  id="title"
                  label="Title"
                  name="title"
                />
                <MyTextArea
                  placeholder="Enter a Message"
                  id="message"
                  label="Message"
                  name="message"
                  rows={3}
                />
                <Card.Description>Signed By:</Card.Description>
                <Card.Description>Cad</Card.Description>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    // disabled={!isValid || !dirty || isSubmitting}
                    // loading={isSubmitting}
                    // color="brown"
                    // inverted
                    content="Cancel"
                    // type="submit"
                  />
                  <Button
                    disabled={!isValid || !dirty || isSubmitting}
                    loading={isSubmitting}
                    // color="brown"
                    positive
                    inverted
                    content="Submit"
                    type="submit"
                  />
                  <Button
                    // disabled={!isValid || !dirty || isSubmitting}
                    // loading={isSubmitting}
                    // color="red"
                    negative
                    // inverted
                    content="Delete"
                    // type="submit"
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
        {/* <Card fluid>
          <Card.Content>
            <Card.Header>Cad Notice</Card.Header>
            <Card.Description>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Reprehenderit quia ratione maxime atque est voluptatem molestias
              ipsum suscipit iusto cupiditat
            </Card.Description>
            <Card.Meta>Signed By:</Card.Meta>
            <Card.Meta>Cad</Card.Meta>
          </Card.Content>
        </Card> */}
      </div>
    </Segment>
  );
};

import React from "react";
import { Segment, Form, Radio } from "semantic-ui-react";

export const Poll = () => {
  return (
    <Segment>
      <h1>Poll</h1>
      <div className="ui warning message">
        <div className="header">Manage Poll.</div>
        <p>Create and manage Poll.</p>
      </div>
      <div className="ui divider" title="List of Resources"></div>
      <div>
        <Form>
          <Form.Field>
            Chicken Egg si scarce in Rivers State? True or false
          </Form.Field>
          <Form.Field>
            <Radio label="True" name="egg-poll" value="true" />
          </Form.Field>
          <Form.Field>
            <Radio label="False" name="egg-poll" value="false" />
          </Form.Field>
        </Form>
      </div>
    </Segment>
  );
};

import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../api/agent";
import agentExpress from "../api/agentExpress";
//import { Activity, ActivityFormValues } from "../models/activity";
import { Resource, ResourceFormValues } from "../models/resource";
import { format } from "date-fns";
import { store } from "./store";
import { Profile } from "../models/profile";
import { Pagination, PagingParams } from "../models/pagination";
import axios from "axios";
import {
  BusinessSectorModel,
  BusinessSectorFormValues,
} from "../models/businessSector";

export default class BusinessSectorStore {
  businessSectorData: BusinessSectorModel[] = [];
  // resourceV2Data: Resource[] = [];
  //comments: ChatComment[] = [];
  businessSectorRegistry = new Map<string, BusinessSectorModel>();
  selectedBusinessSectorModel: BusinessSectorModel | undefined = undefined;
  editMode = false;
  loading = false;
  loadingInitial = false;
  pagination: Pagination | null = null;
  pagingParams = new PagingParams();
  predicate = new Map().set("all", true);
  baseAPI = process.env.REACT_APP_API_URL;

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.predicate.keys(),
      () => {
        this.pagingParams = new PagingParams();
        this.businessSectorRegistry.clear();
        this.loadBusinessSectorModel();
      }
    );
  }

  setPagingParams = (pagingParams: PagingParams) => {
    pagingParams.pageNumber = 1;
    pagingParams.pageSize = 500;
    this.pagingParams = pagingParams;
  };

  setPredicate = (predicate: string, value: string | Date) => {
    const resetPredicate = () => {
      this.predicate.forEach((value, key) => {
        if (key !== "dateUploaded") this.predicate.delete(key);
      });
    };
    switch (predicate) {
      case "all":
        resetPredicate();
        this.predicate.set("all", true);
        break;
      case "isGoing":
        resetPredicate();
        this.predicate.set("isGoing", true);
        break;
      case "isHost":
        resetPredicate();
        this.predicate.set("isHost", true);
        break;
      case "dateUploaded":
        this.predicate.delete("dateUploaded");
        this.predicate.set("dateUploaded", value);
    }
  };

  get axiosParams() {
    const params = new URLSearchParams();
    // params.append("pageNumber", this.pagingParams.pageNumber.toString());
    // params.append("pageSize", this.pagingParams.pageSize.toString());
    params.append("pageNumber", "1");
    params.append("pageSize", "500");
    this.predicate.forEach((value, key) => {
      if (key === "dateUploaded") {
        params.append(key, (value as Date).toISOString());
      } else {
        params.append(key, value);
      }
    });
    return params;
  }

  get businessSectorByDate() {
    return Array.from(this.businessSectorRegistry.values()).sort(
      (a, b) => a.dateUploaded!.getTime() - b.dateUploaded!.getTime()
    );
  }

  // get groupedEcoBusinessSector() {
  //   return Object.entries(
  //     this.businessSectorByDate.reduce((businessSector) => {
  //       const date = format(businessSector.dateUploaded!, "dd MMM yyyy");
  //       businessSector[date] = businessSector[date]
  //         ? [...businessSector[date], businessSector]
  //         : [businessSector];
  //       return businessSector;
  //     }, {} as { [key: string]: BusinessSectorModel[] })
  //   );
  // }

  // loadActivities = async () => {
  //   this.loadingInitial = true;
  //   try {
  //     const result = await agent.Activities.list(this.axiosParams);
  //     result.data.forEach((activity) => {
  //       this.setActivity(activity);
  //     });
  //     this.setPagination(result.pagination);
  //     this.setLoadingInitial(false);
  //   } catch (error) {
  //     console.log(error);
  //     this.setLoadingInitial(false);
  //   }
  // };

  loadBusinessSectorModel = async () => {
    this.loadingInitial = true;
    try {
      console.log("1. LOG = " + this.axiosParams);
      const result = await agent.EcoBusinessSector.list(this.axiosParams);
      //this.resourceData.push(result);
      //console.log("1. result from loadResources()", result);
      // result.map(
      //   (resource) =>
      //     //this.resourceData.push(resource)
      //     this.setResourceData(resource)
      // );
      // console.log("2. result from loadResources()");
      this.businessSectorData = result.data;
      // console.log(this.resourceData);
      // result.data.forEach((resource) => {
      //   this.setResourceData(resource);
      // });
      //this.setPagination(result.pagination);
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
    }
  };

  loadEcoBusinessSectorDashboard = async () => {
    this.loadingInitial = true;
    try {
      const result = await agentExpress.EcoBusinessSector.list();
      this.businessSectorData = result;
      console.log("1. In loadEcoBusinessSectorDashboard: result", result);
      console.log(
        "2. In loadEcoBusinessSectorDashboard: this.EcoBusinessSectorData",
        this.businessSectorData
      );
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
    }
  };

  setPagination = (pagination: Pagination) => {
    this.pagination = pagination;
  };

  loadEcoBusinessSector = async (id: string) => {
    let businessSector = this.getBusinessSectorModel(id);
    if (businessSector) {
      this.selectedBusinessSectorModel = businessSector;
      return businessSector;
    } else {
      this.loadingInitial = true;
      try {
        businessSector = await agent.EcoBusinessSector.details(id);
        this.setBusinessSectorModelData(businessSector);
        runInAction(() => {
          this.selectedBusinessSectorModel = businessSector;
        });
        this.setLoadingInitial(false);
        return businessSector;
      } catch (error) {
        console.log(error);
        this.setLoadingInitial(false);
      }
    }
  };
  private setBusinessSectorModelData = (
    businessSector: BusinessSectorModel
  ) => {
    //resource.dateUploaded = new Date(resource.dateUploaded!);
    this.businessSectorData.push(businessSector);
  };

  private setBusinessSectorModel = (businessSector: BusinessSectorModel) => {
    businessSector.dateUploaded = new Date(businessSector.dateUploaded!);
    this.businessSectorRegistry.set(
      businessSector.businessSectorOID,
      businessSector
    );
  };

  private getBusinessSectorModel = (id: string) => {
    return this.businessSectorRegistry.get(id);
  };

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  createBusinessSectorModel = async (
    businessSector: BusinessSectorFormValues
  ) => {
    try {
      await agent.EcoBusinessSector.create(businessSector);
      const newBusinessSectorModel = new BusinessSectorModel(businessSector);
      //   newResource.hostUsername = user!.username;
      //   newResource.attendees = [attendee];
      this.setBusinessSectorModel(newBusinessSectorModel);
      runInAction(() => {
        this.selectedBusinessSectorModel = newBusinessSectorModel;
      });
    } catch (error) {
      console.log(error);
    }
  };

  updateBusinessSectorModel = async (
    businessSector: BusinessSectorFormValues
  ) => {
    try {
      await agent.EcoBusinessSector.update(businessSector);
      runInAction(() => {
        if (businessSector.businessSectorOID) {
          let updatedBusinessSectorModel = {
            ...this.getBusinessSectorModel(businessSector.businessSectorOID),
            ...businessSector,
          };
          this.businessSectorRegistry.set(
            businessSector.businessSectorOID,
            updatedBusinessSectorModel as BusinessSectorModel
          );
          this.selectedBusinessSectorModel = updatedBusinessSectorModel as BusinessSectorModel;
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  deleteBusinessSectorModel = async (id: string) => {
    this.loading = true;
    try {
      await agent.EcoBusinessSector.delete(id);
      runInAction(() => {
        this.businessSectorRegistry.delete(id);
        this.loading = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  //   updateAttendance = async () => {
  //     const user = store.userStore.user;
  //     this.loading = true;
  //     try {
  //       await agent.Activities.attend(this.selectedActivity!.id);
  //       runInAction(() => {
  //         if (this.selectedActivity?.isGoing) {
  //           this.selectedActivity.attendees =
  //             this.selectedActivity.attendees?.filter(
  //               (a) => a.username !== user?.username
  //             );
  //           this.selectedActivity.isGoing = false;
  //         } else {
  //           const attendee = new Profile(user!);
  //           this.selectedActivity?.attendees?.push(attendee);
  //           this.selectedActivity!.isGoing = true;
  //         }
  //         this.activityRegistry.set(
  //           this.selectedActivity!.id,
  //           this.selectedActivity!
  //         );
  //       });
  //     } catch (error) {
  //       console.log(error);
  //     } finally {
  //       runInAction(() => (this.loading = false));
  //     }
  //   };

  cancelResourceToggle = async () => {
    this.loading = true;
    try {
      //await agent.Resources.attend(this.selectedResource!.resourceGUID);
      runInAction(() => {
        // this.selectedResource!.isCancelled =
        //   !this.selectedResource?.isCancelled;
        this.businessSectorRegistry.set(
          this.selectedBusinessSectorModel!.businessSectorOID,
          this.selectedBusinessSectorModel!
        );
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

  //   updateAttendeeFollowing = (username: string) => {
  //     this.activityRegistry.forEach((activity) => {
  //       activity.attendees.forEach((attendee) => {
  //         if (attendee.username === username) {
  //           attendee.following
  //             ? attendee.followersCount--
  //             : attendee.followersCount++;
  //           attendee.following = !attendee.following;
  //         }
  //       });
  //     });
  //   };

  clearSelectedBusinessSectorModel = () => {
    this.selectedBusinessSectorModel = undefined;
  };
}

//*************************************************************
// PROJECT NAME : Blinh Hub Network
// CLASS        : MSMEBlinkActivitiesActors
// DATE CREATED : 11 February 2022
// TIME CREATED : 08:09:27
// DEVELOPER    : Ibinaiye Wale Kayode
//*************************************************************

import React, { useEffect, useState } from "react";
import DataGrid, {
  FilterPanel,
  FilterRow,
  SearchPanel,
  Button,
  Paging,
  Popup,
  Editing,
  Export,
  Pager,
  Grouping,
  GroupPanel,
  RowDragging,
  Column,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import "whatwg-fetch";
import { store, useStore } from "../../../app/stores/store";
import { Segment } from "semantic-ui-react";
import { dxGridProps } from "../../../app/common/staticProps";
import ActivitiesActorsForm from "../forms/ActivitiesActorsForm";
import { observer } from "mobx-react-lite";

//********************FUNCTIONS******************************* */
function handleErrors(response: any) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}
//********************FUNCTIONS******************************* */

type Props = {};

const ActivitiesActorsGrid = (props: Props) => {
  const [dataSource, setDataSource] = useState();
  const [open, setOpen] = useState(false);
  const { activitiesActorsStore } = useStore();
  const { setLoadingInitial } = activitiesActorsStore;
  const [rowID, setRowID] = useState(0);
  const jwt = store.commonStore.token;
  const baseAPI = process.env.REACT_APP_API_URL;

  useEffect(() => {
    // setRowID(0);
    const customDataSource: any = new CustomStore({
      key: "blinkActorOID",
      load: () => {
        return fetch(`${baseAPI}/MSMEBlinkActivitiesActorss`, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        })
          .then(handleErrors)
          .then((response) => response.json())
          .then((response) => {
            return {
              data: response.result.data,
              totalCount: response.count,
              summary: response.summary,
              groupCount: response.count,
            };
          });
      },
      // insert: (values) => {
      //   // console.log("Value =" + JSON.stringify(values));
      //   return fetch(`${baseAPI}/MSMEBlinkActivitiesActors`, {
      //     method: "POST",
      //     body: JSON.stringify(values),
      //     headers: {
      //       Authorization: `Bearer ${jwt}`,
      //       "Content-Type": "application/json",
      //     },
      //   }).then(handleErrors);
      // },
      remove: (key) => {
        return fetch(`${baseAPI}/MSMEBlinkActivitiesActorss/${key}`, {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }).then(handleErrors);
      },
      // update: (values) => {
      //   return values;
      // },
    });

    //Save to store
    setDataSource(customDataSource);
  }, [baseAPI, jwt]);

  const handleEditClick = (args: any) => {
    setRowID(args.row.data.blinkActorOID);
    setLoadingInitial(true);
    setOpen(true);
  };

  const handleNewTask = () => {
    setRowID(0);
    setOpen(true);
  };

  function onToolbarPreparing(e: any) {
    let toolbarItems = e.toolbarOptions.items;

    // customize addRow toolbar button
    for (let i = 0; i < toolbarItems.length; i++) {
      let item = toolbarItems[i];
      if (item.name === "addRowButton") {
        item.options.onClick = handleNewTask;
        break;
      }
    }
  }

  return (
    <Segment>
      <>
        <h1>MSMEBlinkActivitiesActors</h1>
        <div className='ui warning message'>
          <div className='header'>List of Activities Actors.</div>
          <p>
            The table below provides the list of all available Activities
            Actors.
          </p>
        </div>
        <div className='ui divider' title='List of ActivitiesActors'></div>
        <div>
          <DataGrid
            id='gridMSMEBlinkActivitiesActors'
            dataSource={dataSource && dataSource}
            {...dxGridProps}
            onToolbarPreparing={onToolbarPreparing}>
            <RowDragging allowReordering={true} />
            <FilterPanel visible={true} />
            <SearchPanel visible={true} />
            <FilterRow visible={true} />
            <Paging defaultPageSize={10} />
            <Pager
              showPageSizeSelector={true}
              allowedPageSizes={[10, 20, 50, 100]}
              showInfo={true}
            />
            <Grouping contextMenuEnabled={true} />
            <GroupPanel visible={true} />
            <Editing
              mode='popup'
              useIcons={true}
              allowUpdating={true}
              allowDeleting={true}
              allowAdding={true}
            />
            <Popup
              title='MSMEBlinkActivitiesActors'
              showTitle={true}
              width={40}
              height={30}></Popup>
            <Export
              enabled={true}
              fileName='MSMEBlinkActivitiesActors'
              allowExportSelectedData={true}
            />

            <Column type='buttons'>
              <Button name='edit' onClick={handleEditClick} icon='edit' />
              <Button name='delete' />
            </Column>

            <Column
              dataField='blinkActorOID'
              allowEditing={false}
              visible={true}></Column>
            <Column
              dataField='quarter'
              allowEditing={true}
              visible={true}></Column>
            <Column dataField='sNo' allowEditing={true} visible={true}></Column>
            <Column
              dataField='supportActorName'
              allowEditing={true}
              visible={true}></Column>
            <Column
              dataField='setupType'
              allowEditing={true}
              visible={true}></Column>
            <Column
              dataField='classification'
              allowEditing={true}
              visible={true}></Column>
            <Column
              dataField='contactDetails'
              allowEditing={true}
              visible={true}></Column>
            <Column
              dataField='telephoneNumber'
              allowEditing={true}
              visible={true}></Column>
            <Column
              dataField='supportFromPIND'
              allowEditing={true}
              visible={true}></Column>
            <Column
              dataField='engagement'
              allowEditing={true}
              visible={true}></Column>
            <Column
              dataField='engagementDate'
              allowEditing={true}
              visible={true}></Column>
            <Column
              dataField='typeOfActivitiy'
              allowEditing={true}
              visible={true}></Column>
            <Column
              dataField='activityDate'
              allowEditing={true}
              visible={true}></Column>
            <Column
              dataField='  typeOfServiceRecipient'
              allowEditing={true}
              visible={true}></Column>
            <Column
              dataField='numberOfServiceRecipient'
              allowEditing={true}
              visible={true}></Column>
            <Column
              dataField='stateOfService'
              allowEditing={true}
              visible={true}></Column>
            <Column
              dataField='lGAOfService'
              allowEditing={true}
              visible={true}></Column>
            <Column
              dataField='communityOfService'
              allowEditing={true}
              visible={true}></Column>
            <Column
              dataField='longitude'
              allowEditing={true}
              visible={true}></Column>
            <Column
              dataField='latitude'
              allowEditing={true}
              visible={true}></Column>
          </DataGrid>
        </div>
      </>
      <ActivitiesActorsForm rowID={rowID} open={open} setOpen={setOpen} />
    </Segment>
  );
};

export default observer(ActivitiesActorsGrid);

export interface LgaModel {
  lgaOID: string;
  lgaCode: string;
  lGA: string;
  stateOID: number;
  dateUploaded: Date | null;

  // date: Date | null;
  // description: string;
  // category: string;
  // city: string;
  // venue: string;
  // hostUsername: string;
  // isCancelled: boolean;
  // isGoing: boolean;
  // isHost: boolean;
  // host?: Profile;
  // attendees: Profile[]
}

export class LgaModel implements LgaModel {
  constructor(init?: LgaFormValues) {
    Object.assign(this, init);
  }
}

export class LgaFormValues {
  lgaOID?: string = undefined;
  lgaCode: string = "";
  lGA: string = "";
  stateOID: number = 0;

  // dateUploaded: Date  = Date.now();

  constructor(lga?: LgaFormValues) {
    if (lga) {
      this.lgaOID = lga.lgaOID;
      this.lgaCode = lga.lgaCode;
      this.lGA = lga.lGA;
      this.stateOID = lga.stateOID;
    }
  }
}

export const initialValues = {
  title: "string",
  lopNumber: "string",
  lopDate: "2022-02-21T21:01:14.157Z",
  expectedDeliveryDate: "2022-02-21T21:01:14.157Z",
  actualDeliveryDate: "2022-02-21T21:01:14.157Z",
  lpoValue: 0,
  paymentTerms: "string",
  expectedPaymentDate: "2022-02-21T21:01:14.157Z",
  fundingSource: "string",
  intermediateFunds: 0,
  stakeholders: "string",
  sharingRatioA: 0,
  sharingRatioB: 0,
  buyerCompanyName: "string",
  buyerContactPerson: "string",
  buyerPhone: "string",
  buyerEmail: "string",
  procurerCompanyName: "string",
  procurerContactPerson: "string",
  procurerPhoneNumber: "string",
  procurerEmail: "string",
  commoditySource: "string",
  height_CM: 0,
  length_CM: 0,
  liveWeight: 0,
  processWeight: 0,
  expectedProfitMargin: 0,
  lastUpdated: "2022-02-21T21:01:14.157Z",
  lastUpdatedBy: "string",
  toolbLinkCosterOfftakePricelVirtual: [
    {
      offtakerPriceOID: 0,
      blinkCosterOID: 0,
      commodityOID: 0,
      quantity: 0,
      unitOfMeasure: "string",
      unitPrice: 0,
      comment: "string",
      commodityType: "string",
      toolbLinkCosterVirtual: {
        blinkCosterOID: 0,
        title: "string",
        lopNumber: "string",
        lopDate: "2022-02-21T21:01:14.157Z",
        expectedDeliveryDate: "2022-02-21T21:01:14.157Z",
        actualDeliveryDate: "2022-02-21T21:01:14.157Z",
        lpoValue: 0,
        paymentTerms: "string",
        expectedPaymentDate: "2022-02-21T21:01:14.157Z",
        fundingSource: "string",
        intermediateFunds: 0,
        stakeholders: "string",
        sharingRatioA: 0,
        sharingRatioB: 0,
        buyerCompanyName: "string",
        buyerContactPerson: "string",
        buyerPhone: "string",
        buyerEmail: "string",
        procurerCompanyName: "string",
        procurerContactPerson: "string",
        procurerPhoneNumber: "string",
        procurerEmail: "string",
        commoditySource: "string",
        height_CM: 0,
        length_CM: 0,
        liveWeight: 0,
        processWeight: 0,
        expectedProfitMargin: 0,
        lastUpdated: "2022-02-21T21:01:14.157Z",
        lastUpdatedBy: "string",
        toolbLinkCosterOfftakePricelVirtual: ["string"],
        toolbLinkCosterExpenseDetailVirtual: ["string"],
      },
      toolbLinkCosterCommodityVirtual: {
        offtakerPriceOID: 0,
        commodityDescription: "string",
        unitPrice: 0,
        unitOfMeasure: "string",
      },
    },
  ],
  toolbLinkCosterExpenseDetailVirtual: [
    {
      expenseOID: 0,
      blinkCosterOID: 0,
      quantity: 0,
      unitOfMeasure: "string",
      unitPrice: 0,
      comment: "string",
      toolbLinkCosterVirtual: {
        blinkCosterOID: 0,
        title: "string",
        lopNumber: "string",
        lopDate: "2022-02-21T21:01:14.157Z",
        expectedDeliveryDate: "2022-02-21T21:01:14.157Z",
        actualDeliveryDate: "2022-02-21T21:01:14.157Z",
        lpoValue: 0,
        paymentTerms: "string",
        expectedPaymentDate: "2022-02-21T21:01:14.157Z",
        fundingSource: "string",
        intermediateFunds: 0,
        stakeholders: "string",
        sharingRatioA: 0,
        sharingRatioB: 0,
        buyerCompanyName: "string",
        buyerContactPerson: "string",
        buyerPhone: "string",
        buyerEmail: "string",
        procurerCompanyName: "string",
        procurerContactPerson: "string",
        procurerPhoneNumber: "string",
        procurerEmail: "string",
        commoditySource: "string",
        height_CM: 0,
        length_CM: 0,
        liveWeight: 0,
        processWeight: 0,
        expectedProfitMargin: 0,
        lastUpdated: "2022-02-21T21:01:14.157Z",
        lastUpdatedBy: "string",
        toolbLinkCosterOfftakePricelVirtual: ["string"],
        toolbLinkCosterExpenseDetailVirtual: ["string"],
      },
      toolbLinkCosterExpenseVirtual: {
        expenseOID: 0,
        expenseDescription: "string",
        unitPrice: 0,
        unitOfMeasure: "string",
      },
    },
  ],
};
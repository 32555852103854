export const categoryOptions = [
    {text: 'Drinks', value: 'drinks'},
    {text: 'Culture', value: 'culture'},
    {text: 'Film', value: 'film'},
    {text: 'Food', value: 'food'},
    {text: 'Music', value: 'music'},
    {text: 'Travel', value: 'travel'},
]

export const resourceOptions = [
  { text: "ALL", value: "ALL" },
  { text: "BSP", value: "BSP" },
  { text: "MSME", value: "MSME" },
];
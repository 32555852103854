import React, { useState } from "react";
import { Segment, Grid, Table, Icon, Form } from "semantic-ui-react";
import {
  linkageMapHeaderData,
  linkageMapTableData,
  linkagemapExpenseData,
  linkagemapIncomeData,
} from "../../app/common/TemStaticData";

export const LinkageMap = () => {
  const [linkageMaps, setLinkageMaps] = useState(linkageMapTableData);
  const [addIncomeFormActive, setAddIncomeFormActive] = useState(false);
  const [addExpenseFormActive, setAddExpenseFormActive] = useState(false);

  const handleAddIncomeFormClick = () => {
    setAddIncomeFormActive(!addIncomeFormActive);
  };
  const handleAddExpenseFormClick = () => {
    setAddExpenseFormActive(!addExpenseFormActive);
  };

  return (
    <Segment>
      <h1>Linkage Map Dashboard</h1>
      <div className="ui warning message">
        <div className="header">Dashboards for accessing Linkage Map.</div>
        <p>
          This dashboard provides a centralized access to current users's
          Linkage Map.
        </p>
      </div>
      <div className="ui divider" title="List of Resources"></div>
      <Grid divided="vertically">
        <Grid.Row columns="3">
          {linkageMapHeaderData.map((item) => (
            <>
              <Grid.Column>
                <div style={{ fontWeight: 600, fontSize: "14px" }}>
                  Title: {item.title}
                </div>
              </Grid.Column>
              <Grid.Column>
                <div style={{ fontWeight: 600, fontSize: "14px" }}>
                  Start Date: {item.startDate}
                </div>
              </Grid.Column>
              <Grid.Column>
                <div style={{ fontWeight: 600, fontSize: "14px" }}>
                  MSME: {item.msme}
                </div>
              </Grid.Column>
              <Grid.Column>
                <div style={{ fontWeight: 600, fontSize: "14px" }}>
                  Product: {item.product}
                </div>
              </Grid.Column>
              <Grid.Column>
                <div style={{ fontWeight: 600, fontSize: "14px" }}>
                  End Date: {item.endDate}
                </div>
              </Grid.Column>
            </>
          ))}
        </Grid.Row>
        <Grid.Row>
          <Table celled compact striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width="5">
                  <StyledFlex>
                    Expense
                    <Icon
                      name="add"
                      onClick={handleAddExpenseFormClick}
                      style={{ cursor: "pointer" }}
                    />
                  </StyledFlex>
                </Table.HeaderCell>
                <Table.HeaderCell width="5">Net </Table.HeaderCell>
                <Table.HeaderCell width="5">
                  <StyledFlex>
                    Income
                    <Icon
                      name="add"
                      onClick={handleAddIncomeFormClick}
                      style={{ cursor: "pointer" }}
                    />
                  </StyledFlex>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row verticalAlign="top">
                <Table.Cell>
                  {/* expense form table */}
                  <ExpenseColumn addExpenseFormActive={addExpenseFormActive} />
                </Table.Cell>

                <Table.Cell>
                  {/* this is for the iddle net field */}
                  {/* <Table celled>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell> hello</Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table> */}
                </Table.Cell>
                <Table.Cell>
                  {/* income form table */}
                  <IncomeColumn addIncomeFormActive={addIncomeFormActive} />
                </Table.Cell>
              </Table.Row>
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell>Total </Table.HeaderCell>
                <Table.HeaderCell>Net </Table.HeaderCell>
                <Table.HeaderCell>Total </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

interface FlexProps {
  children: React.ReactNode;
}
const StyledFlex: React.FC<FlexProps> = ({ children }) => (
  <div style={{ display: "flex", justifyContent: "space-between" }}>
    {children}
  </div>
);

interface ExpenseProps {
  addExpenseFormActive: boolean;
}

const ExpenseColumn: React.FC<ExpenseProps> = ({ addExpenseFormActive }) => {
  const [expenseColumnData, setexpenseColumnData] = useState(
    linkagemapExpenseData
  );
  const [addExpenseForm, setAddExpenseForm] = useState({
    id: 0,
    expense: 0,
  });

  const handleAddFormSubmit = (event: any) => {
    event.preventDefault();

    const newExpenseData = {
      id: expenseColumnData.length,
      expense: addExpenseForm.expense,
    };

    const newExpenseDatas = [...expenseColumnData, newExpenseData];
    setexpenseColumnData(newExpenseDatas);
  };

  const handleAddFormChange = (event: any) => {
    event.preventDefault();

    const fieldName = event.target.getAttribute("name");
    const fieldValue: any = event.target.value;

    const newFormData: any = { ...addExpenseForm };
    newFormData[fieldName] = fieldValue;
    setAddExpenseForm(newFormData);
  };

  return (
    <Form autoComplete="off" onSubmit={handleAddFormSubmit}>
      <Table celled compact attached>
        <Table.Body>
          {addExpenseFormActive && (
            <Table.Row>
              <Table.Cell>
                <StyledFlex>
                  <input
                    name="expense"
                    required
                    onChange={handleAddFormChange}
                    type="number"
                  />
                  <button
                    type="submit"
                    style={{
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                    }}>
                    <Icon name="save" />
                  </button>
                </StyledFlex>
              </Table.Cell>
            </Table.Row>
          )}

          {expenseColumnData.map((item) => (
            <Table.Row key={item.id}>
              <Table.Cell>
                <StyledFlex>
                  <div>{item.expense}</div>
                  <Icon name="cut" style={{ cursor: "pointer" }} />
                </StyledFlex>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Form>
  );
};

interface IncomeColumnProps {
  addIncomeFormActive: boolean;
}

const IncomeColumn: React.FC<IncomeColumnProps> = ({ addIncomeFormActive }) => {
  const [incomeColumnData, setIncomeColumnData] =
    useState(linkagemapIncomeData);
  const [addIncomeForm, setAddIncomeForm] = useState({
    id: 0,
    expense: 0,
  });

  const handleAddFormSubmit = (event: any) => {
    event.preventDefault();

    const newExpenseData = {
      id: incomeColumnData.length + 1,
      expense: addIncomeForm.expense,
    };

    const newExpenseDatas = [...incomeColumnData, newExpenseData];
    setIncomeColumnData(newExpenseDatas);
  };

  const handleAddFormChange = (event: any) => {
    event.preventDefault();

    const fieldName = event.target.getAttribute("name");
    const fieldValue: any = event.target.value;

    const newFormData: any = { ...addIncomeForm };
    newFormData[fieldName] = fieldValue;
    setAddIncomeForm(newFormData);
  };

  return (
    <Form autoComplete="off" onSubmit={handleAddFormSubmit}>
      <Table id="my-form" celled attached>
        <Table.Header></Table.Header>
        <Table.Body>
          {addIncomeFormActive && (
            <Table.Row>
              <Table.Cell>
                <StyledFlex>
                  <input
                    name="expense"
                    required
                    onChange={handleAddFormChange}
                    type="number"
                  />
                  <button
                    type="submit"
                    style={{
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                    }}>
                    <Icon name="save" />
                  </button>
                </StyledFlex>
              </Table.Cell>
            </Table.Row>
          )}

          {incomeColumnData.map((item) => (
            <Table.Row key={item.id}>
              <Table.Cell>
                <StyledFlex>
                  <div>{item.expense}</div>
                  <Icon name="cut" style={{ cursor: "pointer" }} />
                </StyledFlex>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Form>
  );
};

//*************************************************************
// PROJECT NAME : Blinh Hub Network
// CLASS        : MSMEBlinkActivities
// DATE CREATED : 11 February 2022
// TIME CREATED : 08:09:12
// DEVELOPER    : Ibinaiye Wale Kayode
//*************************************************************
export interface MSMEBlinkActivity {
  blinkActivityOID: number;
  activityType: string;
  activityTitle: string;
  detailDescription: string;
  activityDate: Date;
  keyActor: string;
  collaboratingPartners: string;
  partneringInstitutions: string;
  activityState: string;
  activityLGA: string;
  activityCommunity: string;
  activityAddress: string;
  longitude: string;
  latitude: string;
  outcomeType: string;
  outcomeValue: number;
  typeOfSupport: string;
}

export class MSMEBlinkActivity implements MSMEBlinkActivity {
  constructor(init?: MSMEBlinkActivityFormValues) {
    Object.assign(this, init);
  }
}

export class MSMEBlinkActivityFormValues {
  blinkActivityOID = 0;
  activityType = "";
  activityTitle = "";
  detailDescription = "";
  activityDate = null;
  keyActor = "";
  collaboratingPartners = "";
  partneringInstitutions = "";
  activityState = "";
  activityLGA = "";
  activityCommunity = "";
  activityAddress = "";
  longitude = "";
  latitude = "";
  outcomeType = "";
  outcomeValue = 0;
  typeOfSupport = "";

  constructor(values?: MSMEBlinkActivityFormValues) {
    if (values) {
      this.blinkActivityOID = values.blinkActivityOID;
      this.activityType = values.activityType;
      this.activityTitle = values.activityTitle;
      this.detailDescription = values.detailDescription;
      this.activityDate = values.activityDate;
      this.keyActor = values.keyActor;
      this.collaboratingPartners = values.collaboratingPartners;
      this.partneringInstitutions = values.partneringInstitutions;
      this.activityState = values.activityState;
      this.activityLGA = values.activityLGA;
      this.activityCommunity = values.activityCommunity;
      this.activityAddress = values.activityAddress;
      this.longitude = values.longitude;
      this.latitude = values.latitude;
      this.outcomeType = values.outcomeType;
      this.outcomeValue = values.outcomeValue;
      this.typeOfSupport = values.typeOfSupport;
    }
  }
}

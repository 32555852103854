import { observer } from "mobx-react-lite";
import React, { SyntheticEvent, useState } from "react";
import {
  Button,
  Card,
  Grid,
  Header,
  Image,
  Segment,
  Tab,
} from "semantic-ui-react";
import PhotoUploadWidget from "../../app/common/imageUpload/PhotoUploadWidget";
import { Photo, Profile } from "../../app/models/profile";
import { useStore } from "../../app/stores/store";

interface Props {
  profile: Profile;
}

export default observer(function ProfilePhotos({ profile }: Props) {
  const {
    profileStore: {
      isCurrentUser,
      uploadPhoto,
      uploading,
      loading,
      setMainPhoto,
      deletePhoto,
    },
  } = useStore();
  const [addPhotoMode, setAddPhotoMode] = useState(false);
  const [target, setTarget] = useState("");
  const [editMode, setEditMode] = useState(false);

  function handlePhotoUpload(file: Blob) {
    uploadPhoto(file).then(() => setAddPhotoMode(false));
  }

  function handleSetMainPhoto(
    photo: Photo,
    e: SyntheticEvent<HTMLButtonElement>
  ) {
    setTarget(e.currentTarget.name);
    setMainPhoto(photo);
  }

  function handleDeletePhoto(
    photo: Photo,
    e: SyntheticEvent<HTMLButtonElement>
  ) {
    setTarget(e.currentTarget.name);
    deletePhoto(photo);
  }

  return (
    <Tab.Pane>
      <Grid>
        <Grid.Column width={16}>
          <Segment>
            <h1>My Photo Gallery</h1>

            <div className="ui warning message">
              {isCurrentUser && (
                <Button
                  floated="right"
                  basic
                  content={addPhotoMode ? "Cancel" : "Add Photo"}
                  onClick={() => setAddPhotoMode(!addPhotoMode)}
                />
              )}
              <div className="header">Manage My Photo Gallery.</div>
              <p>
                Add pictures of you business facility or events. Please user
                good quality pictures that sells you business to prospective
                customers. Do not add too many pictures because most people do
                not have time to go through all the pictures before taking
                decisions. We strongly suggest that you add the top 10 pictures
                that best describes your business location/office/farm/factory.
              </p>
            </div>
            <div className="ui divider" title="List of Resources"></div>
          </Segment>
          {/* <Header floated="left" icon="image" content="Photos" /> */}
        </Grid.Column>
        <Grid.Column width={16}>
          {addPhotoMode ? (
            <PhotoUploadWidget
              uploadPhoto={handlePhotoUpload}
              loading={uploading}
            />
          ) : (
            <Card.Group itemsPerRow={5}>
              {profile.photos?.map((photo) => (
                <Card key={photo.id}>
                  <Image src={photo.url} />
                  {isCurrentUser && (
                    <Button.Group fluid widths={2}>
                      <Button
                        basic
                        color="green"
                        content="Main"
                        name={"main" + photo.id}
                        disabled={photo.isMain}
                        loading={target === "main" + photo.id && loading}
                        onClick={(e) => handleSetMainPhoto(photo, e)}
                      />
                      <Button
                        basic
                        color="red"
                        icon="trash"
                        loading={target === photo.id && loading}
                        onClick={(e) => handleDeletePhoto(photo, e)}
                        disabled={photo.isMain}
                        name={photo.id}
                      />
                    </Button.Group>
                  )}
                </Card>
              ))}
            </Card.Group>
          )}
        </Grid.Column>
      </Grid>
    </Tab.Pane>
  );
});

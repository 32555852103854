import React from "react";
import { Grid } from "semantic-ui-react";
import classes from "./coster.module.css";

type Props = {};

const Summary = (props: Props) => {
  return (
    <section>
      <div className=''>
        <Grid className={classes.border}>
          <Grid.Row columns={1} className={classes.row}>
            <Grid.Column className={classes.column_2}>
              <header className={classes.header_3}>Summary Report</header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1} className={classes.row}>
            <Grid.Column className={classes.column}>
              <p className={classes.paragraph_1}>
                To supply 200 of CHICKEN EGGS X30 by 44549 to Spar Port Harcourt
                Mall from Slaughter. We are going to spend 371000 therefore we
                need an intermediate fund of 565000.{" "}
              </p>
              <p className={classes.paragraph_1}>
                Our Projected Profit of 10 will be 9000 if we supply at 1900 per
                Kg.{" "}
              </p>
              <p className={classes.paragraph_1}>
                The will be a PROFIT of 9000 if we execute this business
              </p>
              <p className={classes.paragraph_1}>
                We CAN Execute this business.
              </p>
              <p className={classes.paragraph_1}>
                If we execute this business the profit sharing among
                stakeholders is CAD 90% (8100) and CDF 10% (900).
              </p>
            </Grid.Column>
          </Grid.Row>
          <Grid celled>
            <Grid.Row columns={2}>
              <Grid.Column className={classes.summary_cell}>
                <p>
                  Prepared on <span>Sunday, February 13, 2022</span>
                </p>
              </Grid.Column>
              <Grid.Column className={classes.summary_cell}></Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid>
      </div>
    </section>
  );
};

export default Summary;

//import { Profile } from "./profile";

export interface ApplicationModel {
  applicationOID: string;
  applicationName: string;
  applicationDescription: string;
  appPrefix: string;
  status: number;
  applicationOrder: number;
  icon: string;
  dateUploaded: Date | null;

  // date: Date | null;
  // description: string;
  // category: string;
  // city: string;
  // venue: string;
  // hostUsername: string;
  // isCancelled: boolean;
  // isGoing: boolean;
  // isHost: boolean;
  // host?: Profile;
  // attendees: Profile[]
}

export class ApplicationModel implements ApplicationModel {
  constructor(init?: ApplicationFormValues) {
    Object.assign(this, init);
  }
}

export class ApplicationFormValues {
  applicationOID?: string = undefined;
  applicationName: string = "";
  applicationDescription: string = "";
  appPrefix: string = "";
  status: number = 0;
  applicationOrder: number = 0;
  icon: string = "";
  // dateUploaded: Date  = Date.now();

  constructor(application?: ApplicationFormValues) {
    if (application) {
      this.applicationOID = application.applicationOID;
      this.applicationName = application.applicationName;
      this.applicationDescription = application.applicationDescription;
      this.appPrefix = application.appPrefix;
      this.status = application.status;
      this.applicationOrder = application.applicationOrder;
      this.icon = application.icon;
    }
  }
}

import axios, { AxiosError, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { history } from "../..";
import { Activity, ActivityFormValues } from "../models/activity";
import { Resource, ResourceFormValues } from "../models/resource";
import { CountryCode, CountryFormValues } from "../models/countryModel";
import { PaginatedResult } from "../models/pagination";
import { Photo, Profile, UserActivity } from "../models/profile";
import {
  AutoLoginReq,
  AutoRegisterReq,
  User,
  UserFormValues,
} from "../models/user";
import { store } from "../stores/store";
import { PageSize } from "devextreme-react/diagram";
import { ApplicationModel, ApplicationFormValues } from "../models/application";
import {
  RoleDefinitionModel,
  RoleDefinitionFormValues,
} from "../models/roleDefinition";
import { LgaModel, LgaFormValues } from "../models/lga";
import { AssignTask, AssignTaskFormValues } from "../models/assignTaskToRole";
import { StateModel, StateFormValues } from "../models/state";
import { State } from "../../features/ecosystem/masterdata/State";
import {
  BusinessSectorModel,
  BusinessSectorFormValues,
} from "../models/businessSector";
import {
  BusinessAreaModel,
  BusinessAreaFormValues,
} from "../models/businessArea";
import { Subscriber, SubscriberFormValues } from "../models/subscribers";
import { AssignRole, AssignRoleFormValues } from "../models/assignRoleToUser";
import { CompanyInfo, CompanyInfoFormValues } from "../models/companyInfo";
import { SystemCodeModel, SystemCodeFormValues } from "../models/systemCode";
import { SystemSet, SystemSetFormValues } from "../models/systemSetting";
import { TaskModel, TaskFormValues } from "../models/task";
// import { PageSize } from "devextreme-react/diagram";
import { PersonalData, PersonalDataFormValues } from "../models/personalData";
import {
  MsmeBusinessData,
  MsmeBusinessDataFormValues,
} from "../models/msmeBusinessData";
import { FeedbackModel, FeedbackFormValues } from "../models/feedaback";
import {
  CosterCommoditysFormValues,
  CosterCommodity,
} from "../models/costerCommoditys";
import {
  CosterExpense,
  CosterExpenseFormValues,
} from "../models/CosterExpense";
import {
  ActivityAttendee,
  ActivityAttendeeFormValues,
} from "../models/ActivityAttendees";
import {
  MSMEBlinkActivity,
  MSMEBlinkActivityFormValues,
} from "../models/MSMEBlinkActivities";
import {
  MSMEBlinkActivitiesActor,
  MSMEBlinkActivitiesActorFormValues,
} from "../models/MSMEBlinkActivitiesActors";
import {
  MSMEBlinkWeeklyUpdate,
  MSMEBlinkWeeklyUpdateFormValues,
} from "../models/MSMEBlinkWeeklyUpdate";
import {
  MSMEBlinkActivityFeedback,
  MSMEBlinkActivityFeedbackFormValues,
} from "../models/MSMEBlinkActivityFeedback";
import {
  ActivityChallenge,
  ActivityChallengeFormValues,
} from "../models/MSMEBlinkActivitiesChallenges";
// import { SystemCodeFormValues } from "../models/SystemCodes";

const sleep = (delay: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
};

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use((config) => {
  const token = store.commonStore.token;
  if (token) config.headers.Authorization = `Bearer ${token}`;
  return config;
});

axios.interceptors.response.use(
  async (response) => {
    if (process.env.NODE_ENV === "development") await sleep(1000);
    const pagination = response.headers["pagination"];
    if (pagination) {
      response.data = new PaginatedResult(
        response.data,
        JSON.parse(pagination)
      );
      return response as AxiosResponse<PaginatedResult<any>>;
    }
    return response;
  },
  (error: AxiosError) => {
    const { data, status, config, headers } = error.response!;
    switch (status) {
      case 400:
        if (config.method === "get" && data.errors.hasOwnProperty("id")) {
          history.push("/not-found");
        }
        if (data.errors) {
          const modalStateErrors = [];
          for (const key in data.errors) {
            if (data.errors[key]) {
              modalStateErrors.push(data.errors[key]);
            }
          }
          throw modalStateErrors.flat();
        } else {
          toast.error(data);
        }
        break;
      case 401:
        if (
          status === 401 &&
          headers["www-authenticate"]?.startsWith(
            'Bearer error="invalid_token"'
          )
        ) {
          store.userStore.logout();
          toast.error("Session expired - please login again");
        }
        break;
      case 404:
        history.push("/not-found");
        break;
      case 500:
        store.commonStore.setServerError(data);
        history.push("/server-error");
        break;
    }
    return Promise.reject(error);
  }
);

const responseBody = <T>(response: AxiosResponse<T>) => {
  //console.log("00. ", response.data);
  return response.data;
};

const requests = {
  get: <T>(url: string) => axios.get<T>(url).then(responseBody),
  post: <T>(url: string, body: {}) =>
    axios.post<T>(url, body).then(responseBody),
  put: <T>(url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
  del: <T>(url: string) => axios.delete<T>(url).then(responseBody),
};

const Activities = {
  list: (params: URLSearchParams) =>
    axios
      .get<PaginatedResult<Activity[]>>("/activities", { params })
      .then(responseBody),
  details: (id: string) => requests.get<Activity>(`/activities/${id}`),
  create: (activity: ActivityFormValues) =>
    requests.post<void>("/activities", activity),
  update: (activity: ActivityFormValues) =>
    requests.put<void>(`/activities/${activity.id}`, activity),
  delete: (id: string) => requests.del<void>(`/activities/${id}`),
  attend: (id: string) => requests.post<void>(`/activities/${id}/attend`, {}),
};

const Account = {
  current: () => requests.get<User>("/account"),
  login: (user: UserFormValues) => requests.post<User>("/account/login", user),
  autoRegister: (user: AutoRegisterReq) =>
    requests.post<any>("/blinkPlanSSO/autoLogin", user),
     
  autoLogin: (user: AutoLoginReq) =>
    requests.post<User>("/EcoAuthentication/sign-in-auto-verify", user),

  logoff: (userGUID: string) =>
    requests.post<void>(`/account/logoff?userGUID=${userGUID}`, {}),

  register: (user: UserFormValues) =>
    requests.post<User>("/account/register", user),
  fbLogin: (accessToken: string) =>
    requests.post<User>(`/account/fbLogin?accessToken=${accessToken}`, {}),
  refreshToken: () => requests.post<User>("/account/refreshToken", {}),
  verifyEmail: (token: string, email: string) =>
    requests.post<void>(
      `/account/verifyEmail?token=${token}&email=${email}`,
      {}
    ),
  resendEmailConfirm: (email: string) =>
    requests.get(`/account/resendEmailConfirmationLink?email=${email}`),
};

const Profiles = {
  get: (username: string) => requests.get<Profile>(`/profiles/${username}`),
  uploadPhoto: (file: Blob) => {
    let formData = new FormData();
    formData.append("File", file);
    return axios.post<Photo>("photos", formData, {
      headers: { "Content-type": "multipart/form-data" },
    });
  },
  setMainPhoto: (id: string) => requests.post(`/photos/${id}/setMain`, {}),
  deletePhoto: (id: string) => requests.del(`/photos/${id}`),
  updateProfile: (profile: Partial<Profile>) =>
    requests.put(`/profiles`, profile),
  updateFollowing: (username: string) =>
    requests.post(`/follow/${username}`, {}),
  listFollowings: (username: string, predicate: string) =>
    requests.get<Profile[]>(`/follow/${username}?predicate=${predicate}`),
  listActivities: (username: string, predicate: string) =>
    requests.get<UserActivity[]>(
      `/profiles/${username}/activities?predicate=${predicate}`
    ),
};

const Resources = {
  list: (params: URLSearchParams) =>
    axios.get<PaginatedResult<Resource[]>>("/resources").then(responseBody),
  details: (id: string) => requests.get<Resource>(`/resources/${id}`),
  create: (resource: ResourceFormValues) =>
    requests.post<void>("/resources", resource),
  update: (resource: ResourceFormValues) =>
    requests.put<void>(`/resources/${resource.resourceGUID}`, resource),
  delete: (id: string) => requests.del<void>(`/resources/${id}`),
};

const PersonalDatas = {
  list: (params: URLSearchParams) =>
    axios
      .get<PaginatedResult<PersonalData[]>>("/msmeprofiles")
      .then(responseBody),
  details: (id: number) => requests.get<PersonalData>(`/msmeprofiles/${id}`),
  detailsByUserGUID: (userGUID: string) =>
    requests.get<PersonalData>(
      `/msmeprofiles/getmsmeprofilebyuserguid/${userGUID}`
    ),
  create: (personalData: PersonalDataFormValues) =>
    requests.post<void>("/msmeprofiles", personalData),
  update: (personalData: PersonalDataFormValues) =>
    requests.put<void>(`/msmeprofiles/${personalData.msmeoid}`, personalData),
  delete: (id: string) => requests.del<void>(`/msmeprofiles/${id}`),
};

const MsmeBusinessDatas = {
  list: (params: URLSearchParams) =>
    axios
      .get<PaginatedResult<MsmeBusinessData[]>>("/msmeprofiles")
      .then(responseBody),
  details: (id: number) =>
    requests.get<MsmeBusinessData>(`/msmeprofiles/${id}`),
  // detailsByUserGUID: (userGUID: string) =>
  //   requests.get<MsmeBusinessData>(
  //     `/msmeprofiles/getmsmeprofilebyuserguid/${userGUID}`
  //   ),
  detailsByUserGUID: (userGUID: string) =>
    requests.get<MsmeBusinessData>(
      `/getbusinessinformationbyuserguid/${userGUID}`
    ),
  create: (msmeBusinessData: MsmeBusinessDataFormValues) =>
    requests.post<void>("/msmeprofiles", msmeBusinessData),
  update: (msmeBusinessData: PersonalDataFormValues) =>
    requests.put<void>(
      `/msmeprofiles/${msmeBusinessData.msmeoid}`,
      msmeBusinessData
    ),
  delete: (id: string) => requests.del<void>(`/msmeprofiles/${id}`),
};

// const PersonalDatas = {
//   list: () => axios.get<any>("/PersonalData").then(responseBody),
//   details: (id: string) => requests.get<PersonalData>(`/PersonalData/${id}`),
//   create: (personalData: PersonalDataFormValues) =>
//     requests.post<void>("/PersonalData", personalData),
//   update: (personalData: PersonalDataFormValues) =>
//     requests.put<void>(`/PersonalData/${personalData.userGUID}`, personalData),
//   delete: (id: string) => requests.del<void>(`/PersonalData/${id}`),
//   listPersonalDataDashboard: () =>
//     requests.get<PersonalData[]>("/PersonalData"),
// };

const Countries = {
  list: (params: URLSearchParams) =>
    axios.get<PaginatedResult<CountryCode[]>>("/ecocountry").then(responseBody),
  details: (id: string) => requests.get<CountryCode>(`/ecocountry/${id}`),
  create: (country: CountryFormValues) =>
    requests.post<void>("/ecocountry", country),
  update: (country: CountryFormValues) =>
    requests.put<void>(`/ecocountry/${country.countryOID}`, country),
  delete: (id: string) => requests.del<void>(`/ecocountry/${id}`),
};
const Applications = {
  list: (params: URLSearchParams) =>
    axios
      .get<PaginatedResult<ApplicationModel[]>>("/EcoApplications")
      .then(responseBody),
  details: (id: string) =>
    requests.get<ApplicationModel>(`/EcoApplications${id}`),
  create: (application: ApplicationFormValues) =>
    requests.post<void>("/EcoApplications", application),
  update: (application: ApplicationFormValues) =>
    requests.put<void>(
      `/EcoApplications/${application.applicationOID}`,
      application
    ),
  delete: (id: string) => requests.del<void>(`/EcoApplications/${id}`),
};

const RoleDefinitions = {
  list: (params: URLSearchParams) =>
    axios
      .get<PaginatedResult<RoleDefinitionModel[]>>("/applicationrole")
      .then(responseBody),
  details: (id: string) =>
    requests.get<RoleDefinitionModel>(`/applicationrole/${id}`),
  create: (roledefinition: RoleDefinitionFormValues) =>
    requests.post<void>("/applicationrole", roledefinition),
  update: (roledefinition: RoleDefinitionFormValues) =>
    requests.put<void>(
      `/applicationrole/${roledefinition.roleOID}`,
      roledefinition
    ),
  delete: (id: string) => requests.del<void>(`/applicationrole/${id}`),
};

const EcoLga = {
  list: (params: URLSearchParams) =>
    axios.get<PaginatedResult<LgaModel[]>>("/ecolga").then(responseBody),
  details: (id: string) => requests.get<LgaModel>(`/ecolga/${id}`),
  create: (lga: LgaFormValues) => requests.post<void>("/ecolga", lga),
  update: (lga: LgaFormValues) =>
    requests.put<void>(`/ecolga/${lga.lgaOID}`, lga),
  delete: (id: string) => requests.del<void>(`/ecolga/${id}`),
};

const AssignTasks = {
  list: (params: URLSearchParams) =>
    axios.get<PaginatedResult<AssignTask[]>>("/ecotask").then(responseBody),
  details: (id: string) => requests.get<AssignTask>(`/ecotask/${id}`),
  create: (assigntask: AssignTaskFormValues) =>
    requests.post<void>("/ecotask", assigntask),
  update: (assigntask: AssignTaskFormValues) =>
    requests.put<void>(`/ecotask/${assigntask.taskOID}`, assigntask),
  delete: (id: string) => requests.del<void>(`/ecotask/${id}`),
};

const EcoState = {
  list: (params: URLSearchParams) =>
    axios.get<PaginatedResult<StateModel[]>>("/ecostate").then(responseBody),
  details: (id: string) => requests.get<StateModel>(`/ecostate/${id}`),
  create: (state: StateFormValues) => requests.post<void>("/ecostate", state),
  update: (state: StateFormValues) =>
    requests.put<void>(`/ecostate/${state.stateOID}`, State),
  delete: (id: string) => requests.del<void>(`/ecostate/${id}`),
};

const EcoBusinessSector = {
  list: (params: URLSearchParams) =>
    axios
      .get<PaginatedResult<BusinessSectorModel[]>>("/ecobusinesssector")
      .then(responseBody),
  details: (id: string) =>
    requests.get<BusinessSectorModel>(`/ecobusinesssector/${id}`),
  create: (businessSector: BusinessSectorFormValues) =>
    requests.post<void>("/ecobusinesssector", businessSector),
  update: (businessSector: BusinessSectorFormValues) =>
    requests.put<void>(
      `/ecobusinesssector/${businessSector.businessSectorOID}`,
      businessSector
    ),
  delete: (id: string) => requests.del<void>(`/ecobusinesssector/${id}`),
};

const EcoBusinessArea = {
  list: (params: URLSearchParams) =>
    axios
      .get<PaginatedResult<BusinessAreaModel[]>>("/ecobusinessarea")
      .then(responseBody),
  details: (id: string) =>
    requests.get<BusinessAreaModel>(`/ecobusinessarea/${id}`),
  create: (businessArea: BusinessAreaFormValues) =>
    requests.post<void>("/ecobusinessarea", businessArea),
  update: (businessArea: BusinessAreaFormValues) =>
    requests.put<void>(
      `/ecobusinessarea/${businessArea.businessAreaOID}`,
      businessArea
    ),
  delete: (id: string) => requests.del<void>(`/ecobusinessarea/${id}`),
};

const Subscribers = {
  list: (params: URLSearchParams) =>
    axios
      .get<PaginatedResult<Subscriber[]>>("/ecosubscriber")
      .then(responseBody),
  details: (id: string) => requests.get<Subscriber>(`/ecosubscriber/${id}`),
  create: (subscriber: SubscriberFormValues) =>
    requests.post<void>("/ecosubscriber", subscriber),
  update: (subscriber: SubscriberFormValues) =>
    requests.put<void>(
      `/ecosubscriber/${subscriber.subscriberOID}`,
      Subscriber
    ),
  delete: (id: string) => requests.del<void>(`/ecosubscriber/${id}`),
};
const AssignRoles = {
  list: (params: URLSearchParams) =>
    axios
      .get<PaginatedResult<AssignRole[]>>("/applicationrole")
      .then(responseBody),
  details: (id: string) => requests.get<AssignRole>(`/ecouserroletask/${id}`),
  create: (assignrole: AssignRoleFormValues) =>
    requests.post<void>("/ecouserroletask", assignrole),
  update: (assignrole: AssignRoleFormValues) =>
    requests.put<void>(`/ecouserroletask/${assignrole.taskOID}`, assignrole),
  delete: (id: string) => requests.del<void>(`/ecouserroletask/${id}`),
};
const CompanyInfos = {
  list: (params: URLSearchParams) =>
    axios.get<PaginatedResult<CompanyInfo[]>>("/EcoCountry").then(responseBody),
  details: (id: string) => requests.get<CompanyInfo>(`/EcoCountry/${id}`),
  create: (companyinfo: CompanyInfoFormValues) =>
    requests.post<void>("/EcoCountry", companyinfo),
  update: (companyinfo: CompanyInfoFormValues) =>
    requests.put<void>(`/EcoCountry/${companyinfo.companyOID}`, companyinfo),
  delete: (id: string) => requests.del<void>(`/EcoCountry/${id}`),
};

// const SystemCodes = {
//   list: (params: URLSearchParams) =>
//     axios
//       .get<PaginatedResult<SystemCodeModel[]>>("/ecosystemcodes")
//       .then(responseBody),
//   details: (id: string) =>
//     requests.get<SystemCodeModel>(`/ecosystemcodes/${id}`),
//   create: (systemcode: SystemCodeFormValues) =>
//     requests.post<void>("/ecosystemcodes", systemcode),
//   update: (systemcode: SystemCodeFormValues) =>
//     requests.put<void>(`/ecosystemcodes/${systemcode.oid}`, systemcode),
//   delete: (id: string) => requests.del<void>(`/ecosystemcodes/${id}`),
// };

const SystemSettings = {
  list: (params: URLSearchParams) =>
    axios
      .get<PaginatedResult<SystemSet[]>>("/ecosystemsettings")
      .then(responseBody),
  details: (id: string) => requests.get<SystemSet>(`/ecosystemsettings/${id}`),
  create: (systemset: SystemSetFormValues) =>
    requests.post<void>("/ecosystemsettings", systemset),
  update: (systemset: SystemSetFormValues) =>
    requests.put<void>(`/ecosystemsettings/${systemset.OID}`, systemset),
  delete: (id: string) => requests.del<void>(`/applicationrole/${id}`),
};
const Tasks = {
  list: (params: URLSearchParams) =>
    axios.get<PaginatedResult<TaskModel[]>>("/ecotask").then(responseBody),
  details: (id: string) => requests.get<TaskModel>(`/ecotask/${id}`),
  create: (task: TaskFormValues) =>
    requests.post<void>("/applicationrole", task),
  update: (task: TaskFormValues) =>
    requests.put<void>(`/ecotask/${task.taskOID}`, task),
  delete: (id: string) => requests.del<void>(`/ecotask/${id}`),
};
const Feedbacks = {
  list: (params: URLSearchParams) =>
    axios.get<PaginatedResult<FeedbackModel[]>>("/feedback").then(responseBody),
  details: (id: string) => requests.get<FeedbackModel>(`/feedback/${id}`),
  create: (feedback: FeedbackFormValues) =>
    requests.post<void>("/feedback", feedback),
  update: (feedback: FeedbackFormValues) =>
    requests.put<void>(`/feedback/${feedback.feedbackOID}`, feedback),
  delete: (id: string) => requests.del<void>(`/ecotask/${id}`),
};
// const RoleDefinitions = {
//   list: (params: URLSearchParams) =>
//     axios
//       .get<PaginatedResult<RoleDefinitionModel[]>>("/applicationrole")
//       .then(responseBody),
//   details: (id: string) =>
//     requests.get<RoleDefinitionModel>(`/applicationrole/${id}`),
//   create: (roledefinition: RoleDefinitionFormValues) =>
//     requests.post<void>("/applicationrole", roledefinition),
//   update: (roledefinition: RoleDefinitionFormValues) =>
//     requests.put<void>(
//       `/applicationrole/${roledefinition.roleOID}`,
//       roledefinition
//     ),
//   delete: (id: string) => requests.del<void>(`/applicationrole/${id}`),
// };
const CosterCommodities = {
  list: (params: URLSearchParams) =>
    axios
      .get<PaginatedResult<CosterCommodity[]>>("/TOOLBLinkCosterCommoditys")
      .then(responseBody),
  details: (id: number) =>
    requests.get<CosterCommodity>(`/TOOLBLinkCosterCommoditys/${id}`),
  create: (values: CosterCommoditysFormValues) =>
    requests.post<void>("/TOOLBLinkCosterCommoditys", values),
  update: (values: CosterCommoditysFormValues) =>
    requests.put<void>(
      `/TOOLBLinkCosterCommoditys/${values.offtakerPriceOID}`,
      values
    ),
  delete: (id: number) =>
    requests.del<void>(`/TOOLBLinkCosterCommoditys/${id}`),
};

const ToolBLinkCosterExpenses = {
  list: (params: URLSearchParams) =>
    axios
      .get<PaginatedResult<CosterExpense[]>>("/TOOLBLinkCosterExpenses")
      .then(responseBody),
  details: (id: number) =>
    requests.get<CosterExpense>(`/TOOLBLinkCosterExpenses/${id}`),
  create: (values: CosterExpenseFormValues) =>
    requests.post<void>("/TOOLBLinkCosterExpenses", values),
  update: (values: CosterExpenseFormValues) =>
    requests.put<void>(`/TOOLBLinkCosterExpenses/${values.expenseOID}`, values),
  delete: (id: number) =>
    requests.del<void>(`/TOOLBLinkCosterCommoditys/${id}`),
};

const ActivityAttendees = {
  list: (params: URLSearchParams) =>
    axios
      .get<PaginatedResult<ActivityAttendee[]>>(
        "/MSMEBlinkActivityAttendeess/GetAllMSMEBlinkActivityAttendees"
      )
      .then(responseBody),
  details: (id: number) =>
    requests.get<ActivityAttendee>(`/MSMEBlinkActivityAttendeess/${id}`),
  create: (values: ActivityAttendeeFormValues) =>
    requests.post<void>("/MSMEBlinkActivityAttendeess", values),
  update: (values: ActivityAttendeeFormValues) =>
    requests.put<void>(
      `ActivityAttendees/MSMEBlinkActivityAttendeess/${values.attendanceOID}`,
      values
    ),
  delete: (id: number) =>
    requests.del<void>(`/MSMEBlinkActivityAttendeess/${id}`),
};

const MSMEBlinkActivities = {
  list: (params: URLSearchParams) =>
    axios
      .get<PaginatedResult<MSMEBlinkActivity[]>>("/MSMEBlinkActivitiess")
      .then(responseBody),
  details: (id: number) =>
    requests.get<MSMEBlinkActivity>(`/MSMEBlinkActivitiess/${id}`),
  create: (values: MSMEBlinkActivityFormValues) =>
    requests.post<void>("/MSMEBlinkActivitiess", values),
  update: (values: MSMEBlinkActivityFormValues) =>
    requests.put<void>(
      `/MSMEBlinkActivitiess/${values.blinkActivityOID}`,
      values
    ),
  delete: (id: number) => requests.del<void>(`/MSMEBlinkActivitiess/${id}`),
};

const MSMEBlinkActivitiesActors = {
  list: (params: URLSearchParams) =>
    axios
      .get<PaginatedResult<MSMEBlinkActivitiesActor[]>>(
        "/MSMEBlinkActivitiesActorss"
      )
      .then(responseBody),
  details: (id: number) =>
    requests.get<MSMEBlinkActivitiesActor>(`/MSMEBlinkActivitiesActorss/${id}`),
  create: (values: MSMEBlinkActivitiesActor) =>
    requests.post<void>("/MSMEBlinkActivitiesActorss", values),
  update: (values: MSMEBlinkActivitiesActorFormValues) =>
    requests.put<void>(
      `/MSMEBlinkActivitiesActorss/${values.blinkActorOID}`,
      values
    ),
  delete: (id: number) => requests.del<void>(`/MSMEBlinkActivitiess/${id}`),
};

const MSMEBlinkWeeklyUpdates = {
  list: (params: URLSearchParams) =>
    axios
      .get<PaginatedResult<MSMEBlinkWeeklyUpdate[]>>(
        "/MSMEBlinkActivitiesActorss"
      )
      .then(responseBody),
  details: (id: number) =>
    requests.get<MSMEBlinkWeeklyUpdate>(`/MSMEBlinkWeeklyUpdates/${id}`),
  create: (values: MSMEBlinkWeeklyUpdateFormValues) =>
    requests.post<void>("/MSMEBlinkWeeklyUpdates", values),
  update: (values: MSMEBlinkWeeklyUpdate) =>
    requests.put<void>(
      `/MSMEBlinkWeeklyUpdates/${values.weeklyUpdateOID}`,
      values
    ),
  delete: (id: number) => requests.del<void>(`/MSMEBlinkWeeklyUpdates/${id}`),
};

const MSMEBlinkActivitiesFeedbacks = {
  list: (params: URLSearchParams) =>
    axios
      .get<PaginatedResult<MSMEBlinkActivityFeedback[]>>(
        "/MSMEBlinkActivityFeedbacks"
      )
      .then(responseBody),
  details: (id: number) =>
    requests.get<MSMEBlinkActivityFeedback>(
      `/MSMEBlinkActivityFeedbacks/${id}`
    ),
  create: (values: MSMEBlinkActivityFeedbackFormValues) =>
    requests.post<void>("/MSMEBlinkActivityFeedbacks", values),
  update: (values: MSMEBlinkActivityFeedbackFormValues) =>
    requests.put<void>(
      `/MSMEBlinkActivityFeedbacks/${values.feedbackOID}`,
      values
    ),
  delete: (id: number) =>
    requests.del<void>(`/MSMEBlinkActivityFeedbacks/${id}`),
};

const ActivitiesChallenges = {
  list: (params: URLSearchParams) =>
    axios
      .get<PaginatedResult<ActivityChallenge[]>>(
        "/MSMEBlinkActivitiesChallengess"
      )
      .then(responseBody),
  details: (id: number) =>
    requests.get<ActivityChallenge>(`/MSMEBlinkActivitiesChallengess/${id}`),
  create: (values: ActivityChallengeFormValues) =>
    requests.post<void>("/MSMEBlinkActivitiesChallengess", values),
  update: (values: ActivityChallengeFormValues) =>
    requests.put<void>(
      `/MSMEBlinkActivitiesChallengess/${values.challengeOID}`,
      values
    ),
  delete: (id: number) =>
    requests.del<void>(`/MSMEBlinkActivitiesChallengess/${id}`),
};

const SystemCodes = {
  list: () => axios.get("/EcoSystemCodes"),
  create: (values: SystemCodeFormValues) =>
    requests.post("/EcoSystemCodes", values),
  update: (values: SystemCodeFormValues) =>
    requests.put(`/EcoSystemCodes/${values.oid}`, values),
  details: (oid: number) => requests.get(`/EcoSystemCodes/${oid}`),
  delete: (oid: number) => requests.del(`/EcoSystemCodes/${oid}`),
  getDistinct: () => requests.get("/EcoSystemCodes/GetAllDistinctSetupType"),
};

const agent = {
  Activities,
  Account,
  Profiles,
  Resources,
  Countries,
  Applications,
  RoleDefinitions,
  EcoLga,
  AssignTasks,
  EcoState,
  EcoBusinessSector,
  EcoBusinessArea,
  Subscribers,
  AssignRoles,
  CompanyInfos,
  SystemCodes,
  SystemSettings,
  Tasks,
  PersonalDatas,
  MsmeBusinessDatas,
  Feedbacks,
  CosterCommodities,
  ToolBLinkCosterExpenses,
  ActivityAttendees,
  MSMEBlinkActivities,
  MSMEBlinkActivitiesActors,
  MSMEBlinkWeeklyUpdates,
  MSMEBlinkActivitiesFeedbacks,
  ActivitiesChallenges,
  // SystemCodes,
};


export default agent;

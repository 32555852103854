import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Header, Segment, Button, Form } from "semantic-ui-react";
import { Link, useParams } from "react-router-dom";
import { Form as FForm, Formik } from "formik";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { useStore } from "../../../app/stores/store";
import { v4 as uuid } from "uuid";
import * as Yup from "yup";
import { marketsData } from "../../../app/common/TemStaticData";
import CADTextInput from "../../../app/common/form/CADTextInput";
import CADTextArea from "../../../app/common/form/CADTextArea";
import CADSelectInput from "../../../app/common/form/CADSelectInput";
import { optionsState } from "../../../app/common/staticData";

const Markets = () => {
  //   const history = useHistory();
  //   const { id } = useParams<{ id: string }>();

  const validationSchema = Yup.object({
    productOID: Yup.string().required("The Market OID is required"),
    marketName: Yup.string().required("The Market Name is required"),
    description: Yup.string().required("description is required"),
    city: Yup.string().required(),
    state: Yup.string().required(),
    streetAddress: Yup.string().required(),
    country: Yup.string().required(),
    contactPerson: Yup.string().required(),
    email: Yup.string().required(),
    phoneNumber: Yup.string().required(),
    website: Yup.string().required(),
  });
  // if (loadingInitial)
  //   return <LoadingComponent content="Loading activity..." />;
  return (
    <div>
      <Segment clearing>
        <Header as='h3' dividing color='brown'>
          Markets
        </Header>
        <Formik
          initialValues={marketsData[0]}
          onSubmit={(values) => {
            console.log(values);
          }}
          validationSchema={validationSchema}>
          {({ handleSubmit, isValid, isSubmitting, dirty }) => (
            <FForm
              className='ui form'
              onSubmit={handleSubmit}
              autoComplete='off'>
              <Form.Group widths='equal'>
                <CADTextInput
                  name='maket-oid'
                  label='Market OID'
                  placeholder='Market OID'
                  id='market-oid'
                  disabled
                />
                <CADTextInput
                  name='marketName'
                  id='market-name'
                  label='Market Name'
                  placeholder='Market Name'
                />
              </Form.Group>
              <Form.Group widths='equal'>
                <CADTextArea
                  name='description'
                  id='description'
                  rows={3}
                  label='Description'
                  placeholder='Enter Description'
                />
              </Form.Group>
              <Header as='h3' dividing color='brown'>
                Contact Information
              </Header>
              <Form.Group widths='equal'>
                <CADTextInput
                  name='city'
                  id='city'
                  label='City'
                  placeholder='Enter City'
                />
                <CADSelectInput
                  name='state'
                  id='state'
                  label='State'
                  placeholder='select state'
                  options={optionsState}
                />
              </Form.Group>
              <Form.Group widths='equal'>
                <CADTextInput
                  name='streetAddress'
                  label='Street Address'
                  placeholder='enter street address'
                  id='streetAddress'
                />
                <CADSelectInput
                  id='country'
                  name='country'
                  label='Country'
                  placeholder='select country'
                  options={optionsState}
                />
              </Form.Group>
              <Form.Group widths='equal'>
                <CADTextInput
                  name='contactPerson'
                  label='Contact Person'
                  placeholder='enter contact person'
                  id='contactPerson'
                />
                <CADTextInput
                  name='email'
                  label='Email'
                  placeholder='Enter email'
                  id='email'
                />
              </Form.Group>
              <Form.Group widths='equal'>
                <CADTextInput
                  name='phoneNumber'
                  label='Phone Number'
                  placeholder='enter phone number'
                  id='phoneNumber'
                />
                <CADTextInput
                  name='wesite'
                  label='Website'
                  placeholder='Website'
                  id='website'
                />
              </Form.Group>
            </FForm>
          )}
        </Formik>
      </Segment>
    </div>
  );
};

export default observer(Markets);

import React from "react";
import { Grid, Table } from "semantic-ui-react";
import MyTextInput from "../../../../app/common/form/MyTextInput";
import classes from "./ticket.module.css";

type Props = {
  values: any;
};

const ContactInformation = (props: Props) => {
  // const values = props.values
  return (
    <section>
      <div className=''>
        <Grid className={classes.border}>
          <Grid.Row columns={1} className={classes.row}>
            <Grid.Column className={classes.column}>
              <header className={classes.header}>Contact Information</header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={2} className={classes.row}>
            <Grid.Column className={classes.column}>
              <Table celled basic='very' className={classes.table}>
                <Table.Body>
                  <TableRow
                    label={"Ticket Number"}
                    name={"ticketNumber"}
                    placeholder={""}
                  />
                  <TableRow label={"Title"} name={"title"} placeholder={""} />
                  <TableRow
                    label={"Application/Platform"}
                    name={"platform"}
                    placeholder={""}
                  />
                </Table.Body>
              </Table>
            </Grid.Column>
            <Grid.Column className={classes.column}>
              {/* First Table on the right side  */}
              <Table celled structured className={classes.table_1}>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell
                      textAlign='center'
                      colSpan={2}
                      className={classes.table_header_1}>
                      Support Provider Information
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <TableRow2
                    label={"Company Name "}
                    name={"companyName"}
                    placeholder={"Company Name"}
                  />
                  <TableRow2
                    label={"Contact Person"}
                    name={"contactPerson"}
                    placeholder={"Contact Person"}
                  />
                  <TableRow2
                    label={"Contact Phone"}
                    name={"contactPhone"}
                    placeholder={"Contact Phone"}
                  />
                  <TableRow2
                    label={"Contact Email"}
                    name={"contactEmail"}
                    placeholder={"Contact Email"}
                  />
                </Table.Body>
              </Table>
              {/* Second Table on the right side  */}
              <Table celled structured className={classes.table_1}>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell
                      textAlign='center'
                      colSpan={2}
                      className={classes.table_header_2}>
                      Reporter Information
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <TableRow2
                    label={"Company Name "}
                    name={"reporterCompanyName"}
                    placeholder={"Company Name"}
                  />
                  <TableRow2
                    label={"Contact Person"}
                    name={"reporterContactPerson"}
                    placeholder={"Contact Person"}
                  />
                  <TableRow2
                    label={"Contact Phone"}
                    name={"reporterContactPhone"}
                    placeholder={"Contact Phone"}
                  />
                  <TableRow2
                    label={"Contact Email"}
                    name={"reporterContactEmail"}
                    placeholder={"Contact Email"}
                  />
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </section>
  );
};

interface ITableRow {
  label: string;
  name: string;
  placeholder: string;
}

export const TableRow2: React.FC<ITableRow> = ({
  label,
  name,
  placeholder,
}) => {
  return (
    <Table.Row className={`${classes.row_border} ${classes.row_border_3}`}>
      <Table.Cell textAlign='right' width={6}>
        {label}
      </Table.Cell>
      <Table.Cell textAlign='left' width={10}>
        <MyTextInput placeholder={placeholder} name={name} />
      </Table.Cell>
    </Table.Row>
  );
};

export const TableRow: React.FC<ITableRow> = ({ label, name, placeholder }) => {
  return (
    <Table.Row className={`${classes.row_border} ${classes.row_border_2}`}>
      <Table.Cell textAlign='right' width={7}>
        {label}
      </Table.Cell>
      <Table.Cell textAlign='left' width={9}>
        <MyTextInput placeholder={placeholder} name={name} />
      </Table.Cell>
    </Table.Row>
  );
};

export default ContactInformation;

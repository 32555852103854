import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Icon, Item, Label, Message, Segment } from 'semantic-ui-react';
import { Activity } from '../../../app/models/activity';
import {format} from 'date-fns';
import ActivityListItemAttendee from './ActivityListItemAttendee';

interface Props {
    activity: Activity
}

export default function ActivityListItem({ activity }: Props) {

    return (
      <Segment.Group>
        <Segment color="green">
          <Label as="a" color="green" ribbon>
            <h3>EVENT</h3>
          </Label>
          <Message color="green">
            <h3> {activity.title}</h3>
            {/* <div>
              <Button as="div" labelPosition="right">
                <Button color="red">
                  <Icon name="heart" />
                  Like
                </Button>
                <Label as="a" basic color="red" pointing="left">
                  2,048
                </Label>
              </Button> 
            </div> */}
          </Message>
        </Segment>
        <Segment>
          {activity.isCancelled && (
            <Label
              attached="top"
              color="red"
              content="Cancelled"
              style={{ textAlign: "center" }}
            />
          )}
          <Item.Group>
            <Item>
              <Item.Image
                style={{ marginBottom: 3 }}
                size="tiny"
                circular
                src={activity.host?.image || "/assets/user.png"}
              />
              <Item.Content>
                <Item.Header as={Link} to={`/activities/${activity.id}`}>
                  {/* {activity.title} */}
                </Item.Header>
                <Item.Description>
                  Hosted by{" "}
                  <Link to={`/profiles/${activity.hostUsername}`}>
                    {activity.host?.displayName}
                  </Link>
                </Item.Description>
                {activity.isHost && (
                  <Item.Description>
                    <Label basic color="orange">
                      You are hosting this activity
                    </Label>
                  </Item.Description>
                )}
                {activity.isGoing && !activity.isHost && (
                  <Item.Description>
                    <Label basic color="green">
                      You are going to this activity
                    </Label>
                  </Item.Description>
                )}
              </Item.Content>
            </Item>
          </Item.Group>
        </Segment>
        <Segment>
          <span>
            <Icon name="clock" />{" "}
            {format(activity.date!, "dd MMM yyyy h:mm aa")}
            <Icon name="marker" /> {activity.venue}
          </span>
        </Segment>
        <Segment secondary>
          <ActivityListItemAttendee attendees={activity.attendees!} />
        </Segment>
        <Segment clearing>
          <span>{activity.description}</span>
          <Button
            as={Link}
            to={`/activities/${activity.id}`}
            color="teal"
            floated="right"
            content="View"
          />
        </Segment>
      </Segment.Group>
    );
}
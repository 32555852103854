import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../api/agent";
import agentExpress from "../api/agentExpress";
//import { Activity, ActivityFormValues } from "../models/activity";
import { Resource, ResourceFormValues } from "../models/resource";
import { format } from "date-fns";
import { store } from "./store";
import { Profile } from "../models/profile";
import { Pagination, PagingParams } from "../models/pagination";
import axios from "axios";
import { StateModel, StateFormValues } from "../models/state";

export default class StateStore {
  stateData: StateModel[] = [];
  // resourceV2Data: Resource[] = [];
  //comments: ChatComment[] = [];
  stateRegistry = new Map<string, StateModel>();
  selectedStateModel: StateModel | undefined = undefined;
  editMode = false;
  loading = false;
  loadingInitial = false;
  pagination: Pagination | null = null;
  pagingParams = new PagingParams();
  predicate = new Map().set("all", true);
  baseAPI = process.env.REACT_APP_API_URL;

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.predicate.keys(),
      () => {
        this.pagingParams = new PagingParams();
        this.stateRegistry.clear();
        this.loadEcoState();
      }
    );
  }

  setPagingParams = (pagingParams: PagingParams) => {
    pagingParams.pageNumber = 1;
    pagingParams.pageSize = 500;
    this.pagingParams = pagingParams;
  };

  setPredicate = (predicate: string, value: string | Date) => {
    const resetPredicate = () => {
      this.predicate.forEach((value, key) => {
        if (key !== "dateUploaded") this.predicate.delete(key);
      });
    };
    switch (predicate) {
      case "all":
        resetPredicate();
        this.predicate.set("all", true);
        break;
      case "isGoing":
        resetPredicate();
        this.predicate.set("isGoing", true);
        break;
      case "isHost":
        resetPredicate();
        this.predicate.set("isHost", true);
        break;
      case "dateUploaded":
        this.predicate.delete("dateUploaded");
        this.predicate.set("dateUploaded", value);
    }
  };

  get axiosParams() {
    const params = new URLSearchParams();
    // params.append("pageNumber", this.pagingParams.pageNumber.toString());
    // params.append("pageSize", this.pagingParams.pageSize.toString());
    params.append("pageNumber", "1");
    params.append("pageSize", "500");
    this.predicate.forEach((value, key) => {
      if (key === "dateUploaded") {
        params.append(key, (value as Date).toISOString());
      } else {
        params.append(key, value);
      }
    });
    return params;
  }

  get stateByDate() {
    return Array.from(this.stateRegistry.values()).sort(
      (a, b) => a.dateUploaded!.getTime() - b.dateUploaded!.getTime()
    );
  }

  // get groupedEcoState() {
  //   return Object.entries(
  //     this.stateByDate.reduce((state) => {
  //       const date = format(state.dateUploaded!, "dd MMM yyyy");
  //       state[date] = state[date] ? [...state[date], state] : [state];
  //       return state;
  //     }, {} as { [key: string]: Resource[] })
  //   );
  // }

  // loadActivities = async () => {
  //   this.loadingInitial = true;
  //   try {
  //     const result = await agent.Activities.list(this.axiosParams);
  //     result.data.forEach((activity) => {
  //       this.setActivity(activity);
  //     });
  //     this.setPagination(result.pagination);
  //     this.setLoadingInitial(false);
  //   } catch (error) {
  //     console.log(error);
  //     this.setLoadingInitial(false);
  //   }
  // };

  loadEcoState = async () => {
    this.loadingInitial = true;
    try {
      console.log("1. LOG = " + this.axiosParams);
      const result = await agent.EcoState.list(this.axiosParams);
      //this.resourceData.push(result);
      //console.log("1. result from loadResources()", result);
      // result.map(
      //   (resource) =>
      //     //this.resourceData.push(resource)
      //     this.setResourceData(resource)
      // );
      // console.log("2. result from loadResources()");
      this.stateData = result.data;
      // console.log(this.resourceData);
      // result.data.forEach((resource) => {
      //   this.setResourceData(resource);
      // });
      //this.setPagination(result.pagination);
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
    }
  };

  loadEcoStateDashboard = async () => {
    this.loadingInitial = true;
    try {
      const result = await agentExpress.EcoState.list();
      this.stateData = result;
      console.log("1. In loadstateDashboard: result", result);
      console.log("2. In loadstateDashboard: this.stateData", this.stateData);
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
    }
  };

  setPagination = (pagination: Pagination) => {
    this.pagination = pagination;
  };

  loadStateModel = async (id: string) => {
    let state = this.getStateModel(id);
    if (state) {
      this.selectedStateModel = state;
      return state;
    } else {
      this.loadingInitial = true;
      try {
        state = await agent.EcoState.details(id);
        this.setStateModelData(state);
        runInAction(() => {
          this.selectedStateModel = state;
        });
        this.setLoadingInitial(false);
        return state;
      } catch (error) {
        console.log(error);
        this.setLoadingInitial(false);
      }
    }
  };
  private setStateModelData = (state: StateModel) => {
    //resource.dateUploaded = new Date(resource.dateUploaded!);
    this.stateData.push(state);
  };

  private setStateModel = (state: StateModel) => {
    state.dateUploaded = new Date(state.dateUploaded!);
    this.stateRegistry.set(state.stateOID, state);
  };

  private getStateModel = (id: string) => {
    return this.stateRegistry.get(id);
  };

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  createStateModel = async (state: StateFormValues) => {
    try {
      await agent.EcoState.create(state);
      const newStateModel = new StateModel(state);
      //   newResource.hostUsername = user!.username;
      //   newResource.attendees = [attendee];
      this.setStateModel(newStateModel);
      runInAction(() => {
        this.selectedStateModel = newStateModel;
      });
    } catch (error) {
      console.log(error);
    }
  };

  updateStateModel = async (state: StateFormValues) => {
    try {
      await agent.EcoState.update(state);
      runInAction(() => {
        if (state.stateOID) {
          let updatedStateModel = {
            ...this.getStateModel(state.stateOID),
            ...state,
          };
          this.stateRegistry.set(
            state.stateOID,
            updatedStateModel as StateModel
          );
          this.selectedStateModel = updatedStateModel as StateModel;
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  deleteStateModel = async (id: string) => {
    this.loading = true;
    try {
      await agent.EcoState.delete(id);
      runInAction(() => {
        this.stateRegistry.delete(id);
        this.loading = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  //   updateAttendance = async () => {
  //     const user = store.userStore.user;
  //     this.loading = true;
  //     try {
  //       await agent.Activities.attend(this.selectedActivity!.id);
  //       runInAction(() => {
  //         if (this.selectedActivity?.isGoing) {
  //           this.selectedActivity.attendees =
  //             this.selectedActivity.attendees?.filter(
  //               (a) => a.username !== user?.username
  //             );
  //           this.selectedActivity.isGoing = false;
  //         } else {
  //           const attendee = new Profile(user!);
  //           this.selectedActivity?.attendees?.push(attendee);
  //           this.selectedActivity!.isGoing = true;
  //         }
  //         this.activityRegistry.set(
  //           this.selectedActivity!.id,
  //           this.selectedActivity!
  //         );
  //       });
  //     } catch (error) {
  //       console.log(error);
  //     } finally {
  //       runInAction(() => (this.loading = false));
  //     }
  //   };

  cancelStateModelToggle = async () => {
    this.loading = true;
    try {
      //await agent.Resources.attend(this.selectedResource!.resourceGUID);
      runInAction(() => {
        // this.selectedResource!.isCancelled =
        //   !this.selectedResource?.isCancelled;
        this.stateRegistry.set(
          this.selectedStateModel!.stateOID,
          this.selectedStateModel!
        );
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

  //   updateAttendeeFollowing = (username: string) => {
  //     this.activityRegistry.forEach((activity) => {
  //       activity.attendees.forEach((attendee) => {
  //         if (attendee.username === username) {
  //           attendee.following
  //             ? attendee.followersCount--
  //             : attendee.followersCount++;
  //           attendee.following = !attendee.following;
  //         }
  //       });
  //     });
  //   };

  clearSelectedStateModel = () => {
    this.selectedStateModel = undefined;
  };
}

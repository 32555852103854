import { Form, Formik } from "formik";
import React, { useState } from "react";
import { Accordion, Icon, Label, Segment } from "semantic-ui-react";
import * as Yup from "yup";
import Analysis from "./Analysis";
import CommodityInformation from "./CommodityInformation";
import ContractInformation from "./ContractInformation";
import classes from "./coster.module.css";
import ExpenseDetails from "./ExpenseDetails";
import SecondaryProducts from "./SecondaryProducts";
import { initialValues } from "./staticData";
import Summary from "./Summary";

const BlinkCoster = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleClick = (e: any, titleProps: any) => {
    let { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };

  const validationSchema = Yup.object({});

  return (
    <div>
      <Segment>
        <div className={classes.company}>
          <div className={classes.logo}></div>
          <div className={classes.name}>
            <h3 className=''>cad consulting limited</h3>
            <p>No 30, Tran-Woji Road, By slaughter bridge</p>
            <p> Port Harcourt Rivers State, Nigeria </p>
          </div>
          <div className=''></div>
        </div>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={(values) => console.log(values)}
          validationSchema={validationSchema}>
          {({ values, handleSubmit, isValid, isSubmitting, dirty }) => (
            <Form
              className='ui form'
              autoComplete='off'
              onSubmit={handleSubmit}>
              <Accordion>
                <Accordion.Title
                  active={activeIndex === 0}
                  index={0}
                  onClick={handleClick}>
                  <Label color='brown' className={classes.accordion_width}>
                    <Icon name='dropdown' />
                    Header
                  </Label>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 0}>
                  <ContractInformation values={values} />
                </Accordion.Content>

                <Accordion.Title
                  active={activeIndex === 1}
                  index={1}
                  onClick={handleClick}>
                  <Label color='brown' className={classes.accordion_width}>
                    <Icon name='dropdown' />
                    Commodity
                  </Label>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 1}>
                  <CommodityInformation values={values} />
                </Accordion.Content>
                <Accordion.Title
                  active={activeIndex === 2}
                  index={2}
                  onClick={handleClick}>
                  <Label color='brown' className={classes.accordion_width}>
                    <Icon name='dropdown' />
                    Expense
                  </Label>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 2}>
                  <ExpenseDetails values={values} />
                </Accordion.Content>
                <Accordion.Title
                  active={activeIndex === 3}
                  index={3}
                  onClick={handleClick}>
                  <Label color='brown' className={classes.accordion_width}>
                    <Icon name='dropdown' />
                    Product
                  </Label>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 3}>
                  <SecondaryProducts />
                </Accordion.Content>
                <Accordion.Title
                  active={activeIndex === 4}
                  index={4}
                  onClick={handleClick}>
                  <Label color='brown' className={classes.accordion_width}>
                    <Icon name='dropdown' />
                    Analysis
                  </Label>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 4}>
                  <Analysis />
                </Accordion.Content>
                <Accordion.Title
                  active={activeIndex === 5}
                  index={5}
                  onClick={handleClick}>
                  <Label color='brown' className={classes.accordion_width}>
                    <Icon name='dropdown' />
                    Summary
                  </Label>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 5}>
                  <Summary />
                </Accordion.Content>
              </Accordion>
            </Form>
          )}
        </Formik>
      </Segment>
    </div>
  );
};

export default BlinkCoster;

//import { Profile } from "./profile";

export interface PersonalData {
  msmeoid: number;
  userGUID: string;
  msmeCode: string;
  surname: string;
  otherNames: string;
  gender: string;
  ageRange: string;
  maritalStatus: string;
  mobilePhone: string;
  stateOfOrigin: string;
  personalBankName: string;
  personalBankAccount: string;
  personalAccountNumber: string;
  displayName: string;
  passion?: string;
  businessName: string;
  businessVision?: string;
  businessMission: string;
  yearOfEstablisment: number | null;
  currentNumberOfFemaleStaff: number | null;
  currentNumberOfMaleStaff: number | null;
  businessEmail: string;
  businessAddress: string;
  businessCity: string;
  businessLGA: string;
  businessState: string;
  stateOfCoverage: string;
  businessBankName: string;
  businessAccountName: string;
  businessAccountNumber: string;
  forProfitOrNot: string;
  sectorOfFocus: string;
  businessArea: string;
  msmeCategory: string;
  assetRange: string;
  futurePlan: string;
  longitude: string;
  latitude: string;
  website: string;
  twitter: string;
  instagram: string;
  whatsApp: string;
  facebook: string;
  typeOfEnterprise: string;
  companyRegistrationNumber: string;
  hasCertificateOfRegistration: string;
  hasTaxClearanceCertificate: string;
  hasProofOfShareholding: string;
  hasVATNumber: string;
  vatNumber: string;
  hasLatestFinancialStatement: string;
  b2BMarket: string;
  inMarket: string;
  repeatBuy: string;
  existingCustomer: string;
  productStandards: string;
  usePhoneForSocialMedia: boolean | null;
  usePhoneForReseachOnInternet: boolean | null;
  usePhoneForBankTransaction: boolean | null;
  useBusinessManagementSoftware: boolean | null;
  canUseMSExcel: boolean | null;
  canUseMSWord: boolean | null;
  canUseMSPowerpoint: boolean | null;
  useBSPToolsForMSME: boolean | null;
  useSoftware: boolean | null;
  businessManagementSoftwareUse: string;
  supervisingEDI: string;
  supportServicesYouNeed: string;
  typeOfPartnershipYouNeed: string;
  businessLinkageOffering: string;
  certifications: string;
  submittionStatus: string;
  submittionDate: Date | null;
  adminApprovalStatus: string;
  adminApprovalDate: Date | null;
  registrationDate: Date | null;
  value?: {
    msmeoid: number;
    userGUID: string;
    msmeCode: string;
    surname: string;
    otherNames: string;
    gender: string;
    ageRange: string;
    maritalStatus: string;
    mobilePhone: string;
    stateOfOrigin: string;
    personalBankName: string;
    personalBankAccount: string;
    personalAccountNumber: string;
    businessEmail: any;
    displayName: string
  };
}

export class BusinessData {
  msmeoid: number = 0;
  userGUID: string = "";
  msmeCode: string = "";
  surname: string = "";
  otherNames: string = "";
  gender: string = "";
  ageRange: string = "";
  maritalStatus: string = "";
  mobilePhone: string = "";
  stateOfOrigin: string = "";
  personalBankName: string = "";
  personalBankAccount: string = "";
  personalAccountNumber: string = "";
  businessEmail: any = null;
  displayName: string = "";
}

export class PersonalData implements PersonalData {
  constructor(init?: PersonalDataFormValues) {
    Object.assign(this, init);
  }
}

export class PersonalDataFormValues {
  msmeoid?: number = 0;
  userGUID?: string = "";
  msmeCode?: string = "";
  surname?: string = "";
  otherNames?: string = "";
  gender?: string = "";
  ageRange?: string = "";
  maritalStatus?: string = "";
  mobilePhone?: string = "";
  stateOfOrigin?: string = "";
  personalBankName?: string = "";
  personalBankAccount?: string = "";
  personalAccountNumber?: string = "";
  displayName?: string = "";
  passion?: string = "";
  businessName?: string = "";
  businessVision?: string = "";
  businessMission?: string = "";
  yearOfEstablisment?: number | null = null;
  currentNumberOfFemaleStaff?: number | null = 0;
  currentNumberOfMaleStaff?: number | null = 0;
  businessEmail?: string = "";
  businessAddress?: string = "";
  businessCity?: string = "";
  businessLGA?: string = "";
  businessState?: string = "";
  stateOfCoverage?: string = "";
  businessBankName?: string = "";
  businessAccountName?: string = "";
  businessAccountNumber?: string = "";
  forProfitOrNot?: string = "";
  sectorOfFocus?: string = "";
  businessArea?: string = "";
  msmeCategory?: string = "";
  assetRange?: string = "";
  futurePlan?: string = "";
  longitude?: string = "";
  latitude?: string = "";
  website?: string = "";
  twitter?: string = "";
  instagram?: string = "";
  whatsApp?: string = "";
  facebook?: string = "";
  typeOfEnterprise?: string = "";
  companyRegistrationNumber?: string = "";
  hasCertificateOfRegistration?: string = "";
  hasTaxClearanceCertificate?: string = "";
  hasProofOfShareholding?: string = "";
  hasVATNumber?: string = "";
  vatNumber?: string = "";
  hasLatestFinancialStatement?: string = "";
  b2BMarket?: string = "";
  inMarket?: string = "";
  repeatBuy?: string = "";
  existingCustomer?: string = "";
  productStandards?: string = "";
  usePhoneForSocialMedia?: boolean | null = null;
  usePhoneForReseachOnInternet?: boolean | null = null;
  usePhoneForBankTransaction?: boolean | null = null;
  useBusinessManagementSoftware?: boolean | null = null;
  canUseMSExcel?: boolean | null = null;
  canUseMSWord?: boolean | null = null;
  canUseMSPowerpoint?: boolean | null = null;
  useBSPToolsForMSME?: boolean | null = null;
  useSoftware?: boolean | null = null;
  businessManagementSoftwareUse?: string = "";
  supervisingEDI?: string = "";
  supportServicesYouNeed?: string = "";
  typeOfPartnershipYouNeed?: string = "";
  businessLinkageOffering?: string = "";
  certifications?: string = "";
  submittionStatus?: string = "";
  submittionDate?: Date | null = null;
  adminApprovalStatus?: string = "";
  adminApprovalDate?: Date | null = null;
  registrationDate?: Date | null = null;

  // this is the place
    //  mSMEOID?: number =0;
    // userGUID?: string='';
    // mSMECode?: string='';
    // surname?: string='';
    // otherNames?: string='';
    // gender?: string='';
    // ageRange?: string='';
    // maritalStatus?: string='';
    // mobilePhone?: string='';
    // stateOfOrigin?: string='';
    // personalBankName?: string='';
    // personalBankAccount?: string='';
    // personalAccountNumber?: string='';
    // businessEmail?: any='';

  constructor(personalData?: PersonalDataFormValues) {
    if (personalData) {
      this.msmeoid = personalData.msmeoid;
      this.userGUID = personalData.userGUID;
      this.msmeCode = personalData.msmeCode;
      this.surname = personalData.surname;
      this.otherNames = personalData.otherNames;
      this.gender = personalData.gender;
      this.ageRange = personalData.ageRange;
      this.maritalStatus = personalData.maritalStatus;
      this.mobilePhone = personalData.mobilePhone;
      this.stateOfOrigin = personalData.stateOfOrigin;
      this.personalBankName = personalData.personalBankName;
      this.personalBankAccount = personalData.personalBankAccount;
      this.personalAccountNumber = personalData.personalAccountNumber;
      this.displayName = personalData.displayName;

      this.passion = personalData.passion;
      this.businessName = personalData.businessName;
      this.businessVision = personalData.businessVision;
      this.businessMission = personalData.businessMission;
      this.yearOfEstablisment = personalData.yearOfEstablisment;
      this.currentNumberOfFemaleStaff = personalData.currentNumberOfFemaleStaff;
      this.currentNumberOfMaleStaff = personalData.currentNumberOfMaleStaff;
      this.businessEmail = personalData.businessEmail;
      this.businessAddress = personalData.businessAddress;
      this.businessCity = personalData.businessCity;
      this.businessLGA = personalData.businessLGA;
      this.businessState = personalData.businessState;
      this.stateOfCoverage = personalData.stateOfCoverage;
      this.businessBankName = personalData.businessBankName;
      this.businessAccountName = personalData.businessAccountName;
      this.businessAccountNumber = personalData.businessAccountNumber;
      this.forProfitOrNot = personalData.forProfitOrNot;
      this.sectorOfFocus = personalData.sectorOfFocus;
      this.businessArea = personalData.businessArea;
      this.msmeCategory = personalData.msmeCategory;
      this.assetRange = personalData.assetRange;
      this.futurePlan = personalData.futurePlan;
      this.longitude = personalData.longitude;
      this.latitude = personalData.latitude;
      this.website = personalData.website;
      this.twitter = personalData.twitter;
      this.instagram = personalData.instagram;
      this.whatsApp = personalData.whatsApp;
      this.facebook = personalData.facebook;
      this.typeOfEnterprise = personalData.typeOfEnterprise;
      this.companyRegistrationNumber = personalData.companyRegistrationNumber;
      this.hasCertificateOfRegistration =
        personalData.hasCertificateOfRegistration;
      this.hasTaxClearanceCertificate = personalData.hasTaxClearanceCertificate;
      this.hasProofOfShareholding = personalData.hasProofOfShareholding;
      this.hasVATNumber = personalData.hasVATNumber;
      this.vatNumber = personalData.vatNumber;
      this.hasLatestFinancialStatement =
        personalData.hasLatestFinancialStatement;
      this.b2BMarket = personalData.b2BMarket;
      this.inMarket = personalData.inMarket;
      this.repeatBuy = personalData.repeatBuy;
      this.existingCustomer = personalData.existingCustomer;
      this.productStandards = personalData.productStandards;
      this.usePhoneForSocialMedia = personalData.usePhoneForSocialMedia;
      this.usePhoneForReseachOnInternet =
        personalData.usePhoneForReseachOnInternet;
      this.usePhoneForBankTransaction = personalData.usePhoneForBankTransaction;
      this.useBusinessManagementSoftware =
        personalData.useBusinessManagementSoftware;
      this.canUseMSExcel = personalData.canUseMSExcel;
      this.canUseMSWord = personalData.canUseMSWord;
      this.canUseMSPowerpoint = personalData.canUseMSPowerpoint;
      this.useBSPToolsForMSME = personalData.useBSPToolsForMSME;
      this.useSoftware = personalData.useSoftware;
      this.businessManagementSoftwareUse =
        personalData.businessManagementSoftwareUse;
      this.supervisingEDI = personalData.supervisingEDI;
      this.supportServicesYouNeed = personalData.supportServicesYouNeed;
      this.typeOfPartnershipYouNeed = personalData.typeOfPartnershipYouNeed;
      this.businessLinkageOffering = personalData.businessLinkageOffering;
      this.certifications = personalData.certifications;
      this.submittionStatus = personalData.submittionStatus;
      this.submittionDate = personalData.submittionDate;
      this.adminApprovalStatus = personalData.adminApprovalStatus;
      this.adminApprovalDate = personalData.adminApprovalDate;
      this.registrationDate = personalData.registrationDate;
    }
  }
}

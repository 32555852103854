//import { Profile } from "./profile";

export interface MsmeBusinessData {
  msmeoid: number;
  userGUID: string;
  msmeCode: string;
  surname: string;
  displayName: string;
  passion?: string;
  businessName: string;
  businessVision?: string;
  businessMission: string;
  yearOfEstablisment: number | null;
  currentNumberOfFemaleStaff: number | null;
  currentNumberOfMaleStaff: number | null;
  businessEmail: string;
  businessAddress: string;
  businessCity: string;
  businessLGA: string;
  businessState: string;
  stateOfCoverage: string;
  businessBankName: string;
  businessAccountName: string;
  businessAccountNumber: string;
  forProfitOrNot: string;
  sectorOfFocus: string;
  businessArea: string;
  msmeCategory: string;
  assetRange: string;
  futurePlan: string;
  longitude: string;
  latitude: string;
  website: string;
  twitter: string;
  instagram: string;
  whatsApp: string;
  facebook: string;
  typeOfEnterprise: string;
  companyRegistrationNumber: string;
  hasCertificateOfRegistration: string;
  hasTaxClearanceCertificate: string;
  hasProofOfShareholding: string;
  hasVATNumber: string;
  vatNumber: string;
  hasLatestFinancialStatement: string;
  b2BMarket: string;
  inMarket: string;
  repeatBuy: string;
  existingCustomer: string;
  productStandards: string;
  usePhoneForSocialMedia: boolean | null;
  usePhoneForReseachOnInternet: boolean | null;
  usePhoneForBankTransaction: boolean | null;
  useBusinessManagementSoftware: boolean | null;
  canUseMSExcel: boolean | null;
  canUseMSWord: boolean | null;
  canUseMSPowerpoint: boolean | null;
  useBSPToolsForMSME: boolean | null;
  useSoftware: boolean | null;
  businessManagementSoftwareUse: string;
  supervisingEDI: string;
  supportServicesYouNeed: string;
  typeOfPartnershipYouNeed: string;
  businessLinkageOffering: string;
  certifications: string;
  submittionStatus: string;
  submittionDate: Date | null;
  adminApprovalStatus: string;
  adminApprovalDate: Date | null;
  registrationDate: Date | null;

}



export class MsmeBusinessData implements MsmeBusinessData {
  constructor(init?: MsmeBusinessDataFormValues) {
    Object.assign(this, init);
  }
}

export class MsmeBusinessDataFormValues {
  msmeoid?: number = 0;
  userGUID?: string = "";
  msmeCode?: string = "";
  displayName: string = "";
  passion?: string = "";
  businessName: string = "";
  businessVision?: string = "";
  businessMission: string = "";
  yearOfEstablisment: number | null = null;
  currentNumberOfFemaleStaff: number | null = 0;
  currentNumberOfMaleStaff: number | null = 0;
  businessEmail: string = "";
  businessAddress: string = "";
  businessCity: string = "";
  businessLGA: string = "";
  businessState: string = "";
  stateOfCoverage: string = "";
  businessBankName: string = "";
  businessAccountName: string = "";
  businessAccountNumber: string = "";
  forProfitOrNot: string = "";
  sectorOfFocus: string = "";
  businessArea: string = "";
  msmeCategory: string = "";
  assetRange: string = "";
  futurePlan: string = "";
  longitude: string = "";
  latitude: string = "";
  website: string = "";
  twitter: string = "";
  instagram: string = "";
  whatsApp: string = "";
  facebook: string = "";
  typeOfEnterprise: string = "";
  companyRegistrationNumber: string = "";
  hasCertificateOfRegistration: string = "";
  hasTaxClearanceCertificate: string = "";
  hasProofOfShareholding: string = "";
  hasVATNumber: string = "";
  vatNumber: string = "";
  hasLatestFinancialStatement: string = "";
  b2BMarket: string = "";
  inMarket: string = "";
  repeatBuy: string = "";
  existingCustomer: string = "";
  productStandards: string = "";
  usePhoneForSocialMedia: boolean | null = null;
  usePhoneForReseachOnInternet: boolean | null = null;
  usePhoneForBankTransaction: boolean | null = null;
  useBusinessManagementSoftware: boolean | null = null;
  canUseMSExcel: boolean | null = null;
  canUseMSWord: boolean | null = null;
  canUseMSPowerpoint: boolean | null = null;
  useBSPToolsForMSME: boolean | null = null;
  useSoftware: boolean | null = null;
  businessManagementSoftwareUse: string = "";
  supervisingEDI: string = "";
  supportServicesYouNeed: string = "";
  typeOfPartnershipYouNeed: string = "";
  businessLinkageOffering: string = "";
  certifications: string = "";
  submittionStatus: string = "";
  submittionDate: Date | null = null;
  adminApprovalStatus: string = "";
  adminApprovalDate: Date | null = null;
  registrationDate: Date | null = null;


  
  constructor(msmeBusinessData?: MsmeBusinessDataFormValues) {
    if (msmeBusinessData) {
      this.msmeoid = msmeBusinessData.msmeoid;
      this.userGUID = msmeBusinessData.userGUID;
      this.msmeCode = msmeBusinessData.msmeCode;
      this.displayName = msmeBusinessData.displayName;
      this.passion = msmeBusinessData.passion;
      this.businessName = msmeBusinessData.businessName;
      this.businessVision = msmeBusinessData.businessVision;
      this.businessMission = msmeBusinessData.businessMission;
      this.yearOfEstablisment = msmeBusinessData.yearOfEstablisment;
      this.currentNumberOfFemaleStaff = msmeBusinessData.currentNumberOfFemaleStaff;
      this.currentNumberOfMaleStaff = msmeBusinessData.currentNumberOfMaleStaff;
      this.businessEmail = msmeBusinessData.businessEmail;
      this.businessAddress = msmeBusinessData.businessAddress;
      this.businessCity = msmeBusinessData.businessCity;
      this.businessLGA = msmeBusinessData.businessLGA;
      this.businessState = msmeBusinessData.businessState;
      this.stateOfCoverage = msmeBusinessData.stateOfCoverage;
      this.businessBankName = msmeBusinessData.businessBankName;
      this.businessAccountName = msmeBusinessData.businessAccountName;
      this.businessAccountNumber = msmeBusinessData.businessAccountNumber;
      this.forProfitOrNot = msmeBusinessData.forProfitOrNot;
      this.sectorOfFocus = msmeBusinessData.sectorOfFocus;
      this.businessArea = msmeBusinessData.businessArea;
      this.msmeCategory = msmeBusinessData.msmeCategory;
      this.assetRange = msmeBusinessData.assetRange;
      this.futurePlan = msmeBusinessData.futurePlan;
      this.longitude = msmeBusinessData.longitude;
      this.latitude = msmeBusinessData.latitude;
      this.website = msmeBusinessData.website;
      this.twitter = msmeBusinessData.twitter;
      this.instagram = msmeBusinessData.instagram;
      this.whatsApp = msmeBusinessData.whatsApp;
      this.facebook = msmeBusinessData.facebook;
      this.typeOfEnterprise = msmeBusinessData.typeOfEnterprise;
      this.companyRegistrationNumber = msmeBusinessData.companyRegistrationNumber;
      this.hasCertificateOfRegistration =
        msmeBusinessData.hasCertificateOfRegistration;
      this.hasTaxClearanceCertificate = msmeBusinessData.hasTaxClearanceCertificate;
      this.hasProofOfShareholding = msmeBusinessData.hasProofOfShareholding;
      this.hasVATNumber = msmeBusinessData.hasVATNumber;
      this.vatNumber = msmeBusinessData.vatNumber;
      this.hasLatestFinancialStatement =
        msmeBusinessData.hasLatestFinancialStatement;
      this.b2BMarket = msmeBusinessData.b2BMarket;
      this.inMarket = msmeBusinessData.inMarket;
      this.repeatBuy = msmeBusinessData.repeatBuy;
      this.existingCustomer = msmeBusinessData.existingCustomer;
      this.productStandards = msmeBusinessData.productStandards;
      this.usePhoneForSocialMedia = msmeBusinessData.usePhoneForSocialMedia;
      this.usePhoneForReseachOnInternet =
        msmeBusinessData.usePhoneForReseachOnInternet;
      this.usePhoneForBankTransaction = msmeBusinessData.usePhoneForBankTransaction;
      this.useBusinessManagementSoftware =
        msmeBusinessData.useBusinessManagementSoftware;
      this.canUseMSExcel = msmeBusinessData.canUseMSExcel;
      this.canUseMSWord = msmeBusinessData.canUseMSWord;
      this.canUseMSPowerpoint = msmeBusinessData.canUseMSPowerpoint;
      this.useBSPToolsForMSME = msmeBusinessData.useBSPToolsForMSME;
      this.useSoftware = msmeBusinessData.useSoftware;
      this.businessManagementSoftwareUse =
        msmeBusinessData.businessManagementSoftwareUse;
      this.supervisingEDI = msmeBusinessData.supervisingEDI;
      this.supportServicesYouNeed = msmeBusinessData.supportServicesYouNeed;
      this.typeOfPartnershipYouNeed = msmeBusinessData.typeOfPartnershipYouNeed;
      this.businessLinkageOffering = msmeBusinessData.businessLinkageOffering;
      this.certifications = msmeBusinessData.certifications;
      this.submittionStatus = msmeBusinessData.submittionStatus;
      this.submittionDate = msmeBusinessData.submittionDate;
      this.adminApprovalStatus = msmeBusinessData.adminApprovalStatus;
      this.adminApprovalDate = msmeBusinessData.adminApprovalDate;
      this.registrationDate = msmeBusinessData.registrationDate;
    }
  }
}

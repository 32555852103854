import React, { useState, useEffect } from "react";
import { Label, Table, Button, Input, Segment } from "semantic-ui-react";
import axios from "axios";

// import "./style.css";

export const SystemSetting = () => {
  // const result = [
  //   {
  //     oid: 18,
  //     settingsCode: "PAT Settings",
  //     category: "URL",
  //     settingsOrder: 1,
  //     subSettingsOrder: 1,
  //     description: "Admin Email Address",
  //     inputUIType: "Textbox",
  //     uom: "Year",
  //     value: "csa.cad@gmail.com",
  //     optionsList: "none",
  //     applicationModule: "ECOSYS",
  //   },
  //   {
  //     oid: 5,
  //     settingsCode: "CADAdminEmail",
  //     category: "Email",
  //     settingsOrder: 1,
  //     subSettingsOrder: 1,
  //     description: "CAD Admin Email",
  //     inputUIType: "Textbox",
  //     uom: "Month",
  //     value: "csa.cad@gmail.com",
  //     optionsList: "csa.cad@gmail.com",
  //     applicationModule: "ECOSYS",
  //   },
  //   {
  //     oid: 2,
  //     settingsCode: "Current_FiscalPeriod",
  //     category: "Period Manager",
  //     settingsOrder: 1,
  //     subSettingsOrder: 1,
  //     description: "Current Fiscal Period",
  //     inputUIType: "Select",
  //     uom: "Month",
  //     value: "4",
  //     optionsList: "1,2,3,4,5,6,7,8,9,10,11,12",
  //     applicationModule: "ECOSYS",
  //   },
  //   {
  //     oid: 3,
  //     settingsCode: "Current_FiscalYear",
  //     category: "Period Manager",
  //     settingsOrder: 1,
  //     subSettingsOrder: 1,
  //     description: "Current Fiscal Year",
  //     inputUIType: "Textbox",
  //     uom: "Month",
  //     value: "2018",
  //     optionsList: "1,2,3,4,5,6,7,8,9,10,11,12",
  //     applicationModule: "ECOSYS",
  //   },
  //   {
  //     oid: 6,
  //     settingsCode: "SMTP",
  //     category: "Email",
  //     settingsOrder: 1,
  //     subSettingsOrder: 1,
  //     description: "Email SMTP",
  //     inputUIType: "Textbox",
  //     uom: "Month",
  //     value: "smtp.gmail.com",
  //     optionsList: "smtp.gmail.com",
  //     applicationModule: "ECOSYS",
  //   },
  //   {
  //     oid: 9,
  //     settingsCode: "SMTPPassword",
  //     category: "Email",
  //     settingsOrder: 1,
  //     subSettingsOrder: 1,
  //     description: "Email SMTP Password",
  //     inputUIType: "Textbox",
  //     uom: "Month",
  //     value: "Cad*91027161",
  //     optionsList: "Cad*91027161",
  //     applicationModule: "ECOSYS",
  //   },
  //   {
  //     oid: 7,
  //     settingsCode: "SMTPPort",
  //     category: "Email",
  //     settingsOrder: 1,
  //     subSettingsOrder: 1,
  //     description: "Email SMTP Port",
  //     inputUIType: "Textbox",
  //     uom: "Month",
  //     value: "587",
  //     optionsList: "587",
  //     applicationModule: "ECOSYS",
  //   },
  //   {
  //     oid: 8,
  //     settingsCode: "SMTPUsername",
  //     category: "Email",
  //     settingsOrder: 1,
  //     subSettingsOrder: 1,
  //     description: "Email SMTP Username",
  //     inputUIType: "Textbox",
  //     uom: "Month",
  //     value: "caderp2018@gmail.com",
  //     optionsList: "caderp2018@gmail.com",
  //     applicationModule: "ECOSYS",
  //   },
  //   {
  //     oid: 23,
  //     settingsCode: "PAT Settings",
  //     category: "Time Line",
  //     settingsOrder: 1,
  //     subSettingsOrder: 1,
  //     description: "Execution Feedback Interval",
  //     inputUIType: "Textbox",
  //     uom: "Year",
  //     value: "7",
  //     optionsList: "none",
  //     applicationModule: "ECOSYS",
  //   },
  //   {
  //     oid: 24,
  //     settingsCode: "PAT Settings",
  //     category: "Time Line",
  //     settingsOrder: 1,
  //     subSettingsOrder: 1,
  //     description: "Issue Return Inteval",
  //     inputUIType: "Textbox",
  //     uom: "Year",
  //     value: "14",
  //     optionsList: "none",
  //     applicationModule: "ECOSYS",
  //   },
  //   {
  //     oid: 10,
  //     settingsCode: "LeaveFinalApproverEmail",
  //     category: "Email",
  //     settingsOrder: 1,
  //     subSettingsOrder: 1,
  //     description: "Leave Final Approver Email",
  //     inputUIType: "Textbox",
  //     uom: "Month",
  //     value: "csa.cad@gmail.com",
  //     optionsList: "csa.cad@gmail.com",
  //     applicationModule: "ECOSYS",
  //   },
  //   {
  //     oid: 25,
  //     settingsCode: "PAT Settings",
  //     category: "Time Line",
  //     settingsOrder: 1,
  //     subSettingsOrder: 1,
  //     description: "Material Collection Lead Time",
  //     inputUIType: "Textbox",
  //     uom: "Year",
  //     value: "3",
  //     optionsList: "none",
  //     applicationModule: "ECOSYS",
  //   },
  //   {
  //     oid: 20,
  //     settingsCode: "PAT Settings",
  //     category: "URL",
  //     settingsOrder: 1,
  //     subSettingsOrder: 1,
  //     description: "Material Picture Folder",
  //     inputUIType: "Textbox",
  //     uom: "Year",
  //     value: "nbplc.caderp.com/Material",
  //     optionsList: "none",
  //     applicationModule: "ECOSYS",
  //   },
  //   {
  //     oid: 21,
  //     settingsCode: "PAT Settings",
  //     category: "Time Line",
  //     settingsOrder: 1,
  //     subSettingsOrder: 1,
  //     description: "Material Return Interval",
  //     inputUIType: "Textbox",
  //     uom: "Year",
  //     value: "7",
  //     optionsList: "none",
  //     applicationModule: "ECOSYS",
  //   },
  //   {
  //     oid: 19,
  //     settingsCode: "PAT Settings",
  //     category: "URL",
  //     settingsOrder: 1,
  //     subSettingsOrder: 1,
  //     description: "PAT URL",
  //     inputUIType: "Textbox",
  //     uom: "Year",
  //     value: "nbplc.caderp.com",
  //     optionsList: "none",
  //     applicationModule: "ECOSYS",
  //   },
  //   {
  //     oid: 13,
  //     settingsCode: "PayslipFTPFolder",
  //     category: "FTP",
  //     settingsOrder: 1,
  //     subSettingsOrder: 1,
  //     description: "PayslipFTPFolder",
  //     inputUIType: "Textbox",
  //     uom: "Month",
  //     value: "ftp://www.caddigitalfarm.com/FileAttachments/",
  //     optionsList: "csa.cad@gmail.com",
  //     applicationModule: "ECOSYS",
  //   },
  //   {
  //     oid: 12,
  //     settingsCode: "PayslipFTPPassword",
  //     category: "FTP",
  //     settingsOrder: 1,
  //     subSettingsOrder: 1,
  //     description: "PayslipFTPPassword",
  //     inputUIType: "Textbox",
  //     uom: "Month",
  //     value: "downloaders",
  //     optionsList: "csa.cad@gmail.com",
  //     applicationModule: "ECOSYS",
  //   },
  //   {
  //     oid: 11,
  //     settingsCode: "PayslipFTPUsername",
  //     category: "FTP",
  //     settingsOrder: 1,
  //     subSettingsOrder: 1,
  //     description: "PayslipFTPUsername",
  //     inputUIType: "Textbox",
  //     uom: "Month",
  //     value: "Filedownload*2014",
  //     optionsList: "csa.cad@gmail.com",
  //     applicationModule: "ECOSYS",
  //   },
  //   {
  //     oid: 22,
  //     settingsCode: "PAT Settings",
  //     category: "Time Line",
  //     settingsOrder: 1,
  //     subSettingsOrder: 1,
  //     description: "Plan Execution Interva;",
  //     inputUIType: "Textbox",
  //     uom: "Year",
  //     value: "14",
  //     optionsList: "none",
  //     applicationModule: "ECOSYS",
  //   },
  //   {
  //     oid: 4,
  //     settingsCode: "SendFromEmail",
  //     category: "Email",
  //     settingsOrder: 1,
  //     subSettingsOrder: 1,
  //     description: "Send From Email",
  //     inputUIType: "Textbox",
  //     uom: "Month",
  //     value: "MichelinBibpay@businesssensor.com",
  //     optionsList: "MichelinBibpay@businesssensor.com",
  //     applicationModule: "ECOSYS",
  //   },
  // ];
  const [records, setRecords] = useState([]);
  const [category, setCategory] = useState([]);

  const baseAPI = process.env.REACT_APP_API_URL; // "http://localhost:5000/api";
  const preferenceAPI = `${baseAPI}/EcoSystemSettings`;
  //   this is the the buttons on the tables
  let prefrenceCategories: any = [];
  //   this is the array containing the tabl data
  let combinedArray: any = [];
  // const fetchPrefrences = () => {
  //   let response = result;
  //   sortPrefrences(response);
  // };
  const fetchPrefrences = () => {
    axios.get(preferenceAPI).then((res) => {
      let data = res.data.result;
      // filterData(data);
      sortPrefrences(data);
      console.log(data);
      console.log(combinedArray);
    });
  };

  const sortPrefrences = (prefrences: any) => {
    let categoryType;

    for (let i = 0; i < prefrences.length; i++) {
      const item = prefrences[i];
      //
      prefrences = prefrences.filter((type: any) => type !== null);
      //   console.log("Just something ", prefrences);
      if (!prefrenceCategories.includes(item.category)) {
        prefrenceCategories.push(item.category);
      }
    }
    // console.log("This is the category ", prefrenceCategories);

    while (prefrences.length > 0) {
      combinedArray.push(
        prefrences.filter(
          (type: any) => type && type.category === prefrences[0].category
        )
      );

      categoryType = combinedArray[combinedArray.length - 1][0].category;

      for (let i = 0; i < prefrences.length; i++) {
        const item = prefrences[i];
        if (item && item.category === categoryType) {
          prefrences.splice(i, 1, null);
        }
      }

      prefrences = prefrences.filter((type: any) => type !== null);
    }

    // console.log("categoryType ", categoryType);
    console.log("combinedArray  ", combinedArray);
    setRecords(combinedArray);
    setCategory(prefrenceCategories);
    console.log("records ", records);
    // console.log("TEST ", prefrences[0]);
  };

  useEffect(() => {
    fetchPrefrences();
  }, []);

  return (
    <>
      <Segment>
        <h1>SystemSetting</h1>
        <div className="ui warning message">
          <div className="header">Manage System Setting.</div>
          <p>Create and manage System Setting.</p>
        </div>
        <div className="ui divider" title="List of System Setting"></div>
        <div className="label">
          {records.map((items, index) => (
            <PrefrenceTable
              record={records[index]}
              category={category[index]}
              key={index}
            />
          ))}
        </div>
      </Segment>
    </>
  );
};

const PrefrenceTable = ({ record, category }: any) => {
  console.log("in prefrence table ", record);

  return (
    <>
      <Label color="green">{category}</Label>
      <Table celled selectable color="brown">
        <Table.Header>
          <Table.Row>
            <TableHead
              headTitles={[
                "OID",
                "Settings Code",
                "Value",
                "Description",
                "Operation",
              ]}
            />
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {record &&
            record.map((item: any, index: any) => (
              <TableData key={index} {...item} />
            ))}
        </Table.Body>
      </Table>
    </>
  );
};

const TableData = ({
  settingsCode,
  value,
  oid,
  description,
  inputUIType,
}: any) => {
  const [, setSaving] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [valueEdited, setValueEdited] = useState(value);
  const baseAPI = process.env.REACT_APP_API_URL; // "http://localhost:5000/api";
  const preferenceAPI = `${baseAPI}/EcoSystemSettings`;

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  // const handleSave = () => {
  //   setSaving(true);
  //   if (valueEdited) {
  //     // return { "UPDATING ": valueEdited, value, oid };
  //     console.log("UPDATING ", valueEdited, value, oid);
  //   }
  // };

  const handleSave = (e: any) => {
    setSaving(true);
    //save to db using the data in rowData
    if (valueEdited) {
      console.log("UPDATING...", valueEdited, value, oid);

      //save to backend
      axios
        .put(preferenceAPI, {
          value: valueEdited || value,
          oid: oid,
        })
        .then((res) => {
          console.log("UPDATE COMPLETE");

          //update the row values
          // record = {
          //   // ...record,
          //   value: valueEdited,
          //   oid,
          //   settingsCode,

          // };

          // message.success("Value updated successfully", 3);
          setSaving(false);
          setIsEditing(false);
          // setTimeout(() => window.location.reload());
        })
        .catch((err) => {
          console.log(value);
          // message.error("Value not updated, please check your network", 3);
          // setValueEdited(value);
          setSaving(false);
          setIsEditing(false);
        });
    }
  };

  const handleChange = (e: any) => {
    setValueEdited(e.target.value);
  };

  return (
    <>
      <Table.Row>
        <Table.Cell> {oid} </Table.Cell>
        <Table.Cell> {settingsCode} </Table.Cell>
        <Table.Cell>
          {/* {value} */}
          {isEditing && inputUIType.toLowerCase() === "textbox" ? (
            !!Number(value) ? (
              <Input
                type="number"
                defaultValue={value}
                placeholder="value..."
                onChange={handleChange}
              />
            ) : (
              <Input
                type="text"
                // onValueChange={handleChange}
                onChange={handleChange}
                defaultValue={value}
                placeholder="value..."
                // onValueChange={handleChange}
              />
            )
          ) : (
            // <Input type='text' defaultValue={value} placeholder='value...' />
            valueEdited || value
          )}
        </Table.Cell>
        <Table.Cell> {description} </Table.Cell>
        <Table.Cell>
          {isEditing ? (
            <Button.Group size="mini">
              <Button positive onClick={handleSave}>
                Save
              </Button>
              <Button.Or />
              <Button onClick={handleCancel} negative>
                Cancel
              </Button>
            </Button.Group>
          ) : (
            <Button size="mini" color="teal" onClick={handleEdit}>
              Edit
            </Button>
          )}
        </Table.Cell>
      </Table.Row>
    </>
  );
};

const TableHead = (props: any) => {
  const { headTitles } = props;
  return headTitles.map((title: any) => (
    <Table.HeaderCell key={title}>{title}</Table.HeaderCell>
  ));
};

export interface AssignRole {
  taskOID: string;
  applicationCode: string;
  moduleDescription: string;
  taskDescription: string;
  userRoleTaskOID: string;
  canEdit: string;
  canDelete: string;
  CanCreate: string;
  canPost: string;
  canPrint: string;
  canExport: string;
  dateUploaded: Date | null;

  // date: Date | null;
  // description: string;
  // category: string;
  // city: string;
  // venue: string;
  // hostUsername: string;
  // isCancelled: boolean;
  // isGoing: boolean;
  // isHost: boolean;
  // host?: Profile;
  // attendees: Profile[]
}

export class AssignRole implements AssignRole {
  constructor(init?: AssignRoleFormValues) {
    Object.assign(this, init);
  }
}

export class AssignRoleFormValues {
  taskOID?: string = undefined;
  applicationCode: string = "";
  moduleDescription: string = "";
  taskDescription: string = "";
  userRoleTaskOID: string = "";
  size: string = "";
  canEdit: string = "";

  canDelete: string = "";
  CanCreate: string = "";
  canPost: string = "";
  canPrint: string = "";
  canExport: string = "";

  // dateUploaded: Date  = Date.now();

  constructor(assignrole?: AssignRoleFormValues) {
    if (assignrole) {
      this.taskOID = assignrole.taskOID;
      this.applicationCode = assignrole.applicationCode;
      this.moduleDescription = assignrole.moduleDescription;
      this.taskDescription = assignrole.taskDescription;
      this.userRoleTaskOID = assignrole.userRoleTaskOID;
      this.canEdit = assignrole.canEdit;
      this.canDelete = assignrole.canDelete;
      this.CanCreate = assignrole.CanCreate;
      this.canPost = assignrole.canPost;
      this.canPrint = assignrole.canPrint;
      this.canExport = assignrole.canExport;
    }
  }
}

import React from "react";
import { Segment } from "semantic-ui-react";

export const Tools = () => {
  return (
      <Segment> 
      <h1>Tools Dashboard</h1>
      <div className="ui warning message">
        <div className="header">Dashboards for accessing tools.</div>
        <p>This dashboard provides a centralized access to available tools.</p>
      </div>
      <div className="ui divider" title="List of Resources"></div>
   </Segment>
  );
};

// import React from "react";
import { Routes, Route } from "react-router-dom";
import ActivityDashboard from "../../features/activities/dashboard/ActivityDashboard";
import HelpersOnlineList from "../../features/activities/dashboard/HelpersOnlineList";
import ActivityDetails from "../../features/activities/details/ActivityDetails";
import ToolBLinkCosterExpenseGrid from "../../features/MSME/details/CosterExpenseList";
import ActivityForm from "../../features/activities/form/ActivityForm";
import BPTemplates from "../../features/business-plan/BPTemplates/BPTemplates";
import ManageBPList from "../../features/business-plan/ManageBPList/ManageBPList";
import RedirectPage from "../../features/home/RedirectPage";
import ProfilePage from "../../features/profiles/ProfilePage";
import ProfilePersonalData from "../../features/profiles/ProfilePersonalData";
import ResourceDashboard from "../../features/resources/form/ResourceDashboard";
import ResourceForm from "../../features/resources/form/ResourceForm";
import ResourceList from "../../features/resources/form/ResourceList";
import ResourceListDx from "../../features/resources/form/ResourceListDx";
import CosterCommoditysDX from "../../features/MSME/details/CosterCommoditysDX";
import HelpersOnline from "../../features/users/HelpersOnline";
// import MSMEBlinkActivityAttendeesGrid from "../../features/MSME/details/MSMEBlinkActivityAttendeesList";
import MSMEBlinkActivitiesGrid from "../../features/MSME/details/ActivitiesList";
// import MSMEBlinkActivitiesActorsGrid from "../../features/MSME/details/MSMEBlinkActivitiesActorsList";
import MSMEBlinkWeeklyUpdateGrid from "../../features/MSME/details/WeeklyUpdateList";
import { Articles } from "../../features/views/Articles";
import { BusinessClinic } from "../../features/views/BusinessClinic";
import { GamePlan } from "../../features/views/GamePlan";
import { LinkageMap } from "../../features/views/LinkageMap";
import { Marketplace } from "../../features/views/Marketplace";
import { Tools } from "../../features/views/Tools";
import BlinkCoster from "../../features/BlinkCoster/BlinkCoster";

// import "semantic-ui-css/semantic.min.css";

export default function ViewRoutes() {
  return (
    // <Suspense fallback={<FullPageLoader />}>
    <Routes>
      <Route path={`tools`} element={<Tools />} />
      <Route path={`businessclinic`} element={<BusinessClinic />} />
      <Route path={`helpersonline`} element={<HelpersOnline />} />
      <Route path={`gameplan`} element={<GamePlan />} />
      <Route path={`marketplace`} element={<Marketplace />} />
      <Route path={`linkagemap`} element={<LinkageMap />} />
      <Route path={`articles`} element={<Articles />} />
      <Route path={`articles`} element={<Articles />} />
      <Route path={`connect-blinkplan`} element={<RedirectPage />} />
      <Route path={`list-helpersonline`} element={<HelpersOnlineList />} />
      <Route path={`bp-templates`} element={<BPTemplates />} />
      <Route path={`manage-bplist`} element={<ManageBPList />} />
      <Route path={`personaldata`} element={<ProfilePersonalData />} />
      <Route path={`activities`} element={<ActivityDashboard />} />
      <Route path={`activities/:id`} element={<ActivityDetails />} />
      <Route path={`createActivity`} element={<ActivityForm />} />
      <Route path={`loadResourcesDx`} element={<ResourceListDx />} />
      <Route path={`loadResources`} element={<ResourceList />} />
      <Route path={`listResourceDashboard`} element={<ResourceDashboard />} />
      <Route path={`createResource`} element={<ResourceForm />} />
      <Route path={`profiles/:username`} element={<ProfilePage />} />
      <Route path={`resources`} element={<ResourceForm />} />
      <Route path={`costerCommoditysDX`} element={<CosterCommoditysDX />} />
      <Route path={`coster-expense`} element={<ToolBLinkCosterExpenseGrid />} />
      {/* <Route
        path={`activities-attendees`}
        element={<MSMEBlinkActivityAttendeesGrid />}
      /> */}
      <Route path={`msme-activities`} element={<MSMEBlinkActivitiesGrid />} />
      {/* <Route
        path={`activities-actors`}
        element={<MSMEBlinkActivitiesActorsGrid />}
      /> */}
      <Route path={`weekly-updates`} element={<MSMEBlinkWeeklyUpdateGrid />} />
      <Route path={`coster`} element={<BlinkCoster />} />
    </Routes>
    // </Suspense>
  );
}

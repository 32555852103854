import React, { useState } from "react";
import {
  Segment,
  Step,
  Icon,
  // Image,
  Header,
  Form,
  Button,
  Label,
  Grid,
  Popup,
  // Message,
  Accordion,
} from "semantic-ui-react";
// import { Form as FForm, Formik } from "formik";
// import CADTextInput from "../../app/common/form/CADTextInput";
// import CADSelectInput from "../../app/common/form/CADSelectInput";
import {
  // issues,
  optionsProblemArea,
  optionsProblemCategory,
  optionsProblemSubCategory,
} from "../../app/common/staticData";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
// import { DataGrid } from "devextreme-react";
// import {
//   Column,
//   FilterPanel,
//   FilterRow,
//   Grouping,
//   GroupPanel,
//   Pager,
//   Paging,
//   RowDragging,
//   SearchPanel,
// } from "devextreme-react/data-grid";
import DataGrid, {
  FilterPanel,
  FilterRow,
  SearchPanel,
  Button as DXButton,
  Paging,
  // Lookup,
  // Popup as DXPopup,
  // Position,
  Editing,
  Export,
  Pager,
  Grouping,
  GroupPanel,
  RowDragging,
  Column,
  RequiredRule,
  // PatternRule,
  // EmailRule,
  // AsyncRule,
} from "devextreme-react/data-grid";
// import { Editing, Export } from "devextreme-react/diagram";
// import { RequiredRule } from "devextreme-react/form";
import CADLabel from "../../app/common/form/CADLabel";
import {
  bcGridData,
  businessClinicStep2,
} from "../../app/common/TemStaticData";
import ContactCard from "./ContactCard";
import { SupportTicketForm } from "../create/posts/SupportTicket/SupportTicket";
import "./BusinessClinic.css";
import { Link } from "react-router-dom";

const stepsHeading = [
  {
    id: 1,
    stepName: "STEP 1",
    title: "Problem Identification",
    // description:
    //   "Search through the list of issues to select an issue from the result.",
    icon: "tasks",
  },
  {
    id: 2,
    stepName: "STEP 2",
    title: "Select A BSP",
    // description:
    //   "Select from the list  of available business support provider (BSP) to assist you in fixing the issue.",
    icon: "tasks",
  },
  {
    id: 3,
    stepName: "STEP 3",
    title: "Create Support Ticket",
    // description: "Post a support request to your selected BSP.",
    icon: "tasks",
  },
  {
    id: 4,
    stepName: "STEP 4",
    title: "Follow Up",
    // description:
    //   "Maintain communication and follow with your BSP to fix the issue. Remember to rate your BSP and close the ticket when the issue is fixed",
    icon: "tasks",
  },
];

export const BusinessClinic = () => {
  const [activeStep, setActiveStep] = useState(1);
  return (
    <>
      <Segment attached>
        <h1>Business Clinic Dashboard</h1>
        {/* <div className="ui warning message">
          <div className="header">
            Dashboards for accessing Business Clinic.
          </div>
          <p>
            This dashboard provides a centralized access to Business Clinic.
          </p>
        </div> */}
        {/* <div className="ui divider" title="List of Resources"></div> */}
      </Segment>
      <Step.Group size='mini' attached='top' fluid>
        {stepsHeading.map((vStep) => (
          <Step
            key={vStep.stepName}
            onClick={() => setActiveStep(vStep.id)}
            disabled={activeStep < vStep.id}
            completed={activeStep >= vStep.id}
            active={activeStep === vStep.id}>
            <Icon name='tasks' />
            <Step.Content>
              <Step.Title>{vStep.stepName}</Step.Title>
              <Step.Description> {vStep.title} </Step.Description>
            </Step.Content>
          </Step>
        ))}
        {/* <Step
          onClick={() => setactiveStep(1)}
          active={activeStep === 1}
          icon={stepsHeading[0].icon}
          title={stepsHeading[0].stepName}
          description={stepsHeading[0].title}
        /> */}
        {/* <Step
          onClick={() => setactiveStep(2)}
          active={activeStep === 2}
          icon={stepsHeading[1].icon}
          title={stepsHeading[1].stepName}
          description={stepsHeading[1].title}
        /> */}
        {/* <Step
          onClick={() => setactiveStep(3)}
          active={activeStep === 3}
          disabled={activeStep !== 3}
          icon={stepsHeading[2].icon}
          title={stepsHeading[2].stepName}
          description={stepsHeading[2].title}
        /> */}
        {/* <Step
          onClick={() => setactiveStep(4)}
          active={activeStep === 4}
          icon={stepsHeading[3].icon}
          title={stepsHeading[3].stepName}
          description={stepsHeading[3].title}
        /> */}
      </Step.Group>

      <Segment attached>
        {activeStep === 1 ? (
          <Step1 setactiveStep={setActiveStep} />
        ) : activeStep === 2 ? (
          <Step2 setactiveStep={setActiveStep} />
        ) : activeStep === 3 ? (
          <Step3 setactiveStep={setActiveStep} />
        ) : (
          <Step4 />
        )}
      </Segment>

      {/* <Step.Group size="mini" fluid attached="bottom">
        {stepsHeading.map((vstep) => (
          <Step
            onClick={() => setactiveStep(vstep.id)}
            active={activeStep === vstep.id}
            key={vstep.stepName}>
            <Icon name="tasks" />
            <Step.Content>
              <Step.Title>{vstep.stepName}</Step.Title>
              <Step.Description> {vstep.title} </Step.Description>
            </Step.Content>
          </Step>
        ))}
      </Step.Group> */}
    </>
  );
};

interface Step1Props {
  setactiveStep: React.Dispatch<React.SetStateAction<number>>;
}

const Step1: React.FC<Step1Props> = ({ setactiveStep }) => {
  const [dataSource, setDataSource] = useState(bcGridData);
  const [selectRow, setSelectRow] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({
    description: "",
    problemCategory: "",
    problemSubCategory: "",
    problemArea: "",
  });
  const [activeIndex, setActiveIndex] = useState(0);

  const handleView = (e: any) => {
    const data = e.row.data;
    setSelectRow(true);
    const selectData = {
      description: data.description,
      problemCategory: data.problemCategory,
      problemSubCategory: data.problemSubCategory,
      problemArea: data.problemArea,
    };
    setSelectedRowData(selectData);
  };

  const handleClick = (e: any, titleProps: any) => {
    const { index } = titleProps;
    //  const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    setActiveIndex(newIndex);
  };

  return (
    <>
      {/* <Header as="h3" dividing color={"brown"}>
        Identify the issue
      </Header> */}
      <div className='ui warning message' style={{ display: "block" }}>
        <p>
          Provide correct detail information about yourself. Please note that
          your data is protected and can only be viewed by the users you want to
          view it.
        </p>
      </div>

      <Header size='small' content='Select Criteria' dividing color='brown' />

      {/* <Accordion as={Form} panels={panels} /> */}
      <Accordion>
        <Accordion.Title
          active={activeIndex === 1}
          index={1}
          onClick={handleClick}>
          <Label color='blue'>
            <Icon name='dropdown' />
            Filter Setup
          </Label>
          {/* What is a dog? */}
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 1}>
          <Form className='ui form' autoComplete='off'>
            <Form.Group widths='equal'>
              <Form.Select
                options={optionsProblemArea}
                placeholder='Select an item'
                label='Select Problem Area'
                name='problemArea'
              />
              <Form.Select
                options={optionsProblemCategory}
                placeholder='Select an item'
                label='Problem Category'
                name='problemCategory'
              />
              <Form.Select
                options={optionsProblemSubCategory}
                placeholder='Select an item'
                label='Problem Sub Category'
                name='problemSubCategory'
              />
            </Form.Group>
            <Form.Group widths='equal'>
              <Button
                floated='right'
                color='brown'
                inverted
                type='submit'
                content='Update Personal Data'>
                Search
              </Button>
            </Form.Group>
          </Form>
        </Accordion.Content>
      </Accordion>

      <Header size='small' content='Search Results' dividing color='brown' />

      <Grid>
        <Grid.Column width='10'>
          <DataGrid
            id='grdResources'
            dataSource={dataSource && dataSource}
            className='dx-datagrid dx-row-alt'
            hoverStateEnabled={true}
            columnAutoWidth={true}
            activeStateEnabled={true}
            columnResizingMode='widget'
            allowColumnResizing={true}
            // onRowUpdating={onRowUpdating}
            columnHidingEnabled={true}
            // onToolbarPreparing={handleToolPreparing}
          >
            <RowDragging allowReordering={true} />
            <FilterPanel visible={true} />
            <SearchPanel visible={true} />
            <FilterRow visible={true} />
            <Paging defaultPageSize={10} />
            <Pager
              showPageSizeSelector={true}
              allowedPageSizes={[10, 20, 50, 100]}
              showInfo={true}
            />
            <Grouping contextMenuEnabled={true} />
            <GroupPanel visible={true} />
            <Editing
              mode='popup'
              useIcons={true}
              allowUpdating={true}
              allowDeleting={true}
              allowAdding={true}
            />
            <Popup
              title='Resources'
              showTitle={true}
              width={40}
              height={30}></Popup>
            <Export
              enabled={true}
              fileName='Resources'
              allowExportSelectedData={true}
            />

            <Column type='buttons'>
              {/* <DXButton
                text="details"
                onClick={handleView}
                // cssClass={"edit-btn"}
              >
                <Label content="details" />
              </DXButton> */}
              <DXButton
                onClick={handleView}
                cssClass={"bc__dx__label"}
                text='details'></DXButton>
            </Column>

            <Column
              dataField='id'
              allowEditing={false}
              visible={false}></Column>
            <Column dataField='description'>
              <RequiredRule />
            </Column>
            <Column dataField='problemCategory'>
              <RequiredRule />
            </Column>
            <Column dataField='problemSubCategory'>
              <RequiredRule />
            </Column>
          </DataGrid>
        </Grid.Column>
        <Grid.Column width='6'>
          <div
            style={{
              background: "hsl(210, 36%, 96%)",
              padding: "10px",
            }}>
            {selectRow ? (
              <>
                <CADLabel
                  label='Description'
                  value={selectedRowData.description}
                />

                <CADLabel
                  label='Problem Area'
                  value={selectedRowData.problemArea}
                />
                <CADLabel
                  label='Problem Category'
                  value={selectedRowData.problemCategory}
                />
                <CADLabel
                  label='Problem Sub Category'
                  value={selectedRowData.problemCategory}
                />
                <Button
                  content='Find a BSP'
                  color='brown'
                  labelPosition='left'
                  icon='search'
                  onClick={() => setactiveStep(2)}
                />
              </>
            ) : (
              <div>click on the view button to see bsp details</div>
            )}
          </div>
        </Grid.Column>
      </Grid>
    </>
  );
};

interface Step2Props {
  setactiveStep: React.Dispatch<React.SetStateAction<number>>;
}
const Step2: React.FC<Step2Props> = ({ setactiveStep }) => {
  const [dataSource, setDataSource] = useState(businessClinicStep2);
  const [detailsCardActive, setdetailsCardActive] = useState(false);

  const handleDetailsClick = (e: any) => {
    e.preventDefault();
    setdetailsCardActive(true);
  };

  return (
    <div>
      <div>
        <DataGrid
          id='grdResources'
          dataSource={dataSource && dataSource}
          className='dx-datagrid dx-row-alt'
          hoverStateEnabled={true}
          columnAutoWidth={true}
          activeStateEnabled={true}
          columnResizingMode='widget'
          allowColumnResizing={true}
          // onRowUpdating={onRowUpdating}
          columnHidingEnabled={true}
          // onToolbarPreparing={handleToolPreparing}
        >
          <RowDragging allowReordering={true} />
          <FilterPanel visible={true} />
          <SearchPanel visible={true} />
          <FilterRow visible={true} />
          <Paging defaultPageSize={10} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={[10, 20, 50, 100]}
            showInfo={true}
          />
          <Grouping contextMenuEnabled={true} />
          <GroupPanel visible={true} />
          <Editing
            mode='popup'
            useIcons={true}
            allowUpdating={true}
            allowDeleting={true}
            allowAdding={true}
          />
          <Popup
            title='Resources'
            showTitle={true}
            width={40}
            height={30}></Popup>
          <Export
            enabled={true}
            fileName='Resources'
            allowExportSelectedData={true}
          />

          <Column dataField='oid' allowEditing={false} visible={false}></Column>
          <Column dataField='name'>
            <RequiredRule />
          </Column>
          <Column dataField='report'>
            <RequiredRule />
          </Column>
          <Column dataField='specialization'>
            <RequiredRule />
          </Column>
          <Column type='buttons'>
            <DXButton
              text='Details'
              // onClick={handleView}
              // cssClass={"edit-btn"}
            >
              <Label as='a' onClick={handleDetailsClick} content='details' />
            </DXButton>
            <DXButton
              // text="Create Ticket"
              // onClick={handleView}
              // cssClass={"edit-btn"}
              text='Create Ticket'>
              <Label
                as='a'
                onClick={() => setactiveStep(3)}
                content='create ticket'
              />
            </DXButton>
          </Column>
        </DataGrid>

        {detailsCardActive && (
          <>
            <Header
              size='large'
              content='Selected BSP Detail'
              dividing
              color='brown'
            />
            <ContactCard />
          </>
        )}
      </div>
    </div>
  );
};

interface Step3Props {
  setactiveStep: React.Dispatch<React.SetStateAction<number>>;
}

const Step3: React.FC<Step3Props> = ({ setactiveStep }) => {
  return (
    <div>
      <SupportTicketForm />
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          // floated="right"
          content='next'
          labelPosition='left'
          icon='caret square right outline'
          onClick={() => setactiveStep(4)}
          color='brown'
        />
      </div>
    </div>
  );
};

const Step4 = () => {
  return (
    <div>
      <div
        style={{
          background: "hsl(210, 36%, 96%)",
          padding: "10px",
        }}>
        <CADLabel
          label='Follow up'
          value='Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum repudiandae impedit rem quos aliquam doloribus fugit in dolores molestiae esse? '
        />

        <CADLabel
          label='Messages'
          value='Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum repudiandae impedit rem quos aliquam doloribus fugit in dolores molestiae esse?'
        />

        <Button
          content='add/view Comment'
          labelPosition='left'
          icon='edit'
          as={Link}
          to={`Activities/348d7c86-3306-4438-eaa4-08d97407645e`}
          color='brown'
        />
      </div>
    </div>
  );
};

import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../api/agent";
import agentExpress from "../api/agentExpress";
//import { Activity, ActivityFormValues } from "../models/activity";
import { Resource, ResourceFormValues } from "../models/resource";
import { format } from "date-fns";
import { store } from "./store";
import { Profile } from "../models/profile";
import { Pagination, PagingParams } from "../models/pagination";
import axios from "axios";
import { CompanyInfo, CompanyInfoFormValues } from "../models/companyInfo";
export default class CompanyStore {
  companyinfoData: CompanyInfo[] = [];
  // resourceV2Data: Resource[] = [];
  //comments: ChatComment[] = [];
  companyinfoRegistry = new Map<string, CompanyInfo>();
  selectedCompanyInfo: CompanyInfo | undefined = undefined;
  editMode = false;
  loading = false;
  loadingInitial = false;
  pagination: Pagination | null = null;
  pagingParams = new PagingParams();
  predicate = new Map().set("all", true);
  baseAPI = process.env.REACT_APP_API_URL;

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.predicate.keys(),
      () => {
        this.pagingParams = new PagingParams();
        this.companyinfoRegistry.clear();
        this.loadCompanyInfos();
      }
    );
  }

  setPagingParams = (pagingParams: PagingParams) => {
    pagingParams.pageNumber = 1;
    pagingParams.pageSize = 500;
    this.pagingParams = pagingParams;
  };

  setPredicate = (predicate: string, value: string | Date) => {
    const resetPredicate = () => {
      this.predicate.forEach((value, key) => {
        if (key !== "dateUploaded") this.predicate.delete(key);
      });
    };
    switch (predicate) {
      case "all":
        resetPredicate();
        this.predicate.set("all", true);
        break;
      case "isGoing":
        resetPredicate();
        this.predicate.set("isGoing", true);
        break;
      case "isHost":
        resetPredicate();
        this.predicate.set("isHost", true);
        break;
      case "dateUploaded":
        this.predicate.delete("dateUploaded");
        this.predicate.set("dateUploaded", value);
    }
  };

  get axiosParams() {
    const params = new URLSearchParams();
    // params.append("pageNumber", this.pagingParams.pageNumber.toString());
    // params.append("pageSize", this.pagingParams.pageSize.toString());
    params.append("pageNumber", "1");
    params.append("pageSize", "500");
    this.predicate.forEach((value, key) => {
      if (key === "dateUploaded") {
        params.append(key, (value as Date).toISOString());
      } else {
        params.append(key, value);
      }
    });
    return params;
  }

  get companyInfosByDate() {
    return Array.from(this.companyinfoRegistry.values()).sort(
      (a, b) => a.dateUploaded!.getTime() - b.dateUploaded!.getTime()
    );
  }

  get groupedCompanyInfos() {
    return Object.entries(
      this.companyInfosByDate.reduce((companyInfos, companyinfo) => {
        const date = format(companyinfo.dateUploaded!, "dd MMM yyyy");
        companyInfos[date] = companyInfos[date]
          ? [...companyInfos[date], companyinfo]
          : [companyinfo];
        return companyInfos;
      }, {} as { [key: string]: CompanyInfo[] })
    );
  }

  // loadActivities = async () => {
  //   this.loadingInitial = true;
  //   try {
  //     const result = await agent.Activities.list(this.axiosParams);
  //     result.data.forEach((activity) => {
  //       this.setActivity(activity);
  //     });
  //     this.setPagination(result.pagination);
  //     this.setLoadingInitial(false);
  //   } catch (error) {
  //     console.log(error);
  //     this.setLoadingInitial(false);
  //   }
  // };

  loadCompanyInfos = async () => {
    this.loadingInitial = true;
    try {
      console.log("1. LOG = " + this.axiosParams);
      const result = await agent.CompanyInfos.list(this.axiosParams);
      //this.resourceData.push(result);
      //console.log("1. result from loadResources()", result);
      // result.map(
      //   (resource) =>
      //     //this.resourceData.push(resource)
      //     this.setResourceData(resource)
      // );
      // console.log("2. result from loadResources()");
      this.companyinfoData = result.data;
      // console.log(this.resourceData);
      // result.data.forEach((resource) => {
      //   this.setResourceData(resource);
      // });
      //this.setPagination(result.pagination);
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
    }
  };

  loadCompanyInfosDashboard = async () => {
    this.loadingInitial = true;
    try {
      const result = await agentExpress.CompanyInfos.list();
      this.companyinfoData = result;
      console.log("1. In loadResourcesDashboard: result", result);
      console.log(
        "2. In loadResourcesDashboard: this.resourceData",
        this.companyinfoData
      );
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
    }
  };

  setPagination = (pagination: Pagination) => {
    this.pagination = pagination;
  };

  loadCompanyInfo = async (id: string) => {
    let companyinfo = this.getCompanyInfo(id);
    if (companyinfo) {
      this.selectedCompanyInfo = companyinfo;
      return companyinfo;
    } else {
      this.loadingInitial = true;
      try {
        companyinfo = await agent.CompanyInfos.details(id);
        this.setCompanyInfoData(companyinfo);
        runInAction(() => {
          this.selectedCompanyInfo = companyinfo;
        });
        this.setLoadingInitial(false);
        return companyinfo;
      } catch (error) {
        console.log(error);
        this.setLoadingInitial(false);
      }
    }
  };
  private setCompanyInfoData = (companyinfo: CompanyInfo) => {
    //resource.dateUploaded = new Date(resource.dateUploaded!);
    this.companyinfoData.push(companyinfo);
  };

  private setCompanyInfo = (companyinfo: CompanyInfo) => {
    companyinfo.dateUploaded = new Date(companyinfo.dateUploaded!);
    this.companyinfoRegistry.set(companyinfo.companyOID, companyinfo);
  };

  private getCompanyInfo = (id: string) => {
    return this.companyinfoRegistry.get(id);
  };

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  createCompanyInfo = async (companyinfo: CompanyInfoFormValues) => {
    try {
      await agent.CompanyInfos.create(companyinfo);
      const newCompanyInfo = new CompanyInfo(companyinfo);
      //   newResource.hostUsername = user!.username;
      //   newResource.attendees = [attendee];
      this.setCompanyInfo(newCompanyInfo);
      runInAction(() => {
        this.selectedCompanyInfo = newCompanyInfo;
      });
    } catch (error) {
      console.log(error);
    }
  };

  updateCompanyInfo = async (companyinfo: CompanyInfoFormValues) => {
    try {
      await agent.CompanyInfos.update(companyinfo);
      runInAction(() => {
        if (companyinfo.companyOID) {
          let updatedCompanyInfo = {
            ...this.getCompanyInfo(companyinfo.companyOID),
            ...companyinfo,
          };
          this.companyinfoRegistry.set(
            companyinfo.companyOID,
            updatedCompanyInfo as CompanyInfo
          );
          this.selectedCompanyInfo = updatedCompanyInfo as CompanyInfo;
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  deleteCompanyInfo = async (id: string) => {
    this.loading = true;
    try {
      await agent.CompanyInfos.delete(id);
      runInAction(() => {
        this.companyinfoRegistry.delete(id);
        this.loading = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  //   updateAttendance = async () => {
  //     const user = store.userStore.user;
  //     this.loading = true;
  //     try {
  //       await agent.Activities.attend(this.selectedActivity!.id);
  //       runInAction(() => {
  //         if (this.selectedActivity?.isGoing) {
  //           this.selectedActivity.attendees =
  //             this.selectedActivity.attendees?.filter(
  //               (a) => a.username !== user?.username
  //             );
  //           this.selectedActivity.isGoing = false;
  //         } else {
  //           const attendee = new Profile(user!);
  //           this.selectedActivity?.attendees?.push(attendee);
  //           this.selectedActivity!.isGoing = true;
  //         }
  //         this.activityRegistry.set(
  //           this.selectedActivity!.id,
  //           this.selectedActivity!
  //         );
  //       });
  //     } catch (error) {
  //       console.log(error);
  //     } finally {
  //       runInAction(() => (this.loading = false));
  //     }
  //   };

  cancelCompanyInfoToggle = async () => {
    this.loading = true;
    try {
      //await agent.Resources.attend(this.selectedResource!.resourceGUID);
      runInAction(() => {
        // this.selectedResource!.isCancelled =
        //   !this.selectedResource?.isCancelled;
        this.companyinfoRegistry.set(
          this.selectedCompanyInfo!.companyOID,
          this.selectedCompanyInfo!
        );
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

  //   updateAttendeeFollowing = (username: string) => {
  //     this.activityRegistry.forEach((activity) => {
  //       activity.attendees.forEach((attendee) => {
  //         if (attendee.username === username) {
  //           attendee.following
  //             ? attendee.followersCount--
  //             : attendee.followersCount++;
  //           attendee.following = !attendee.following;
  //         }
  //       });
  //     });
  //   };

  clearSelectedCompanyInfo = () => {
    this.selectedCompanyInfo = undefined;
  };
}

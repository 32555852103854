import React, { useEffect } from "react";
import { Segment, Container, Divider } from "semantic-ui-react";

import Axios from "axios";
import { Popup, ToolbarItem } from "devextreme-react/popup";
import BusinessAreaPopup from "../ecoforms/BusinessAreaPopup";

import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import "devextreme-react/text-area";
import { Item } from "devextreme-react/form";
//import { baseAPI } from "../../../api/APIEndpoints";
import DataGrid, {
  FilterPanel,
  FilterRow,
  SearchPanel,
  Button,
  Paging,
  Lookup,
  Position,
  Editing,
  Export,
  Pager,
  Grouping,
  GroupPanel,
  RowDragging,
  Column,
  RequiredRule,
  PatternRule,
  EmailRule,
  AsyncRule,
} from "devextreme-react/data-grid";
// import { useSelector } from "react-redux";
import CustomStore from "devextreme/data/custom_store";
import "whatwg-fetch";
import { store, useStore } from "../../../app/stores/store";
import BusinessAreaForm from "../ecoforms/BusinessAreaForm";

export const BusinessArea = () => {
  const [dataSource, setDataSource] = React.useState({});
  const [businessAreaOID, setBusinessAreaOID] = React.useState("GRID");
  const [rowData, setRowData]: any = React.useState({});
  const { businessAreaStore } = useStore();
  const { loading, setLoadingInitial, loadingInitial } = businessAreaStore;
  const [mode, setMode] = React.useState("GRID");
  const [onVisible, setOnVisible] = React.useState(false);

  const baseAPI = process.env.REACT_APP_API_URL; // "http://localhost:5000/api";

  const jwt = store.commonStore.token;
  function handleErrors(response: any) {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  }

  const customDataSource: any = new CustomStore({
    // key: "countryOID",
    load: () => {
      return fetch(`${baseAPI}/EcoBusinessArea`, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      })
        .then(handleErrors)
        .then((response) => response.json())
        .then((response) => {
          return {
            data: response.result.data,
            totalCount: response.count,
            summary: response.summary,
            groupCount: response.count,
          };
        });
    },
    insert: (values: any) => {
      return fetch(`${baseAPI}/EcoBusinessArea`, {
        method: "POST",
        body: JSON.stringify(values),
        headers: {
          Authorization: `Bearer ${jwt}`,
          "Content-Type": "application/json",
        },
      }).then(handleErrors);
    },
    remove: (key) => {
      return fetch(`${baseAPI}/EcoBusinessArea`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${jwt}`,
          "Content-Type": "application/json",
        },
      }).then(handleErrors);
    },
    update: (values) => {
      return values;
    },
  });

  const handleEdit = (e: any) => {
    // const data = e.row.data;
    setRowData((data: any) => {
      return { ...e.row.result.data };
    });
    setMode("FORM-EDIT");
    // console.log(rowData);
  };

  const handleAddNew = () => {
    setOnVisible(true);
  };

  const handleView = (e: any) => {
    // const data = e.row.data;
    setBusinessAreaOID(e.row.data.businessAreaOID);
    setLoadingInitial(true);
    // console.log(e.row.data);
    setMode("FORM-EDIT");
    // console.log(rowData);
  };

  function onToolbarPreparing(e: any) {
    let toolbarItems = e.toolbarOptions.items;

    // customize addRow toolbar button
    for (let i = 0; i < toolbarItems.length; i++) {
      let item = toolbarItems[i];
      if (item.name === "addRowButton") {
        item.options.onClick = handleAddNew;
        break;
      }
    }
  }
  return mode === "GRID" ? (
    <Segment>
      <h1>Business Area</h1>
      <div className="ui warning message">
        <div className="header">Manage Business Area.</div>
        <p>Create and manage Business Area.</p>
      </div>
      <div className="ui divider" title="List of Business Area"></div>
      <div>
        <DataGrid
          id="[$GridID]"
          dataSource={customDataSource}
          className="dx-datagrid dx-row-alt"
          hoverStateEnabled={true}
          onToolbarPreparing={onToolbarPreparing}
          columnAutoWidth={true}
          activeStateEnabled={true}
          columnResizingMode="widget"
          allowColumnResizing={true}
          showBorders={true}
          columnHidingEnabled={true}>
          {/* <RowDragging allowReordering={true} /> */}
          <FilterPanel visible={true} />
          <SearchPanel visible={true} />
          <FilterRow visible={true} />
          <Paging defaultPageSize={20} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={[2, 10, 20, 50, 100]}
            showInfo={true}
          />
          <Grouping contextMenuEnabled={true} />
          <GroupPanel visible={true} />
          <Export
            enabled={true}
            fileName={"Assign_Task_to_Role"}
            allowExportSelectedData={true}
          />
          <Editing
            mode="popup"
            useIcons={true}
            allowUpdating={true}
            allowDeleting={true}
            allowAdding={true}
          />
          <Column type="buttons">
            <Button text="View" onClick={handleView} cssClass={"edit-btn"} />
          </Column>
          <Column dataField="businessAreaOID"></Column>

          <Column dataField="description"></Column>
          <Column dataField="status"></Column>

          <Column dataField="businessAreaID"></Column>

          <Column dataField="businessSectorID"></Column>
        </DataGrid>

        {/* <Popup
          visible={onVisible}
          showCloseButton={false}
          width={700}
          minHeight={"auto"}
          height={600}>
          <BusinessAreaPopup
            setOnVisible={() => setOnVisible(false)}
            businessSectorOID={0}
          />
        </Popup> */}
      </div>
    </Segment>
  ) : (
    <BusinessAreaForm
      setMode={() => setMode("GRID")}
      businessAreaOID={businessAreaOID}
    />
  );
};

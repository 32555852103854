export const resourceOptions = [
  { text: "ALL", value: "ALL" },
  { text: "BSP", value: "BSP" },
  { text: "MSME", value: "MSME" },
];

export const optionsGender = [
  { key: "m", text: "Male", value: "male" },
  { key: "f", text: "Female", value: "female" },
  { key: "o", text: "Other", value: "other" },
];
export const optionsAgeRange = [
  { key: "a", text: "18 to 35 years", value: "18 to 35 years" },
  { key: "b", text: "Above 35 Years", value: "Above 35 Years" },
];

export const optionsMaritalStatus = [
  { key: "a", text: "Married", value: "Married" },
  { key: "b", text: "Single", value: "Single" },
];
export const optionsState = [
  { key: "1", text: "NONE", value: "NONE" },
  { key: "2", text: "", value: "" },
  { key: "3", text: "Adamawa", value: "Adamawa" },
  { key: "4", text: "Akwa Ibom", value: "Akwa Ibom" },
  { key: "5", text: "Anambra", value: "Anambra" },
  { key: "6", text: "Bauchi", value: "Bauchi" },
  { key: "7", text: "Bayelsa", value: "Bayelsa" },
  { key: "8", text: "Benue", value: "Benue" },
  { key: "9", text: "Borno", value: "Borno" },
  { key: "10", text: "Cross River", value: "Cross River" },
  { key: "11", text: "Delta", value: "Delta" },
  { key: "12", text: "Ebonyi", value: "Ebonyi" },
  { key: "13", text: "Edo", value: "Edo" },
  { key: "14", text: "Ekiti", value: "Ekiti" },
  { key: "15", text: "Enugu", value: "Enugu" },
  { key: "16", text: "FCT", value: "FCT" },
  { key: "17", text: "Gombe", value: "Gombe" },
  { key: "18", text: "Imo", value: "Imo" },
  { key: "19", text: "Jigawa", value: "Jigawa" },
  { key: "20", text: "Kaduna", value: "Kaduna" },
  { key: "21", text: "Kano", value: "Kano" },
  { key: "22", text: "Katsina", value: "Katsina" },
  { key: "23", text: "Kebbi", value: "Kebbi" },
  { key: "24", text: "Kogi", value: "Kogi" },
  { key: "25", text: "Kwara", value: "Kwara" },
  { key: "26", text: "Lagos", value: "Lagos" },
  { key: "27", text: "Nassarawa", value: "Nassarawa" },
  { key: "28", text: "Niger", value: "Niger" },
  { key: "29", text: "Ogun", value: "Ogun" },
  { key: "30", text: "Ondo", value: "Ondo" },
  { key: "31", text: "Osun", value: "Osun" },
  { key: "32", text: "Oyo", value: "Oyo" },
  { key: "33", text: "Plateau", value: "Plateau" },
  { key: "34", text: "Rivers", value: "Rivers" },
  { key: "35", text: "Sokoto", value: "Sokoto" },
  { key: "36", text: "Taraba", value: "Taraba" },
  { key: "37", text: "Yobe", value: "Yobe" },
  { key: "38", text: "Zamfara", value: "Zamfara" },
];
export const optionsYesNo = [
  { key: "a", text: "Yes", value: "Yes" },
  { key: "b", text: "No", value: "No" },
];
export const optionsBSPServices = [
  { key: "a", text: "None", value: "None" },
  { key: "b", text: "Needs Analysis", value: "Needs Analysis" },
];
export const optionsBusinessLinkage = [
  { key: "a", text: "None", value: "None" },
  { key: "b", text: "Access to Competence", value: "Access to Competence" },
  { key: "c", text: "Access to Market", value: "Access to Market" },
  { key: "d", text: "Access to Finance", value: "Access to Finance" },
];
export const optionsActivityType = [
  { key: "a", text: "Event", value: "Event" },
  { key: "b", text: "Public Notice", value: "PubNote" },
  { key: "c", text: "Support Ticket", value: "Support" },
  { key: "d", text: "Advertisement", value: "Advert" },
  { key: "e", text: "Opinion Poll", value: "Poll" },
  { key: "f", text: "Open Conversation", value: "OpenConv" },
  { key: "g", text: "Business Opportunity", value: "BizOpp" },
  { key: "h", text: "Quiz", value: "Quiz" },
  { key: "h", text: "Business Plan", value: "BizPlan" },
];

export const optionsProblemArea = [
  { key: "a", text: "Access to market", value: "Market" },
];
export const optionsProblemCategory = [
  { key: "a", text: "Problem Category", value: "Poultry" },
];
export const optionsProblemSubCategory = [
  { key: "a", text: "Layers", value: "Layers" },
];
export const issues = [
  {
    description: "hello",
    problemArea: "problemArea",
    problemCategory: "problemCategory",
    problemSubCategory: "problemSubCategory",
  },
  {
    description: "description",
    problemArea: "problemArea",
    problemCategory: "problemCategory",
    problemSubCategory: "problemSubCategory",
  },
  {
    description: "description",
    problemArea: "problemArea",
    problemCategory: "problemCategory",
    problemSubCategory: "problemSubCategory",
  },
];

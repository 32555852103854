import { Route, Routes } from "react-router-dom";
import { Groups } from "../../features/create/others/Group";
import { JobLinkage } from "../../features/create/others/JobLinkage";
import { Advertisement } from "../../features/create/posts/Advertisement";
import { BusinessOpportunity } from "../../features/create/posts/BusinessOpportunity";
import Markets from "../../features/create/posts/Markets";
// import { OpenConversation } from "../../features/views/OpenConversation";
import { OpenConversation } from "../../features/create/posts/OpenConversation";
import PaymentTerms from "../../features/create/posts/PaymentTerms";
import { Poll } from "../../features/create/posts/Poll";
import { PublicNotice } from "../../features/create/posts/PublicNotice";
import { Quiz } from "../../features/create/posts/Quiz";
// import { SupportTicket } from "../../features/create/posts/SupportTicket/SupportTicket";
import { SupportTicketV2 } from "../../features/create/posts/SupportTicket/SupportTicketV2";
import { Survey } from "../../features/create/posts/Survey";
// import { ProductandServices } from "../../../src/features/create/posts/ProductandServices";

export default function CreateRoutes() {
  return (
    <Routes>
      <Route path={`paymentterms`} element={<PaymentTerms />} />
      <Route path={`markets`} element={<Markets />} />
      <Route path={`supportticket`} element={<SupportTicketV2 />} />
      <Route path={`openconversation`} element={<OpenConversation />} />
      <Route path={`businessopportunity`} element={<BusinessOpportunity />} />
      <Route path={`advertisement`} element={<Advertisement />} />
      <Route path={`publicnotice`} element={<PublicNotice />} />
      <Route path={`quiz`} element={<Quiz />} />
      <Route path={`poll`} element={<Poll />} />
      <Route path={`survey`} element={<Survey />} />
      <Route path={`joblinkage`} element={<JobLinkage />} />
      <Route path={`group`} element={<Groups />} />
      {/* <Route path={`ProductandServices`} element={<ProductandServices />} /> */}
    </Routes>
  );
}

import React from "react";
import { Route, Routes } from "react-router-dom";
import AppDashboard from "../../features/activities/dashboard/AppDashboard";
import PrivateRoute from "../layout/PrivateRoute";
import CreateRoutes from "./createRoutes";
import EcosystemRoutes from "./ecosystemRoutes";
import ViewRoutes from "./viewRoutes";

const MergeHubRoutes = () => {
  return (
    <Routes>
      <Route
        path='gateway'
        element={
          <PrivateRoute>
            <AppDashboard />
          </PrivateRoute>
        }
      />
      <Route
        path='view/*'
        element={
          <PrivateRoute>
            <ViewRoutes />
          </PrivateRoute>
        }
      />
      <Route
        path='ecosystem/*'
        element={
          <PrivateRoute>
            <EcosystemRoutes />
          </PrivateRoute>
        }
      />

      <Route
        path='create/*'
        element={
          <PrivateRoute>
            <CreateRoutes />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};


export default MergeHubRoutes;

import React from "react";
import { Segment } from "semantic-ui-react";

export const Groups = () => {
  return (
    <Segment>
      <h1>Groups</h1>
      <div className="ui warning message">
        <div className="header">Manage Groups.</div>
        <p>Create and manage user/member groups.</p>
      </div>
      <div className="ui divider" title="List of Resources"></div>
    </Segment>
  );
};

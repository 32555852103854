import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../api/agent";
//import agentExpress from "../api/agentExpress";
//import { Activity, ActivityFormValues } from "../models/activity";
// import { PersonalData, PersonalDataFormValues } from "../models/personalData";
import { format } from "date-fns";
//import { store } from "./store";
import { Profile } from "../models/profile";
import { Pagination, PagingParams } from "../models/pagination";
import { MsmeBusinessData, MsmeBusinessDataFormValues } from "../models/msmeBusinessData";
//import axios from "axios";

export default class MsmeBusinessInfoStore {
  MsmeBusinessDataData: MsmeBusinessData [] = [];
  msmeBusinessDataRegistry = new Map<string, MsmeBusinessData>();
  selectedMsmeBusinessData: MsmeBusinessData | undefined = undefined;
  editMode = false;
  loading = false;
  loadingInitial = false;
  pagination: Pagination | null = null;
  pagingParams = new PagingParams();
  predicate = new Map().set("all", true);
  baseAPI = process.env.REACT_APP_API_URL;

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.predicate.keys(),
      () => {
        this.pagingParams = new PagingParams();
        this.msmeBusinessDataRegistry.clear();
        this.loadBusinessDatas();
      }
    );
  }

  setPagingParams = (pagingParams: PagingParams) => {
    pagingParams.pageNumber = 1;
    pagingParams.pageSize = 500;
    this.pagingParams = pagingParams;
  };

  setPredicate = (predicate: string, value: string | Date) => {
    const resetPredicate = () => {
      this.predicate.forEach((value, key) => {
        if (key !== "dateUploaded") this.predicate.delete(key);
      });
    };
    switch (predicate) {
      case "all":
        resetPredicate();
        this.predicate.set("all", true);
        break;
      case "isGoing":
        resetPredicate();
        this.predicate.set("isGoing", true);
        break;
      case "isHost":
        resetPredicate();
        this.predicate.set("isHost", true);
        break;
      case "dateUploaded":
        this.predicate.delete("dateUploaded");
        this.predicate.set("dateUploaded", value);
    }
  };

  get axiosParams() {
    const params = new URLSearchParams();
    
    params.append("pageNumber", "1");
    params.append("pageSize", "500");
    this.predicate.forEach((value, key) => {
      if (key === "dateUploaded") {
        params.append(key, (value as Date).toISOString());
      } else {
        params.append(key, value);
      }
    });
    return params;
  }

 



  loadBusinessDatas = async () => {
    this.loadingInitial = true;
    try {
      console.log("1. LOG = " + this.axiosParams);
      const result = await agent.MsmeBusinessDatas.list(this.axiosParams);
     
      this.MsmeBusinessDataData = result.data;
   
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
    }
  };

  

  setPagination = (pagination: Pagination) => {
    this.pagination = pagination;
  };

  loadMsmeBusinessData = async (vUserGUID: string) => {
    let msmeBusinessData = this.getBusinessData(vUserGUID);
    
      this.loadingInitial = true;
      try {
        msmeBusinessData = await agent.MsmeBusinessDatas.detailsByUserGUID(vUserGUID);
        console.log("4. agent.BusinessDatas.detailsByUserGUID", msmeBusinessData);
        this.setBusinessDataData(msmeBusinessData);
        runInAction(() => {
          this.selectedMsmeBusinessData = msmeBusinessData;
        });
        this.setLoadingInitial(false);
        return msmeBusinessData;
      } catch (error) {
        console.log(error);
        this.setLoadingInitial(false);
      }
    // }
  };
  private setBusinessDataData = (msmeBusinessData: MsmeBusinessData) => {
    this.MsmeBusinessDataData.push(msmeBusinessData);
  };

  private setBusinessData = (msmeBusinessData: MsmeBusinessData) => {
    this.msmeBusinessDataRegistry.set(msmeBusinessData.userGUID, msmeBusinessData);
  };

  private getBusinessData = (id: string) => {
    return this.msmeBusinessDataRegistry.get(id);
  };

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  createMsmeBusinessData = async (msmeBusinessData: MsmeBusinessDataFormValues ) => {
    try {
      console.log("2. createMsmeBusinessData", msmeBusinessData);
      await agent.MsmeBusinessDatas.create(msmeBusinessData);
      const newBusinessData = new MsmeBusinessData(msmeBusinessData);
      this.setBusinessData(newBusinessData);
      runInAction(() => {
        this.selectedMsmeBusinessData = newBusinessData;
      });
    } catch (error) {
      console.log(error);
    }
  };

  updateMsmeBusinessData = async (msmeBusinessData: MsmeBusinessDataFormValues ) => {
    try {
      await agent.MsmeBusinessDatas.update(msmeBusinessData);
      runInAction(() => {
        if (msmeBusinessData.userGUID) {
          let updatedBusinessData = {
            ...this.getBusinessData(msmeBusinessData.userGUID),
            ...msmeBusinessData,
          };
          this.msmeBusinessDataRegistry.set(
            msmeBusinessData.userGUID,
            updatedBusinessData as MsmeBusinessData
          );
          this.selectedMsmeBusinessData = updatedBusinessData as MsmeBusinessData;
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  deleteBusinessData = async (id: string) => {
    this.loading = true;
    try {
      await agent.MsmeBusinessDatas.delete(id);
      runInAction(() => {
        this.msmeBusinessDataRegistry.delete(id);
        this.loading = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.loading = false;
      });
    }
  };



  cancelBusinessDataToggle = async () => {
    this.loading = true;
    try {
      runInAction(() => {
        
        this.msmeBusinessDataRegistry.set(
          this.selectedMsmeBusinessData!.userGUID,
          this.selectedMsmeBusinessData!
        );
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

 

  clearSelectedBusinessData = () => {
    this.selectedMsmeBusinessData = undefined;
  };
}

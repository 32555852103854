import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Header, Segment } from "semantic-ui-react";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { useStore } from "../../../app/stores/store";
import { v4 as uuid } from "uuid";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import MyTextInput from "../../../app/common/form/MyTextInput";
import MyTextArea from "../../../app/common/form/MyTextArea";
import MySelectInput from "../../../app/common/form/MySelectInput";
import { resourceOptions } from "../../../app/common/options/categoryOptions";
import MyDateInput from "../../../app/common/form/MyDateInput";
//import { ActivityFormValues } from "../../../app/models/activity";
import { ResourceFormValues } from "../../../app/models/resource";

export default observer(function ResourceForm({
  resourceGUID,
  setMode ,
}: {
  resourceGUID?: string  ;
  setMode?: any  ;
}) {
  const navigate = useNavigate();
  const { resourceStore } = useStore();
  const { createResource, updateResource, loadResource, loadingInitial } =
    resourceStore;
  // const { resourceGUID } = useParams<{ resourceGUID: string }>();

  const [resource, setResource] = useState<ResourceFormValues>(
    new ResourceFormValues()
  );

  const validationSchema = Yup.object({
    title: Yup.string().required("The resource title is required"),
    description: Yup.string().required("The resource description is required"),
    category: Yup.string().required("The resource category is required"),
    //date: Yup.string().required("Date is required").nullable(),
    source: Yup.string().required("The resource source is required"),
    code: Yup.string().required("The resource code is required"),
  });

  useEffect(() => {
    if (resourceGUID)
      loadResource(resourceGUID).then((resource) => {
        console.log("resource", resource);
        setResource(new ResourceFormValues(resource));
      });
  }, [resourceGUID, loadResource]);

  function handleFormSubmit(resource: ResourceFormValues) {
    if (!resource.resourceGUID) {
      let newResource = {
        ...resource,
        resourceGUID: uuid(),
      };
      createResource(newResource).then(() =>
        navigate(`/resources/${newResource.resourceGUID}`)
      );
    } else {
      updateResource(resource).then(() =>
        navigate(`/resources/${resource.resourceGUID}`)
      );
    }
  }

  if (loadingInitial) return <LoadingComponent content='Loading resource...' />;

  return (
    <Segment clearing>
      <Button
        // disabled={isSubmitting || !dirty || !isValid}
        // loading={isSubmitting}
        floated='left'
        positive
        type='button'
        content='Back'
        onClick={() => setMode()}
        icon='back-arrow'
      />
      <h1>Resource Details</h1>
      <div className='ui warning message'>
        <div className='header'>Create, edit and delete Resources.</div>
        <p>
          You can manage resources from this page. You can create, edit, delete
          a resource.
        </p>
      </div>
      {/* <Header content="Resource Details" sub color="teal" /> */}
      <Formik
        validationSchema={validationSchema}
        enableReinitialize
        initialValues={resource}
        onSubmit={(values) => handleFormSubmit(values)}>
        {({ handleSubmit, isValid, isSubmitting, dirty }) => (
          <Form className='ui form' onSubmit={handleSubmit} autoComplete='off'>
            <MyTextInput placeholder='Code' name='code' />
            <MyTextInput name='title' placeholder='Title' />
            <MyTextArea rows={3} placeholder='Description' name='description' />
            <MySelectInput
              options={resourceOptions}
              placeholder='Category'
              name='category'
            />
            {/* <MyDateInput
              placeholderText="Date"
              name="date"
              showTimeSelect
              timeCaption="time"
              dateFormat="MMMM d, yyyy h:mm aa"
            /> */}
            {/* <Header content="Location Details" sub color="teal" /> */}
            <MyTextInput placeholder='Size' name='size' />
            <MyTextInput placeholder='Source' name='source' />
            <Button
              disabled={isSubmitting || !dirty || !isValid}
              loading={isSubmitting}
              floated='right'
              positive
              type='submit'
              content='Submit'
            />
            <Button
              as={Link}
              to='/resources'
              floated='right'
              type='button'
              content='Cancel'
            />
          </Form>
        )}
      </Formik>
    </Segment>
  );
});

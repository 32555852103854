import React, { useEffect } from "react";
import { Segment } from "semantic-ui-react";

import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import "devextreme-react/text-area";

//import { baseAPI } from "../../../api/APIEndpoints";
import DataGrid, {
  FilterPanel,
  FilterRow,
  SearchPanel,
  Button,
  Paging,
  Lookup,
  Popup,
  Position,
  Editing,
  Export,
  Pager,
  Grouping,
  GroupPanel,
  RowDragging,
  Column,
  RequiredRule,
  PatternRule,
  EmailRule,
  AsyncRule,
} from "devextreme-react/data-grid";

import CustomStore from "devextreme/data/custom_store";
import "whatwg-fetch";
import { store, useStore } from "../../../app/stores/store";
import AssignTaskForm from "../ecoforms/AssignTaskForm";
export const AssignTaskToRoles = () => {
  const DataSource = [
    {
      TaskOID: 2,
      userRoleTaskOID: 0,
      ApplicationDescription: "",
      ApplicationOID: 8,
      TaskCode: "ApplicationTask",
      TaskDescription: "Task Defintion",
      TaskOrder: 3,
      UiDescription: "Task Definition",
      ModuleDescription: "Ecosystem",
    },
    {
      TaskOID: 3,
      userRoleTaskOID: 0,
      ApplicationDescription: "",
      ApplicationOID: 8,
      TaskCode: "Applications",
      TaskDescription: "Applications",
      TaskOrder: 22,
      UiDescription: "",
      ModuleDescription: "Ecosystem",
    },
    {
      TaskOID: 4,
      userRoleTaskOID: 0,
      ApplicationDescription: "",
      ApplicationOID: 8,
      TaskCode: "CompanyInfo",
      TaskDescription: "CompanyInfomation",
      TaskOrder: 1,
      UiDescription: "CompanyInfomation",
      ModuleDescription: "Ecosystem",
    },
    {
      TaskOID: 5,
      userRoleTaskOID: 0,
      ApplicationDescription: "",
      ApplicationOID: 8,
      TaskCode: "Role Definition",
      TaskDescription: "Role Definition",
      TaskOrder: 1,
      UiDescription: "Role Definition",
      ModuleDescription: "Ecosystem",
    },
  ];
  const [onVisible, setOnVisible] = React.useState(false);
  const baseAPI = process.env.REACT_APP_API_URL; // "http://localhost:5000/api";

  const jwt = store.commonStore.token;

  function handleErrors(response: any) {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  }

  const customDataSource: any = new CustomStore({
    key: "taskOID",
    load: () => {
      return fetch(`${baseAPI}/EcoTask`, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      })
        .then(handleErrors)
        .then((response) => response.json())
        .then((response) => {
          return {
            data: response.result,
            totalCount: response.count,
            summary: response.summary,
            groupCount: response.count,
          };
        });
    },
    insert: (values: any) => {
      return fetch(`${baseAPI}/EcoTask`, {
        method: "POST",
        body: JSON.stringify(values),
        headers: {
          Authorization: `Bearer ${jwt}`,
          "Content-Type": "application/json",
        },
      }).then(handleErrors);
    },
    remove: (key: any) => {
      return fetch(`${baseAPI}/EcoTask/${key}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }).then(handleErrors);
    },
    update: (values: any) => {
      return values;
    },
  });
  const onRowUpdating = (e: any) => {
    e.newData = Object.assign(e.oldData, e.newData);

    fetch(`${baseAPI}/EcoTask`, {
      method: "PUT",
      body: JSON.stringify(e.newData),
      headers: {
        Authorization: `Bearer ${jwt}`,
        "Content-Type": "application/json",
      },
    }).then(customDataSource.load());
  };

  return (
    <Segment>
      <h1>Assign Task To Roles</h1>
      <div className="ui warning message">
        <div className="header">Manage Assign Task To Roles.</div>
        <p>Create and manage Assign Task To Roles.</p>
      </div>
      <div className="ui divider" title="List of Assign Task To Roles"></div>
      <div>
        <AssignTaskForm setOnVisible={() => setOnVisible(false)} taskOID={0} />
        <DataGrid
          id="[$GridID]"
          dataSource={DataSource}
          className="dx-datagrid dx-row-alt"
          hoverStateEnabled={true}
          rowAlternationEnabled={true}
          columnAutoWidth={true}
          activeStateEnabled={true}
          columnResizingMode="widget"
          allowColumnResizing={true}
          showBorders={true}
          columnHidingEnabled={true}>
          {/* <RowDragging allowReordering={true} /> */}
          <FilterPanel visible={true} />
          <SearchPanel visible={true} />
          <FilterRow visible={true} />
          <Paging defaultPageSize={20} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={[2, 10, 20, 50, 100]}
            showInfo={true}
          />
          <Grouping contextMenuEnabled={true} />
          <GroupPanel visible={true} />
          <Export
            enabled={true}
            fileName={"Assign_Task_to_Role"}
            allowExportSelectedData={true}
          />
          <Editing
            mode="popup"
            useIcons={true}
            allowUpdating={true}
            allowDeleting={true}
            allowAdding={true}>
            {" "}
          </Editing>
          {/* <Scrolling mode="standard" />
          <Loader enabled /> */}
          {/* <Column type="buttons" caption="Operation">
            <Point
              text="Permissions"
              cssClass={"manage-permission-btn"}
              // onClick={commandClick}
            />
          </Column> */}
          {/* <Column dataField="userRoleTaskOID"></Column> */}
          <Column dataField="TaskOID"></Column>
          <Column dataField="userRoleTaskOID"></Column>
          <Column dataField="ApplicationDescription"></Column>
          <Column dataField="ApplicationOID"></Column>
          <Column
            dataField="TaskCode"
            // cellRender={renderCheckBox}
          ></Column>
          <Column
            dataField="TaskDescription"

            // cellRender={renderCheckBox}
          ></Column>
          <Column
            dataField="TaskOrder"

            // cellRender={renderCheckBox}
          ></Column>
          <Column
            dataField="UiDescription"
            // cellRender={renderCheckBox}
          ></Column>
          <Column
            dataField="ModuleDescription"
            // cellRender={renderCheckBox}
          ></Column>
        </DataGrid>
      </div>
    </Segment>
  );
};

import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { Form as FForm, Formik } from "formik";
import * as Yup from "yup";
import { Segment, Header, Form, Table, Button, Icon } from "semantic-ui-react";
import {
  paymentTableData,
  paymentTermsData,
} from "../../../app/common/TemStaticData";
import CADTextInput from "../../../app/common/form/CADTextInput";
import CADTextArea from "../../../app/common/form/CADTextArea";

const PaymentTerms = () => {
  // states
  const [paymentTerms, setpaymentTerms] = useState(paymentTableData);
  const [addFormData, setAddFormData] = useState({
    sn: "",
    buyer: "",
  });
  const [editFormData, setEditFormData] = useState({
    buyer: "",
  });
  const [editPaymentTermId, setEditPaymentTermId] = useState(0);
  const [addFormActive, setaddFormActive] = useState(false);

  // functions

  const handleAddFormClick = () => {
    setaddFormActive(!addFormActive);
    setEditPaymentTermId(0);
  };

  const handeEditClicked = (e: any, paymentTerm: any) => {
    e.preventDefault();
    setEditPaymentTermId(paymentTerm.sn);

    setaddFormActive(false);
    const formValues = {
      buyer: paymentTerm.buyer,
    };
    setEditFormData(formValues);
  };

  const handleCancelClick = (e: any) => {
    e.preventDefault();
    setEditPaymentTermId(0);
  };

  const handleAddFormChange = (event: any) => {
    event.preventDefault();

    const fieldName = event.target.getAttribute("name");
    const fieldValue: any = event.target.value;

    const newFormData: any = { ...addFormData };
    newFormData[fieldName] = fieldValue;
    setAddFormData(newFormData);
  };

  const handleAddFormSubmit = (event: any) => {
    event.preventDefault();

    const newPaymentTerm = {
      sn: paymentTerms.length,
      buyer: addFormData.buyer,
    };

    const newPaymentTerms = [...paymentTerms, newPaymentTerm];
    setpaymentTerms(newPaymentTerms);
  };

  const handleEditFormChange = (event: any) => {
    event.preventDefault();

    const fieldName = event.target.getAttribute("name");
    const fieldValue: any = event.target.value;

    const newFormData: any = { ...editFormData };
    newFormData[fieldName] = fieldValue;

    setEditFormData(newFormData);
  };

  const handleEditFormSubmit = (e: any) => {
    e.preventDefault();
    const editPaymentTerm = {
      sn: editPaymentTermId,
      buyer: editFormData.buyer,
    };
    const newPaymentTerms = [...paymentTerms];
    const index = paymentTerms.findIndex(
      (paymentTerm) => paymentTerm.sn === editPaymentTermId
    );
    newPaymentTerms[index] = editPaymentTerm;
    setpaymentTerms(newPaymentTerms);
    setEditPaymentTermId(0);
  };

  const validationSchema = Yup.object({
    paymentTermOID: Yup.string().required(),
    paymentTermName: Yup.string().required("payment term name is required"),
    description: Yup.string().required(),
    condition: Yup.string().required(),
  });

  return (
    <Segment>
      <Header as="h3" dividing color="brown">
        Payment Terms/Conditions
      </Header>

      <Formik
        initialValues={paymentTermsData[0]}
        onSubmit={(values) => {
          console.log(values);
        }}
        validationSchema={validationSchema}>
        {({ handleSubmit, isValid, isSubmitting, dirty }) => (
          <FForm className="ui form" onSubmit={handleSubmit} autoComplete="off">
            <Form.Group widths="equal">
              <CADTextInput
                name="paymentTermOID"
                label="Payment Term OID"
                placeholder="Payment Term OID"
                id="paymentTermOID"
                disabled
              />
              <CADTextInput
                name="paymentTermName"
                label="Payment Term Name"
                placeholder="Payment Term Name"
                id="paymentTermName"
              />
            </Form.Group>
            <Form.Group widths="equal">
              <CADTextArea
                name="description"
                id="description"
                rows={3}
                label="Description"
                placeholder="Enter Description"
              />
            </Form.Group>
            <Form.Group widths="equal">
              <CADTextArea
                name="condition"
                id="condition"
                rows={3}
                label="Condition"
                placeholder="Enter Condition"
              />
            </Form.Group>
          </FForm>
        )}
      </Formik>
      <Form
        autoComplete="off"
        onSubmit={addFormActive ? handleAddFormSubmit : handleEditFormSubmit}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>Coperate Buyers</div>
          {/* <Button size="mini" onClick={handleAddFormClick} content="Add" /> */}
        </div>
        <Table celled compact>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell> Sno </Table.HeaderCell>
              <Table.HeaderCell> Buyer </Table.HeaderCell>
              <Table.HeaderCell>
                <Button
                  size="mini"
                  onClick={handleAddFormClick}
                  content="Add"
                  color="brown"
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {/* this is the add form */}
            {addFormActive && (
              <AddForm
                handleAddFormChange={handleAddFormChange}
                handleAddFormClick={handleAddFormClick}
              />
            )}
            {paymentTerms.map((paymentTerm) => (
              <>
                {editPaymentTermId === paymentTerm.sn ? (
                  <EditableRow
                    paymentTerm={paymentTerm}
                    editFormData={editFormData}
                    handleEditFormChange={handleEditFormChange}
                    handleCancelClick={handleCancelClick}
                  />
                ) : (
                  <ReadonlyRow
                    paymentTerm={paymentTerm}
                    handeEditClicked={handeEditClicked}
                  />
                )}
              </>
            ))}
          </Table.Body>
        </Table>
      </Form>
    </Segment>
  );
};

interface AddFormProps {
  handleAddFormChange: any;
  handleAddFormClick: any;
}
const AddForm: React.FC<AddFormProps> = ({
  handleAddFormChange,
  handleAddFormClick,
}) => (
  <Table.Row>
    <Table.Cell>
      <input type="text" disabled name="s/no" placeholder="s/no" />
    </Table.Cell>
    <Table.Cell>
      <input
        type="text"
        name="buyer"
        required
        placeholder="Enter a buyer..."
        // value={editFormData.buyer}
        onChange={handleAddFormChange}
      />
    </Table.Cell>
    <Table.Cell>
      <StyledButton type="submit">
        <Icon name="save" />
      </StyledButton>
      <StyledButton>
        <Icon onClick={handleAddFormClick} name="cancel" />
      </StyledButton>
    </Table.Cell>
  </Table.Row>
);

interface ReadonlyRowProps {
  paymentTerm: any;
  handeEditClicked: any;
}

const ReadonlyRow: React.FC<ReadonlyRowProps> = ({
  paymentTerm,
  handeEditClicked,
}) => (
  <Table.Row>
    <Table.Cell width="2">{paymentTerm.sn}</Table.Cell>
    <Table.Cell width="12">{paymentTerm.buyer}</Table.Cell>
    <Table.Cell width="2">
      <StyledButton>
        <Icon
          name="edit"
          onClick={(event: any) => handeEditClicked(event, paymentTerm)}
        />
      </StyledButton>
      <StyledButton>
        <Icon name="cut" />
      </StyledButton>
    </Table.Cell>
  </Table.Row>
);

interface EditableRowProps {
  paymentTerm: any;
  editFormData: any;
  handleEditFormChange: any;
  handleCancelClick: any;
}

const EditableRow: React.FC<EditableRowProps> = ({
  paymentTerm,
  editFormData,
  handleEditFormChange,
  handleCancelClick,
}) => (
  <Table.Row>
    <Table.Cell>{paymentTerm.sn}</Table.Cell>
    <Table.Cell>
      <input
        type="text"
        name="buyer"
        required
        placeholder="Enter a buyer..."
        value={editFormData.buyer}
        onChange={handleEditFormChange}
      />
    </Table.Cell>
    <Table.Cell>
      <StyledButton type="submit">
        <Icon name="save" />
      </StyledButton>
      <StyledButton>
        <Icon name="cancel" onClick={handleCancelClick} />
      </StyledButton>
    </Table.Cell>
  </Table.Row>
);

interface StyledButtonProps {
  type?: string;
  children: React.ReactNode;
}
/**
 * this just removes the background and border form a button and add {cursor : pointer}
 * @type this is  string
 * @returns jsx element
 */
const StyledButton: React.FC<StyledButtonProps> = ({ children }) => (
  <button style={{ background: "none", border: "none", cursor: "pointer" }}>
    {children}
  </button>
);

export default observer(PaymentTerms);

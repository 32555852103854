import React from "react";
import { Link } from "react-router-dom";
import { ErrorMessage, Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import { Button, Header } from "semantic-ui-react";
import MyTextInput from "../../../app/common/form/MyTextInput";
import { useStore } from "../../../app/stores/store";
import * as Yup from "yup";
import ValidationErrors from "../../errors/ValidationErrors";
import { createMedia } from "@artsy/fresnel";
import { Image } from "semantic-ui-react";
import "./RegisterForm.css";

const { MediaContextProvider, Media } = createMedia({
  // breakpoints values can be either strings or integers
  breakpoints: {
    sm: 0,
    md: 768,
    lg: 1024,
    xl: 1569,
  },
});

export default observer(function RegisterForm() {
  const { userStore } = useStore();
  return (
    <MediaContextProvider>
      <div className="register-screen">
        <div className="">
          <div className="register-container">
            <div className="curve-circle" />
            <section className="register-left">
              <div className="img-continer">
                <Media greaterThanOrEqual="md">
                  <Image
                    centered
                    size="small"
                    src="/assets/Blinkhub_Logo.png"
                  />
                </Media>
              </div>
              <Header className="header" textAlign="center" as="h2">
                Sign up to BlinkHub
              </Header>
              <Formik
                initialValues={{
                  displayName: "",
                  username: "",
                  email: "",
                  password: "",
                  error: null,
                }}
                onSubmit={async (values, { setErrors }) => {
                  console.log("1. Before");
                  userStore
                    .register(values)
                    .catch((error) => setErrors({ error }));
                  console.log("2. After");
                }}
                validationSchema={Yup.object({
                  displayName: Yup.string().required(),
                  username: Yup.string().required(),
                  email: Yup.string().required().email(),
                  password: Yup.string().required(),
                })}>
                {({ handleSubmit, isSubmitting, errors, isValid, dirty }) => (
                  <Form
                    className="ui form error"
                    onSubmit={handleSubmit}
                    autoComplete="off">
                    <div className="form-control">
                      <label htmlFor="display-name">Display Name</label>
                      <MyTextInput
                        id="display-name"
                        name="displayName"
                        placeholder="Display Name"
                      />
                    </div>
                    <div className="form-control">
                      <label htmlFor="username">User Name</label>
                      <MyTextInput
                        id="username"
                        name="username"
                        placeholder="Username"
                      />
                    </div>
                    <div className="form-control">
                      <label htmlFor="email">Email</label>
                      <MyTextInput
                        id="email"
                        name="email"
                        placeholder="Email"
                      />
                    </div>
                    <div className="form-control">
                      <label htmlFor="password">Password</label>
                      <MyTextInput
                        id="password"
                        name="password"
                        placeholder="Password"
                        type="password"
                      />
                    </div>
                    <ErrorMessage
                      name="error"
                      render={() => <ValidationErrors errors={errors.error} />}
                    />
                    <div className="button-position">
                      <Button
                        disabled={!isValid || !dirty || isSubmitting}
                        loading={isSubmitting}
                        color="brown"
                        inverted
                        content="Register"
                        as={Link}
                        to="/auth/verifyEmail"
                        type="submit"
                        fluid        
                      />
                      <Button
                        disabled={isSubmitting}
                        color="brown"
                        inverted
                        content="Cancel"
                        as={Link}
                        to="/"
                        type="submit"
                        fluid
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </section>
            <section className="register-right">
              <h2> Welcome, </h2>
              <p>To the world of business support services </p>
              <h3> Business Linkage Network </h3>
            </section>
            <Media lessThan="md">
              <Image centered size="small" src="/assets/Blinkhub_Logo.png" />
            </Media>
          </div>
        </div>
      </div>
    </MediaContextProvider>
  );
});

// {/* <Formik
//   initialValues={{
//     displayName: "",
//     username: "",
//     email: "",
//     password: "",
//     error: null,
//   }}
//   onSubmit={(values, { setErrors }) => {
//     console.log("1. Before");
//     userStore.register(values).catch((error) => setErrors({ error }));
//     console.log("2. After");
//   }}
//   validationSchema={Yup.object({
//     displayName: Yup.string().required(),
//     username: Yup.string().required(),
//     email: Yup.string().required().email(),
//     password: Yup.string().required(),
//   })}>
//   {({ handleSubmit, isSubmitting, errors, isValid, dirty }) => (
//     <Form className="ui form error" onSubmit={handleSubmit} autoComplete="off">
//       <Header
//         as="h2"
//         content="Sign up to Blinkhub"
//         color="teal"
//         textAlign="center"
//       />
//       <MyTextInput name="displayName" placeholder="Display Name" />
//       <MyTextInput name="username" placeholder="Username" />
//       <MyTextInput name="email" placeholder="Email" />
//       <MyTextInput name="password" placeholder="Password" type="password" />
//       <ErrorMessage
//         name="error"
//         render={() => <ValidationErrors errors={errors.error} />}
//       />
//       <Button
//         disabled={!isValid || !dirty || isSubmitting}
//         loading={isSubmitting}
//         positive
//         content="Register"
//         type="submit"
//         fluid
//       />
//     </Form>
//   )}
// </Formik>; */}

export interface Subscriber {
  subscriberOID: string;
  subscriberCode: string;
  databaseName: string;
  loginName: string;
  password: string;
  serverName: string;
  companyName: string;
  canCreate: number;
  dateUploaded: Date | null;

  // date: Date | null;
  // description: string;
  // category: string;
  // city: string;
  // venue: string;
  // hostUsername: string;
  // isCancelled: boolean;
  // isGoing: boolean;
  // isHost: boolean;
  // host?: Profile;
  // attendees: Profile[]
}

export class Subscriber implements Subscriber {
  constructor(init?: SubscriberFormValues) {
    Object.assign(this, init);
  }
}

export class SubscriberFormValues {
  subscriberOID?: string = undefined;
  subscriberCode: string = "";
  databaseName: string = "";
  loginName: string = "";
  password: string = "";
  serverName: string = "";
  companyName: string = "";
  canCreate: number = 0;

  // dateUploaded: Date  = Date.now();

  constructor(subscriber?: SubscriberFormValues) {
    if (subscriber) {
      this.subscriberOID = subscriber.subscriberOID;
      this.subscriberCode = subscriber.subscriberCode;
      this.databaseName = subscriber.databaseName;
      this.loginName = subscriber.loginName;
      this.password = subscriber.password;
      this.serverName = subscriber.serverName;
      this.companyName = subscriber.companyName;
      this.canCreate = subscriber.canCreate;
    }
  }
}

import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../api/agent";
import agentExpress from "../api/agentExpress";
//import { Activity, ActivityFormValues } from "../models/activity";
import { Resource, ResourceFormValues } from "../models/resource";
import { format } from "date-fns";
import { store } from "./store";
import { Profile } from "../models/profile";
import { Pagination, PagingParams } from "../models/pagination";
import axios from "axios";
import { HelpersOnline, HelpersOnlineFormValues } from "../models/helpersOnline";

export default class HelpersOnlineStore {
  resourceData: HelpersOnline[] = [];
  resourceV2Data: HelpersOnline[] = [];
  //comments: ChatComment[] = [];
  resourceRegistry = new Map<string, HelpersOnline>();
  selectedResource: HelpersOnline | undefined = undefined;
  editMode = false;
  loading = false;
  loadingInitial = false;
  pagination: Pagination | null = null;
  pagingParams = new PagingParams();
  predicate = new Map().set("all", true);
  baseAPI = process.env.REACT_APP_API_URL;

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.predicate.keys(),
      () => {
        this.pagingParams = new PagingParams();
        this.resourceRegistry.clear();
        this.loadResources();
      }
    );
  }

  setPagingParams = (pagingParams: PagingParams) => {
    pagingParams.pageNumber = 1;
    pagingParams.pageSize = 500;
    this.pagingParams = pagingParams;
  };

  setPredicate = (predicate: string, value: string | Date) => {
    const resetPredicate = () => {
      this.predicate.forEach((value, key) => {
        if (key !== "dateUploaded") this.predicate.delete(key);
      });
    };
    switch (predicate) {
      case "all":
        resetPredicate();
        this.predicate.set("all", true);
        break;
      case "isGoing":
        resetPredicate();
        this.predicate.set("isGoing", true);
        break;
      case "isHost":
        resetPredicate();
        this.predicate.set("isHost", true);
        break;
      case "dateUploaded":
        this.predicate.delete("dateUploaded");
        this.predicate.set("dateUploaded", value);
    }
  };

  get axiosParams() {
    const params = new URLSearchParams();
    // params.append("pageNumber", this.pagingParams.pageNumber.toString());
    // params.append("pageSize", this.pagingParams.pageSize.toString());
    params.append("pageNumber", "1");
    params.append("pageSize", "500");
    this.predicate.forEach((value, key) => {
      if (key === "dateUploaded") {
        params.append(key, (value as Date).toISOString());
      } else {
        params.append(key, value);
      }
    });
    return params;
  }

  // get resourcesByDate() {
  //   return Array.from(this.resourceRegistry.values()).sort(
  //     (a, b) => a.dateUploaded!.getTime() - b.dateUploaded!.getTime()
  //   );
  // }

  // get groupedResources() {
  //   return Object.entries(
  //     this.resourcesByDate.reduce((resources, resource) => {
  //       const date = format(resource.dateUploaded!, "dd MMM yyyy");
  //       resources[date] = resources[date]
  //         ? [...resources[date], resource]
  //         : [resource];
  //       return resources;
  //     }, {} as { [key: string]: Resource[] })
  //   );
  // }

  // loadActivities = async () => {
  //   this.loadingInitial = true;
  //   try {
  //     const result = await agent.Activities.list(this.axiosParams);
  //     result.data.forEach((activity) => {
  //       this.setActivity(activity);
  //     });
  //     this.setPagination(result.pagination);
  //     this.setLoadingInitial(false);
  //   } catch (error) {
  //     console.log(error);
  //     this.setLoadingInitial(false);
  //   }
  // };

  loadResources = async () => {
    this.loadingInitial = true;
    try {
      console.log("1. LOG = " + this.axiosParams);
      const result = await agentExpress.HelpersOnlines.list();
      //this.resourceData.push(result);
      //console.log("1. result from loadResources()", result);
      // result.map(
      //   (resource) =>
      //     //this.resourceData.push(resource)
      //     this.setResourceData(resource)
      // );
      // console.log("2. result from loadResources()");
      this.resourceData = result.data;
      // console.log(this.resourceData);
      // result.data.forEach((resource) => {
      //   this.setResourceData(resource);
      // });
      //this.setPagination(result.pagination);
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
    }
  };

  // loadResourcesDashboard = async () => {
  //   this.loadingInitial = true;
  //   try {
  //     const result = await agentExpress.Resources.list();
  //     this.resourceV2Data = result;
  //     console.log("1. In loadResourcesDashboard: result", result);
  //     console.log(
  //       "2. In loadResourcesDashboard: this.resourceData",
  //       this.resourceV2Data
  //     );
  //     this.setLoadingInitial(false);
  //   } catch (error) {
  //     console.log(error);
  //     this.setLoadingInitial(false);
  //   }
  // };

  // setPagination = (pagination: Pagination) => {
  //   this.pagination = pagination;
  // };

  loadResource = async (id: string) => {
    let resource = this.getResource(id);
    if (resource) {
      this.selectedResource = resource;
      return resource;
    } else {
      this.loadingInitial = true;
      try {
        resource = await agentExpress.HelpersOnlines.details(id);
        this.setResourceData(resource);
        runInAction(() => {
          this.selectedResource = resource;
        });
        this.setLoadingInitial(false);
        return resource;
      } catch (error) {
        console.log(error);
        this.setLoadingInitial(false);
      }
    }
  };
  private setResourceData = (resource: HelpersOnline) => {
    this.resourceData.push(resource);
  };

  private setResource = (resource: HelpersOnline) => {
    // resource.dateUploaded = new Date(resource.dateUploaded!);
    this.resourceRegistry.set(resource.id, resource);
  };

  private getResource = (id: string) => {
    return this.resourceRegistry.get(id);
  };

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  // createResource = async (resource: HelpersOnlineFormValues) => {
  //   try {
  //     await agentExpress.HelpersOnline.create(resource);
  //     const newResource = new HelpersOnline(resource);
  //     //   newResource.hostUsername = user!.username;
  //     //   newResource.attendees = [attendee];
  //     this.setResource(newResource);
  //     runInAction(() => {
  //       this.selectedResource = newResource;
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // updateResource = async (resource: ResourceFormValues) => {
  //   try {
  //     await agent.Resources.update(resource);
  //     runInAction(() => {
  //       if (resource.resourceGUID) {
  //         let updatedResource = {
  //           ...this.getResource(resource.resourceGUID),
  //           ...resource,
  //         };
  //         this.resourceRegistry.set(
  //           resource.resourceGUID,
  //           updatedResource as Resource
  //         );
  //         this.selectedResource = updatedResource as Resource;
  //       }
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // deleteResource = async (id: string) => {
  //   this.loading = true;
  //   try {
  //     await agent.Resources.delete(id);
  //     runInAction(() => {
  //       this.resourceRegistry.delete(id);
  //       this.loading = false;
  //     });
  //   } catch (error) {
  //     console.log(error);
  //     runInAction(() => {
  //       this.loading = false;
  //     });
  //   }
  // };
 
  // cancelResourceToggle = async () => {
  //   this.loading = true;
  //   try { 
  //     runInAction(() => { 
  //       this.resourceRegistry.set(
  //         this.selectedResource!.id,
  //         this.selectedResource!
  //       );
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   } finally {
  //     runInAction(() => (this.loading = false));
  //   }
  // };
 
  clearSelectedResource = () => {
    this.selectedResource = undefined;
  };
}

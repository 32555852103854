import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Header, Segment, Icon, Confirm } from "semantic-ui-react";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { store, useStore } from "../../../app/stores/store";
import { v4 as uuid } from "uuid";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import MyTextInput from "../../../app/common/form/MyTextInput";
import MyTextArea from "../../../app/common/form/MyTextArea";
import MyDisabledInput from "../../../app/common/form/MyDisbabledInput";
import MySelectInput from "../../../app/common/form/MySelectInput";
import { resourceOptions } from "../../../app/common/options/categoryOptions";
import MyDateInput from "../../../app/common/form/MyDateInput";
//import { ActivityFormValues } from "../../../app/models/activity";

import {
  BusinessAreaModel,
  BusinessAreaFormValues,
} from "../../../app/models/businessArea";

import agentExpress from "../../../app/api/agentExpress";

export default observer(function BusinessAreaForm({
  businessAreaOID,
  setMode,
}: {
  businessAreaOID: string;
  setMode: Function;
}) {
  const navigate = useNavigate();
  const { businessAreaStore } = useStore();
  const {
    createBusinessAreaModel,
    updateBusinessAreaModel,
    loadBusinessAreaModel,
    deleteBusinessAreaModel,
    loadingInitial,
  } = businessAreaStore;
  // const { resourceGUID } = useParams<{ resourceGUID: string }>();
  const [open, setOpen] = useState(false);
  const baseAPI = process.env.REACT_APP_API_URL; // "http://localhost:5000/api";
  const jwt = store.commonStore.token;
  const [businessArea, setBusinessArea] = useState<BusinessAreaModel | any>([]);
  const [businessAreaModel, setBusinessAreaModel] =
    useState<BusinessAreaFormValues>(new BusinessAreaFormValues());

  const validationSchema = Yup.object({
    businessAreaOID: Yup.string().required(
      "The resource  businessAreaOID is required"
    ),
    description: Yup.string().required("The resource description is required"),
    businessAreaID: Yup.string().required(
      "The resource businessAreaID is required"
    ),
    //date: Yup.string().required("Date is required").nullable(),
    businessSectorID: Yup.string().required(
      "The resource businessSectorIDe is required"
    ),
  });

  // useEffect(() => {
  //   if (countryOID) {
  //     // loadCountryCode(countryOID).then((country) => {

  //     // })
  //     axios
  //       .get(`http://localhost:5000/api/ecocountry/${countryOID}`)
  //       .then((res) => res.data?.result?.data[0])
  //       .then((data) => {
  //         setCountryCode(data);
  //         loadingInitial(false);
  //         // console.log(data);
  //       });
  //     // console.log(country?.result?.data[0]);
  //     // const data = country?.result?.data[0];
  //   }
  // }
  console.log(businessAreaOID);

  useEffect(() => {
    if (businessAreaOID)
      loadBusinessAreaModel(businessAreaOID).then((businessArea) =>
        setBusinessAreaModel(new BusinessAreaFormValues(businessArea))
      );
    agentExpress.EcoBusinessArea.details(businessAreaOID).then((response) => {
      // getCountryCode(country.countryOID)
      if (response) {
        // return response.filter((country: any) => {
        //   return country.countryOID;
        // });
        // setCountry(row.data.countryOID);
        console.log(response);

        setBusinessArea(response?.result.data[0]);

        // console.log("6. useEffect response", response.result.data);
        // console.log(response.result.data);
      }
      return {
        data: response.result.data,
      };
    });
  }, [businessAreaOID, BusinessAreaModel]);

  // function b(countryOID: any, ) {

  //   return result.filter((item) => {
  //     return item.countryOID === idToSearch;
  //   });
  // }
  function handleFormSubmit(businessArea: BusinessAreaFormValues) {
    if (!businessArea.businessAreaOID) {
      let newBusinessAreaModel = {
        ...businessArea,
        businessSectorOID: uuid(),
      };
      createBusinessAreaModel(new BusinessAreaModel()).then((response) =>
        fetch(
          `${baseAPI}/EcoBusinessArea/${newBusinessAreaModel.businessAreaOID}`
        )
      );
    } else {
      updateBusinessAreaModel(businessArea).then(() =>
        fetch(`${baseAPI}/EcoBusinessArea/${businessArea.businessAreaOID}`, {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${jwt}`,
            "Content-Type": "application/json",
          },
        })
      );
    }
  }

  const handleDelete = (id: any) => {
    fetch(`${baseAPI}/EcoBusinessArea/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${jwt}`,
        "Content-Type": "application/json",
      },
    }).then((result) => {
      result.json().then((resp) => {
        console.log(resp.result.data);
        return {
          data: resp.result.data,
        };
      });
    });
    setMode();
  };

  const handleEdit = (id: any) => {
    fetch(`${baseAPI}/EcoBusinessArea/${id}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${jwt}`,
        "Content-Type": "application/json",
      },
    }).then((result) => {
      result.json().then((resp) => {
        console.log(resp.result.data);
        return {
          data: resp.result.data,
        };
      });
    });
    setMode();
  };
  if (loadingInitial)
    return <LoadingComponent content="Loading business area..." />;

  const show = () => {
    setOpen(true);
  };
  const handleConfirm = () => {
    setOpen(false);
  };
  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <Segment clearing>
      <Icon
        name="arrow alternate circle left"
        onClick={() => setMode()}
        floated="left"
        size="big"
        type="button"
      />
      {/* <Button
        // disabled={isSubmitting || !dirty || !isValid}
        // loading={isSubmitting}
        floated="left"
        // positive
        style={{ marginTop: "15px" }}
        type="button"
        // content="Back"
        onClick={() => setMode()}
        icon="arrow alternate circle left black center big"
      /> */}
      <h1 style={{ marginTop: "-30px", marginLeft: "35px" }}>
        Country Details
      </h1>
      <div className="ui warning message">
        <div className="header">Create, edit and delete Country.</div>
        <p>
          You can manage resources from this page. You can create, edit, delete
          a country.
        </p>
      </div>
      {/* <Header content="Resource Details" sub color="teal" /> */}
      <Formik
        validationSchema={validationSchema}
        enableReinitialize
        initialValues={businessArea}
        // onSubmit={(values) => handleFormSubmit(values)}
        onSubmit={(values) => {
          handleFormSubmit(values);
          // setMode();
        }}>
        {({ handleSubmit, isValid, isSubmitting, dirty }) => (
          <Form
            className="ui form"
            // onSubmit={handleSubmit}
            onSubmit={() => {
              handleSubmit();
              // setMode();
            }}
            autoComplete="off">
            <MyDisabledInput
              placeholder="sectorOID"
              name="sectorOID"
              label="Sector OID"
            />
            <MyTextInput
              name="description"
              placeholder="description"
              label="Description"
            />
            <MyTextInput
              name="businessAreaID"
              placeholder="businessAreaID"
              // rows={3}
              label="Business AreaID"
            />
            <MySelectInput
              options={resourceOptions}
              placeholder="businessSectorID"
              name="businessSectorID"
              label="Business SectorID"
            />

            {/* <MyDateInput
              placeholderText="Date"
              name="date"
              showTimeSelect
              timeCaption="time"
              dateFormat="MMMM d, yyyy h:mm aa"
            /> */}
            {/* <Header content="Location Details" sub color="teal" /> */}

            <Button
              as={Link}
              onClick={() => setMode()}
              floated="right"
              type="button"
              content="Cancel"
            />
            <div>
              <Button
                negative
                type="button"
                floated="right"
                content="Delete"
                onClick={() => show()}
              />
              <Confirm
                open={open}
                content="Are you sure you wish to delete this entity?"
                cancelButton="No"
                confirmButton="Yes"
                onConfirm={() => handleDelete(businessAreaOID)}
                onCancel={handleCancel}
                size="tiny"
              />
            </div>
            {/* <Button
              negative
              type="button"
              floated="right"
              content="Delete"
              onClick={() => {
                if (
                  window.confirm("Are you sure you wish to delete this entity?")
                ) {
                  handleDelete(countryOID);
                }
              }}
            /> */}
            <Button
              disabled={isSubmitting || !dirty || !isValid}
              loading={isSubmitting}
              floated="right"
              positive
              type="submit"
              content="Submit"
            />
          </Form>
        )}
      </Formik>
    </Segment>
  );
});

import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../api/agent";
import {
  ActivityChallenge,
  ActivityChallengeFormValues,
} from "../models/MSMEBlinkActivitiesChallenges";
import { Pagination, PagingParams } from "../models/pagination";

export default class MSMEBlinkActivitiesChallengesStore {
  MSMEBlinkActivitiesChallengesData: ActivityChallenge[] = []; //This is all  data for get all
  activitiesChallengesRegistry = new Map<number, ActivityChallenge>();
  selectedActivitiesChallenges: ActivityChallenge | undefined = undefined;

  editMode: boolean = false;
  loading: boolean = false;
  loadingInitial: boolean = false;
  pagination: Pagination | null = null;
  pagingParams = new PagingParams();
  predicate = new Map().set("all", true);
  baseAPI = process.env.REACT_APP_API_URL;

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.predicate.keys(),
      () => {
        this.pagingParams = new PagingParams();
        this.activitiesChallengesRegistry.clear();
      }
    );
  }

  get axiosParams() {
    const params = new URLSearchParams();
    params.append("pageNumber", this.pagingParams.pageNumber.toString());
    params.append("pageSize", this.pagingParams.pageNumber.toString());
    this.predicate.forEach((value, key) => {
      params.append(key, value);
    });
    return params;
  }

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  private getMSMEBlinkActivitiesChallenges = (id: number) => {
    return this.activitiesChallengesRegistry.get(id);
  };

  private setMSMEBlinkActivitiesChallengesData = (
    values: ActivityChallenge
  ) => {
    this.MSMEBlinkActivitiesChallengesData.push(values);
  };

  loadMSMEBlinkActivitiesChallenges = async (id: number) => {
    let values = this.getMSMEBlinkActivitiesChallenges(id);

    if (values) {
      this.selectedActivitiesChallenges = values;
      return values;
    } else {
      this.setLoadingInitial(true);
      try {
        values = await agent.ActivitiesChallenges.details(id);
        this.setMSMEBlinkActivitiesChallengesData(values);
        runInAction(() => {
          this.selectedActivitiesChallenges = values;
        });
        return values;
      } finally {
        this.setLoadingInitial(false);
      }
    }
  };

  createMSMEBlinkActivitiesChallenges = async (
    values: ActivityChallengeFormValues
  ) => {
    await agent.ActivitiesChallenges.create(values);
    const data = new ActivityChallenge(values);

    runInAction(() => {
      this.selectedActivitiesChallenges = data;
    });
  };

  updateMSMEBlinkActivitiesChallenges = async (
    values: ActivityChallengeFormValues
  ) => {
    await agent.ActivitiesChallenges.update(values);
  };

  deleteMSMEBlinkActivitiesChallenges = async (id: number) => {
    this.loading = true;
    try {
      await agent.ActivitiesChallenges.delete(id);
      runInAction(() => {
        this.activitiesChallengesRegistry.delete(id);
        this.loading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  clearMSMEBlinkActivitiesChallenges = () => {
    this.selectedActivitiesChallenges = undefined;
  };
}

//*************************************************************
// PROJECT NAME : Blinh Hub Network
// CLASS        : MSMEBlinkActivitiesChallenges
// DATE CREATED : 14 February 2022
// TIME CREATED : 04:53:57
// DEVELOPER    : Ibinaiye Wale Kayode
//*************************************************************

import React, { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Formik } from "formik";
import * as Yup from "yup";
import { ActivityChallengeFormValues } from "../../../app/models/MSMEBlinkActivitiesChallenges";
import { useStore } from "../../../app/stores/store";
import MyTextInput from "../../../app/common/form/MyTextInput";
import { Button, Form, Header, Icon, Message } from "semantic-ui-react";
import DXLoadingComponent from "../../../app/layout/DXLoadingComponent";
import { IMasterDetail } from "../../../app/common/staticProps";
import { AxiosError } from "axios";
import notify from "devextreme/ui/notify";

type Props = {
  rowID?: number;
  setMode?: React.Dispatch<React.SetStateAction<IMasterDetail>>;
};

const MSMEBlinkActivitiesChallengesForm: React.FC<Props> = ({
  rowID,
  setMode,
}) => {
  const [initialValues, setInitialValues] =
    useState<ActivityChallengeFormValues>(new ActivityChallengeFormValues());
  const { MSMEBlinkActivitiesChallengesStore } = useStore();
  const {
    createMSMEBlinkActivitiesChallenges,
    updateMSMEBlinkActivitiesChallenges,
    loadMSMEBlinkActivitiesChallenges,
    loadingInitial,
    setLoadingInitial,
  } = MSMEBlinkActivitiesChallengesStore;

  const validationSchema = Yup.object({
    challengeOID: Yup.string().required("The  ChallengeOID Is required"),
    blinkActivityOID: Yup.string().required(
      "The  BlinkActivityOID Is required"
    ),
    logType: Yup.string().required("The  LogType Is required"),
    challenge: Yup.string().required("The  Challenge Is required"),
    lessonsLerned: Yup.string().required("The  Lessons Learned Is required"),
  });

  const handleBack = useCallback(() => {
    // setMode("GRID");
  }, []);

  useEffect(() => {
    if (!rowID) return setLoadingInitial(false);
    loadMSMEBlinkActivitiesChallenges(rowID)
      .then((res: any) => {
        const response = res.result[0];
        setInitialValues(new ActivityChallengeFormValues(response));
      })
      .catch((err: AxiosError) => {
        notify("Bad Request", "error", 3000);
        handleBack();
      });
  }, [rowID, loadMSMEBlinkActivitiesChallenges, setLoadingInitial, handleBack]);

  const handleSubmit = (values: ActivityChallengeFormValues) => {
    try {
      if (rowID) {
        const newMSMEBlinkActivitiesChallenges = {
          ...values,
        };
        updateMSMEBlinkActivitiesChallenges(
          newMSMEBlinkActivitiesChallenges
        ).then(() => {
          handleBack();
        });
      } else {
        createMSMEBlinkActivitiesChallenges(values).then(() => {
          handleBack();
        });
      }
    } catch (error) {
      notify("Server Error", "error", 3000);
    }
  };

  if (loadingInitial) return <DXLoadingComponent />;

  return (
    <div>
      <Header as='h2'>
        <Icon
          onClick={handleBack}
          size='big'
          name='arrow circle left'
          color='blue'
          style={{ cursor: "pointer" }}
        />
        MSMEBlinkActivitiesChallenges Details
      </Header>
      <Message color='brown' size='mini'>
        <Message.Header>Manage MSMEBlinkActivitiesChallenges</Message.Header>
        <p> You can create and manage a msmeblinkactivitieschallenges. </p>
      </Message>
      <Formik
        validationSchema={validationSchema}
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values) => handleSubmit(values)}>
        {({ handleSubmit, isValid, isSubmitting, dirty }) => (
          <Form className='ui form' autoComplete='off' onSubmit={handleSubmit}>
            <MyTextInput
              placeholder='ChallengeOID'
              label='Challenge OID'
              name='challengeOID'
            />
            <MyTextInput
              placeholder='BlinkActivityOID'
              label='BlinkActivity OID'
              name='blinkActivityOID'
            />
            <MyTextInput
              placeholder='LogType'
              label='Log Type'
              name='logType'
            />
            <MyTextInput
              placeholder='Challenge'
              label='Challenge'
              name='challenge'
            />
            <MyTextInput
              placeholder='Lessons Learned'
              label='Lessons Learned'
              name='lessonsLerned'
            />

            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                disabled={isSubmitting || !dirty || !isValid}
                loading={isSubmitting}
                floated='right'
                positive
                type='submit'
                content='Submit'
              />
              <Button
                onClick={handleBack}
                floated='right'
                type='button'
                content='Cancel'
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default observer(MSMEBlinkActivitiesChallengesForm);

import React, { useState } from "react";
import { useStore } from "../../app/stores/store";
import { Button, Grid, Header, Segment, Tab } from "semantic-ui-react";
import ProfileEditForm from "./ProfileEditForm";
import { observer } from "mobx-react-lite";
import ProfilePersonalDataEditForm from "./ProfilePersonalDataEditForm";

export default observer(function ProfilePersonalData() {
  const { profileStore, userStore } = useStore();
  const { user } = userStore;
  const { isCurrentUser, profile } = profileStore;
  const [editMode, setEditMode] = useState(false);

  return (
    <Tab.Pane>
      <Grid>
        <Grid.Column width="16">
          {/* <Segment>
            <h1>Personal Data</h1>

            <div className="ui warning message">
              {isCurrentUser && (
                <Button
                  floated="right"
                  basic
                  content={editMode ? "Cancel" : "Edit Data"}
                  onClick={() => setEditMode(!editMode)}
                />
              )}
              <div className="header">Personal Data Update.</div>
              <p>
                Provide correct detail information about yourself. Please note
                that your data is protected and can only be viewed by the users
                you want to view it.
              </p>
            </div>
            <div className="ui divider" title="List of Resources"></div>
          </Segment> */}
        </Grid.Column>
        <Grid.Column width="16">
          {/* {editMode ? ( */}
          {/* <ProfilePersonalDataEditForm setEditMode={setEditMode} /> */}
          <ProfilePersonalDataEditForm
            setMode={() => setEditMode(true)}
            userGUID={user?.userGUID}
          />
          {/* ) : ( */}
          {/* <span style={{ whiteSpace: "pre-wrap" }}>{profile?.bio}</span> */}
          {/* )} */}
        </Grid.Column>
      </Grid>
    </Tab.Pane>
  );
});

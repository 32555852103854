import React from "react";
import { Segment } from "semantic-ui-react";

export const Marketplace = () => {
  return (
    <Segment>
      <h1>Marketplace Dashboard</h1>
      <div className="ui warning message">
        <div className="header">Dashboards for accessing Marketplaces.</div>
        <p>
          This dashboard provides a centralized access to available Marketplaces.
        </p>
      </div>
      <div className="ui divider" title="List of Resources"></div>
    </Segment>
  );
};

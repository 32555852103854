//*************************************************************
// PROJECT NAME : Blink Hub Network
// CLASS        : ToolBLinkCosterExpense
// DATE CREATED : 10 February 2022
// TIME CREATED : 01:20:53
// DEVELOPER    : Ibinaiye Wale Kayode
//*************************************************************

import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import { Button, Form, Header, Icon, Message } from "semantic-ui-react";
// import LoadingComponent from "../../../../app/layout/LoadingComponent";
import { useStore } from "../../../app/stores/store";
import { Formik, Form as FForm } from "formik";
import * as Yup from "yup";
import MyTextInput from "../../../app/common/form/MyTextInput";
// import MyTextArea from "../../../../app/common/form/MyTextArea";
// import MySelectInput from "../../../../app/common/form/MySelectInput";
//import { resourceOptions } from "../../../app/common/options/categoryOptions";
// import MyDateInput from "../../../../app/common/form/MyDateInput";
import { CosterExpenseFormValues } from "../../../app/models/CosterExpense";
import { IMasterDetail } from "../../../app/common/staticProps";
import DXLoadingComponent from "../../../app/layout/DXLoadingComponent";

type IProp = {
  rowID: number;
  setMode: React.Dispatch<React.SetStateAction<IMasterDetail>>;
};

export default observer(function ToolBLinkCosterExpenseForm({
  rowID,
  setMode,
}: IProp) {
  const { toolBLinkCosterExpenseStore } = useStore();
  const {
    createCosterExpense,
    updateCosterExpense,
    loadCosterExpense,
    loadingInitial,
    setLoadingInitial,
  } = toolBLinkCosterExpenseStore;

  const [initialValues, setInitialValues] = useState<CosterExpenseFormValues>(
    new CosterExpenseFormValues()
  );

  const handleBack = useCallback(() => {
    setMode("GRID");
  }, [setMode]);

  const validationSchema = Yup.object({
    // ExpenseOID: Yup.string().required("The  ExpenseOID Is required"),
    expenseDescription: Yup.string().required(
      "The expenseDescription Is required"
    ),
    unitPrice: Yup.string().required("The  unitPrice Is required"),
  });

  useEffect(() => {
    if (!rowID) return setLoadingInitial(false);
    // if (rowID)
    loadCosterExpense(rowID).then((res: any) => {
      const response = res.result[0];
      // console.log(response);

      setInitialValues(new CosterExpenseFormValues(response));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowID]);

  function handleSubmit(values: CosterExpenseFormValues) {
    try {
      if (!rowID) {
        let newValues = {
          ...values,
        };
        createCosterExpense(newValues).then(() => {
          handleBack();
        });
      } else {
        updateCosterExpense(values).then(() => {
          handleBack();
        });
      }
    } catch (error) {}
  }

  if (loadingInitial) return <DXLoadingComponent />;

  return (
    <div>
      <Header as='h2'>
        <Icon
          onClick={handleBack}
          size='big'
          name='arrow circle left'
          color='blue'
          style={{ cursor: "pointer" }}
        />
        Expense
      </Header>
      <Message color='brown' size='mini'>
        <Message.Header>Manage Expense</Message.Header>
        <p> You can create and manage Expense. </p>
      </Message>
      <Formik
        validationSchema={validationSchema}
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values) => handleSubmit(values)}>
        {({ handleSubmit, isValid, isSubmitting, dirty }) => (
          <FForm className='ui form' autoComplete='off' onSubmit={handleSubmit}>
            <Form.Group widths={2}>
              <MyTextInput
                disabled
                placeholder='expenseOID'
                label='OID'
                name='expenseOID'
              />
              <MyTextInput
                placeholder='expenseDescription'
                label='expenseDescription'
                name='expenseDescription'
              />
            </Form.Group>
            <Form.Group widths={2}>
              <MyTextInput
                placeholder='unitPrice'
                label='unitPrice'
                type='number'
                name='unitPrice'
              />
              <MyTextInput
                placeholder='unitOfMeasure'
                label='unitOfMeasure'
                name='unitOfMeasure'
              />
            </Form.Group>
            {/* <Button
              disabled={isSubmitting || !dirty || !isValid}
              loading={isSubmitting}
              floated="right"
              positive
              type="submit"
              content="Submit"
            />
            <Button
              onClick={handleBack}
              floated="right"
              type="button"
              content="Cancel"
            /> */}
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                disabled={isSubmitting || !dirty || !isValid}
                loading={isSubmitting}
                floated='right'
                positive
                type='submit'
                content='Submit'
              />
              <Button
                onClick={handleBack}
                to='/resources'
                floated='right'
                type='button'
                content='Cancel'
              />
            </div>
          </FForm>
        )}
      </Formik>
    </div>
  );
});

import { observer } from "mobx-react-lite";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Header, Segment, Icon, Confirm } from "semantic-ui-react";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { store, useStore } from "../../../app/stores/store";
import { v4 as uuid } from "uuid";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import MyTextInput from "../../../app/common/form/MyTextInput";
import MyTextArea from "../../../app/common/form/MyTextArea";
import MyDisabledInput from "../../../app/common/form/MyDisbabledInput";
import MySelectInput from "../../../app/common/form/MySelectInput";
import { resourceOptions } from "../../../app/common/options/categoryOptions";
import MyDateInput from "../../../app/common/form/MyDateInput";
//import { ActivityFormValues } from "../../../app/models/activity";

import agentExpress from "../../../app/api/agentExpress";
import {
  ApplicationModel,
  ApplicationFormValues,
} from "../../../app/models/application";

export default observer(function ApplicationForm({
  applicationOID,
  setMode,
}: {
  applicationOID: string;
  setMode: Function;
}) {
  // const navigate = useNavigate();
  const { applicationStore } = useStore();
  const {
    createApplicationModel,
    updateApplicationModel,
    loadApplicationModel,
    deleteApplicationModel,
    loadingInitial,
  } = applicationStore;
  // const { resourceGUID } = useParams<{ resourceGUID: string }>();
  const [open, setOpen] = useState(false);
  const baseAPI = process.env.REACT_APP_API_URL; // "http://localhost:5000/api";
  const jwt = store.commonStore.token;
  const [application, setApplication] = useState<ApplicationModel | any>([]);
  const [applicationModel, setApplicationModel] =
    useState<ApplicationFormValues>(new ApplicationFormValues());

  const validationSchema = Yup.object({
    applicationOID: Yup.string().required("The  applicationOID is required"),
    applicationName: Yup.string().required("The  applicationName is required"),
    applicationDescription: Yup.string().required(
      "The  applicationDescription is required"
    ),
    //date: Yup.string().required("Date is required").nullable(),
    appPrefix: Yup.string().required("The  appPrefix is required"),
    status: Yup.string().required("The  status is required"),
    applicationOrder: Yup.string().required("The  appPrefix is required"),
    icon: Yup.string().required("The  icon is required"),
  });

  // useEffect(() => {
  //   if (countryOID) {
  //     // loadCountryCode(countryOID).then((country) => {

  //     // })
  //     axios
  //       .get(`http://localhost:5000/api/ecocountry/${countryOID}`)
  //       .then((res) => res.data?.result?.data[0])
  //       .then((data) => {
  //         setCountryCode(data);
  //         loadingInitial(false);
  //         // console.log(data);
  //       });
  //     // console.log(country?.result?.data[0]);
  //     // const data = country?.result?.data[0];
  //   }
  // }
  console.log(applicationOID);

  useEffect(() => {
    if (applicationOID)
      loadApplicationModel(applicationOID).then((application) =>
        setApplicationModel(new ApplicationFormValues(application))
      );
    agentExpress.Applications.details(applicationOID).then((response) => {
      // getCountryCode(country.countryOID)
      if (response) {
        // return response.filter((country: any) => {
        //   return country.countryOID;
        // });
        // setCountry(row.data.countryOID);
        console.log(response);

        setApplication(response?.result.data[0]);

        // console.log("6. useEffect response", response.result.data);
        // console.log(response.result.data);
      }
      return {
        data: response.result.data,
      };
    });
  }, [applicationOID, loadApplicationModel]);

  // function b(countryOID: any, ) {

  //   return result.filter((item) => {
  //     return item.countryOID === idToSearch;
  //   });
  // }
  let navigate = useNavigate();
  function handleFormSubmit(application: ApplicationFormValues) {
    if (!application.applicationOID) {
      let newApplicationModel = {
        ...application,
        applicationOID: uuid(),
      };
      createApplicationModel(new ApplicationModel()).then((response) =>
        fetch(
          `${baseAPI}/EcoApplications/${newApplicationModel.applicationOID}`
        )
      );
    } else {
      updateApplicationModel(application).then(() =>
        navigate(`${baseAPI}/EcoApplications/${application.applicationOID}`, {
          // method: "PUT",
          // headers: {
          //   Authorization: `Bearer ${jwt}`,
          //   "Content-Type": "application/json",
          // },
        })
      );
    }
  }

  const handleDelete = (id: any) => {
    fetch(`${baseAPI}/EcoApplications/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${jwt}`,
        "Content-Type": "application/json",
      },
    }).then((result) => {
      result.json().then((resp) => {
        console.log(resp.result.data);
        return {
          data: resp.result.data,
        };
      });
    });
    setMode();
  };

  const handleEdit = (id: any) => {
    fetch(`${baseAPI}/EcoApplications/${id}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${jwt}`,
        "Content-Type": "application/json",
      },
    }).then((result) => {
      result.json().then((resp) => {
        console.log(resp.result.data);
        return {
          data: resp.result.data,
        };
      });
    });
    setMode();
  };
  if (loadingInitial)
    return <LoadingComponent content="Loading application..." />;

  const show = () => {
    setOpen(true);
  };
  const handleConfirm = () => {
    setOpen(false);
  };
  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <Segment clearing>
      <Icon
        name="arrow alternate circle left"
        onClick={() => setMode()}
        floated="left"
        size="big"
        type="button"
      />
      {/* <Button
        // disabled={isSubmitting || !dirty || !isValid}
        // loading={isSubmitting}
        floated="left"
        // positive
        style={{ marginTop: "15px" }}
        type="button"
        // content="Back"
        onClick={() => setMode()}
        icon="arrow alternate circle left black center big"
      /> */}
      <h1 style={{ marginTop: "-30px", marginLeft: "35px" }}>
        Application Details
      </h1>
      <div className="ui warning message">
        <div className="header">Create, edit and delete Application.</div>
        <p>
          You can manage resources from this page. You can create, edit, delete
          an application.
        </p>
      </div>
      {/* <Header content="Resource Details" sub color="teal" /> */}
      <Formik
        validationSchema={validationSchema}
        enableReinitialize
        initialValues={application}
        // onSubmit={(values) => handleFormSubmit(values)}
        onSubmit={(values) => {
          handleFormSubmit(values);
          // setMode();
        }}>
        {({ handleSubmit, isValid, isSubmitting, dirty }) => (
          <Form
            className="ui form"
            // onSubmit={handleSubmit}
            onSubmit={() => {
              handleSubmit();
              // setMode();
            }}
            autoComplete="off">
            <MyDisabledInput
              placeholder="applicationOID"
              name="applicationOID"
              label="Application OID"
            />
            <MyTextInput
              name="applicationName"
              placeholder="applicationName"
              label="Application Name"
            />
            <MyTextInput
              name="applicationDescription"
              placeholder="applicationDescription"
              // rows={3}
              label="Application Description"
            />
            <MyTextInput
              placeholder="appPrefix"
              name="appPrefix"
              // rows={3}
              label="App Prefix"
            />
            <MyTextInput
              placeholder="status"
              name="status"
              // rows={3}
              label="Status"
            />

            <MyTextInput
              placeholder="applicationOrder"
              name="applicationOrder"
              // rows={3}
              label="Application Order"
            />

            <MyTextInput
              placeholder="icon"
              name="icon"
              // rows={3}
              label="Icon"
            />

            <Button
              as={Link}
              onClick={() => setMode()}
              floated="right"
              type="button"
              content="Cancel"
            />
            <div>
              <Button
                negative
                type="button"
                floated="right"
                content="Delete"
                onClick={() => show()}
              />
              <Confirm
                open={open}
                content="Are you sure you wish to delete this entity?"
                cancelButton="No"
                confirmButton="Yes"
                onConfirm={() => handleDelete(applicationOID)}
                onCancel={handleCancel}
                size="tiny"
              />
            </div>
            {/* <Button
              negative
              type="button"
              floated="right"
              content="Delete"
              onClick={() => {
                if (
                  window.confirm("Are you sure you wish to delete this entity?")
                ) {
                  handleDelete(countryOID);
                }
              }}
            /> */}
            <Button
              disabled={isSubmitting || !dirty || !isValid}
              loading={isSubmitting}
              floated="right"
              positive
              type="submit"
              content="Submit"
            />
          </Form>
        )}
      </Formik>
    </Segment>
  );
});

import React, { useState } from "react";
import { Container } from "semantic-ui-react";
import MainMenu from "../layout/MainMenu";
import NavBar from "../layout/NavBar";
import MergeHubRoutes from "./mergeHubRoutes";

// export default function MainLayout() {
//     return (

//     )
// };

export function WrapperContainer() {
  const [showMainMenu, setShowMainMenu] = useState(false);

  const handleClick = (): void => {
    setShowMainMenu(!showMainMenu);
  };
  return (
    <>
      <NavBar handleClick={handleClick} />
      <Container fixed='left'>
        <MainMenu showMainMenu={showMainMenu} handleClick={handleClick} />
      </Container>
      <Container style={{ marginTop: "7em" }}>{<MergeHubRoutes />}</Container>
    </>
  );
}

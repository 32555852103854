export const supportTicketData = [
  {
    supportTicketoid: "001",
    supportTicketguid: "348d7c86-3306-4438-eaa4-08d97407645e",
    issuedescription:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt necessitatibus molestiae in reprehenderit nemo deleniti placeat praesentium iusto repellendus recusandae?",
    image: ",",
    postedBy: "Henry Noble",
    solution:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt necessitatibus molestiae in reprehenderit nemo deleniti placeat praesentium iusto repellendus recusandae?",
    solvedBY: "Henry Noble",
    datePosted: "10-20-2020",
    dateSolved: "10-20-2020",
    title: "Poultry Bords Not Laying optimally",
    
  },
];

export const advertismentData = [
  {
    advertOID: "001",
    productName: "Quail Eggs",
    productDescription: ["Line 1", "Line 2"],
    contactName: "Ibinaiye Wale Kayode",
    phoneNumber: "08033131904",
    email: "csa.cad@gmail.com",
    postedBy: "Ibinaiye Wale Kayode",
    website: "www.cadconsultinglimited.com",
    facebook: "ibwki",
    instagram: "ibwki",
    twitter: "ibwki",
    Linkedin: "ibwki",
    // socials:{
    // }
  },
];

export const contactCardData = [
  {
    advertOID: "001",
    companyName: "Cad consulting limited",
    contactName: "Ibinaiye Wale Kayode",
    serviceDescription: [
      "Lorem ipsum dolor sit amet, cor adipisicing elit. Et, neque?",
      "Lorem ipsum dolor sur adipisicing elit. Et, neque?",
      "Lorem ipsum dolor sit amet, consectetur adipisicing . Et, neque?",
      "Lorem ipsum dolor sit amet, consectetur adipisicing, neque?",
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Et, "
    ],
    phoneNumber: "08033131904",
    email: "csa.cad@gmail.com",
    // postedBy: "Ibinaiye Wale Kayode",
    website: "www.cadconsultinglimited.com",
    facebook: "ibwki",
    instagram: "ibwki",
    twitter: "ibwki",
    Linkedin: "ibwki",
    // socials:{
    // }
  },
];


export const marketsData = [
  {
    productOID: "",
    marketName: "",
    description: "",
    city: "",
    state: "",
    streetAddress: "",
    country: "",
    contactPerson: "",
    email: "",
    phoneNumber: "",
    website: "",
  },
];

export const paymentTermsData = [
  {
    paymentTermOID: "",
    paymentTermName: "",
    description: "",
    condition: "",
  },
  {
    paymentTermOID: "",
    paymentTermName: "",
    description: "",
    condition: "",
  },
];


export const paymentTableData = [
  { sn: 1, buyer: "cad1" },
  { sn: 2, buyer: "cad2" },
  { sn: 3, buyer: "cad3" },
];

export const productandServices = [
  {
    productOID:'',
    productName:'',
    description:''
  }
]


export const productansServicesTableData = [
  { oid: 1, buyer: "cad1" },
  { oid: 2, buyer: "cad2" },
  { oid: 3, buyer: "cad3" },
];

export const linkageMapHeaderData = [
  {
    id:0,
    title:'',
    startDate:'',
    msme:'',
    product:'',
    endDate:'',
  }
]


export const linkageMapTableData = [
  { 
    id:1,
    income:20_000,
    expense:15_000,
  },
  { 
    id:2,
    income:25_000,
    expense:12_000,
  },
  { 
    id:3,
    income:23_000,
    expense:13_000,
  },
  { 
    id:4,
    income:19_000,
    expense:29_000,
  },
]


export const linkagemapExpenseData =[
  { 
    id:1,
    expense:1_000,
  },
  { 
    id:2,
    expense:2_000,
  },
  { 
    id:3,
    expense:3_000,
  }
]
export const linkagemapIncomeData =[
  { 
    id:1,
    expense:1_000,
  }
]


export const createGroupForm = [
  { 
    oid: 0,
    groupName:'',
    description:'',

  }
]

export const businessClinicStep2 = [
  {
    oid:1,
    name:'Henry Noble',
    report:'hello',
    specialization:'chicken',
  },
  {
    oid:1,
    name:'Henry Noble',
    report:'hello',
    specialization:'pigs',
  },
]


export const bcGridData =[
  {
    id:1,
    description:'bad market',
    problemCategory:'customers',
    problemSubCategory:'msny competitors',
    problemArea:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. et!',
  },
  {
    id:2,
    description:'bad market 1',
    problemCategory:'customers',
    problemSubCategory:'msny competitors',
    problemArea:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. et!',
  },
  {
    id:1,
    description:'bad market 2',
    problemCategory:'customers',
    problemSubCategory:'msny competitors',
    problemArea:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. et!',
  },
]

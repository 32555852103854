export interface RoleDefinitionModel {
  roleOID: string;
  name: string;
  description: string;
  rolePriority: string;
  concurrencyStamp: string;
  dateUploaded: Date | null;

  // date: Date | null;
  // description: string;
  // category: string;
  // city: string;
  // venue: string;
  // hostUsername: string;
  // isCancelled: boolean;
  // isGoing: boolean;
  // isHost: boolean;
  // host?: Profile;
  // attendees: Profile[]
}

export class RoleDefinitionModel implements RoleDefinitionModel {
  constructor(init?: RoleDefinitionFormValues) {
    Object.assign(this, init);
  }
}

export class RoleDefinitionFormValues {
  roleOID?: string = undefined;
  name: string = "";
  description: string = "";
  rolePriority: string = "";
  concurrencyStamp: string = "";

  // dateUploaded: Date  = Date.now();

  constructor(roledefinition?: RoleDefinitionFormValues) {
    if (roledefinition) {
      this.roleOID = roledefinition.roleOID;
      this.name = roledefinition.name;
      this.concurrencyStamp = roledefinition.concurrencyStamp;
      this.description = roledefinition.description;
      this.rolePriority = roledefinition.rolePriority;
    }
  }
}

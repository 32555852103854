import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Image,
  Container,
  Message,
  Divider,
  Segment,
  Header,
  Grid,
  Card,
} from "semantic-ui-react";

const dashboardItems = [
  {
    id: "1",
    title: "Create and Manage Business Plan",
    description: "Create a professional business plan in minutes.  ",
    image: "/assets/BH-business-plan.jpg",
    link: "/app/view/manage-bplist",
    tab: true,
  },
  // {
  //   id: "2",
  //   title: "Get Business support from experts",
  //   description:
  //     "Interact with Business Support Providers to help you out with business related issues.",
  //   image: "/assets/BH-business-support-services.jpg",
  //   link: "app/create/supportticket",
  //   tab: false,
  // },
  {
    id: "2",
    title: "Get Business Support from experts",
    description:"Interact with Business Support Providers to help you out with business related issues.",
    image: "/assets/BH-business-support-services.jpg",
    link: "/app/create/supportticket",
    tab: false,
  },
  {
    id: "3",
    title: "I need Business Linkage",
    description:
      "Get linked to various  stakeholders an business oppotunities. ",
    image: "/assets/BH-business-linkages.png",
    link: "/app/view/linkagemap",
    tab: false,
  },
  {
    id: "4",
    title: "Visit Business Clinic",
    description: "Report issues and allow business 'doctors' to fix it. ",
    image: "/assets/BH-BUSINESS-CLINIC.jpg",
    link: "/app/view/businessclinic",
    tab: false,
  },
  {
    id: "5",
    title: "Look for Upcoming events",
    description: "Search for an upcoming event that is beneficial to attend. ",
    image: "/assets/BH-upcoming-events.jpg",
    link: "/app/view/activities",
    tab: false,
  },
  {
    id: "6",
    title: "Helpers online",
    description:
      "Access business support providers currently online to help you .",
    image: "/assets/BH-helpers-online.png",
    link: "/app/view/list-helpersonline",
    tab: false,
  },
  
];

export default function AppDashboard() {
  return (
    <Container>
      <Segment.Group style={{ marginTop: "50px" }}>
        <Segment color="brown">
          <Header size="medium">What will you like to do ?</Header>
          <Message
            icon="info circle"
            color="brown"
            content="Blinkhub provides you with a lot of business support services. Tou can select one from the list provided below"
          />
          <Header dividing as="h2">
            Make a choice to proceed
          </Header>

          <Grid columns="3" stackable stretched>
            {dashboardItems.map((item) => (
              <>
                <Grid.Column key={item.id} stretched name="dashboard">
                  <Card
                    style={{
                      height: "100%",
                      boxShadow: "0 5px 15px rgba(0, 0, 0, 0.2)",
                    }}
                    as={Link}
                    target={item.tab && "_blank"}
                    to={`${item.link}`}
                    labelPosition="right"
                  >
                    <Image
                      style={{ height: "150px" }}
                      src={item.image}
                      fluid
                      verticalAlign="middle"
                    />
                    <Card.Content>
                      <Card.Description basic>
                        <h5>{item.title}</h5>
                      </Card.Description>
                      <Divider />
                      <Card.Meta> {item.description} </Card.Meta>
                    </Card.Content>
                    <Card.Content>
                      <Button fluid content="Continue" color="red" />
                    </Card.Content>
                  </Card>
                </Grid.Column>
              </>
            ))}
          </Grid>
        </Segment>
      </Segment.Group>
    </Container>
  );
}

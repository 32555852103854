// import { observer } from "mobx-react-lite";
import React, { Fragment, useEffect, useState } from "react";
// import { Link, useHistory, useParams } from "react-router-dom";
// import { Button, Header, Segment } from "semantic-ui-react";
import { Table } from "semantic-ui-react";
// import LoadingComponent from "../../../app/layout/LoadingComponent";
import { useStore } from "../../../app/stores/store";
//import { ActivityFormValues } from "../../../app/models/activity";
// import { Resource, ResourceFormValues } from "../../../app/models/resource";
import agentExpress from "../../../app/api/agentExpress";
import { Resource } from "../../../app/models/resource";
import { resourceStaticData } from "../form/resourceData";
import { Card, Image, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";

export default function ResourceList() {
  // const history = useHistory();
  const baseAPI = process.env.REACT_APP_API_URL; // "https://localhost:44322/api";
  const [result, setResult] = useState<Resource[]>([]);
  const { resourceStore } = useStore();
  const { resourceV2Data, loadResourcesDashboard, loading } = resourceStore;
  // const { resourceGUID } = useParams<{ resourceGUID: string }>();

  // useEffect(() => {
  //   if (resourceData.length === 0) loadResourcesDashboard();
  // }, [resourceData.length, loadResourcesDashboard]);

  useEffect(() => {
    // if (resourceData.length === 0) {
    //   const tmp =  axios
    //   .get<Resource[]>(`${baseAPI}/ResourceV2s`)
    //   .then((response) => {
    //     return {
    //       data: response.data,
    //     };
    //   });
    // };
    // if (result?.length !== 0) return;

    try {
      // if (resourceV2Data.length === 0) {
      //   loadResourcesDashboard();
      //        setResult(resourceV2Data);
      //        console.log("5. resourceData", resourceV2Data);
      // }

      agentExpress.Resources.list().then((response) => {
        if (response) {
          setResult(response?.result.data);
          // console.log("6. useEffect response", response.result.data);
        }
      });
    } catch (error) {
      console.log("6. Error :", error);
    }

    // setResult(tmp);

    // try {
    //   const fetchResource = async () => {
    //     const response = await axios.get<Resource[]>(`${baseAPI}/ResourceV2s`);
    //     const data = response.data;

    //     console.log("2. Data", data);
    //     // setResult(data)
    //   };
    // } catch (error) {
    //   console.log(error);
    // }
  }, [
    // resourceV2Data.length,
    result.length,
    // resourceV2Data,
    // loadResourcesDashboard,
  ]);

  return (
    <Fragment>
      <Card.Group>
        {result &&
          result.map((resource, i) => (
            <Card link>
              <Card.Content>
                {/* <Image
                  floated="right"
                  size="mini"
                  src="/assets/categoryimages/food.jpg"
                /> */}
                <Card.Header>{resource.title}</Card.Header>
                <Card.Meta>{resource.code}</Card.Meta>
                <Card.Description>
                  {resource.description} <strong>{resource.category}</strong>
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                <span>
                  Downloads: <strong>{resource.downloads}</strong> Size{" "}
                  <strong>{resource.size}</strong>
                </span>
                <div className="ui two buttons">
                  <Button
                    as={Link}
                    target="_blank"
                    rel="noopener noreferrer"
                    to={`/content/${resource.resourceGUID}.pdf`}
                    basic
                    color="green"
                    onClick={() => {
                      alert("Put download code here." + resource.resourceGUID);
                    }}
                  >
                    {/* <a
                      href={`https://blinkhubapp.caderp.com/content/${resource.resourceGUID}.pdf`}
                      download="test.pdf"
                      // target="_blank"
                      // rel="noopener noreferrer"
                    > */}
                    Download
                    {/* </a> */}
                  </Button>
                  <Button
                    as={Link}
                    target="_blank"
                    rel="noopener noreferrer"
                    basic
                    color="red"
                    // onClick={() => {
                    //   alert("Put view code here." + resource.resourceGUID);
                    // }}
                    to={`/content/${resource.resourceGUID}.pdf`}
                  >
                    {/* <a
                      href={`https://blinkhubapp.caderp.com/content/${resource.resourceGUID}.pdf`}
                      download="test.pdf"
                      // target="_blank"
                      // rel="noopener noreferrer"
                    > */}
                    View
                    {/* </a> */}
                  </Button>
                </div>
              </Card.Content>
            </Card>
          ))}
      </Card.Group>
      hello

      {/* <Table celled selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Sno.</Table.HeaderCell>
            <Table.HeaderCell>Resource GUID</Table.HeaderCell>
            <Table.HeaderCell>Code</Table.HeaderCell>
            <Table.HeaderCell>Category</Table.HeaderCell>
            <Table.HeaderCell>Title</Table.HeaderCell>
            <Table.HeaderCell>Description</Table.HeaderCell>
            <Table.HeaderCell>Size</Table.HeaderCell>
            <Table.HeaderCell>Source</Table.HeaderCell>
            <Table.HeaderCell>Downloads</Table.HeaderCell>
            <Table.HeaderCell>DateUp loaded</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {result?.length !== 0 &&
            result.map((resource, i) => (
              <Table.Row key={resource.resourceGUID}>
                <Table.Cell>{i + 1}</Table.Cell>
                <Table.Cell>{resource.resourceGUID}</Table.Cell>
                <Table.Cell>{resource.code}</Table.Cell>
                <Table.Cell>{resource.category}</Table.Cell>
                <Table.Cell>{resource.title}</Table.Cell>
                <Table.Cell>{resource.description}</Table.Cell>
                <Table.Cell>{resource.size}</Table.Cell>
                <Table.Cell>{resource.source}</Table.Cell>
                <Table.Cell>{resource.downloads}</Table.Cell>
                <Table.Cell>{resource.dateUploaded}</Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table> */}
    </Fragment>
  );
}

import React, { useState } from "react";
import {
  Segment,
  Message,
  Header,
  Button,
  // Accordion,
  // Label,
  // Icon,
} from "semantic-ui-react";
import { supportTicketData } from "../../../../app/common/TemStaticData";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import MyTextArea from "../../../../app/common/form/MyTextArea";

// import classes from "./ticket.module.css";
import ContactInformation from "./ContactInformation";
import DescriptionOfIssues from "./DescriptionOfIssues";
import RecommendedSolution from "./RecommendedSolution";

export const SupportTicketV2 = () => {
  // const [activeIndex, setActiveIndex] = useState(0);
  const [initialValues /* setInitialValues*/] = useState({
    recommendedSolution: `
# Hello world 

- Option 1
- option 2
`,
  });

  // const handleClick = (e: any, titleProps: any) => {
  //   let { index } = titleProps;
  //   const newIndex = activeIndex === index ? -1 : index;
  //   setActiveIndex(newIndex);
  // };

  const validationSchema = Yup.object({});

  return (
    <Segment>
      <h1>Support Ticket</h1>
      <Message color='brown'>
        <Message.Header className='header'>
          Manage Support Ticket.
        </Message.Header>
        <Message.Content>
          Create, manage and get feedback on Support Ticket.
        </Message.Content>
      </Message>
      <div className='ui divider' />

      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values) => console.log(values)}
        validationSchema={validationSchema}>
        {({ values, handleSubmit, isValid, isSubmitting, dirty }) => (
          <Form className='ui form' autoComplete='off' onSubmit={handleSubmit}>
            {/* <Accordion> */}
            {/* <Accordion.Title
                active={activeIndex === 0}
                index={0}
                onClick={handleClick}> */}
            {/* <Label color='brown' className={classes.accordion_width}>
                  <Icon name='dropdown' />
                  Header
                </Label> */}
            {/* </Accordion.Title> */}
            {/* <Accordion.Content active={activeIndex === 0}> */}
            <ContactInformation values={values} />
            {/* </Accordion.Content> */}
            {/* <Accordion.Title
                active={activeIndex === 1}
                index={1}
                onClick={handleClick}> */}
            {/* <Label color='brown' className={classes.accordion_width}>
                  <Icon name='dropdown' />
                  Header
                </Label> */}
            {/* </Accordion.Title> */}
            {/* <Accordion.Content active={activeIndex === 1}> */}
            <DescriptionOfIssues />
            {/* </Accordion.Content> */}

            {/* <Accordion.Title
                active={activeIndex === 2}
                index={2}
                onClick={handleClick}>
                <Label color='brown' className={classes.accordion_width}>
                  <Icon name='dropdown' />
                  Header
                </Label>
              </Accordion.Title> */}
            {/* <Accordion.Content active={activeIndex === 2}> */}
            <RecommendedSolution />
            {/* </Accordion.Content> */}
            {/* </Accordion> */}
          </Form>
        )}
      </Formik>
    </Segment>
  );
};

export const SupportTicketForm = () => {
  const validationSchema = Yup.object({
    issuedescription: Yup.string().required("The Issue descripion is required"),
    solution: Yup.string().required("Solution is required is required"),
  });
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          columnGap: "2px",
          marginBottom: 20,
        }}>
        <Header style={{ margin: 0 }} size='medium' className=''>
          Ticket: {supportTicketData[0].supportTicketoid}
        </Header>
        <Header style={{ margin: 0 }} size='medium' className=''>
          Title: {supportTicketData[0].title}
        </Header>
      </div>
      <Formik
        validationSchema={validationSchema}
        enableReinitialize
        initialValues={supportTicketData[0]}
        onSubmit={(values) => console.log(values)}>
        <Form autoComplete='off' className='ui form'>
          <MyTextArea
            id='issues-description'
            label='Description of issues'
            rows={3}
            placeholder='Description of issues'
            name='description'
          />
          <MyTextArea
            id='image'
            label='Image'
            rows={3}
            placeholder='Image'
            name='description'
          />
          <Header style={{ margin: 0 }} size='tiny'>
            Posted By : {supportTicketData[0].postedBy}
          </Header>
          <MyTextArea
            id='recomended-solution'
            label='Recomended Solution'
            rows={3}
            placeholder='Recomended Solution'
            name='description'
          />
          <Header style={{ margin: 0 }} size='tiny'>
            Recomended By : {supportTicketData[0].solvedBY}
          </Header>
          <Header style={{ margin: 0 }} size='tiny'>
            Date Posted : {supportTicketData[0].datePosted}
          </Header>

          <Button
            content='Save'
            // labelPosition="left"
            // icon="save"
            inverted
            // as={Link}
            // to={`Activities/${supportTicketData[0].supportTicketguid}`}
            style={{ marginBottom: "10px" }}
            color='brown'
          />
        </Form>
      </Formik>
    </>
  );
};

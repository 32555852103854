import React from "react";
import HtmlEditor, {
  Toolbar,
  Item,
  MediaResizing,
} from "devextreme-react/html-editor";

import { useField } from "formik";
import { Form, Label } from "semantic-ui-react";

import "devextreme/ui/html_editor/converters/markdown";

interface Props {
  name: string;
  defaultValue?: string;
  placeholder?: string;
  label?: string;
  id?: string;
  height?: string;
}

const sizeValues = ["8pt", "10pt", "12pt", "14pt", "18pt", "24pt", "36pt"];
const fontValues = ["Arial", "Georgia", "Tahoma", "Times New Roman", "Verdana"];
const headerValues = [false, 1, 2, 3, 4, 5];

export default function DxHtmlEditor(props: Props) {
  const [field, meta, helpers] = useField(props.name);

  return (
    <Form.Field error={meta.touched && !!meta.error}>
      <label htmlFor={props.id}>{props.label}</label>
      <HtmlEditor
        // defaultValue={props.defaultValue}
        defaultValue={field.value}
        valueType='markdown'
        height={props.height}
        // height='725px'
        onValueChanged={(e) => helpers.setValue(e.value)}>
        {/* Configuration goes here */}
        <Toolbar multiline={true}>
          <Item name='undo' />
          <Item name='redo' />
          <Item name='separator' />
          <Item name='size' acceptedValues={sizeValues} />
          <Item name='font' acceptedValues={fontValues} />
          <Item name='separator' />
          <Item name='bold' />
          <Item name='italic' />
          <Item name='strike' />
          <Item name='underline' />
          <Item name='separator' />
          <Item name='alignLeft' />
          <Item name='alignRight' />
          <Item name='alignJustify' />
          <Item name='separator' />
          <Item name='orderedList' />
          <Item name='bulletList' />
          <Item name='separator' />
          <Item name='header' acceptedValues={headerValues} />
          <Item name='separator' />
          <Item name='color' />
          <Item name='background' />
          <Item name='separator' />
          <Item name='link' />
          <Item name='image' />
          <Item name='separator' />
          <Item name='clear' />
          <Item name='codeBlock' />
          <Item name='blockquote' />
          <Item name='separator' />
          {/* Inserting Tables */}
          <Item name='insertTable' />
          <Item name='deleteTable' />
          <Item name='insertRowAbove' />
          <Item name='insertRowBelow' />
          <Item name='deleteRow' />
          <Item name='insertColumnLeft' />
          <Item name='insertColumnRight' />
          <Item name='deleteColumn' />
          <Item name='cellProperties' />
          <Item name='tableProperties' />
        </Toolbar>
        <MediaResizing enabled={true} />
      </HtmlEditor>

      {meta.touched && meta.error ? (
        <Label basic color='red'>
          {meta.error}
        </Label>
      ) : null}
    </Form.Field>
  );
}

//*************************************************************
// PROJECT NAME : Blinh Hub Network
// CLASS        : MSMEBlinkWeeklyUpdate
// DATE CREATED : 11 February 2022
// TIME CREATED : 08:09:30
// DEVELOPER    : Ibinaiye Wale Kayode
//*************************************************************

import React, { useEffect, useState } from "react";
import DataGrid, {
  FilterPanel,
  FilterRow,
  SearchPanel,
  Button,
  Paging,
  Popup,
  Editing,
  Export,
  Pager,
  Grouping,
  GroupPanel,
  RowDragging,
  Column,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import "whatwg-fetch";
import { store, useStore } from "../../../app/stores/store";
import { Segment } from "semantic-ui-react";
import { dxGridProps, IMasterDetail } from "../../../app/common/staticProps";
import MSMEBlinkWeeklyUpdateForm from "../forms/WeeklyUpdateForm";
import { observer } from "mobx-react-lite";

//********************FUNCTIONS******************************* */
function handleErrors(response: any) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}
//********************FUNCTIONS******************************* */

type Props = {};

const MSMEBlinkWeeklyUpdateGrid = (props: Props) => {
  const [dataSource, setDataSource] = useState();
  const [mode, setMode] = useState<IMasterDetail>("GRID");
  const { MSMEBlinkWeeklyUpdateStore } = useStore();
  const { setLoadingInitial } = MSMEBlinkWeeklyUpdateStore;
  const [rowID, setRowID] = useState(0);
  const jwt = store.commonStore.token;
  const baseAPI = process.env.REACT_APP_API_URL;

  useEffect(() => {
    // setRowID(0);
    const customDataSource: any = new CustomStore({
      key: "weeklyUpdateOID",
      load: () => {
        return fetch(`${baseAPI}/MSMEBlinkWeeklyUpdates`, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        })
          .then(handleErrors)
          .then((response) => response.json())
          .then((response) => {
            return {
              data: response.result.data,
              totalCount: response.count,
              summary: response.summary,
              groupCount: response.count,
            };
          });
      },
      // insert: (values) => {
      //   // console.log("Value =" + JSON.stringify(values));
      //   return fetch(`${baseAPI}/MSMEBlinkWeeklyUpdate`, {
      //     method: "POST",
      //     body: JSON.stringify(values),
      //     headers: {
      //       Authorization: `Bearer ${jwt}`,
      //       "Content-Type": "application/json",
      //     },
      //   }).then(handleErrors);
      // },
      remove: (key) => {
        return fetch(`${baseAPI}/MSMEBlinkWeeklyUpdates/${key}`, {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }).then(handleErrors);
      },
      // update: (values) => {
      //   return values;
      // },
    });

    //Save to store
    setDataSource(customDataSource);
  }, [baseAPI, jwt]);

  const handleEditClick = (args: any) => {
    setRowID(args.row.data.weeklyUpdateOID);
    setLoadingInitial(true);
    setMode("FORM-EDIT");
  };

  const handleNewTask = () => {
    setRowID(0);
    setMode("ADD-NEW");
  };

  function onToolbarPreparing(e: any) {
    let toolbarItems = e.toolbarOptions.items;

    // customize addRow toolbar button
    for (let i = 0; i < toolbarItems.length; i++) {
      let item = toolbarItems[i];
      if (item.name === "addRowButton") {
        item.options.onClick = handleNewTask;
        break;
      }
    }
  }

  return (
    <Segment>
      {mode === "GRID" ? (
        <>
          <h1>MSMEBlinkWeeklyUpdate</h1>
          <div className='ui warning message'>
            <div className='header'>List of MSMEBlinkWeeklyUpdate.</div>
            <p>
              The table below provides the list of all available
              MSMEBlinkWeeklyUpdate.
            </p>
          </div>
          <div
            className='ui divider'
            title='List of MSMEBlinkWeeklyUpdate'></div>
          <div>
            <DataGrid
              id='gridMSMEBlinkWeeklyUpdate'
              dataSource={dataSource && dataSource}
              {...dxGridProps}
              onToolbarPreparing={onToolbarPreparing}>
              <RowDragging allowReordering={true} />
              <FilterPanel visible={true} />
              <SearchPanel visible={true} />
              <FilterRow visible={true} />
              <Paging defaultPageSize={10} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[10, 20, 50, 100]}
                showInfo={true}
              />
              <Grouping contextMenuEnabled={true} />
              <GroupPanel visible={true} />
              <Editing
                mode='popup'
                useIcons={true}
                allowUpdating={true}
                allowDeleting={true}
                allowAdding={true}
              />
              <Popup
                title='MSMEBlinkWeeklyUpdate'
                showTitle={true}
                width={40}
                height={30}></Popup>
              <Export
                enabled={true}
                fileName='MSMEBlinkWeeklyUpdate'
                allowExportSelectedData={true}
              />

              <Column type='buttons'>
                <Button name='edit' onClick={handleEditClick} icon='edit' />
                <Button name='delete' />
              </Column>

              <Column
                dataField='weeklyUpdateOID'
                allowEditing={false}
                visible={true}></Column>
              <Column
                dataField='weekCode'
                allowEditing={true}
                visible={true}></Column>
              <Column
                dataField='startDate'
                allowEditing={true}
                visible={true}></Column>
              <Column
                dataField='description'
                allowEditing={true}
                visible={true}></Column>
              <Column
                dataField='performedBy'
                allowEditing={true}
                visible={true}></Column>
            </DataGrid>
          </div>
        </>
      ) : (
        <MSMEBlinkWeeklyUpdateForm rowID={rowID} setMode={setMode} />
      )}
    </Segment>
  );
};

export default observer(MSMEBlinkWeeklyUpdateGrid);

import React, { useCallback } from "react";
import { SelectBox } from "devextreme-react";
import { useField } from "formik";
import { Form, Label } from "semantic-ui-react";

interface Props {
  placeholder?: string;
  name: string;
  dataSource: any[];
  label?: string;
  id?: string;
  valueExpr: string;
  displayExpr: string;
}

export default function DxSelectBox(props: Props) {
  const [field, meta, helpers] = useField(props.name);

  const onValueChanged = useCallback(
    (e) => {
      //  console.log(e.previousValue);
      helpers.setValue(e.value);
    },
    [helpers]
  );
  return (
    <Form.Field error={meta.touched && !!meta.error}>
      <label htmlFor={props.id}>{props.label}</label>
      {/* <Select
        id={props.id}
        clearable
        options={props.options}
        value={field.value || null}
        onBlur={() => helpers.setTouched(true)}
        onChange={(e, d) => helpers.setValue(d.value)}
        placeholder={props.placeholder}
    /> */}
      <SelectBox
        dataSource={props.dataSource}
        valueExpr={props.valueExpr}
        displayExpr={props.displayExpr}
        searchEnabled={true}
        onValueChanged={onValueChanged}
        value={field.value || null}
        searchMode={"contains"}
        // showClearButton={true}
      />
      {meta.touched && meta.error ? (
        <Label basic color='red'>
          {meta.error}
        </Label>
      ) : null}
    </Form.Field>
  );
}

import React, { useState, useEffect } from "react";
import { useStore } from "../../app/stores/store";
import {
  Accordion,
  Button,
  Grid,
  GridColumn,
  Header,
  Icon,
  Message,
  Segment,
  Tab,
} from "semantic-ui-react";
import ProfileEditForm from "./ProfileEditForm";
import { observer } from "mobx-react-lite";
import { Form as FForm, Formik } from "formik";
import { Form } from "semantic-ui-react";
import {
  optionsAgeRange,
  optionsBSPServices,
  optionsBusinessLinkage,
  optionsGender,
  optionsMaritalStatus,
  optionsYesNo,
} from "../../app/common/staticData";
import * as Yup from "yup";
import CADDateInput from "../../app/common/form/CADDateInput";
import CADTextInput from "../../app/common/form/CADTextInput";
import CADTextArea from "../../app/common/form/CADTextArea";
import CADSelectInput from "../../app/common/form/CADSelectInput";
import {
  BusinessData,
  PersonalDataFormValues,
} from "../../app/models/personalData";
import {
  MsmeBusinessData,
  MsmeBusinessDataFormValues,
} from "../../app/models/msmeBusinessData";

export default observer(function ProfileBusinessInformation({
  userGUID,
}: {
  userGUID: string | undefined;
}) {
  const { profileStore, msmeBusinessInfoStore, userStore } = useStore();
  const { isCurrentUser, profile } = profileStore;
  const [editMode, setEditMode] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [businessData, setBusinessData] = useState<MsmeBusinessDataFormValues>(
    new MsmeBusinessDataFormValues()
  );
  const [loading, setLoading] = useState(false);

  const handleClick = (e: any, titleProps: any) => {
    const { index } = titleProps;
    // const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };
  const {
    profileStore: { updateProfile },
  } = useStore();
  const {
    createMsmeBusinessData,
    updateMsmeBusinessData,
    loadMsmeBusinessData,
    loadingInitial,
  } = msmeBusinessInfoStore;
  const { user } = userStore;

  useEffect(() => {
    if (userGUID) {
      console.log("Calling endpoint", userGUID);
      loadMsmeBusinessData(userGUID).then(async (businessData) => {
        console.log(businessData, "last here DATA HERE");
        const newObj = {
          ...businessData,
          displayName: profile?.displayName,
          msmeCode:
            businessData?.msmeCode === "" ? "NONE" : businessData?.msmeCode,
        };

        setBusinessData(
          new MsmeBusinessDataFormValues(newObj as MsmeBusinessData)
        );
      });
    }
  }, [userGUID, loadMsmeBusinessData, profile?.displayName]);

  function handleFormSubmit(
    personalData: MsmeBusinessDataFormValues | MsmeBusinessData
  ) {
    console.log("Form Values", personalData.msmeoid);
    setLoading(true);
    if (!personalData.userGUID) {
      let newBusinessData = {
        ...personalData,
        userGUID: user?.userGUID,
        dispayName: user?.displayName,
      };
      createMsmeBusinessData(newBusinessData).then(
        () => setLoading(false)
        // history.push(`/personaldata/${newPersonalData.msmeoid}`)
      );
    } else {
      updateMsmeBusinessData(businessData).then(
        () => setLoading(false)
        // history.push(`/personalData/${personalData.msmeoid}`)
      );
    }
  }

  return (
    <Tab.Pane>
      <Grid>
        <Grid.Column width="16">
          <Segment>
            <h1>Business Information</h1>

            <div className="ui warning message">
              {isCurrentUser && (
                <Button
                  floated="right"
                  basic
                  content={editMode ? "Cancel" : "Edit Data"}
                  onClick={() => setEditMode(!editMode)}
                />
              )}
              <div className="header">Business Information Update.</div>
              <p>
                Provide correct detail information about your business. Please
                note that your data is protected and can only be viewed by the
                users you want to view it.
              </p>
            </div>
            <div className="ui divider" title="List of Resources"></div>
          </Segment>
        </Grid.Column>

        <Grid.Column width="16">
          <Formik
            initialValues={businessData}
            // initialValues={{
            //   displayName: profile?.displayName,
            //   bio: profile?.bio,
            // }}
            // onSubmit={(values) => {
            //   updateProfile(values).then(() => {
            //     setEditMode(false);
            //   });
            // }}
            onSubmit={(values) => {
              handleFormSubmit(values);
            }}
            validationSchema={Yup.object({
              displayName: Yup.string().required(),
            })}>
            {({ handleSubmit, isSubmitting, isValid, dirty }) => (
              <FForm
                onSubmit={handleSubmit}
                autoComplete="off"
                className="ui form">
                <Form.Group widths="equal">
                  <Button
                    positive
                    type="submit"
                    loading={isSubmitting}
                    content="Update Personal Data"
                    floated="right"
                    disabled={!isValid || !dirty}
                  />
                </Form.Group>
                <Accordion fluid styled>
                  <Accordion.Title
                    active={activeIndex === 0}
                    index={0}
                    onClick={handleClick}>
                    <h3>
                      <Icon name="dropdown" />
                      General Business Information
                    </h3>
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === 0}>
                    <Message info>
                      <Message.Header>
                        Update General Information?
                      </Message.Header>
                      <p>
                        {" "}
                        Provide general information about your business below..
                      </p>
                    </Message>
                    <Header as="h3" dividing color={"brown"}>
                      GENERAL INFORMATION
                    </Header>
                    <Form.Group widths="equal">
                      <CADTextInput
                        label="MSME OID"
                        placeholder="MSME OID"
                        name="mSMEOID"
                        disabled
                      />
                      <CADTextInput
                        label="User GUID"
                        placeholder="User GUID"
                        name="userGUID"
                        disabled
                      />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <CADTextInput
                        label="MSME Code"
                        placeholder="MSME Code"
                        name="mSMECode"
                        disabled
                      />
                      <CADTextInput
                        label="Display Name"
                        placeholder="Display Name"
                        name="displayName"
                        disabled
                      />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <CADTextInput
                        label="Business Name"
                        placeholder="Business Name"
                        name="businessName"
                      />
                      <CADTextInput
                        label="Type Of Enterprise"
                        placeholder="Type Of Enterprise"
                        name="typeOfEnterprise"
                      />
                      <CADTextInput
                        label="Blink Category"
                        placeholder="Blink Category"
                        name="blinkCategory"
                      />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <CADTextInput
                        label="For Profit Or Not"
                        placeholder="For Profit Or Not"
                        name="forProfitOrNot"
                      />
                      <CADTextInput
                        label="Sector Of Focus"
                        placeholder="Sector Of Focus"
                        name="sectorOfFocus"
                      />
                      <CADTextInput
                        label="Business Area"
                        placeholder="Business Area"
                        name="businessArea"
                      />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <CADTextInput
                        label="Year Of Establisment"
                        placeholder="Year Of Establisment"
                        name="yearOfEstablisment"
                      />
                      <CADTextInput
                        label="Number Of Female Staff"
                        placeholder="Current Number Of Female Staff"
                        name="currentNumberOfFemaleStaff"
                      />
                      <CADTextInput
                        label="Number Of Male Staff"
                        placeholder="Current Number Of Male Staff"
                        name="currentNumberOfMaleStaff"
                      />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <CADTextArea
                        rows={3}
                        label="Business Vision"
                        placeholder="Business Vision"
                        name="businessVision"
                      />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <CADTextArea
                        rows={3}
                        label="Mission Statement"
                        placeholder="Mission Statement"
                        name="businessMission"
                      />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <CADTextArea
                        rows={3}
                        label="Passion"
                        placeholder="Passion"
                        name="passion"
                      />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <CADTextArea
                        rows={3}
                        label="Future Plan"
                        placeholder="Future Plan"
                        name="futurePlan"
                      />
                    </Form.Group>

                    <Header as="h3" dividing color={"brown"}>
                      BUSINESS ADDRESS
                    </Header>
                    <Form.Group widths="equal">
                      <CADTextInput
                        label="Business Email"
                        placeholder="Business Email"
                        name="businessEmail"
                      />
                      <CADTextInput
                        label="Business Address"
                        placeholder="Business Address"
                        name="businessAddress"
                      />
                      <CADTextInput
                        label="City"
                        placeholder="City"
                        name="businessCity"
                      />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <CADTextInput
                        label="LGA"
                        placeholder="LGA"
                        name="businessLGA"
                      />
                      <CADTextInput
                        label="Business State"
                        placeholder="Business State"
                        name="businessState"
                      />
                      <CADTextInput
                        label="States Of Coverage"
                        placeholder="States Of Coverage"
                        name="stateOfCoverage"
                      />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <CADTextInput
                        label="Longitude"
                        placeholder="Longitude"
                        name="longitude"
                      />
                      <CADTextInput
                        label="Latitude"
                        placeholder="Latitude"
                        name="latitude"
                      />
                    </Form.Group>
                    <Header as="h3" dividing color={"brown"}>
                      BANK INFORMATION
                    </Header>
                    <Form.Group widths="equal">
                      <CADTextInput
                        label="Bank Name"
                        placeholder="Business Bank Name"
                        name="businessBankName"
                      />
                      <CADTextInput
                        label="Account Name"
                        placeholder="Business Account Name"
                        name="businessAccountName"
                      />
                      <CADTextInput
                        label="Account Number"
                        placeholder="Business Account Number"
                        name="businessAccountNumber"
                      />
                    </Form.Group>

                    <Form.Group widths="equal">
                      <CADTextInput
                        label="Category"
                        placeholder="Category"
                        name="mSMECategory"
                      />
                    </Form.Group>
                    <Header as="h3" dividing color={"brown"}>
                      WEB LINKS
                    </Header>
                    <Form.Group widths="equal">
                      <CADTextInput
                        label="Website"
                        placeholder="Website"
                        name="website"
                      />
                      <CADTextInput
                        label="Twitter"
                        placeholder="Twitter"
                        name="twitter"
                      />
                      <CADTextInput
                        label="Instagram"
                        placeholder="Instagram"
                        name="instagram"
                      />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <CADTextInput
                        label="WhatsApp"
                        placeholder="WhatsApp"
                        name="whatsApp"
                      />
                      <CADTextInput
                        label="Facebook"
                        placeholder="Facebook"
                        name="facebook"
                      />
                    </Form.Group>
                  </Accordion.Content>

                  <Accordion.Title
                    active={activeIndex === 1}
                    index={1}
                    onClick={handleClick}>
                    <h3>
                      <Icon name="dropdown" />
                      Company Compliance
                    </h3>
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === 1}>
                    <Message info>
                      <Message.Header>
                        Update Compliance Criteria
                      </Message.Header>
                      <p>
                        {" "}
                        Provide information about you business's Compliance to
                        regulatory authorities.
                      </p>
                    </Message>
                    <Header as="h3" dividing color="brown">
                      COMPANY COMPLIANCE
                    </Header>
                    <Form.Group widths="equal">
                      <CADTextInput
                        label="Company Registration Number"
                        placeholder="Company Registration Number"
                        name="companyRegistrationNumber"
                      />
                      <CADSelectInput
                        options={optionsYesNo}
                        placeholder="Select an item."
                        label="Has Certificate Of Registration"
                        name="hasCertificateOfRegistration"
                      />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <CADSelectInput
                        options={optionsYesNo}
                        placeholder="Select an item."
                        label="Has Tax Clearance"
                        name="hasTaxClearanceCertificate"
                      />
                      <CADSelectInput
                        options={optionsYesNo}
                        placeholder="Select an item."
                        label="Has Proof Of Shareholding"
                        name="hasProofOfShareholding"
                      />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <CADSelectInput
                        options={optionsYesNo}
                        placeholder="Select an item."
                        label="Has VAT Number"
                        name="hasVATNumber"
                      />
                      <CADTextInput
                        label="VAT Number"
                        placeholder="Select an item."
                        name="vATNumber"
                      />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <CADSelectInput
                        options={optionsYesNo}
                        placeholder="Select an item."
                        label="Has Latest Financial Statement"
                        name="hasLatestFinancialStatement"
                      />
                    </Form.Group>
                  </Accordion.Content>

                  <Accordion.Title
                    active={activeIndex === 2}
                    index={2}
                    onClick={handleClick}>
                    <h3>
                      <Icon name="dropdown" />
                      Market
                    </h3>
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === 2}>
                    <Message info>
                      <Message.Header>Update Market Information</Message.Header>
                      <p>
                        {" "}
                        Provide relevant information about your current market.
                      </p>
                    </Message>

                    {/* <Formik
              initialValues={{
                displayName: profile?.displayName,
                bio: profile?.bio,
              }}
             
            onSubmit={(values) => {
                updateProfile(values).then(() => {
                  setEditMode(false);
                });
              }}
              validationSchema={Yup.object({
                displayName: Yup.string().required(),
              })}
            >
              {({ isSubmitting, isValid, dirty }) => (
                <FForm className="ui form"> */}
                    {/* <Form.Group widths="equal">
                    <Button
                      positive
                      type="submit"
                      loading={isSubmitting}
                      content="Update Personal Data"
                      floated="right"
                      disabled={!isValid || !dirty}
                    />
                  </Form.Group> */}

                    <Header as="h3" dividing color="brown">
                      MARKET INFORMATION
                    </Header>
                    <Form.Group widths="equal">
                      <CADSelectInput
                        options={optionsYesNo}
                        placeholder="Select an item."
                        label="Are you products/services already in the market?"
                        name="inMarket"
                      />
                      <CADSelectInput
                        options={optionsYesNo}
                        placeholder="Select an item."
                        label="Does you business engage in B2B Transactions?"
                        name="b2BMarket"
                      />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <CADSelectInput
                        options={optionsYesNo}
                        placeholder="Select an item."
                        label="Do you have existing customers?"
                        name="existingCustomer"
                      />
                      <CADSelectInput
                        options={optionsYesNo}
                        placeholder="Select an item."
                        label="Do you have repeat patronage for you customers?"
                        name="repeatBuy"
                      />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <CADSelectInput
                        options={optionsYesNo}
                        placeholder="Select an item."
                        label="Are your products/services standardized?"
                        name="productStandards"
                      />
                    </Form.Group>
                  </Accordion.Content>

                  <Accordion.Title
                    active={activeIndex === 3}
                    index={3}
                    onClick={handleClick}>
                    <h3>
                      <Icon name="dropdown" />
                      ICT Adoption
                    </h3>
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === 3}>
                    <Message info>
                      <Message.Header>
                        Update your level of ICT Adoption?
                      </Message.Header>
                      <p>
                        {" "}
                        Provide information about how your business makes use of
                        Information and Communication Technology to enhance your
                        operations.
                      </p>
                    </Message>
                    <Header as="h3" dividing color="brown">
                      ICT COMPLIANCE DETAILS
                    </Header>
                    <Form.Group widths="equal">
                      <Form.Checkbox
                        fluid
                        label="I Use Phone For Social Media."
                        name="usePhoneForSocialMedia"
                      />
                      <Form.Checkbox
                        fluid
                        label="I Use Phone For Research On Internet."
                        name="usePhoneForReseachOnInternet"
                      />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <Form.Checkbox
                        fluid
                        label="I Use Phone For Bank Transaction."
                        name="usePhoneForBankTransaction"
                      />
                      <Form.Checkbox
                        fluid
                        label="I Use Business Management Software."
                        name="useBusinessManagementSoftware"
                      />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <Form.Checkbox
                        fluid
                        label="I Can Use MS Excel."
                        name="canUseMSExcel"
                      />
                      <Form.Checkbox
                        fluid
                        label="I Can Use MS Word"
                        name="canUseMSWord"
                      />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <Form.Checkbox
                        fluid
                        label="I Can Use MS Powerpoint"
                        name="canUseMSPowerpoint"
                      />
                      <Form.Checkbox
                        fluid
                        label="I Use BSP Tools For MSME Support."
                        name="useBSPToolsForMSME"
                      />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <Form.Checkbox
                        fluid
                        label="I Use Other Software for Managing My Business."
                        name="useSoftware"
                      />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <CADTextInput
                        label="Other Business Management Software."
                        placeholder="List out the names of other software used to manage your business."
                        name="businessManagementSoftwareUse"
                      />
                    </Form.Group>
                  </Accordion.Content>

                  <Accordion.Title
                    active={activeIndex === 4}
                    index={4}
                    onClick={handleClick}>
                    <h3>
                      <Icon name="dropdown" />
                      Collaboration And Partnerships
                    </h3>
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === 4}>
                    <Message info>
                      <Message.Header>
                        Collaborations And Partnerships Update
                      </Message.Header>
                      <p>
                        {" "}
                        Specify the type of Collaboration And Partnerships you
                        desire for your business.
                      </p>
                    </Message>
                    <Header as="h3" dividing color="brown">
                      COLLABORATIONS AND PARTNERSHIPS DETAILS
                    </Header>
                    <Form.Group widths="equal">
                      <CADTextArea
                        rows={3}
                        label="Affiliated Institute"
                        placeholder="Enter the name of the Enterprise Development Institute your business is affiliated with."
                        name="supervisingEDI"
                      />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <CADSelectInput
                        options={optionsBSPServices}
                        label="Select BSP Services"
                        placeholder="Select an item."
                        name="supportServicesYouNeed"
                      />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <CADSelectInput
                        options={optionsBusinessLinkage}
                        label="What kind of Business Linkage do you provide?"
                        placeholder="Select an item."
                        name="businessLinkageOffering"
                      />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <CADTextArea
                        rows={3}
                        label="Type Of Partnership You Need"
                        placeholder="Explain the type of partnership you need."
                        name="typeOfPartnershipYouNeed"
                      />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <CADTextArea
                        rows={3}
                        label="List out the certifications you have"
                        placeholder="List out the certifications you have."
                        name="certifications"
                      />
                    </Form.Group>
                  </Accordion.Content>
                </Accordion>
              </FForm>
            )}
          </Formik>
        </Grid.Column>

        {/* <Grid.Column width="16">
          {editMode ? (
            <ProfileEditForm setEditMode={setEditMode} />
          ) : (
            <span style={{ whiteSpace: "pre-wrap" }}>{profile?.bio}</span>
          )}
        </Grid.Column> */}
      </Grid>
    </Tab.Pane>
  );
});

import React from "react";
import { Card, Header, Grid, List, Image, Label } from "semantic-ui-react";
import { contactCardData } from "../../app/common/TemStaticData";

const ContactCard = () => {
  return (
    <div>
      <Card fluid style={{ boxShadow: "0 5px 15px rgba(0, 0, 0, 0.2)" }}>
        {/* <Card.Content>
          <Header style={{ margin: 0 }} content="ADVERTISMENT" />
        </Card.Content> */}
        <Card.Content>
          <Grid divided>
            <Grid.Column width="10">
              <Header style={{ margin: 0 }} size="huge">
                {contactCardData[0].companyName}
              </Header>
              <Header style={{ margin: "5px 0 0 0 " }} size="medium">
                my name is {contactCardData[0].contactName}
              </Header>
              <Header style={{ margin: "5px 0 0 0 " }} size="small">
                I can help you with{" "}
              </Header>
              <List bulleted>
                {contactCardData[0].serviceDescription.map((item) => (
                  <List.Item>{item}</List.Item>
                ))}
              </List>
            </Grid.Column>
            <Grid.Column width="6">
              <Image
                style={{ height: "250px", objectFit: "cover" }}
                rounded
                size="large"
                src="/assets/placeholder.png"
              />
            </Grid.Column>
          </Grid>
        </Card.Content>
        <Card.Content>
          <Card.Meta style={{ fontWeight: 700 }}> Contact </Card.Meta>
          <Grid columns="2">
            <Grid.Column style={{ paddingBottom: 0 }}>
              <div style={{ fontWeight: 600 }}>
                Website: {contactCardData[0].website}
              </div>
            </Grid.Column>
            <Grid.Column style={{ paddingBottom: 0 }}>
              <div style={{ fontWeight: 600 }}>
                Phone Number: {contactCardData[0].phoneNumber}
              </div>
            </Grid.Column>
            <Grid.Column style={{ paddingBottom: 0 }}>
              <div style={{ fontWeight: 600 }}>
                Facebook : {contactCardData[0].facebook}
              </div>
            </Grid.Column>
            <Grid.Column style={{ paddingBottom: 0 }}>
              <div style={{ fontWeight: 600 }}>
                Email : {contactCardData[0].email}
              </div>
            </Grid.Column>
            <Grid.Column style={{ paddingBottom: 0 }}>
              <div style={{ fontWeight: 600 }}>
                Instagram : {contactCardData[0].instagram}
              </div>
            </Grid.Column>
            <Grid.Column style={{ paddingBottom: 0 }}>
              <div style={{ fontWeight: 600 }}>
                Twitter : {contactCardData[0].twitter}
              </div>
            </Grid.Column>
          </Grid>
        </Card.Content>
      </Card>
    </div>
  );
};

export default ContactCard;

import React, { useEffect } from "react";
import { Segment, Container, Divider } from "semantic-ui-react";

import Axios from "axios";

import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import "devextreme-react/text-area";
import { Item } from "devextreme-react/form";
//import { baseAPI } from "../../../api/APIEndpoints";
import DataGrid, {
  FilterPanel,
  FilterRow,
  SearchPanel,
  Button,
  Paging,
  Lookup,
  Popup,
  Position,
  Editing,
  Export,
  Pager,
  Grouping,
  GroupPanel,
  RowDragging,
  Column,
  RequiredRule,
  PatternRule,
  EmailRule,
  AsyncRule,
} from "devextreme-react/data-grid";
// import { useSelector } from "react-redux";
import CustomStore from "devextreme/data/custom_store";
import "whatwg-fetch";
import { store, useStore } from "../../../app/stores/store";

//********************FUNCTIONS******************************* */
function handleErrors(response: any) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}
//********************FUNCTIONS******************************* */

export const Bank = () => {
  return (
    <Segment>
      <h1>Banks</h1>
      <div className="ui warning message">
        <div className="header">Manage Banks.</div>
        <p>Create and manage Banks.</p>
      </div>
      <div className="ui divider" title="List of Banks"></div>
      <div>
        <DataGrid
          className="dx-datagrid dx-row-alt"
          hoverStateEnabled={true}
          // dataSource={bankName}
          rowAlternationEnabled={true}
          columnAutoWidth={true}
          showBorders={true}
          activeStateEnabled={true}
          columnResizingMode="widget"
          allowColumnResizing={true}
          columnHidingEnabled={true}>
          <FilterPanel visible={true} />
          <SearchPanel visible={true} />
          <FilterRow visible={true} />
          <Paging defaultPageSize={10} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={[2, 10, 20, 50, 100]}
            showInfo={true}
          />
          <Grouping contextMenuEnabled={true} />
          <GroupPanel visible={true} />
          <Editing
            mode="popup"
            useIcons={true}
            allowUpdating={true}
            allowAdding={true}
            allowDeleting={true}
          />
          <Export
            enabled={true}
            // fileName="FABranchs"
            allowExportSelectedData={true}
          />
          <Column type="buttons" />
          <Column dataField="bankOID" allowEditing={false}></Column>
          <Column dataField="bankCode"></Column>
          <Column dataField="bankName">
            <RequiredRule />
          </Column>
          <Column dataField="routeCode"></Column>
          <Column dataField="address1">
            <RequiredRule />
          </Column>
          <Column dataField="address2"></Column>
          <Column dataField="address3"></Column>
          <Column dataField="Phone">
            <RequiredRule />
          </Column>
          <Column dataField="email">
            <RequiredRule />
          </Column>
          <Column dataField="accountName">
            <RequiredRule />
          </Column>
          <Column dataField="shortName"></Column>
          <Column dataField="accountNumber"></Column>
          <Column dataField="contact">
            <RequiredRule />
          </Column>
          <Column dataField="website"></Column>
          <Column dataField="comment"></Column>
        </DataGrid>
      </div>
    </Segment>
  );
};

//import { Profile } from "./profile";

export interface Resource {
  resourceGUID: string;
  code: string;
  category: string;
  title: string;
  description: string;
  size: string;
  source: string;
  downloads: number;
  sortOrder: number;
  dateUploaded: Date | null;

  // date: Date | null;
  // description: string;
  // category: string;
  // city: string;
  // venue: string;
  // hostUsername: string;
  // isCancelled: boolean;
  // isGoing: boolean;
  // isHost: boolean;
  // host?: Profile;
  // attendees: Profile[]
}

export class Resource implements Resource {
  constructor(init?: ResourceFormValues) {
    Object.assign(this, init);
  }
}

export class ResourceFormValues {
  resourceGUID?: string = undefined;
  code: string = "";
  category: string = "";
  title: string = "";
  description: string = "";
  size: string = "";
  source: string = "";
  downloads: number = 0;
  sortOrder: number = 0;
  // dateUploaded: Date  = Date.now();

  constructor(resource?: ResourceFormValues) {
    if (resource) {
      this.resourceGUID = resource.resourceGUID;
      this.code = resource.code;
      this.category = resource.category;
      this.description = resource.description;
      this.title = resource.title;
      this.size = resource.size;
      this.source = resource.source;
      this.sortOrder = resource.sortOrder;
    }
  }
}

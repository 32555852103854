import { RangeContainer } from "devextreme-react/circular-gauge";
import React from "react";
import { Link } from "react-router-dom";
import { Button, Icon, Label } from "semantic-ui-react";
import { useStore } from "../stores/store";


 
 

export const UsersOnline = () => {
    const { userStore } = useStore();
    const { user } = userStore;

    
    return (
      <div>
        <Button as="div" labelPosition="right">
          <Button color="green" as={Link} to="/view/helpersonline">
            {/* <Icon name="users" /> */}
            Helpers online
          </Button>
          <Label as="a" basic color="green" pointing="left">
            {user?.usersOnline}
          </Label>
        </Button>
        {/* <Button as="div" labelPosition="right">
      <Button basic color="blue">
        <Icon name="fork" />
        Fork
      </Button>
      <Label as="a" basic color="blue" pointing="left">
        2,048
      </Label>
    </Button> */}
      </div>
    );
};


export default UsersOnline;

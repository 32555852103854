import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Header, Segment } from "semantic-ui-react";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { useStore } from "../../../app/stores/store";
import { v4 as uuid } from "uuid";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import MyTextInput from "../../../app/common/form/MyTextInput";
import MyTextArea from "../../../app/common/form/MyTextArea";
import MySelectInput from "../../../app/common/form/MySelectInput";
import { resourceOptions } from "../../../app/common/options/categoryOptions";
import MyDateInput from "../../../app/common/form/MyDateInput";
//import { ActivityFormValues } from "../../../app/models/activity";

import { StateFormValues } from "../../../app/models/state";

export default observer(function StateForm({
  stateOID,
  setMode,
}: {
  stateOID: string;
  setMode: Function;
}) {
  const navigate = useNavigate();
  const { stateStore } = useStore();
  const { createStateModel, updateStateModel, loadStateModel, loadingInitial } =
    stateStore;
  // const { resourceGUID } = useParams<{ resourceGUID: string }>();

  const [state, setState] = useState<StateFormValues>(new StateFormValues());

  const validationSchema = Yup.object({
    title: Yup.string().required("The resource title is required"),
    description: Yup.string().required("The State description is required"),
    category: Yup.string().required("The State category is required"),
    //date: Yup.string().required("Date is required").nullable(),
    source: Yup.string().required("The State source is required"),
    code: Yup.string().required("The resource code is required"),
  });

  useEffect(() => {
    if (stateOID)
      loadStateModel(stateOID).then((state) =>
        setState(new StateFormValues(state))
      );
  }, [stateOID, loadStateModel]);

  function handleFormSubmit(state: StateFormValues) {
    if (!state.stateOID) {
      let newStateModel = {
        ...state,
        stateOID: uuid(),
      };
      createStateModel(newStateModel).then(() =>
        fetch(`/ecostate/${newStateModel.stateOID}`)
      );
    } else {
      updateStateModel(state).then(() => fetch(`/ecostate/${state.stateOID}`));
    }
  }

  if (loadingInitial) return <LoadingComponent content="Loading resource..." />;

  return (
    <Segment clearing>
      <Button
        // disabled={isSubmitting || !dirty || !isValid}
        // loading={isSubmitting}
        floated="left"
        positive
        type="button"
        content="Back"
        onClick={() => setMode()}
        icon="back-arrow"
      />
      <h1>Resource Details</h1>
      <div className="ui warning message">
        <div className="header">Create, edit and delete Resources.</div>
        <p>
          You can manage resources from this page. You can create, edit, delete
          a resource.
        </p>
      </div>
      {/* <Header content="Resource Details" sub color="teal" /> */}
      <Formik
        validationSchema={validationSchema}
        enableReinitialize
        initialValues={state}
        onSubmit={(values) => handleFormSubmit(values)}>
        {({ handleSubmit, isValid, isSubmitting, dirty }) => (
          <Form className="ui form" onSubmit={handleSubmit} autoComplete="off">
            <MyTextInput placeholder="stateCode" name="statecode" />
            <MyTextInput name="state" placeholder="State" />
            <MyTextArea rows={3} placeholder="countryOID" name="countryOID" />

            {/* <MyDateInput
              placeholderText="Date"
              name="date"
              showTimeSelect
              timeCaption="time"
              dateFormat="MMMM d, yyyy h:mm aa"
            /> */}
            {/* <Header content="Location Details" sub color="teal" /> */}
            <MyTextInput placeholder="ecoCountry" name="ecocountry" />
            <MyTextInput
              placeholder="geoPoliticalZone"
              name="geopoliticalzone"
            />
            <MyTextInput placeholder="ecoLGAs" name="ecolga" />

            <Button
              disabled={isSubmitting || !dirty || !isValid}
              loading={isSubmitting}
              floated="right"
              positive
              type="submit"
              content="Submit"
            />
            <Button
              as={Link}
              to="/ecostate"
              floated="right"
              type="button"
              content="Cancel"
            />
          </Form>
        )}
      </Formik>
    </Segment>
  );
});

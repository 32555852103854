//*************************************************************
// PROJECT NAME : Blinh Hub Network
// CLASS        : MSMEBlinkActivityAttendees
// DATE CREATED : 11 February 2022
// TIME CREATED : 08:09:15
// DEVELOPER    : Ibinaiye Wale Kayode
//*************************************************************

export interface ActivityAttendee {
  attendanceOID: number;
  blinkActivityOID: number;
  msmeoid: number;
}

export class ActivityAttendee implements ActivityAttendee {
  constructor(init?: ActivityAttendeeFormValues) {
    Object.assign(this, init);
  }
}

export class ActivityAttendeeFormValues {
  attendanceOID = 0;
  blinkActivityOID = "";
  msmeoid = "";
  constructor(values?: ActivityAttendeeFormValues) {
    if (values) {
      this.attendanceOID = values.attendanceOID;
      this.blinkActivityOID = values.blinkActivityOID;
      this.msmeoid = values.msmeoid;
    }
  }
}

import { LoadIndicator } from "devextreme-react";
import React from "react";

interface Props {
  // inverted?: boolean;
  content?: string;
}

export default function DXLoadingComponent({ content = "Loading..." }: Props) {
  return (
    <div style={{ textAlign: "center" }}>
      <LoadIndicator
        id='large-indicator'
        height={60}
        width={60}></LoadIndicator>
      <div>{content}</div>
    </div>
  );
}

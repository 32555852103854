export interface StateModel {
  stateOID: string;
  stateCode: string;
  state: string;
  countryOID: number;
  ecoCountry: string;
  geoPoliticalZone: string;
  ecoLGAs: string;
  dateUploaded: Date | null;

  // date: Date | null;
  // description: string;
  // category: string;
  // city: string;
  // venue: string;
  // hostUsername: string;
  // isCancelled: boolean;
  // isGoing: boolean;
  // isHost: boolean;
  // host?: Profile;
  // attendees: Profile[]
}

export class StateModel implements StateModel {
  constructor(init?: StateFormValues) {
    Object.assign(this, init);
  }
}

export class StateFormValues {
  stateOID?: string = undefined;
  stateCode: string = "";
  state: string = "";
  countryOID: number = 0;
  ecoCountry: string = "";
  geoPoliticalZone: string = "";
  ecoLGAs: string = "";

  // dateUploaded: Date  = Date.now();

  constructor(state?: StateFormValues) {
    if (state) {
      this.stateOID = state.stateOID;
      this.stateCode = state.stateCode;
      this.state = state.state;
      this.countryOID = state.countryOID;
      this.ecoCountry = state.ecoCountry;
      this.geoPoliticalZone = state.geoPoliticalZone;
      this.ecoLGAs = state.ecoLGAs;
    }
  }
}

import React from "react";
import { Grid, Table } from "semantic-ui-react";
import classes from "./coster.module.css";

type Props = {};

const Analysis = (props: Props) => {
  return (
    <section>
      <div className=''>
        <Grid className={classes.border}>
          <Grid.Row columns={1} className={classes.row}>
            <Grid.Column className={classes.column_2}>
              <header className={classes.header}>Analysis</header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={3} className={classes.row}>
            <Grid.Column width={6} className={classes.column}>
              <Table>
                <Table.Body>
                  <TableRow label={" Height (cm)"} value={"0"} active />
                  <TableRow
                    label={" Head to Tail Length (cm)"}
                    value={"0"}
                    active
                  />
                  <TableRow label={" Live weight (kg)"} value={"600"} active />
                  <TableRow
                    label={" Processed weight (kg)"}
                    value={"200"}
                    active
                  />
                  <TableRow label={" Meat Ratio"} value={"100%"} disabled />
                  <TableRow
                    label={" Total Purchase Price (N) "}
                    value={"371,000.00"}
                    active
                  />
                  <TableRow
                    label={" Off Take Price (per Kg)"}
                    value={"1,900.00"}
                    active
                  />
                </Table.Body>
              </Table>
              <Table>
                <Table.Body>
                  <TableRow
                    label={" Total Value of Secondary Products"}
                    value={"-"}
                    disabled
                  />
                  <TableRow
                    label={"OffTaker's Offer (N) "}
                    value={"380,000.00"}
                    disabled
                  />
                  <TableRow
                    label={"Breakeven Price (N) "}
                    value={"1,855.00"}
                    disabled
                  />
                  <TableRow label={" "} value={"371,000.00"} disabled />
                  <TableRow label={"Profit Margin (%)"} value={"10"} active />
                  <TableRow label={""} value={"408,100.00"} disabled />
                  <TableRow label={"Profit"} value={"37,100.00"} disabled />
                  {/* <TableRow label={""} value={""} disabled /> */}
                  <TableRow
                    label={"Actual Selling Price per Kg"}
                    value={"2,040.00"}
                    disabled
                  />
                </Table.Body>
              </Table>
            </Grid.Column>
            <Grid.Column width={2}></Grid.Column>
            <Grid.Column width={8} className={classes.column_2}>
              <header className={`${classes.header} ${classes.header_2}`}>
                Profitability
              </header>
              <Table>
                <Table.Body>
                  <TableRow
                    label={"Purchase Price"}
                    value={"371,000.00"}
                    disabled
                  />
                  <TableRow
                    label={"Off Take Price (per Kg)"}
                    value={"1,900.00"}
                    disabled
                  />
                  <TableRow
                    label={"Process Weight (Kg)"}
                    value={"200"}
                    disabled
                  />
                  <TableRow
                    label={"Off Take Price Total Price"}
                    value={"380,000.00"}
                    disabled
                  />
                  <TableRow
                    label={"Total Value of Secondary Products"}
                    value={"-"}
                    disabled
                  />
                  <TableRow
                    label={"Total Value of Sales"}
                    value={"380,000.00"}
                    disabled
                  />
                  <TableRow label={"Profit"} value={"9,000.00"} disabled />
                </Table.Body>
              </Table>

              <div className={classes.profit_box}>
                <h3>Profit</h3>
                <p>
                  <span>N</span>
                  <span>9,000.00</span>
                </p>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </section>
  );
};

interface ITableRow {
  label: string;
  value: string;
  active?: boolean;
  disabled?: boolean;
}

export const TableRow2: React.FC<ITableRow> = ({
  label,
  value,
  active,
  disabled,
}) => {
  return (
    <Table.Row className={`${classes.row_border_6}  `}>
      <Table.Cell textAlign='right' width={9}>
        {label}
      </Table.Cell>
      <Table.Cell
        textAlign='center'
        width={7}
        className={`${active ? classes.active_cell_1 : null} ${
          disabled ? classes.readonly_cell_2 : null
        }`}>
        {value}
      </Table.Cell>
    </Table.Row>
  );
};
export const TableRow: React.FC<ITableRow> = ({
  label,
  value,
  active,
  disabled,
}) => {
  return (
    <Table.Row className={`${classes.row_border_5}  `}>
      <Table.Cell textAlign='right' width={9}>
        {label}
      </Table.Cell>
      <Table.Cell
        textAlign='center'
        width={7}
        className={`${active ? classes.active_cell_1 : null} ${
          disabled ? classes.readonly_cell_2 : null
        }`}>
        {value}
      </Table.Cell>
    </Table.Row>
  );
};

export default Analysis;

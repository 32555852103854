import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../api/agent";
import { SystemCodeFormValues } from "../models/SystemCodes";

type setupType = "GENDER" | "NumberOfStaff";

// interface ISystemCodesSetupType {
//   [key: string]: SystemCodeFormValues[];
// }

export default class SystemCodeStores {
  systemCodes: SystemCodeFormValues[] = [];
  //   filteredSystemCodes: SystemCodeFormValues[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  async getSystemCodes() {
    const systemCodesData = await agent.SystemCodes.list();
    this.systemCodes = await systemCodesData.data.result;
    // console.log("systemCodes", this.systemCodes.filter);
    // const res = systemCodes
  }

  async filterSystemCodes(setupType: setupType) {
    const data = this.systemCodes.filter(
      (item) => item.setupType.toUpperCase() === setupType.toUpperCase()
    );

    return data;
  }
}

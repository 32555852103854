//*************************************************************
// PROJECT NAME : Blinh Hub Network
// CLASS        : MSMEBlinkActivityFeedback
// DATE CREATED : 14 February 2022
// TIME CREATED : 04:53:53
// DEVELOPER    : Ibinaiye Wale Kayode
//*************************************************************
export interface MSMEBlinkActivityFeedback {
  feedbackOID: number;
  blinkActivityOID: number;
  transformationTitle: string;
  caseDescription: string;
  beforeBSPSupport: string;
  bspSupportProvided: string;
  afterBSPSupport: string;
  quote: string;
  activityOutcome: string;
}

export class MSMEBlinkActivityFeedback implements MSMEBlinkActivityFeedback {
  constructor(init?: MSMEBlinkActivityFeedbackFormValues) {
    Object.assign(this, init);
  }
}

export class MSMEBlinkActivityFeedbackFormValues {
  feedbackOID: number = 0;
  blinkActivityOID: number = 0;
  transformationTitle: string = "";
  caseDescription: string = "";
  beforeBSPSupport: string = "";
  bspSupportProvided: string = "";
  afterBSPSupport: string = "";
  quote: string = "";
  activityOutcome: string = "";

  constructor(values?: MSMEBlinkActivityFeedbackFormValues) {
    if (values) {
      this.feedbackOID = values.feedbackOID;
      this.blinkActivityOID = values.blinkActivityOID;
      this.transformationTitle = values.transformationTitle;
      this.caseDescription = values.caseDescription;
      this.beforeBSPSupport = values.beforeBSPSupport;
      this.bspSupportProvided = values.bspSupportProvided;
      this.afterBSPSupport = values.afterBSPSupport;
      this.quote = values.quote;
      this.activityOutcome = values.activityOutcome;
    }
  }
}

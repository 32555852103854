import React, { useState } from "react";
import { Segment } from "semantic-ui-react";
// import FeedbackForm from "../ecoforms/FeedbackForm";
export const Feedback = () => {
  const [onVisible, setOnVisible] = React.useState(false);
  return (
    <Segment style={{ padding: "3em 2em" }}>
      <h1>Feedbacks</h1>
      <div className="ui warning message">
        <div className="header">Manage Feedbacks.</div>
        <p>Create and manage Feedbacks.</p>
      </div>
      <div className="ui divider" title="List of Feedbacks"></div>
      {/* <FeedbackForm setOnVisible={() => setOnVisible(false)} feedbackOID={0} /> */}
    </Segment>
  );
};

//*************************************************************
// PROJECT NAME : Blinh Hub Network
// CLASS        : MSMEBlinkWeeklyUpdate
// DATE CREATED : 11 February 2022
// TIME CREATED : 08:09:30
// DEVELOPER    : Ibinaiye Wale Kayode
//*************************************************************
import React, { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { FieldArray, Formik } from "formik";
import * as Yup from "yup";
import { MSMEBlinkWeeklyUpdateFormValues } from "../../../app/models/MSMEBlinkWeeklyUpdate";
import { useStore } from "../../../app/stores/store";
import MyTextInput from "../../../app/common/form/MyTextInput";
import {
  Button,
  Form,
  Grid,
  Header,
  Icon,
  Label,
  Message,
  Table,
} from "semantic-ui-react";
import DXLoadingComponent from "../../../app/layout/DXLoadingComponent";
import { IMasterDetail } from "../../../app/common/staticProps";
import { AxiosError } from "axios";
import notify from "devextreme/ui/notify";
// import MyDateInput from "../../../app/common/form/MyDateInput";
import MySelectInput from "../../../app/common/form/MySelectInput";
import DXDateBox from "../../../app/common/form/DXDateBox";

type Props = {
  rowID: number;
  setMode: React.Dispatch<React.SetStateAction<IMasterDetail>>;
};

const MSMEBlinkWeeklyUpdateForm: React.FC<Props> = ({ rowID, setMode }) => {
  const [initialValues, setInitialValues] =
    useState<MSMEBlinkWeeklyUpdateFormValues>(
      new MSMEBlinkWeeklyUpdateFormValues()
    );
  const { MSMEBlinkWeeklyUpdateStore } = useStore();
  const {
    createMSMEBlinkWeeklyUpdate,
    updateMSMEBlinkWeeklyUpdate,
    loadMSMEBlinkWeeklyUpdate,
    loadingInitial,
    setLoadingInitial,
  } = MSMEBlinkWeeklyUpdateStore;

  const validationSchema = Yup.object({
    weeklyUpdateOID: Yup.string().required("The WeeklyUpdateOID Is required"),
    weekCode: Yup.string().required("The WeekCode Is required"),
    startDate: Yup.string().required("The StartDate Is required"),
    description: Yup.string().required("The Description Is required"),
  });

  const handleBack = useCallback(() => {
    setMode("GRID");
  }, [setMode]);

  useEffect(() => {
    if (!rowID) return setLoadingInitial(false);
    loadMSMEBlinkWeeklyUpdate(rowID)
      .then((res: any) => {
        const response = res.result[0];
        setInitialValues(new MSMEBlinkWeeklyUpdateFormValues(response));
      })
      .catch((err: AxiosError) => {
        notify("Bad Request", "error", 3000);
        handleBack();
      });
  }, [rowID, loadMSMEBlinkWeeklyUpdate, setLoadingInitial, handleBack]);

  const handleSubmit = (values: MSMEBlinkWeeklyUpdateFormValues) => {
    try {
      if (rowID) {
        const newMSMEBlinkWeeklyUpdate = {
          ...values,
        };
        updateMSMEBlinkWeeklyUpdate(newMSMEBlinkWeeklyUpdate).then(() => {
          handleBack();
        });
      } else {
        createMSMEBlinkWeeklyUpdate(values).then(() => {
          handleBack();
        });
      }
    } catch (error) {
      notify("Server Error", "error", 3000);
    }
  };

  if (loadingInitial) return <DXLoadingComponent />;

  return (
    <div>
      <Header as='h2'>
        <Icon
          onClick={handleBack}
          size='big'
          name='arrow circle left'
          color='blue'
          style={{ cursor: "pointer" }}
        />
        Weekly Update
      </Header>
      <Message color='brown' size='mini'>
        <Message.Header>Manage MSMEBlinkWeeklyUpdate</Message.Header>
        <p> You can create and manage a weekly update. </p>
      </Message>
      <Formik
        validationSchema={validationSchema}
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values) => handleSubmit(values)}>
        {({ values, handleSubmit, isValid, isSubmitting, dirty }) => (
          <Form className='ui form' autoComplete='off' onSubmit={handleSubmit}>
            <Form.Group widths={"equal"}>
              <MyTextInput
                placeholder='weeklyUpdateOID'
                name='weeklyUpdateOID'
                label='weeklyUpdateOID'
                disabled
              />
              <MyTextInput
                placeholder='weekCode'
                name='weekCode'
                label='weekCode'
              />
              <DXDateBox
                placeholder='startDate'
                name='startDate'
                // showTimeSelect
                // timeCaption="time"
                // dateFormat="MMMM d, yyyy h:mm aa"
                label='startDate'
              />
            </Form.Group>
            <Grid>
              <Grid.Row>
                <Grid.Column width={10}>
                  <MyTextInput
                    placeholder='description'
                    name='description'
                    label='description'
                  />
                </Grid.Column>

                <Grid.Column width={6}>
                  <MySelectInput
                    options={[]}
                    placeholder='performedBy'
                    name='performedBy'
                    label='Performed By'
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Grid style={{ padding: "1em" }}>
              <Grid.Row>
                <FieldArray name='msmeBlinkWeeklyUpdateDetailVirtual'>
                  {({ insert, remove, push }) => (
                    <>
                      <Header
                        as='h3'
                        color='grey'
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}>
                        Enter/Update weekly activities
                        <span
                          style={{
                            color: "hsl(205, 78%, 60%)",
                            textTransform: "uppercase",
                            fontSize: "1rem",
                            cursor: "pointer",
                            userSelect: "none",
                          }}
                          onClick={() =>
                            push({
                              // weeklyUpdateOID: values.weeklyUpdateOID,
                              taskDescription: "",
                              taskStatus: "",
                              proposedDate: "",
                              actualDate: "",
                              bspUserGUID: "null",
                            })
                          }>
                          <Icon name='plus' /> Add Item
                        </span>
                      </Header>
                      <Table celled>
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell>SN</Table.HeaderCell>
                            <Table.HeaderCell>
                              Task Description
                            </Table.HeaderCell>
                            <Table.HeaderCell>Task Status</Table.HeaderCell>
                            <Table.HeaderCell>Proposed Date</Table.HeaderCell>
                            <Table.HeaderCell>Actual Date</Table.HeaderCell>
                            <Table.HeaderCell>Del</Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>

                        <Table.Body>
                          {values.msmeBlinkWeeklyUpdateDetailVirtual.length >
                            0 &&
                            values.msmeBlinkWeeklyUpdateDetailVirtual.map(
                              (item: any, index: number) => (
                                <Table.Row key={index}>
                                  <Table.Cell>{index + 1}</Table.Cell>
                                  <Table.Cell>
                                    <MyTextInput
                                      placeholder='taskDescription'
                                      name={`msmeBlinkWeeklyUpdateDetailVirtual.${index}.taskDescription`}
                                    />
                                  </Table.Cell>
                                  <Table.Cell>
                                    <MyTextInput
                                      placeholder='taskStatus'
                                      name={`msmeBlinkWeeklyUpdateDetailVirtual.${index}.taskStatus`}
                                    />
                                  </Table.Cell>
                                  <Table.Cell>
                                    <DXDateBox
                                      placeholder='proposedDate'
                                      name={`msmeBlinkWeeklyUpdateDetailVirtual.${index}.proposedDate`}
                                    />
                                  </Table.Cell>
                                  <Table.Cell>
                                    <DXDateBox
                                      placeholder='actualDate'
                                      name={`msmeBlinkWeeklyUpdateDetailVirtual.${index}.actualDate`}
                                    />
                                  </Table.Cell>
                                  <Table.Cell>
                                    <Label
                                      color='red'
                                      size='big'
                                      as='a'
                                      type='button'
                                      className='secondary'
                                      onClick={() => remove(index)}
                                      style={{ userSelect: "none" }}>
                                      X
                                    </Label>
                                  </Table.Cell>
                                </Table.Row>
                              )
                            )}
                        </Table.Body>
                      </Table>
                    </>
                  )}
                </FieldArray>
              </Grid.Row>
            </Grid>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "1rem",
              }}>
              <Button
                disabled={isSubmitting || !dirty || !isValid}
                loading={isSubmitting}
                floated='right'
                positive
                type='submit'
                content='Submit'
              />
              <Button
                onClick={handleBack}
                floated='right'
                type='button'
                content='Cancel'
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default observer(MSMEBlinkWeeklyUpdateForm);

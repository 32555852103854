import React, { useEffect } from "react";
import { Segment, Container, Divider } from "semantic-ui-react";

import Axios from "axios";

import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import "devextreme-react/text-area";
import { Item } from "devextreme-react/form";
//import { baseAPI } from "../../../api/APIEndpoints";
import DataGrid, {
  FilterPanel,
  FilterRow,
  SearchPanel,
  Button,
  Paging,
  Lookup,
  Popup,
  Position,
  Editing,
  Export,
  Pager,
  Grouping,
  GroupPanel,
  RowDragging,
  Column,
  RequiredRule,
  PatternRule,
  EmailRule,
  AsyncRule,
} from "devextreme-react/data-grid";
// import { useSelector } from "react-redux";
import CustomStore from "devextreme/data/custom_store";
import "whatwg-fetch";
import { store, useStore } from "../../../app/stores/store";

export const Subscription = () => {
  const [dataSource, setDataSource] = React.useState({});
  const [subscriberOID, setSubscriberOID] = React.useState("GRID");
  const [rowData, setRowData]: any = React.useState({});
  const { countryStore } = useStore();
  const { loading, setLoadingInitial, loadingInitial } = countryStore;
  const [mode, setMode] = React.useState("GRID");
  const baseAPI = process.env.REACT_APP_API_URL; // "http://localhost:5000/api";

  const jwt = store.commonStore.token;
  function handleErrors(response: any) {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  }

  const customDataSource: any = new CustomStore({
    // key: "suscribeOID",
    load: () => {
      return fetch(`${baseAPI}/EcoSubscriber`, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      })
        .then(handleErrors)
        .then((response) => response.json())
        .then((response) => {
          return {
            data: response.result,
            totalCount: response.count,
            summary: response.summary,
            groupCount: response.count,
          };
        });
    },
    insert: (values: any) => {
      return fetch(`${baseAPI}/EcoSubscriber`, {
        method: "POST",
        body: JSON.stringify(values),
        headers: {
          Authorization: `Bearer ${jwt}`,
          "Content-Type": "application/json",
        },
      }).then(handleErrors);
    },
    remove: (key) => {
      return fetch(`${baseAPI}/EcoSubscriber`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${jwt}`,
          "Content-Type": "application/json",
        },
      }).then(handleErrors);
    },
    update: (values) => {
      return values;
    },
  });

  const handleEdit = (e: any) => {
    // const data = e.row.data;
    setRowData((data: any) => {
      return { ...e.row.data };
    });
    setMode("FORM-EDIT");
    // console.log(rowData);
  };
  // const onRowUpdating = (e: any) => {
  //   e.newData = Object.assign(e.oldData, e.newData);

  //   fetch(`${baseAPI}/EcoCountry`, {
  //     method: "PUT",
  //     body: JSON.stringify(e.newData),
  //     headers: {
  //       Authorization: `Bearer ${jwt}`,
  //       "Content-Type": "application/json",
  //     },
  //   }).then(customDataSource.load());
  // };
  const handleView = (e: any) => {
    // const data = e.row.data;
    setSubscriberOID(e.row.data.countryOID);
    setLoadingInitial(true);
    // console.log(e.row.data);
    setMode("FORM-EDIT");
    // console.log(rowData);
  };

  return (
    <Segment>
      <h1>Subscriptions</h1>
      <div className="ui warning message">
        <div className="header">Manage Subscriptions.</div>
        <p>Create and manage Subscriptions.</p>
      </div>
      <div className="ui divider" title="List of Subscriptions"></div>
      <div>
        <DataGrid
          id="[$GridID]"
          dataSource={customDataSource}
          className="dx-datagrid dx-row-alt"
          hoverStateEnabled={true}
          columnAutoWidth={true}
          // onRowUpdating={onRowUpdating}
          activeStateEnabled={true}
          columnResizingMode="widget"
          allowColumnResizing={true}
          showBorders={true}
          columnHidingEnabled={true}>
          <RowDragging allowReordering={true} />
          <FilterPanel visible={true} />
          <SearchPanel visible={true} />
          <FilterRow visible={true} />
          <Paging defaultPageSize={20} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={[2, 10, 20, 50, 100]}
            showInfo={true}
          />
          <Grouping contextMenuEnabled={true} />
          <GroupPanel visible={true} />
          <Export
            enabled={true}
            fileName={"Assign_Task_to_Role"}
            allowExportSelectedData={true}
          />
          <Editing
            mode="popup"
            useIcons={true}
            allowUpdating={true}
            allowDeleting={true}
            allowAdding={true}
          />
          {/* <Scrolling mode="standard" />
          <Loader enabled /> */}
          {/* <Column type="buttons" caption="Operation">
            <Point
              text="Permissions"
              cssClass={"manage-permission-btn"}
              // onClick={commandClick}
            />
          </Column> */}
          {/* <Column dataField="userRoleTaskOID"></Column> */}
          <Column dataField="subscriberCode"></Column>
          <Column dataField="databaseName"></Column>
          <Column dataField="loginName"></Column>
          <Column dataField="password"></Column>
          <Column
            dataField="serverName"
            // cellRender={renderCheckBox}
          ></Column>
          <Column
            dataField="companyName"

            // cellRender={renderCheckBox}
          ></Column>
          <Column
            dataField="canCreate"

            // cellRender={renderCheckBox}
          ></Column>
        </DataGrid>
      </div>
    </Segment>
  );
};

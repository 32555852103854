import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Header, Form, Segment } from "semantic-ui-react";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { useStore } from "../../app/stores/store";
import { v4 as uuid } from "uuid";
import { Form as FForm, Formik } from "formik";
import * as Yup from "yup";
import MyTextInput from "../../app/common/form/MyTextInput";
import MyTextArea from "../../app/common/form/MyTextArea";
import MySelectInput from "../../app/common/form/MySelectInput";
import { resourceOptions } from "../../app/common/options/categoryOptions";
import MyDateInput from "../../app/common/form/MyDateInput";
//import { ActivityFormValues } from "../../../app/models/activity";
import { ResourceFormValues } from "../../app/models/resource";
import { PersonalDataFormValues } from "../../app/models/personalData";
import {
  optionsAgeRange,
  optionsGender,
  optionsMaritalStatus,
  optionsState,
} from "../../app/common/staticData";
import CADTextInput from "../../app/common/form/CADTextInput";
import CADSelectInput from "../../app/common/form/CADSelectInput";
import UserStore from "../../app/stores/userStore";

export default observer(function ProfileBlinkhubStatusEditForm({
  userGUID,
  setMode,
}: {
  userGUID: string | undefined;
  setMode: Function;
}) {
  const navigate = useNavigate();
  const { personalDataStore, userStore } = useStore();
  const {
    createPersonalData,
    updatePersonalData,
    loadPersonalData,
    loadingInitial,
  } = personalDataStore;
  const { user } = userStore;

  // const { userGUID } = useParams<{ userGUID: string }>();

  const [personalData, setPersonalData] = useState<PersonalDataFormValues>(
    new PersonalDataFormValues()
  );
  const {
    profileStore: { profile, updateProfile },
  } = useStore();

  const validationSchema = Yup.object({
    // surname: Yup.string().required("Surname is required."),
    // otherNames: Yup.string().required("Other names is required."),
    // gender: Yup.string().required("Gender is required."),
    // //date: Yup.string().required("Date is required").nullable(),
    // ageRange: Yup.string().required("Age Range is required"),
    // stateOfOrigin: Yup.string().required("State of origin is required"),
    // maritalStatus: Yup.string().required("Marital Status is required"),
    // mobilePhone: Yup.string().required("Mobile Phone is required"),
    // businessEmail: Yup.string().required("Business Email is required"),
  });

  useEffect(() => {
    if (userGUID)
      loadPersonalData(userGUID).then((personalData) => {
        console.log("2. loadPersonalData", personalData);
        setPersonalData(new PersonalDataFormValues(personalData));
      });
  }, [userGUID, loadPersonalData]);

  function handleFormSubmit(personalData: PersonalDataFormValues) {
    console.log("Form Values", personalData);
    if (!personalData.userGUID) {
      let newPersonalData = {
        ...personalData,
        userGUID: user?.userGUID,
        dispayName: user?.displayName,
      };
      createPersonalData(newPersonalData).then(() =>
        navigate(`/personaldata/${newPersonalData.userGUID}`)
      );
    } else {
      updatePersonalData(personalData).then(() =>
        navigate(`/personalData/${personalData.userGUID}`)
      );
    }
  }

  if (loadingInitial) return <LoadingComponent content='Loading resource...' />;

  return (
    <Segment clearing>
      {/* <Button
        // disabled={isSubmitting || !dirty || !isValid}
        // loading={isSubmitting}
        floated="left"
        positive
        type="button"
        content="Back"
        // onClick={() => setMode()}
        icon="back-arrow"
      /> */}
      <h1>Blinkhub Status</h1>
      <div className='ui warning message'>
        <div className='header'>View Blinkhub Status.</div>
        <p>
          You can view your current status on Blinkhub.network from this page.
        </p>
      </div>
      {/* <Header content="Resource Details" sub color="teal" /> */}
      <Formik
        // initialValues={{
        //   mSMEOID: 0,
        //   displayName: personalData.displayName,
        //   surname: personalData.surname,
        //   otherNames: personalData.otherNames,
        //   userGUID: personalData.userGUID,
        //   mSMECode: personalData.mSMECode,
        //   gender: personalData.gender,
        //   ageRange: personalData.ageRange,
        //   maritalStatus: personalData.maritalStatus,
        //   mobilePhone: personalData.mobilePhone,
        //   stateOfOrigin: personalData.stateOfOrigin,
        //   personalBankName: personalData.personalBankName,
        //   personalBankAccount: personalData.personalBankAccount,
        //   personalAccountNumber: personalData.personalAccountNumber,
        // }}
        validationSchema={validationSchema}
        //enableReinitialize
        initialValues={personalData}
        //onSubmit={(values) => handleFormSubmit(values)}
        onSubmit={(values) => {
          handleFormSubmit(values);
        }}
        // validationSchema={Yup.object({
        //   displayName: Yup.string().required(),
        // })}
      >
        {({ handleSubmit, isValid, isSubmitting, dirty }) => (
          <FForm className='ui form' onSubmit={handleSubmit} autoComplete='off'>
            <Form.Group widths='equal'>
              <Button
                // disabled={isSubmitting || !dirty || !isValid}
                //disabled={isSubmitting || !true || !isValid}
                // disabled={false || !true || !true}
                //disabled={!(false || false || true)}
                // disabled={(isSubmitting || dirty || isValid)}
                disabled={isSubmitting || !dirty || !isValid}
                loading={isSubmitting}
                floated='right'
                positive
                type='submit'
                content='Update Personal Data'
              />
            </Form.Group>

            <Header as='h3' dividing color={"brown"}>
              BLINKHUB STATUS
            </Header>
            <Form.Group widths='equal'>
              <CADTextInput
                label='Display Name'
                // placeholder="Display Name"
                name='displayName'
                disabled
              />
              <CADTextInput
                label='MSME OID'
                // placeholder="MSME OID"
                name='mSMEOID'
                disabled
              />
            </Form.Group>
            <Form.Group widths='equal'>
              <CADTextInput
                label='User GUID'
                //placeholder="User GUID"
                name='userGUID'
                disabled
              />
              <CADTextInput
                label='MSME Code'
                //placeholder="MSME Code"
                name='mSMECode'
                disabled
              />
            </Form.Group>
            <Form.Group widths='equal'>
              <CADTextInput
                name='submittionStatus'
                label='Submittion Status'
                disabled
              />
              <CADTextInput name='SubmittionDate' label='Submittion Date' />
            </Form.Group>
            <Form.Group widths='equal'>
              <CADTextInput
                name='AdminApprovalStatus'
                label='Admin Approval Status'
                disabled
              />
              <CADTextInput
                name='AdminApprovalDate'
                label='Admin Approval Date'
              />
            </Form.Group>
            <Form.Group widths='equal'>
              <CADTextInput
                name='RegistrationDate'
                label='Registration Date'
                disabled
              />
            </Form.Group>
          </FForm>
        )}
      </Formik>
    </Segment>
  );
});

import React from "react";
import { Container, Segment, Divider, Grid, Header } from "semantic-ui-react";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import "devextreme-react/text-area";
import DataGrid, {
  FilterPanel,
  FilterRow,
  SearchPanel,
  Button,
  Paging,
  Lookup,
  Popup,
  Position,
  Editing,
  Export,
  Pager,
  Grouping,
  GroupPanel,
  RowDragging,
  Column,
  RequiredRule,
  PatternRule,
  EmailRule,
  AsyncRule,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import "whatwg-fetch";
// import { store, useStore } from "../../../app/stores/store";
import systemCodeForm from "../ecoforms/systemCodeForm";
import SetupTypeGrid from "./SetupTypeGrid";
import { store, useStore } from "../../../app/stores/store";
import data from "../../../../db.json";
export const SystemCode = React.memo(() => {
  // const data = [
  //   {
  //     oid: 1,
  //     setupType: "dgf",
  //     code: "123",
  //     description: "dfs",
  //     codeOrderApplication: "dsg",
  //   },
  //   {
  //     oid: 2,
  //     setupType: "dgf",
  //     code: "123",
  //     description: "dfs",
  //     codeOrderApplication: "dsg",
  //   },
  // ];
  const [setupType, setSetupType] = React.useState(1);
  const [systemCodeData, setSystemCodeData] = React.useState("");
  // const [setupTypeData, setSetupTypeData] = React.useState("");
  const [dataLoading, setDataLoading] = React.useState(true);
  const jwt = store.commonStore.token;
  function handleErrors(response: any) {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  }
  const baseAPI = process.env.REACT_APP_API_URL; // "http://localhost:5000/api";

  function onSelectionChanged(selectedRowsData: any) {
    // console.log(selectedRowsData.selectedRowsData[0])
    let data = selectedRowsData.selectedRowsData[0].setupType;
    setSetupType(data);
  }

  React.useEffect(() => {
    let shouldLoad: boolean = false;
    const customDataSource: any = new CustomStore({
      key: "id",
      load: () => {
        return fetch(
          `${baseAPI}/EcoSystemCodes/GetAllDistinctSetupType
      `,
          {
            headers: {
              Authorization: `Bearer ${jwt}`,
            },
          }
        )
          .then(handleErrors)
          .then((response) => response.json())
          .then((response) => {
            //console.log(response.result);
            return {
              data: response.result,
              totalCount: response.count,
              summary: response.summary,
              groupCount: response.count,
            };
          });
      },
    });

    const setupTypeDataSource: any = new CustomStore({
      key: "oid",
      load: () => {
        return fetch(
          `${baseAPI}/EcoSystemCodes/GetEcoSystemCodeBySetupType/${setupType}`,
          {
            headers: {
              Authorization: `Bearer ${jwt}`,
            },
          }
        )
          .then(handleErrors)
          .then((response) => response.json())
          .then((response) => {
            // console.log(response.result.data)
            return {
              data: response.result.data,
              totalCount: response.count,
              summary: response.summary,
              groupCount: response.count,
            };
          });
      },
    });

    if (!shouldLoad) {
      setSystemCodeData(customDataSource);
      // setSetupTypeData(setupTypeDataSource);
      setDataLoading(false);
    }

    return () => {
      shouldLoad = true;
    };
  }, [setupType, jwt, baseAPI]);

  return (
    <Segment>
      <h1>SystemCode</h1>
      <div className="ui warning message">
        <div className="header">Manage System Code.</div>
        <p>Create and manage System Code.</p>
      </div>
      <div className="ui divider" title="List of SystemCode"></div>

      <Grid columns="equal">
        <Grid.Column width={6}>
          <div>
            <DataGrid
              id="[$GridID]"
              dataSource={systemCodeData}
              className="dx-datagrid dx-row-alt"
              hoverStateEnabled={true}
              onSelectionChanged={onSelectionChanged}
              columnAutoWidth={true}
              showBorders={true}
              selection={{ mode: "single" }}

              // onSelectionChanged={onSelectionChanged}
            >
              <Paging defaultPageSize={20} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[2, 10, 20, 50, 100]}
                showInfo={true}
              />
              <Grouping contextMenuEnabled={true} />
              <GroupPanel visible={true} />
              <FilterPanel visible={true} />
              <SearchPanel visible={true} />
              <FilterRow visible={true} />
              <Export
                enabled={true}
                fileName={"SystemCodes"}
                allowExportSelectedData={true}
              />
              <Editing
                mode="popup"
                useIcons={true}
                allowUpdating={true}
                allowDeleting={true}
                allowAdding={true}
              />

              <Column dataField="setupType" allowEditing={false}></Column>
              <Column dataField="code">
                <RequiredRule />
              </Column>
              <Column dataField="description">
                <RequiredRule />
              </Column>
              <Column dataField="codeOrderApplication" allowEditing={true}>
                <RequiredRule message="Code Order Application is required and is a number" />
              </Column>
            </DataGrid>
          </div>
        </Grid.Column>
        <Grid.Column>
          <div>
            <Segment>
              <Header as="h4">{setSetupType}</Header>
              <SetupTypeGrid
                // initialData={!dataLoading ? setSetupTypeData : {}}
                setupType={setupType}
              />
            </Segment>
          </div>
        </Grid.Column>
      </Grid>
    </Segment>
  );
});

import React from "react";
import { Grid, Table } from "semantic-ui-react";
import classes from "./coster.module.css";

type Props = {};

const SecondaryProducts = (props: Props) => {
  return (
    <section>
      <div className=''>
        <Grid className={classes.border}>
          <Grid.Row columns={1} className={classes.row}>
            <Grid.Column className={classes.column}>
              <header className={classes.header}>Secondary Products</header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1} className={classes.row}>
            <Grid.Column className={classes.column}>
              <Table celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell className={classes.table_header_3}>
                      Description
                    </Table.HeaderCell>
                    <Table.HeaderCell className={classes.table_header_3}>
                      Quantity
                    </Table.HeaderCell>
                    <Table.HeaderCell className={classes.table_header_3}>
                      UOM
                    </Table.HeaderCell>
                    <Table.HeaderCell className={classes.table_header_3}>
                      Unit Price
                    </Table.HeaderCell>
                    <Table.HeaderCell className={classes.table_header_3}>
                      Total Amount
                    </Table.HeaderCell>
                    <Table.HeaderCell className={classes.table_header_3}>
                      Comment
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <TableRow
                    description={""}
                    quantity={"1.00"}
                    uom={"Pcs"}
                    unitPrice={"5,000.00"}
                    totalAmount={"5,000.00"}
                    comment={""}
                  />
                  <TableRow
                    description={""}
                    quantity={""}
                    uom={"Pcs"}
                    unitPrice={"-"}
                    totalAmount={"-"}
                    comment={""}
                  />
                  <TableRow
                    description={""}
                    quantity={""}
                    uom={"Pcs"}
                    unitPrice={"-"}
                    totalAmount={"-"}
                    comment={""}
                  />
                  <TableRow
                    description={""}
                    quantity={""}
                    uom={"Pcs"}
                    unitPrice={""}
                    totalAmount={"-"}
                    comment={""}
                  />
                </Table.Body>
                <Table.Footer className={classes.table_footer_1}>
                  <Table.Row>
                    <Table.HeaderCell colSpan={2} textAlign='right'>
                      -
                    </Table.HeaderCell>
                    <Table.HeaderCell colSpan={4}>
                      Total Amount
                      <span className=''>380,000.00</span>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Footer>
              </Table>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </section>
  );
};

interface ITableRow {
  description: string;
  quantity: string;
  uom: string;
  unitPrice: string;
  totalAmount: string;
  comment: string;
}

export const TableRow: React.FC<ITableRow> = ({
  description,
  quantity,
  uom,
  unitPrice,
  totalAmount,
  comment,
}) => {
  return (
    <Table.Row className={`${classes.row_border_4}`}>
      <Table.Cell textAlign='left'>{description}</Table.Cell>
      <Table.Cell textAlign='left'>{quantity}</Table.Cell>
      <Table.Cell textAlign='left'>{uom}</Table.Cell>
      <Table.Cell textAlign='left'>{unitPrice}</Table.Cell>
      <Table.Cell textAlign='left' className={classes.readonly_cell_1}>
        {totalAmount}
      </Table.Cell>
      <Table.Cell textAlign='left'>{comment}</Table.Cell>
    </Table.Row>
  );
};

export default SecondaryProducts;

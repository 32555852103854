import React from "react";
import { Header, Segment } from "semantic-ui-react";
import DataGrid, {
  FilterPanel,
  FilterRow,
  SearchPanel,
  Button,
  Paging,
  Lookup,
  // Popup,
  Position,
  Editing,
  Export,
  Pager,
  Grouping,
  GroupPanel,
  RowDragging,
  Column,
  RequiredRule,
  // PatternRule,
  // EmailRule,
  // AsyncRule,
} from "devextreme-react/data-grid";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import "devextreme-react/text-area";
import { store, useStore } from "../../app/stores/store";

export const Messaging = () => {
  const dataSource = [
    {
      oid: 0,
      Subject: "Reset Password",
      Category: "Security",
      MessageBody: "Please reset your password by clicking here",
    },
    {
      oid: 1,
      Subject: "Inspection Notification",
      Category: "Training",
      MessageBody: "This is to inform you that our team will contact you.",
    },
  ];
  const baseAPI = process.env.REACT_APP_API_URL; // "http://localhost:5000/api";

  const jwt = store.commonStore.token;
  function handleErrors(response: any) {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  }

  return (
    <Segment>
      <Header>Messaging</Header>

      <div className="ui warning message">
        <div className="header">Manage Messaging.</div>
        <p>Create and manage Messaging.</p>
      </div>
      <div className="ui divider" title="List of Messaging"></div>
      <div>
        <DataGrid
          id="grdResources"
          dataSource={dataSource}
          rowAlternationEnabled={true}
          hoverStateEnabled={true}
          columnAutoWidth={true}
          activeStateEnabled={true}
          columnResizingMode="widget"
          allowColumnResizing={true}
          // onToolbarPreparing={onToolbarPreparing}
          columnHidingEnabled={true}>
          <RowDragging allowReordering={true} />
          <FilterPanel visible={true} />
          <SearchPanel visible={true} />
          <FilterRow visible={true} />
          <Paging defaultPageSize={10} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={[10, 20, 50, 100]}
            showInfo={true}
          />
          <Grouping contextMenuEnabled={true} />
          <GroupPanel visible={true} />
          <Editing
            mode="popup"
            useIcons={true}
            allowUpdating={true}
            allowDeleting={true}
            allowAdding={true}>
            {" "}
          </Editing>

          {/* <Popup
            title="Resources"
            showTitle={true}
            width={40}
            height={30}></Popup> */}

          <Export
            enabled={true}
            fileName="Messaging"
            allowExportSelectedData={true}
          />

          {/* <Column type="buttons">
            <Button text="View" onClick={handleView} cssClass={"edit-btn"} />
          </Column> */}

          <Column
            dataField="messageOID"
            // allowEditing={false}
            visible={false}></Column>
          <Column dataField="Subject">
            <RequiredRule />
          </Column>
          <Column dataField="Category">
            <RequiredRule />
          </Column>
          <Column dataField="MessageBody">
            <RequiredRule />
          </Column>
        </DataGrid>
        {/* <Popup
          // title="Create"
          // showTitle={true}
          visible={onVisible}
          showCloseButton={false}
          width={700}
          minHeight={"auto"}
          height={600}>
          <CountryPopup
            setOnVisible={() => setOnVisible(false)}
            countryOID={0}
          />
        </Popup> */}
      </div>
    </Segment>
  );
};

//*************************************************************
// PROJECT NAME : Blinh Hub Network
// CLASS        : MSMEBlinkActivitiesActors
// DATE CREATED : 11 February 2022
// TIME CREATED : 08:09:27
// DEVELOPER    : Ibinaiye Wale Kayode
//*************************************************************
import React, { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Formik } from "formik";
import * as Yup from "yup";
import { MSMEBlinkActivitiesActorFormValues } from "../../../app/models/MSMEBlinkActivitiesActors";
import { useStore } from "../../../app/stores/store";
import MyTextInput from "../../../app/common/form/MyTextInput";
import { Button, Form, Grid, Message, Modal } from "semantic-ui-react";
import DXLoadingComponent from "../../../app/layout/DXLoadingComponent";
import { AxiosError } from "axios";
import notify from "devextreme/ui/notify";
import DXDateBox from "../../../app/common/form/DXDateBox";
import DxSelectBox from "../../../app/common/form/DxSelectBox";

type Props = {
  rowID: number;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
};

const ActivitiesActorsForm: React.FC<Props> = ({ rowID, setOpen, open }) => {
  const [initialValues, setInitialValues] =
    useState<MSMEBlinkActivitiesActorFormValues>(
      new MSMEBlinkActivitiesActorFormValues()
    );
  const { activitiesActorsStore } = useStore();
  const {
    createActivitiesActors,
    updateActivitiesActors,
    loadActivitiesActors,
    loadingInitial,
    setLoadingInitial,
  } = activitiesActorsStore;

  const validationSchema = Yup.object({
    blinkActorOID: Yup.string().required("The BlinkActorOID Is required"),
    quarter: Yup.string().required("The Quarter Is required"),
    sNo: Yup.string().required("The SNo Is required"),
    supportActorName: Yup.string().required("The SupportActorName Is required"),
    setupType: Yup.string().required("The SetupType Is required"),
    classification: Yup.string().required("The Classification Is required"),
    contactDetails: Yup.string().required("The ContactDetails Is required"),
    telephoneNumber: Yup.string().required("The TelephoneNumber Is required"),
    supportFromPIND: Yup.string().required("The SupportFromPIND Is required"),
    engagement: Yup.string().required("The Engagement Is required"),
    engagementDate: Yup.string().required("The EngagementDate Is required"),
    typeOfActivitiy: Yup.string().required("The TypeOfActivity Is required"),
    activityDate: Yup.string().required("The ActivityDate Is required"),
    typeOfServiceRecipient: Yup.string().required(
      "The TypeOfServiceRecipient Is required"
    ),
    numberOfServiceRecipient: Yup.string().required(
      "The NumberOfServiceRecipient Is required"
    ),
    stateOfService: Yup.string().required("The StateOfService Is required"),
    lGAOfService: Yup.string().required("The LGAOfService Is required"),
    communityOfService: Yup.string().required(
      "The CommunityOfService Is required"
    ),
    longitude: Yup.string().required("The Longitude Is required"),
    latitude: Yup.string().required("The Latitude Is required"),
  });

  const handleBack = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  useEffect(() => {
    if (!rowID) return setLoadingInitial(false);
    loadActivitiesActors(rowID)
      .then((res: any) => {
        const response = res.result[0];
        setInitialValues(new MSMEBlinkActivitiesActorFormValues(response));
      })
      .catch((err: AxiosError) => {
        notify("Bad Request", "error", 3000);
        handleBack();
      });
  }, [rowID, loadActivitiesActors, setLoadingInitial, handleBack]);

  const handleSubmit = (values: MSMEBlinkActivitiesActorFormValues) => {
    try {
      if (rowID) {
        const newMSMEBlinkActivitiesActors = {
          ...values,
        };
        updateActivitiesActors(newMSMEBlinkActivitiesActors).then(() => {
          handleBack();
        });
      } else {
        createActivitiesActors(values).then(() => {
          handleBack();
        });
      }
    } catch (error) {
      notify("Server Error", "error", 3000);
    }
  };

  return (
    <div>
      <Modal open={open}>
        <Modal.Header>Activity Actors</Modal.Header>

        <Message color='brown' size='mini'>
          <Message.Header>Manage Activities Actors</Message.Header>
          <p> You can create and manage a activities actors. </p>
        </Message>
        <Modal.Content>
          {loadingInitial ? (
            <DXLoadingComponent />
          ) : (
            <Formik
              validationSchema={validationSchema}
              enableReinitialize
              initialValues={initialValues}
              onSubmit={(values) => handleSubmit(values)}>
              {({ handleSubmit, isValid, isSubmitting, dirty }) => (
                <Form
                  className='ui form'
                  autoComplete='off'
                  onSubmit={handleSubmit}>
                  <Grid>
                    <Grid.Row columns={3}>
                      <Grid.Column>
                        <MyTextInput
                          placeholder='BlinkActorOID'
                          label='Blink Actor OID'
                          name='blinkActorOID'
                          disabled
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <DxSelectBox
                          // placeholder='Quarter'
                          label='Quarter'
                          name='quarter'
                          dataSource={[]}
                          valueExpr={""}
                          displayExpr={""}
                        />
                      </Grid.Column>

                      <Grid.Column>
                        <MyTextInput
                          placeholder='SupportActorName'
                          label='Support Actor Name'
                          name='supportActorName'
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <DxSelectBox
                          label='Setup Type'
                          name='setupType'
                          dataSource={[]}
                          valueExpr={""}
                          displayExpr={""}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <DxSelectBox
                          placeholder='Classification'
                          label='Classification'
                          name='classification'
                          dataSource={[]}
                          valueExpr={""}
                          displayExpr={""}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <MyTextInput
                          placeholder='ContactDetails'
                          label='ContactDetails'
                          name='contactDetails'
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <MyTextInput
                          placeholder='TelephoneNumber'
                          label='Telephone Number'
                          name='telephoneNumber'
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <MyTextInput
                          placeholder='SupportFromPIND'
                          label='Support From PIND'
                          name='supportFromPIND'
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <MyTextInput
                          placeholder='Engagement'
                          label='Engagement'
                          name='engagement'
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <DXDateBox
                          placeholder='EngagementDate'
                          label='Engagement Date'
                          name='engagementDate'
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <DXDateBox
                          placeholder='ActivityDate'
                          label='Activity Date'
                          name='activityDate'
                        />
                      </Grid.Column>

                      <Grid.Column>
                        <DxSelectBox
                          label='Type Of Service Recipient'
                          name='typeOfServiceRecipient'
                          dataSource={[]}
                          valueExpr={""}
                          displayExpr={""}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <DxSelectBox
                          placeholder='TypeOfActivity'
                          label='Type Of Activity'
                          name='typeOfActivitiy'
                          dataSource={[]}
                          valueExpr={""}
                          displayExpr={""}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <MyTextInput
                          placeholder='NumberOfServiceRecipient'
                          label='Number Of Service Recipient'
                          name='numberOfServiceRecipient'
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <DxSelectBox
                          label='State Of Service'
                          name='stateOfService'
                          dataSource={[]}
                          valueExpr={""}
                          displayExpr={""}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <DxSelectBox
                          label='LGA Of Service'
                          name='lGAOfService'
                          dataSource={[]}
                          valueExpr={""}
                          displayExpr={""}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <MyTextInput
                          placeholder='CommunityOfService'
                          label='Community Of Service'
                          name='communityOfService'
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <MyTextInput
                          placeholder='Longitude'
                          label='Longitude'
                          name='longitude'
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <MyTextInput
                          placeholder='Latitude'
                          label='Latitude'
                          name='latitude'
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>

                  <Modal.Actions
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      padding: ".5em",
                    }}>
                    <Button
                      type='button'
                      color='black'
                      onClick={() => setOpen(false)}>
                      Cancel
                    </Button>
                    <Button
                      disabled={isSubmitting || !dirty || !isValid}
                      content='Submit'
                      loading={isSubmitting}
                      type='submit'
                      labelPosition='right'
                      icon='checkmark'
                      positive
                    />
                  </Modal.Actions>
                </Form>
              )}
            </Formik>
          )}
        </Modal.Content>
      </Modal>
    </div>
  );
};

export default observer(ActivitiesActorsForm);

import React, { useState } from "react";
import { Formik, Form } from "formik";
import MyTextInput from "../../../app/common/form/MyTextInput";

import MyDisabledInput from "../../../app/common/form/MyDisbabledInput";

import { Button, Icon } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { store, useStore } from "../../../app/stores/store";

import {
  RoleDefinitionModel,
  RoleDefinitionFormValues,
} from "../../../app/models/roleDefinition";

// import handleFormSubmit from "../ecoforms/CountryForm";
// import handleFormSubmit from "./Country";
export default observer(function CountryPopup({
  setOnVisible,
  roleOID,
}: {
  roleOID: number;
  setOnVisible: Function;
}) {
  const navigate = useNavigate();
  const { roleDefinitionStore } = useStore();
  const {
    createRoleDefinitionModel,
    updateRoleDefinitionModel,
    loadRoleDefinitionModel,
    deleteRoleDefinitionModel,
    loadingInitial,
  } = roleDefinitionStore;
  const baseAPI = process.env.REACT_APP_API_URL; // "http://localhost:5000/api";
  const jwt = store.commonStore.token;
  const [roleDefinition, setRoleDefinition] = useState<
    RoleDefinitionModel | any
  >([]);
  const [roleDefinitionModel, setRoleDefinitionModel] =
    useState<RoleDefinitionFormValues>(new RoleDefinitionFormValues());
  const validationSchema = Yup.object({
    roleOID: Yup.string(),
    name: Yup.string().required("The name is required"),
    description: Yup.string().required("The description is required"),
    //date: Yup.string().required("Date is required").nullable(),
    rolePriority: Yup.string().required("The  rolePriority is required"),
    concurrencyStamp: Yup.string().required(
      "The  concurrencyStamp is required"
    ),
  });

  function handleErrors(response: any) {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  }

  const handleFormSubmit = (values: any) => {
    // console.log(values);
    fetch(`${baseAPI}/ApplicationRole/`, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        Authorization: `Bearer ${jwt}`,
        "Content-Type": "application/json",
      },
    }).then((response) => {
      response.json().then((response) => {
        // console.log(response);
        setOnVisible();

        return {
          data: response.result.data,
        };
      });
    });
  };
  return (
    <div>
      <Icon
        name="arrow alternate circle left"
        onClick={() => setOnVisible()}
        floated="left"
        size="big"
        type="button"
      />
      <h1 style={{ marginTop: "-30px", marginLeft: "35px" }}>
        Country Details
      </h1>
      <div className="ui warning message">
        <div className="header">Create, edit and delete Country.</div>
        <p>
          You can manage resources from this page. You can create, edit, delete
          a country.
        </p>
      </div>
      <Formik
        validationSchema={validationSchema}
        enableReinitialize
        initialValues={roleDefinitionModel}
        onSubmit={(values) => {
          handleFormSubmit(values);
          // setOnVisible();
        }}>
        {({ handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit} className="ui form" autoComplete="off">
            <MyDisabledInput
              placeholder="roleOID"
              name="roleOID"
              label="roleOID"
            />
            <MyTextInput name="name" placeholder="Name" label="Name" />
            <MyTextInput
              name="description"
              placeholder="Description"
              // rows={3}
              label="Description"
            />
            <MyTextInput
              placeholder="rolePriority"
              name="rolePriority"
              // rows={3}
              label="Role Priority"
            />
            <MyTextInput
              placeholder="concurrency Stamp"
              name="concurrencyStamp"
              // rows={3}
              label="Concurrency Stamp"
            />

            {/* <MyDateInput
              placeholderText="Date"
              name="date"
              showTimeSelect
              timeCaption="time"
              dateFormat="MMMM d, yyyy h:mm aa"
            /> */}
            {/* <Header content="Location Details" sub color="teal" /> */}

            <Button
              as={Link}
              onClick={() => setOnVisible()}
              floated="right"
              type="button"
              content="Cancel"
            />

            <Button
              loading={isSubmitting}
              floated="right"
              positive
              type="submit"
              content="Submit"
            />
          </Form>
        )}
      </Formik>
    </div>

    // </Segment>
  );
});

import { createContext, useContext } from "react";
// import makeInspectable from "mobx-devtools-mst";

import ActivityStore from "./activityStore";
import CommentStore from "./commentStore";
import CommonStore from "./commonStore";
import ModalStore from "./modalStore";
import ProfileStore from "./profileStore";
import UserStore from "./userStore";
import ResourceStore from "./resourceStore";
import HelpersOnlineStore from "./helpersOnlineStore";
import PersonalDataStore from "./personalDataStore";
import MsmeBusinessInfoStore from "./msmeBusinessInfoStore";
import CosterCommoditysStore from "./costerCommoditysStore";
import ToolBLinkCosterExpenseStore from "./CosterExpenseStore";
import ActivityAttendeesStore from "./ActivityAttendeesStore";
import MSMEBlinkActivitiesStore from "./MSMEBlinkActivitiesStore";
import ActivitiesActorsStore from "./MSMEBlinkActivitiesActorsStore";
import MSMEBlinkWeeklyUpdateStore from "./MSMEBlinkWeeklyUpdateStore";
import MSMEBlinkActivityFeedbackStore from "./MSMEBlinkActivityFeedbackStore";
import MSMEBlinkActivitiesChallengesStore from "./MSMEBlinkActivitiesChallengesStore";
import SystemCodeStores from "./SystemCodeStores";

import ApplicationStore from "./applicationStore";
import RoleDefinitionStore from "./roleDefinitionStore";
import LgaStore from "./lgaStore";
import StateStore from "./stateStore";
import BusinessAreaStore from "./businessAreaStore";
import BusinessSectorStore from "./businessSectorStore";
import SubscriberStore from "./subscribersStore";
import AssignTaskStore from "./assignTaskStore";

import CountryStore from "./countryStore";
import TaskStore from "./task";
// import SystemCodeStore from "./systemCodeStore";
import CompanyStore from "./companyStore";
import FeedbackStore from "./feedbackStore";
import AssignRoleStore from "./assignRoleStore";

interface Store {
  activityStore: ActivityStore;
  commonStore: CommonStore;
  userStore: UserStore;
  modalStore: ModalStore;
  profileStore: ProfileStore;
  commentStore: CommentStore;
  resourceStore: ResourceStore;
  countryStore: CountryStore;
  applicationStore: ApplicationStore;
  roleDefinitionStore: RoleDefinitionStore;
  lgaStore: LgaStore;
  stateStore: StateStore;
  businessAreaStore: BusinessAreaStore;
  businessSectorStore: BusinessSectorStore;
  subscriberStore: SubscriberStore;
  assignTaskStore: AssignTaskStore;
  // systemCodeStore: SystemCodeStore;
  taskStore: TaskStore;
  companyStore: CompanyStore;
  helpersOnlineStore: HelpersOnlineStore;
  personalDataStore: PersonalDataStore;
  msmeBusinessInfoStore: MsmeBusinessInfoStore;
  feedbackStore: FeedbackStore;
  assignRoleStore: AssignRoleStore;
  costerCommoditysStore: CosterCommoditysStore;
  toolBLinkCosterExpenseStore: ToolBLinkCosterExpenseStore;
  activityAttendeesStore: ActivityAttendeesStore;
  MSMEBlinkActivitiesStore: MSMEBlinkActivitiesStore;
  activitiesActorsStore: ActivitiesActorsStore;
  MSMEBlinkWeeklyUpdateStore: MSMEBlinkWeeklyUpdateStore;
  MSMEBlinkActivityFeedbackStore: MSMEBlinkActivityFeedbackStore;
  MSMEBlinkActivitiesChallengesStore: MSMEBlinkActivitiesChallengesStore;
  SystemCodeStores: SystemCodeStores;
}

export const store: Store = {
  activityStore: new ActivityStore(),
  commonStore: new CommonStore(),
  userStore: new UserStore(),
  modalStore: new ModalStore(),
  profileStore: new ProfileStore(),
  commentStore: new CommentStore(),
  resourceStore: new ResourceStore(),
  countryStore: new CountryStore(),
  applicationStore: new ApplicationStore(),
  roleDefinitionStore: new RoleDefinitionStore(),
  lgaStore: new LgaStore(),
  stateStore: new StateStore(),
  businessAreaStore: new BusinessAreaStore(),
  businessSectorStore: new BusinessSectorStore(),
  subscriberStore: new SubscriberStore(),
  assignTaskStore: new AssignTaskStore(),
  taskStore: new TaskStore(),
  // systemCodeStore: new SystemCodeStore(),
  companyStore: new CompanyStore(),
  helpersOnlineStore: new HelpersOnlineStore(),
  personalDataStore: new PersonalDataStore(),
  msmeBusinessInfoStore: new MsmeBusinessInfoStore(),
  feedbackStore: new FeedbackStore(),
  assignRoleStore: new AssignRoleStore(),
  costerCommoditysStore: new CosterCommoditysStore(),
  toolBLinkCosterExpenseStore: new ToolBLinkCosterExpenseStore(),
  activityAttendeesStore: new ActivityAttendeesStore(),
  MSMEBlinkActivitiesStore: new MSMEBlinkActivitiesStore(),
  activitiesActorsStore: new ActivitiesActorsStore(),
  MSMEBlinkWeeklyUpdateStore: new MSMEBlinkWeeklyUpdateStore(),
  MSMEBlinkActivityFeedbackStore: new MSMEBlinkActivityFeedbackStore(),
  MSMEBlinkActivitiesChallengesStore: new MSMEBlinkActivitiesChallengesStore(),
  SystemCodeStores: new SystemCodeStores(),
};

export const StoreContext = createContext(store);

export function useStore() {
  return useContext(StoreContext);
}

// ! Make Mobx dev-tools active
// makeInspectable(StoreContext);
